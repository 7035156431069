import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

export abstract class IdpBaseService {

    tokenInfo;

    constructor(protected http: HttpClient,
                protected authService: AuthService) {
    }

    public abstract renewToken(scope?: any): Observable<any>;
    public abstract getRoomOrGroupList(query, type, domains, groupType): Observable<any>;
    public abstract getRoomOrGroupListByEmail(query, type, domains, groupType): Observable<any>;
    public abstract getUserOrGroupList(query, type, domains, groupType): Observable<any>;
    public abstract getUserOrGroupListByEmail(query, type, domains, groupType): Observable<any>;
    public abstract getWorkspaceGroupMembers(buildingId, domains, buildingName?): Promise<any>;
    public abstract getGroupMembers(groupId, domains): Promise<any>;
    public abstract searchUsers(userToSearch: string, domains): Observable<any>;
    public abstract searchUsersByEmail(userToSearch: string, domains): Observable<any>;
    public abstract getLoggedInUserDetails(): Observable<any>;
    public abstract getDomains();
    public abstract getRoomListDetails(resourceId): Observable<any>;
    public abstract getIdpAccessToken(googleScope?);
    public abstract getIdpToken(): Observable<any>;
    public abstract getAuthPermissions(googleScope?, scopeCheck?);
    public abstract checkGrantReadWorkspaceAndGroup(): Observable<[boolean, boolean]>;
    public abstract checkGrantReadUsersAndGroup(): Observable<[boolean, boolean]>;
    public abstract checkGrantReadUsers(): Observable<boolean>;
    public abstract checkGrantReadUserGroups(): Observable<boolean>;
    public abstract findMissingPermissionsInAzure(googleScope?, scopeCheck?);
    public abstract checkOffice365Admin(googleScope ?, scopeCheck ?);
    public abstract getCalendarAccountResources();
    public abstract RenewIdpAccessToken(scope?: any): Observable<any>;
}
