import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'lib-AuthN',
    template: `
    <p>
      auth-n works!
    </p>
  `,
    styles: [
    ]
})
export class AuthNComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
