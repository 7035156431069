import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { BaseComponent } from '../../shared/components/base.component';
import { SCPRouterLinks } from '../../shared/models/router-model';

export interface IMenuItem {
  title: string;
  viewLink: SCPRouterLinks | string;
  icon: string;
  isVisible: boolean;
  children?: IMenuItem[];

  isOpen?: boolean;
}

@Component({
  selector: 'lib-jacloud-layout',
  templateUrl: './jacloud-layout.component.html',
  styleUrls: ['./jacloud-layout.component.scss'],
})
export class JacloudLayoutComponent extends BaseComponent implements OnInit, OnDestroy {
  @HostBinding('class.scp_theme') applySCPThemeClass = true;

  isMenuExpanded = true;
  menuList: IMenuItem[] = [];

  constructor(private router: Router) {
    super();

    const isAdmin = this.appService.isSCPAdminUser;

    this.menuList = [
      { title: 'scp.common.dashboard', viewLink: SCPRouterLinks.dashboard, icon: 'space_dashboard', isVisible: isAdmin },
      { title: 'scp.common.users', viewLink: SCPRouterLinks.users, icon: 'supervised_user_circle', isVisible: isAdmin },
      // { title: 'scp.common.departments', viewLink: SCPRouterLinks.departments, icon: 'business', isVisible: isAdmin },
      { title: 'scp.common.devices', viewLink: SCPRouterLinks.devices, icon: 'print', isVisible: isAdmin },
      { title: 'scp.common.jobs', viewLink: SCPRouterLinks.jobs, icon: 'work', isVisible: true }, // Visible for ALL users
      { title: 'scp.common.reports', viewLink: SCPRouterLinks.reports, icon: 'assessment', isVisible: isAdmin },
      {
        title: 'scp.common.settings',
        viewLink: SCPRouterLinks.security,
        icon: 'settings',
        isVisible: true,
        children: [
          { title: 'scp.common.security', viewLink: SCPRouterLinks.security, icon: 'lock', isVisible: true },
          { title: 'scp.common.adminusers', viewLink: SCPRouterLinks.adminUsers, icon: 'supervised_user_circle', isVisible: isAdmin },
          { title: 'scp.common.tenant', viewLink: SCPRouterLinks.tenant, icon: 'apartment', isVisible: isAdmin },
          //hide the domains page for SCP based on SHPLT-7880 ticket
        //   { title: 'scp.common.supporteddomains', viewLink: SCPRouterLinks.supportedDomains, icon: 'dns', isVisible: isAdmin },
        ],
      },
      { title: 'scp.common.subscriptions', viewLink: SCPRouterLinks.subscriptions, icon: 'subscriptions', isVisible: isAdmin },
      {
        title: 'scp.common.system',
        viewLink: SCPRouterLinks.adminLogs,
        icon: 'system_update_alt',
        isVisible: isAdmin,
        children: [
          { title: 'scp.common.adminlog', viewLink: SCPRouterLinks.adminLogs, icon: 'dvr', isVisible: isAdmin },
          { title: 'scp.common.systemlog', viewLink: SCPRouterLinks.systemLogs, icon: 'dvr', isVisible: isAdmin },
        ],
      },
    ];

    this.addOrRemoveMainContainerID();
    this.updateMenuItems(this.menuList);
  }

  ngOnInit() {
    this.router.events
      .pipe(
        this.takeUntilDestroyed(),
        filter((evt) => evt instanceof NavigationEnd)
      )
      .subscribe(() => this.updateMenuItems(this.menuList));
  }

  // NOTE: This recursive logic allows for multi level/deep sub menus
  updateMenuItems(menuItems: IMenuItem[]) {
    for (const menuItem of menuItems.values()) {
      if (menuItem.children) {
        menuItem.isOpen = JSON.stringify(menuItem.children).includes(this.router.url);

        this.updateMenuItems(menuItem.children);
      }
    }
  }

  toggleMenu() {
    this.isMenuExpanded = !this.isMenuExpanded;
  }

  toggleSubMenu($evt: Event, menuItem) {
    $evt.stopPropagation();

    menuItem.isOpen = !menuItem.isOpen;
  }

  // HACK: For Firefox/Safari left hand menu issue
  addOrRemoveMainContainerID(shouldRemoveId = false) {
    const el = document.querySelector('section.main-container');

    if (shouldRemoveId) {
      return el.removeAttribute('id');
    }

    return el.setAttribute('id', 'scp_main_container');
  }

  ngOnDestroy() {
    this.addOrRemoveMainContainerID(true);

    super.ngOnDestroy();
  }
}
