import { environment } from './../../environments/environment';

const langList = {
    US: {
        en: {
            privacyUrl: 'https://business.sharpusa.com/synappx-support/about/privacy',
            termsUseUrl: 'https://business.sharpusa.com/synappx-support/about/termsofuse',
            helpUrl: 'https://business.sharpusa.com/synappx-support',
            supportType: 'https://business.sharpusa.com/synappx-support/about',
            gsuiteSupportUrl: 'https://business.sharpusa.com/synappx-support/g-suite-api-scope'
        }
    },

    EU: {
        da: {
            privacyUrl: 'https://www.sharp.dk/synappx/privacy',
            termsUseUrl: 'https://www.sharp.dk/synappx/terms',
            helpUrl: 'https://sharp.dk/synappx/support/admin',
            supportType: 'https://www.sharp.dk/synappx/support',
            gsuiteSupportUrl: 'https://support.google.com/a/answer/2406043',
            supportSiteUrl: 'https://synappx-support.sharp.eu/en/synappx-meeting/admin-support/how-it-works/choose-provider'
        },
        de: {
            privacyUrl: 'https://www.sharp.de/synappx/datenschutz',
            termsUseUrl: 'https://www.sharp.de/synappx/nutzungsbedingungen',
            helpUrl: 'https://sharp.de/synappx/support/admin',
            supportType: 'https://www.sharp.de/synappx/support',
            gsuiteSupportUrl: 'https://support.google.com/a/answer/2406043',
            supportSiteUrl: 'https://synappx-support.sharp.eu/en/synappx-meeting/admin-support/how-it-works/choose-provider'
        },
        en: {
            privacyUrl: 'https://www.sharp.co.uk/synappx/privacy',
            termsUseUrl: 'https://www.sharp.co.uk/synappx/terms',
            helpUrl: 'https://sharp.co.uk/synappx/support/admin',
            supportType: 'https://www.sharp.co.uk/synappx/support',
            gsuiteSupportUrl: 'https://support.google.com/a/answer/2406043',
            supportSiteUrl: 'https://synappx-support.sharp.eu/en/synappx-meeting/admin-support/how-it-works/choose-provider'
        },
        es: {
            privacyUrl: 'https://www.sharp.es/synappx/privacy',
            termsUseUrl: 'https://www.sharp.es/synappx/terms',
            helpUrl: 'https://sharp.es/synappx/support/admin',
            supportType: 'https://www.sharp.es/synappx/support',
            gsuiteSupportUrl: 'https://support.google.com/a/answer/2406043',
            supportSiteUrl: 'https://synappx-support.sharp.eu/en/synappx-meeting/admin-support/how-it-works/choose-provider'
        },
        fi: {
            privacyUrl: 'https://www.sharp.fi/synappx/privacy',
            termsUseUrl: 'https://www.sharp.fi/synappx/terms',
            helpUrl: 'https://sharp.fi/synappx/support/admin',
            supportType: 'https://www.sharp.fi/synappx/support',
            gsuiteSupportUrl: 'https://support.google.com/a/answer/2406043',
            supportSiteUrl: 'https://synappx-support.sharp.eu/en/synappx-meeting/admin-support/how-it-works/choose-provider'
        },
        fr: {
            privacyUrl: 'https://www.sharp.fr/synappx/privacy',
            termsUseUrl: 'https://www.sharp.fr/synappx/terms',
            helpUrl: 'https://sharp.fr/synappx/support/admin',
            supportType: 'https://www.sharp.fr/synappx/support',
            gsuiteSupportUrl: 'https://support.google.com/a/answer/2406043',
            supportSiteUrl: 'https://synappx-support.sharp.eu/en/synappx-meeting/admin-support/how-it-works/choose-provider'
        },
        it: {
            privacyUrl: 'https://www.sharp.it/synappx/privacy',
            termsUseUrl: 'https://www.sharp.it/synappx/terms',
            helpUrl: 'https://sharp.it/synappx/support/admin',
            supportType: 'https://www.sharp.it/synappx/support',
            gsuiteSupportUrl: 'https://support.google.com/a/answer/2406043',
            supportSiteUrl: 'https://synappx-support.sharp.eu/en/synappx-meeting/admin-support/how-it-works/choose-provider'
        },
        nl: {
            privacyUrl: 'https://www.sharp.nl/synappx/privacybeleid',
            termsUseUrl: 'https://www.sharp.nl/synappx/voorwaarden',
            helpUrl: 'https://sharp.nl/synappx/support/admin',
            supportType: 'https://www.sharp.nl/synappx/support',
            gsuiteSupportUrl: 'https://support.google.com/a/answer/2406043',
            supportSiteUrl: 'https://synappx-support.sharp.eu/en/synappx-meeting/admin-support/how-it-works/choose-provider'
        },
        no: {
            privacyUrl: 'https://www.sharp.no/synappx/privacy',
            termsUseUrl: 'https://www.sharp.no/synappx/terms',
            helpUrl: 'https://sharp.no/synappx/support/admin',
            supportType: 'https://www.sharp.no/synappx/support',
            gsuiteSupportUrl: 'https://support.google.com/a/answer/2406043',
            supportSiteUrl: 'https://synappx-support.sharp.eu/en/synappx-meeting/admin-support/how-it-works/choose-provider'
        },
        pl: {
            privacyUrl: 'https://www.sharp.pl/synappx/polityka-prywatnosci',
            termsUseUrl: 'https://www.sharp.pl/synappx/warunki-korzystania',
            helpUrl: 'https://sharp.pl/synappx/wsparcie/admin',
            supportType: 'https://www.sharp.pl/synappx/wsparcie',
            gsuiteSupportUrl: 'https://support.google.com/a/answer/2406043',
            supportSiteUrl: 'https://synappx-support.sharp.eu/en/synappx-meeting/admin-support/how-it-works/choose-provider'
        },
        sv: {
            privacyUrl: 'https://www.sharp.se/synappx/privacy',
            termsUseUrl: 'https://www.sharp.se/synappx/terms',
            helpUrl: 'https://sharp.se/synappx/support/admin',
            supportType: 'https://www.sharp.se/synappx/support',
            gsuiteSupportUrl: 'https://support.google.com/a/answer/2406043',
            supportSiteUrl: 'https://synappx-support.sharp.eu/en/synappx-meeting/admin-support/how-it-works/choose-provider'
        }

    }
};

export function stackUrls(lang) {
    const region = (environment.stackRegion in langList) ? environment.stackRegion : 'US';
    const regionLanguages = langList[region];
    lang = (lang in regionLanguages) ? lang : 'en';
    return {
        privacyUrl: regionLanguages[lang].privacyUrl,
        termsUseUrl: regionLanguages[lang].termsUseUrl,
        helpUrl: regionLanguages[lang].helpUrl,
        supportType: regionLanguages[lang].supportType,
        gsuiteSupportUrl: regionLanguages[lang].gsuiteSupportUrl,
        supportSiteUrl: regionLanguages[lang].supportSiteUrl
    };
}
