
<div *ngIf="!ispageLoaded" class="load_admin_users">
    <mat-spinner [diameter]="70" class="mat-spinner-color"></mat-spinner>
</div>
<div *ngIf="ispageLoaded" [ngClass]=" isStackRegionUS ? 'scpContainerUSRegion' : 'scp_container'" class=" custom_add_admin">
    <h1 mat-dialog-title>{{ heading | translate }}</h1>
    <div mat-dialog-content class="scp-dialog-content">
        <div  class="custom-query fx_row">
            <mat-radio-group id="searchType" name="searchType" class="fx_90" [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="searchType" (ngModelChange)="selectSearch()">
                <span class="align-search">
                    <mat-label class="search">{{ 'searchBy' | translate }}</mat-label>
                </span>
                <span class="pl_20">
                    <mat-radio-button checked="true" id="searchTypeUsername" name="searchTypeUsername"
                        [value]="'Username'" (click)="onRadioButtonSelect()">
                        <mat-label>{{ 'AdminName' | translate }}</mat-label>
                    </mat-radio-button>
                </span>
                <span class="pl_20">
                    <mat-radio-button id="searchTypeEmail" name="searchTypeEmail" [value]="'Email'"
                        (click)="onRadioButtonSelect()">
                        <mat-label>{{ 'Email' | translate }}</mat-label>
                    </mat-radio-button>
                </span>
            </mat-radio-group>
        </div>
        <form id="addAdminForm" class="fx_column" name="addAdminForm" #addAdminForm [formGroup]="addAdminGroup">
            <mat-form-field class="align-center pl_10" *ngIf="searchType === 'Username'">
                <input id="userName" [readonly]="this.isEdit" [ngClass]="{ noneditable: isEdit }" name="userName"
                    #adminName matInput placeholder="" (keyup)="adminNameChange($event)"
                    formControlName="adminName" [matAutocomplete]="auto" />
                    <mat-label>{{'AdminName' | translate }}</mat-label>
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let user of userSearchResult; let i = index"
                        [title]="user.userName + '&nbsp;' + '|&nbsp;' + user.userEmail" [value]="user.userName"
                        (onSelectionChange)="userSelection(i)">
                        <span class="item">{{ user.userName }}</span>
                        <small class="sub-item"> | {{ user.userEmail }}</small>
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="addAdminGroup.get('adminName').hasError('required')">{{'EnterValue'| translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="align-center pl_10" *ngIf="searchType === 'Email'">
                <input id="email" [readonly]="this.isEdit" [ngClass]="{ noneditable: isEdit }" name="email" #emailSearch
                    matInput placeholder="" (keyup)="adminNameChange($event)" formControlName="adminName"
                    [matAutocomplete]="auto" />
                    <mat-label>{{'Email' | translate }}</mat-label>
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let user of userSearchResult; let i = index"
                        [title]="user.userName + '&nbsp;' + '|&nbsp;' + user.userEmail" [value]="user.userName"
                        (onSelectionChange)="userSelection(i)">
                        <small class="sub-item">{{ user.userName }} | </small>
                        <span class="item">{{ user.userEmail }}</span>
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="addAdminGroup.get('adminName').hasError('required')">{{'EnterValue'| translate }}
                </mat-error>
            </mat-form-field>
        </form>
    </div>
    <div mat-dialog-actions>
        <button style="padding: 0 16px; line-height: 36px" mat-button [type]="submit" [disabled]="disableSave()"
            class="scp_primary_btn" id="admin-add-dilogue-button-id" (click)="saveClick()">{{'Save'
            | translate }}</button>
        <button style="padding: 0 16px; line-height: 36px" mat-button (click)="onNoClick()" class="scp_secondary_btn"
            id="admin-add-cancel-dilogue-button-id">{{'Cancel' | translate }}</button>
    </div>
</div>