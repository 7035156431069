import { Injectable } from '@angular/core';
import { IdpBaseService } from './idp-base.service';
import { Observable, throwError } from 'rxjs';
import { LocalAppDataService } from '../local-app-data.service';

import { GoogleApiService } from './google-api.service';
import { O365ApiService } from './o365-api.service';
import { HttpClient } from '@angular/common/http';
import { CloudPrintEnvService } from '../cloud-print-env.service';

@Injectable({
    providedIn: 'root'
})
export class IdpCommonService {

    idpBaseService: IdpBaseService;
    private isGoogle: boolean;
    public supportedDomains: string[] = [];

    /**
     * The constructor for idp common service
     * @param http The instance http client
     * @param localAppDataService The instance of local app data service
     */
    constructor(protected http: HttpClient,
        protected localAppDataService: LocalAppDataService, protected envServive: CloudPrintEnvService) {
        // constructor
        this.localAppDataService.usingAuthInfo().subscribe(() => {
            // Initialize the service
            this.initialize(this.localAppDataService.TenantInfoAtLogin);
        })
    }

    /**
     * Initialize this service based on tenant information available at login time
     * @param tenantData The tenant data at login time
     * 
     * @description This service will initialize the list of supported domains and also 
     * which api (google/office 365) service to call when importing users
     */
    initialize(tenantData: any) {
        // Multi Domain handling. Get the supported domains list
        this.supportedDomains = (tenantData.domainAliases || []).slice();
        let tenantDomain = tenantData.customerContactEmail.split('@')[1];
        tenantDomain = tenantDomain.toLowerCase();
        this.supportedDomains.push(tenantDomain);

        // Link to either GoogleApiService or O365ApiService based on application type
        this.isGoogle = (this.localAppDataService.ApplicationType === 'google-apps');
        if (this.isGoogle) {
            this.idpBaseService = new GoogleApiService(this.http, this.localAppDataService, this.envServive);
        } else {
            this.idpBaseService = new O365ApiService(this.http, this.localAppDataService, this.envServive);
        }
    }

     /* Search for users by name within given list of domains
     * @param userToSearch The user name pattern to search for
     * @returns An Observable that resolves into a list of users
     * 
     * @description This API will forward the search call to active IDP Service (O365ApiService or GoogleApiService)
     */
    searchForUserIdp(userToSearch): Observable<any> {
        return this.idpBaseService.searchUsers(userToSearch.slice(0, 100), this.supportedDomains);
    }


    /**
     * Search for users by email within given list of domains
     * @param userToSearch The user email pattern to search for
     * @returns An Observable that resolves into a list of users
     * 
     * @description This API will forward the search call to active IDP Service (O365ApiService or GoogleApiService)
     */
    searchForUserIdpByEmail(userToSearch): Observable<any> {
        return this.idpBaseService.searchUsersByEmail(userToSearch.slice(0, 100), this.supportedDomains);
    }

    getIdpDomain(): Promise<any> {
        return this.idpBaseService.getDomains();
    }
    getIdpToken() {
        return this.idpBaseService.getIdpToken();
    }

    checkGrantReadUsers(): Observable<boolean> {
        return this.idpBaseService.checkGrantReadUsers();
    }

}
