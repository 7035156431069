import { Component, Inject, OnInit } from '@angular/core';
import { IReturnDialogDataOnClose } from '../../../../shared/interfaces/sspUI';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminInfo } from '../../AdminInfo';
import { LocalAppDataService } from '../../../../shared/services/local-app-data.service';
import { throwDialogContentAlreadyAttachedError } from '@angular/cdk/dialog';
import { TranslateService } from "@ngx-translate/core";

import { RmmInfo, User, UserInfo } from '../../../../shared/interfaces/User';
import { ADMIN_ROLES } from '../../../../shared/constant/constant';
@Component({
    selector: 'lib-select-services',
    templateUrl: './select-services.component.html',
    styleUrls: ['./select-services.component.scss']
})
export class SelectServicesComponent implements OnInit {

    isLoaded: boolean = false;
    
    /**
     * email id of the user to add
     */
    email: string;

    /**
     * Name of the user to add
     */
    name: string;

    /**
     * The admin info object
     */
    adminInfo: AdminInfo;

    /**
     * The other services (go/rmm) where this user can be added
     */
    otherServices: Array<string>;

    /**
     * true if the user is already added to go
     */
    alreadyAddedGo: boolean;

    /**
     * true to show Synappx Go (customer has license for go). false to hide the 'Synappx Go' checkbox
     */
    showSynappxGo: boolean;

    /**
     * true if the user is already added to Synappx Manage
     */
    alreadyAddedRMM: boolean;

    /**
     * true to show Synappx Manage (customer has license for Synappx Manage). false to hide the 'Synappx Manage' checkbox
     */
    showRMM: boolean;

    /**
     * Always true
     */
    addCloudPrint: boolean;

    /**
     * The list of services, this selected user will be added as admin
     * @description This array will contain values like go, rmm, cp
     * 
     * 'cp' is always by default present
     * go and rmm will be added based on checkbox state.
     * 
     */
    selectedServices: Array<string>;

    /**
     * Used to show different role section.
     */
    differentRoleSection: boolean = false;

    /**
     * Used to show remove role section.
     */
    removeRoleSection: boolean = false;

    changeRole: string;

    otherServiceName: string;

    /**
     * @description Different role already assigned translated message to be shown in select-service.component.html dialog
     * when trying to add different role while adding Admin(IT Main) / Support Admin(IT Helpdesk) from Synappx Go / Synappx Manage. 
     */
    roleAlreadyAssignedMessage: string;
    
    /**
     * @description Message for change role.
     */
    changeRoleMessage: string;
    constructor(
        @Inject(MAT_DIALOG_DATA) public dialog_data: any,
        protected localAppDataService: LocalAppDataService,
        private translate: TranslateService,
        public selectServiceDialogRef: MatDialogRef<SelectServicesComponent>
    ) {
        this.email = this.dialog_data.email;
        this.name = this.dialog_data.selectedName;
        this.adminInfo = this.dialog_data.adminInfo;
        this.otherServices = this.dialog_data.otherServices;
        this.addCloudPrint = true;
        this.selectedServices = ['scp'];
    }

    /**
     * override the ngOnInit to set visibility and enable/disable of checkboxes of other products (Synappx Go / Synappx Manage)
     */
    ngOnInit(): void {
        if (this.adminInfo && this.otherServices) {
            if (this.localAppDataService.TenantInfoAtLogin.tier?.rmm && this.localAppDataService.ViewRMM) {
                this.showRMM = true;
                this.alreadyAddedRMM = !this.otherServices.includes('rmm');
            }
            if ((this.localAppDataService.TenantInfoAtLogin.tier?.rocketStart || this.localAppDataService.TenantInfoAtLogin.tier?.marvel) && 
                (this.localAppDataService.ViewGo || this.localAppDataService.ViewMeeting))
             {
                this.showSynappxGo = true;
                this.alreadyAddedGo = !this.otherServices.includes('synappx');
            }
        }
        this.showOtherSection();
    }

    /**
     * @description Used to show different role / remove role sections.
     */
    showOtherSection() {
        if(this.adminRolePresentForAnyServices(this.adminInfo.userInfo, this.adminInfo.rmmInfo) && !this.adminInfo.userInfo.isRootAdmin) {
            if (this.differentRolePresentForOtherServices().length === 1 &&
                this.differentRolePresentForOtherServices().includes('rmm')) {
                if (this.adminInfo.rmmInfo?.role.indexOf('Service') >= 0) {
                    this.showRemoveRoleSection();
                } else {
                    this.showDifferentRoleSection(true, false, this.translate.instant('CloudRMM'), this.translate.instant('ITHelpdesk'));
                }
            } else if (this.differentRolePresentForOtherServices().length === 1 &&
                this.differentRolePresentForOtherServices().includes('go')) {
                this.showDifferentRoleSection(true, false, this.translate.instant('SynappxGo'), this.translate.instant('SupportAdmin'));
            } else if (this.differentRolePresentForOtherServices().length === 2) {
                if (this.adminInfo.rmmInfo?.role.indexOf('Service') >= 0) {
                    this.showRemoveRoleSection();
                    return;
                }
                this.showDifferentRoleSection(false, true);
            }
        }
    }

    /**
     * @description Used to show remove role section.
     */
    showRemoveRoleSection() {
        this.removeRoleSection = true;
        this.otherServiceName = this.translate.instant('CloudRMM');
        switch (this.adminInfo.rmmInfo?.role) {
            case 'Service Support':
                this.changeRole = this.translate.instant('ServiceSupport');
                break;
            case 'Service Main':
                this.changeRole = this.translate.instant('ServiceMain');
                break;
            case 'Service View Only':
                this.changeRole = this.translate.instant('ServiceViewOnly');
                break;
            default:
                console.log('No such role found');
                break;
        }
    }

    /**
     * @description get array of services where different role is present.
     * @returns array of services.
     */
    differentRolePresentForOtherServices(): Array<string>{
        const otherServices = [];
        // rmm
        if (this.adminInfo.rmmInfo && ADMIN_ROLES.RMM_ADMIN_ROLES.indexOf(this.adminInfo.rmmInfo?.role || '') > -1 && this.adminInfo.rmmInfo?.role != 'IT Main') {
            otherServices.push('rmm');
        }
        // go
        if(ADMIN_ROLES.GO_ADMIN_TYPES.indexOf(this.adminInfo.userInfo?.userType.toLowerCase() || '') > -1) {
            if (this.adminInfo.userInfo && ADMIN_ROLES.GO_ADMIN_TYPES.indexOf(this.adminInfo.userInfo?.userType.toLowerCase() || '') > -1 && this.adminInfo.userInfo?.adminType != 'Admin') {
                otherServices.push('go');
            }
        }
        return otherServices;
    }

    /**
     * @description Used to show different role section.
     * @param includeServiceName 
     * @param differentRoleAssignedForMultiServices true if different role assigned for multiple services, otherwise false.
     * @param serviceName Service name where different role is already assigned.
     * @param changeRole Role name for service where different role is already assigned.
     */
    showDifferentRoleSection(includeServiceName: boolean, differentRoleAssignedForMultiServices: boolean, serviceName?: string, changeRole?: string) {
        this.differentRoleSection = true;           
        if (differentRoleAssignedForMultiServices) {
            this.roleAlreadyAssignedMessage = this.translate.instant('RoleAlreadyAssignedForMultiServicesSupportAdmin', { email: this.email });
        } else {
            this.roleAlreadyAssignedMessage = this.translate.instant('RoleAlreadyAssigned', { email: this.email, role: changeRole, service: serviceName });
        }
        if (!includeServiceName) {
            this.changeRoleMessage = this.translate.instant('ChangeRoleForMultipleService');
        } else {
            this.changeRoleMessage = this.translate.instant('ChangeRole', { service: serviceName });
        }            
    }

    /**
     * @description Checks if admin role is present for any other services.
     * @param userInfo User information fetched from DB.
     * @returns true if any other services has admin role, other wise false.
     */
    adminRolePresentForAnyServices(userInfo: UserInfo, rmmInfo: RmmInfo) {
        return (ADMIN_ROLES.GO_ADMIN_TYPES.indexOf(userInfo?.userType.toLowerCase() || '') > -1 ||
            ADMIN_ROLES.RMM_ADMIN_ROLES.indexOf(rmmInfo?.role || '') > -1) ? true : false; 
    }

    /**
     * Add the product to the final result
     * @param product The product name to add
     */
    addProduct(product: string) {
        if (!this.selectedServices.includes(product)) {
            this.selectedServices.push(product);
        }
    }

    /**
     * Remove the product name from the final result
     * @param product The product name to remove
     */
    removeProduct(product: string) {
        this.selectedServices = this.selectedServices.filter(entry => entry != product);
    }

    /**
     * Handle the selection change event of the checkbox
     * @param event The checkbox selection change event from browser
     * @param product The product name corresponding to the checkbox (cp/go/rmm)
     */
    selectionChange(event, product: string) {
        // If other two products, and user is not already Added to them
        if ((product == 'synappx' && !this.alreadyAddedGo) ||
            (product == 'rmm' && !this.alreadyAddedRMM)) {
            if (event) {
                // checked - add go/rmm to result
                this.addProduct(product);
            } else {
                // unchecked - remove go/rmm from the result
                this.removeProduct(product);
            }
        }
    }

    submit() { }

    /**
     * Save button is clicked.
     */
    saveClicked() {

        // Close the dialog with the result
        this.selectServiceDialogRef.close({
            action: true,
            adminInfo: this.adminInfo,
            selectedServices: this.selectedServices,
            alreadyAddedGo: this.alreadyAddedGo,
            alreadyAddedRMM: this.alreadyAddedRMM
        });
    }
    /**
     * @description Called when click 'OK' button on dialog.
     */
    okClicked() {
        this.selectServiceDialogRef.close();
    }
}
