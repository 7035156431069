import { Component, OnInit, Inject } from '@angular/core';
import { LocalAppDataService } from '../../../shared/services/local-app-data.service';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StackUrlsService } from '../../../shared/services/ssp/stack-urls.service';
import { CloudPrintEnvService } from '../../../shared/services/cloud-print-env.service';
@Component({
  selector: 'lib-grant-permissions',
  templateUrl: './grant-permissions.component.html',
  styleUrls: ['./grant-permissions.component.scss']
})
export class GrantPermissionsComponent implements OnInit {

    public tenantDomain: string;
    public azureAppId: string;
    public callbackUrl: string;
    public requiredScopes: string;
    public url: string;
    public domainwidelURL;
    public gsuiteSupportUrl; 
    public userEmail: string;
    public PermissionsRequiredMessage: string;
    public GooglePermissionMessage: string;
    public GooglePermissionDescription: string;
    public isGoogle: boolean;
    public isO365: boolean;
    public isRMM: boolean = false;
    public hasGoAccess: boolean = false;
    public isStackRegionEU: boolean = false;
    public supportSiteUrl:string; 
    public MoreInformationMessage: string;
    

    constructor(
        public confirmDialogRef: MatDialogRef<GrantPermissionsComponent>,
        private appDataService: LocalAppDataService,
        private translate: TranslateService,
        private StackUrlsService: StackUrlsService,
        private scpEnvService: CloudPrintEnvService,
        @Inject(MAT_DIALOG_DATA) public data: any){
        this.isGoogle = this.appDataService.ApplicationType === 'google-apps';
        this.isO365 = this.appDataService.ApplicationType === 'waad';
        this.isRMM = (this.appDataService.TenantTier == "rmm") || false;
    }
    ngOnInit() {
        if (this.scpEnvService.stackRegion == 'EU') {
            this.isStackRegionEU = true;
        }
        const browserLang = this.appDataService.browserLang();
        const stackUrl = this.StackUrlsService.stackUrls(browserLang)
        this.domainwidelURL = "https://admin.google.com/ac/owl/domainwidedelegation";
        this.gsuiteSupportUrl = stackUrl.gsuiteSupportUrl;
        this.supportSiteUrl = stackUrl.supportSiteUrl;
        this.userEmail = this.appDataService.Upn;
        this.tenantDomain = this.userEmail.toLowerCase().split('@')[1];
        this.azureAppId = this.appDataService.AuthConfig.azure_app_client_id;
        this.callbackUrl = encodeURIComponent(this.appDataService.AuthConfig.callbackURL);
        this.requiredScopes = 'openid profile email offline_access User.Read Group.Read.All Directory.Read.All';
        this.url = `https://login.microsoftonline.com/${this.tenantDomain}/v2.0/adminconsent?client_id=${this.azureAppId}&redirect_uri=${this.callbackUrl}&scope=${this.requiredScopes}`;
        this.translate.get('PermissionsRequiredMessage', {
            ahref_start: `<a href='${this.url}'><u>`,
            ahref_end: `</u></a>`,
        }).subscribe((PermissionsRequiredMessage: string) => {
            this.PermissionsRequiredMessage = PermissionsRequiredMessage;
        });
        if (this.isGoogle) {
            this.translate.get('GooglePriviledgeConfigLink', {
                ahref_start: `<a href='${this.gsuiteSupportUrl}' target="_blank"><u>`,
                ahref_end: `</u></a>`,
            }).subscribe((GooglePermissionMessage: string) => {
                this.GooglePermissionMessage = GooglePermissionMessage;
            });
            this.GooglePermissionDescription = this.translate.instant('GoogleAdminPriviledgeInfo');
            if (this.isStackRegionEU) {
                this.translate.get('MoreInformation', {
                    ahref_start: `<a href='${this.supportSiteUrl}' target="_blank" class="primary-link"><u>`,
                    ahref_end: `</u></a>`,
                }).subscribe((MoreInformationMessage: string) => {
                    this.MoreInformationMessage = MoreInformationMessage;
                });
            }
        }
    }
    submit() {
        this.confirmDialogRef.close(0);
    }
}
