import { Observable, Subject } from 'rxjs';
import { InjectionToken } from '@angular/core';


/**
 * A static item injected from application.
 * 
 * <br/><b>example</b>: The logged-in user name is a static item
 */
export interface IStaticItem {
    /**
     * get the static item as string
     */
    get str(): string;

    /**
     * get the static item as object
     */
    get obj(): any;

    /**
     * get the static item as number
     */
    get num(): number;
};


/**
 * A dynamic item injected from application
 * 
 * <br/><b>example</b>: The dynamic item injected from application could be jwt, 
 *  jwt is dynamic because it keeps renewing every one hour
 */
export interface IDynamicItem {
    /**
     * get the dynamic item as Observable<string>
     */
    get str(): Observable<string>;

    /**
     * get the dynamic item as Observable<any>
     */
    get obj(): Observable<any>;

    /**
     * get the dynamic item as Observable<number>
     */
    get num(): Observable<number>;

    /**
     * get the dynamic item availability
     */
    get available(): Observable<any>;
};


/**
 * The complete application data injected from appliation
 */
export interface IAppData {
    /**
     * Get the static data entry injected by application based on keyname
     * @param name The name of the data (e.g: user_name, etc)
     * @returns A IStaticItem instance
     */
    getStatic(name: string): IStaticItem | undefined;

    /**
     * Check if the application has inserted the given static item
     * @param name The key-name of the static item
     * @returns true if the static item is inserted by application. false otherwise
     */
    hasStatic(name: string): boolean;

    /**
     * gets the list of keys of static items injected from application
     */
    staticEntries(): Array<string>;

    /**
     * Get the dynamic data entry injected by application based on keyname
     * @param name The key-name of the item (e.g: auth_access_token, idp_access_token)
     * @returns A IDynamicItem instance
     */
    getDynamic(name: string): IDynamicItem | undefined;

    /**
     * Check if the application has inserted the given dynamic item
     * @param name The key-name of the dynamic item
     * @returns true if the dynamic item is inserted by application. false otherwise
     */
    hasDynamic(name: string): boolean;

    /**
     * gets the list of keys of dynamic items injected from application
     */
    dynamicEntries(): Array<string>;

    debug();
};


// The APP_DATA Injection token that should be injected to the service
export const APP_DATA = new InjectionToken<IAppData>('APP_DATA');

