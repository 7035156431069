import { AuthService } from './shared/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError, ActivatedRoute } from '@angular/router';
import { TokenStorage } from './core/token.storage';
import { NetworkService } from './shared/services/network.service';
import { NotificationService } from './shared/services/notification.service';
import * as FontFaceObserver from 'fontfaceobserver';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { ProgressLoaderService } from './shared/services/progress-loader.service';
import { filter } from 'rxjs/operators';
import { CloudPrintRuntimeService } from './shared/services/cloud-print-runtime.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(
        public auth: AuthService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private token: TokenStorage,
        private networkService: NetworkService,
        private notificationService: NotificationService,
        private translate: TranslateService,
        private loaderService: ProgressLoaderService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private gtmService: GoogleTagManagerService,
        private cpService: CloudPrintRuntimeService) {
        let previousURL;
        let currentURL;
        this.loadTranslation();
        this.registerSVGIcons();
        this.networkService.setNetworkListener();
        this.replaceHttp();
        this.router.events
            .subscribe((routerEvent) => {
                if (routerEvent instanceof NavigationEnd) {
                    this.loaderService.hideNavigationProgress();
                    previousURL = routerEvent.url;
                    if (routerEvent.url !== '/access_token' && !routerEvent.url.includes('/guestprovider') && routerEvent.url !== '/') {
                        this.token.setCurrentUrl(routerEvent.url);
                    }
                    if (this.auth.isLoggedIn && (localStorage.getItem('redirectURL') == sessionStorage.getItem('currentUrl'))) {
                        localStorage.removeItem('redirectURL')
                    }
                }
                this.SaveOriginalUrl();
                if (routerEvent instanceof NavigationCancel || routerEvent instanceof NavigationError) {
                    this.loaderService.hideNavigationProgress();
                }
                if (routerEvent instanceof NavigationStart) {
                    currentURL = routerEvent.url;
                    if (previousURL && previousURL == '/service') {
                        this.loaderService.showNavigationProgress();
                    }
                    if (!previousURL && currentURL == '/downloads') {
                        localStorage.setItem("navigate-download", "true");
                    }
                    if (this.auth.isLoggedIn && currentURL === '/' && previousURL !== '/') {
                        this.router.navigate([previousURL]);
                    }

                    if (currentURL !== '/' && routerEvent.url !== '/access_token' && currentURL.includes('rmm')) {
                        // currentURL.toString().split('/').length == 4 is removed since this condition only checking for URL having ID
                        localStorage.setItem('redirectURL', currentURL)
                    }
                    // If feat_v33 is enabled, re-direct some of the routes
                    if (environment.optional && environment.optional.feat_v33 === "true") {
                        // old route: /spaces/spacedetail/...
                        // new rout3: /roomdetail/...
                        let targetUrl = routerEvent.url;
                        // old route: /roomlist => new route: /workspace-list
                        if (targetUrl === '/roomlist') {
                            this.router.navigate([`/workspace-list/`]);
                        }
                        if (targetUrl.indexOf('?')) {
                            targetUrl = targetUrl.split('?')[0];
                        }
                        const segments = targetUrl.split('/');
                        // console.log(`segments: [${segments.length}]: ${JSON.stringify(segments)}`);
                        if (routerEvent.url.indexOf("/spaces/spacedetail/") >= 0 && segments.length == 4) {
                            const wsId = segments[3];
                            this.router.navigate([`/workspace-details/${wsId}`]);
                        }
                        if (routerEvent.url.indexOf("/spaces/spacedetail/") >= 0 && (segments[4] == 'mfpagentsummary' || segments[4] == 'displayagentsummary')) {
                            const wsId = segments[3];
                            const forwardTo = segments[4]
                            this.router.navigate([`/workspace-details/${wsId}/${forwardTo}`]);
                        }

                    }
                }
            });
        // Comment out this method call if using
        // hash-based routing
        auth.handleAuthentication();

        // Uncomment this method call if using
        // hash-based routing
        // auth.handleAuthenticationWithHash();

        // Font Face Observer is a fast and simple web font loader. We are using  it to load fonts and customise our browser’s font loading behaviour.
        const materialIcons = new FontFaceObserver('Material Icons');
        materialIcons.load().then(() => { console.log('Material Icons are loaded'); });
    }

    ngOnInit() {
        if (environment.googleTagEnable === 'true' && environment.stackRegion === 'EU' && environment.googleTagId !== '') {
            this.setUpAnalytics();
        }
        this.checkNetwork();
        //to update the title of the tab
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
        ).subscribe(() => {
            const rt = this.getChild(this.activatedRoute);
            rt.data.subscribe(data => {
                if(data.title){
                    this.titleService.setTitle(data.title)
                }
                else{
                    this.titleService.setTitle('Synappx Admin')
                }
            });
        });
    }

    setUpAnalytics() {
        this.router.events.forEach(item => {
            if (item instanceof NavigationEnd) {
                const gtmTag = {
                    event: 'page',
                    pageUrl: item.url,
                    pageTitle: item.url.split('/').pop()
                };

                this.gtmService.pushTag(gtmTag);
            }
        });
    }

    getChild(activatedRoute: ActivatedRoute) {
        if (activatedRoute.firstChild) {
            return this.getChild(activatedRoute.firstChild);
        } else {
            return activatedRoute;
        }
    }

    // this function is used to replace the http calls to https
    replaceHttp() {
        let hrefLink = window.location.href;
        if (hrefLink.split(':')[0] === 'http' && !hrefLink.includes('localhost')) {
            hrefLink = hrefLink.replace('http', 'https');
            window.location.replace(hrefLink);
        }
    }

    // CHECK NETWORK CONNECTION TOASTER
    checkNetwork() {
        this.networkService.online$.subscribe(res => {
            if (res === false) {
                this.notificationService.showNotification('NetworkDown', undefined, 'N/A', { verticalPosition: 'top', panelClass: 'warning' });
            } else {
                this.notificationService.snackBar.dismiss();
            }
        });
    }

    // checks and stores the URL if 'admin_consent' parameter is present
    SaveOriginalUrl() {
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);
        const isAdminConsentPresent = searchParams.has('admin_consent');
        if (isAdminConsentPresent) {
            console.log('admin_consent parameter is present.');
            sessionStorage.setItem('adminConsentUrl', url.href); // Store the URL in sessionStorage
        }
    }

    // function to register the svg icons as mat icons
    registerSVGIcons() {
        const svgIconList = [
            { name: 'svg_casting', url: '../assets/images/icons/svg/icon_casting.svg' },
            { name: 'svg_display', url: '../assets/images/icons/svg/icon_displays.svg' },
            { name: 'svg_devices', url: '../assets/images/icons/svg/icon_devices.svg'},
            { name: 'svg_mfp_access', url: '../assets/images/icons/svg/icon_mfp_access.svg' },
            { name: 'svg_import', url: '../assets/images/icons/svg/icon_import.svg' },
            { name: 'display_agent', url: '../assets/images/icons/svg/icon_ws_display_agent_outline.svg' },
            { name: 'svg_id_card', url: '../assets/images/icons/svg/icon_id_card.svg' },
            { name: 'svg_mail_unverified', url: '../assets/images/icons/svg/icon_unverified_mail.svg' },
            { name: 'svg_mail_verified', url: '../assets/images/icons/svg/icon_verified_mail.svg' },
            { name: 'svg_icon_ws_bt_speaker', url: '../assets/images/icons/svg/icon_ws_bt_speaker.svg' },
            { name: 'svg_icon_ws_checkin', url: '../assets/images/icons/svg/icon_ws_checkin.svg' },
            { name: 'svg_icon_ws_display', url: '../assets/images/icons/svg/icon_ws_display.svg' },
            { name: 'svg_icon_ws_mfp', url: '../assets/images/icons/svg/icon_ws_mfp.svg' },
            { name: 'svg_mfp', url: '../assets/rmm/images/icons/svg/rmm_icon_mfp.svg' },
            { name: 'svg_create', url: '../assets/rmm/images/icons/svg/rmm_icon_create.svg' },
            { name: 'svg_rmm_mfp', url: '../assets/rmm/images/icons/svg/rmm_icon_alldevices.svg' },
            { name: 'svg_rmm_display', url: '../assets/rmm/images/icons/svg/rmm_icon_displays.svg' },
            { name: 'svg_eweb', url: '../assets/rmm/images/icons/svg/rmm_icon_eweb_Icon.svg' },
            { name: 'svg_devicecloning', url: '../assets/rmm/images/icons/svg/rmm_icon_device_cloning.svg' },
            { name: 'svg_createschedule', url: '../assets/rmm/images/icons/svg/rmm_icon_create_schedule.svg' },
            { name: 'svg_securitycontrol', url: '../assets/rmm/images/icons/svg/rmm_icon_security_control.svg' },
            { name: 'svg_createpolicy', url: '../assets/rmm/images/icons/svg/rmm_icon_create_policy.svg' },
            { name: 'svg_fleetreport', url: '../assets/rmm/images/icons/svg/rmm_icon_fleet_report.svg' },
            { name: 'svg_securityreport', url: '../assets/rmm/images/icons/svg/rmm_icon_security_report.svg' },
            { name: 'svg_rmm_agent', url: '../assets/rmm/images/icons/svg/rmm_icon_agent.svg' },
            { name: 'svg_rmm_emailalert', url: '../assets/rmm/images/icons/svg/rmm_icon_email.svg' },
            { name: 'svg_rmm_countervariation', url: '../assets/rmm/images/icons/svg/rmm_icon_countervariation.svg' },
            { name: 'svg_rmm_connectWise', url: '../assets/rmm/images/icons/svg/rmm_icon_connectWise.svg' },
            { name: 'svg_storagebackup', url: '../assets/rmm/images/icons/svg/rmm_icon_storage_backup.svg' },
            { name: 'svg_uni_mfp', url: '../assets/images/icons/svg/uniws/icon_ws_mfp.svg'},
            { name: 'svg_uni_collab', url: '../assets/images/icons/svg/uniws/icon_ws_collab.svg' },
            { name: 'svg_uni_checkin', url: '../assets/images/icons/svg/uniws/icon_ws_checkin.svg' },
            { name: 'svg_uni_display', url: '../assets/images/icons/svg/uniws/icon_ws_display.svg' },
            { name: 'svg_uni_display_go', url: '../assets/images/icons/svg/uniws/icon_ws_display_go.svg' },
            { name: 'svg_uni_display_cast', url: '../assets/images/icons/svg/uniws/icon_ws_display_cast.svg' },
            { name: 'svg_uni_display_cast_go', url: '../assets/images/icons/svg/uniws/icon_ws_display_cast_go.svg' },
            { name: 'svg_uni_display_go_disable', url: '../assets/images/icons/svg/uniws/icon_ws_display_go_disable.svg' },
            { name: 'svg_uni_display_cast_disable', url: '../assets/images/icons/svg/uniws/icon_ws_display_cast_disable.svg' },
            { name: 'svg_uni_display_cast_disable_go', url: '../assets/images/icons/svg/uniws/icon_ws_display_cast_disable_go.svg' },
            { name: 'svg_uni_display_cast_go_disable', url: '../assets/images/icons/svg/uniws/icon_ws_display_cast_go_disable.svg' },
            { name: 'svg_icon_ws_casting', url: '../assets/images/icons/svg/uniws/icon_ws_casting.svg' },
            { name: 'svg_customtype', url: '../assets/rmm/images/icons/svg/rmm_icon_customdevicetype.svg' },
            { name: 'svg_technicalservice', url: '../assets/rmm/images/icons/svg/rmm_icon_technicalservice.svg' },
            { name: 'svg_servicereport', url: '../assets/rmm/images/icons/svg/rmm_icon_servicereport.svg' },
            { name: 'svg_alert', url: '../assets/rmm/images/icons/svg/rmm_icon_alert.svg' },
            { name: 'svg_getdata', url: '../assets/rmm/images/icons/svg/rmm_icon_getdata.svg' },
            { name: 'svg_remotemaintenance', url: '../assets/rmm/images/icons/svg/rmm_icon_remotemaintenance.svg' },
            { name: 'svg_firmwareupdate', url: '../assets/rmm/images/icons/svg/rmm_icon_firmwareupdate.svg' },
            { name: 'svg_customname', url: '../assets/rmm/images/icons/svg/rmm_icon_customname.svg' },
            { name: 'svg_removecustomname', url:'../assets/rmm/images/icons/svg/rmm_icon_remove_customname.svg'},
            { name: 'svg_removeschedule', url:'../assets/rmm/images/icons/svg/rmm_icon_device_remove_schedule.svg'},    
            { name: 'svg_rmm_dashboard', url: '../assets/rmm/images/icons/svg/rmm_icon_dashboard.svg' },
            { name: 'svg_rmm_powerinputschedule', url: '../assets/rmm/images/icons/svg/rmm_icon_powerinput_schedule.svg' },
            { name: 'svg_rmm_ceojuice', url: '../assets/rmm/images/icons/svg/rmm_icon_ceo_juice.svg' },
            { name: 'svg_firmwareupdate2', url: '../assets/rmm/images/icons/svg/rmm_icon_firmwareupdate.svg' },
            { name: 'svg_tasks', url: '../assets/rmm/images/icons/svg/rmm_icon_tasks.svg' },
            { name: 'svg_delete_cloningfile', url: '../assets/rmm/images/icons/svg/rmm_icon_delete_file.svg' }, 
            { name: 'svg_filter', url: '../assets/rmm/images/icons/svg/rmm_icon_filter.svg' },
            { name: 'svg_rmm_security', url: '../assets/rmm/images/icons/svg/rmm_icon_security.svg' },
            { name: 'svg_rmm_rop', url: '../assets/rmm/images/icons/svg/rmm_icon_rop.svg' },
            { name: 'svg_rmm_analytics', url: '../assets/rmm/images/icons/svg/rmm_icon_analytics.svg' },
            { name: 'svg_rmm_about', url: '../assets/rmm/images/icons/svg/rmm_icon_about.svg' },
            { name: 'svg_rmm_setting_download', url: '../assets/rmm/images/icons/svg/rmm_icon_setting_download.svg' },
            { name: 'svg_rmm_setting', url: '../assets/rmm/images/icons/svg/rmm_icon_setting.svg' },
            { name: 'svg_rmm_system', url: '../assets/rmm/images/icons/svg/rmm_icon_system.svg' },
            { name: 'svg_rmm_log', url: '../assets/rmm/images/icons/svg/rmm_icon_log.svg' },
            { name: 'svg_rmm_download', url: '../assets/rmm/images/icons/svg/rmm_icon_download.svg' },
            { name: 'svg_rmm_delete', url: '../assets/rmm/images/icons/svg/rmm_icon_delete.svg' },
            { name: 'svg_rmm_edit', url: '../assets/rmm/images/icons/svg/rmm_icon_edit.svg' },
            { name: 'svg_rmm_status_update', url: '../assets/rmm/images/icons/svg/rmm_icon_status_update.svg' },
            { name: 'svg_rmm_mail', url: '../assets/rmm/images/icons/svg/rmm_icon_mail.svg' },
            { name: 'svg_rmm_refresh', url: '../assets/rmm/images/icons/svg/rmm_icon_refresh.svg' },
            { name: 'svg_rmm_add', url: '../assets/rmm/images/icons/svg/rmm_icon_add.svg' },
            { name: 'svg_rmm_remove', url: '../assets/rmm/images/icons/svg/rmm_icon_remove.svg' },

            
];
        svgIconList.forEach((icon)  => {
            this.matIconRegistry.addSvgIcon(
                icon.name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(icon.url),
            );
        });
    }

    //Supporting languages code list
    loadTranslation(): any {
        let browserLang = this.translate.getBrowserLang();
        let supportedLanguages = ['en'];
        if (environment.stackRegion == "EU") {
            supportedLanguages = ['da', 'de', 'en', 'es', 'fi', 'fr', 'it', 'nl', 'no', 'pl', 'sv'];
            // check only for norwegien
            const norLangCode = ['no', 'nb', 'nn'];
            if (norLangCode.includes(browserLang)) {
                browserLang = 'no';
            }
            // set browserlang to spanish if catalan or spanish is selected
            const spanishLangCode = ['ca', 'es'];
            if (spanishLangCode.includes(browserLang)) {
                browserLang = 'es';
            }
            browserLang = supportedLanguages.includes(browserLang) ? browserLang : 'en';
            this.translate.use(browserLang);
        } else {
            browserLang = 'en';
            this.translate.use(browserLang);
        }
        return browserLang;
    }

}
