import { Injectable, Inject } from '@angular/core';
import { AuthEnv, AUTH_ENV, Auth0Config } from './auth-env';

/**
 * The environment service is used to contain the runtime environment settings
 * 
 * These settings are injected into this library from application at runtime
 * 
 * This is done to ensure we can build this library independent of enviornment configuration.
 */
@Injectable({
    providedIn: 'root'
})
export class SynappxEnvService {

    private _authEnv: AuthEnv;

    constructor(@Inject(AUTH_ENV) authEnv: AuthEnv) { this._authEnv = authEnv; }

    /**
     * Gets the auth0 configuration
     * 
     * The auth0 configuration will be injected into the enviroment service 
     * from the synappx admin portal application
     */
    public get AuthConfig(): Auth0Config | undefined {
        return this._authEnv?.AuthConfig;
    }

    // /**
    //  * Get the azure application client id
    //  */
    // public get AzureAppClientId(): String {
    //     return this._authEnv?.azure_app_client_id || '';
    // }

    /**
     * Gets the production flag
     * 
     * The auth0 configuration will be injected into the enviroment service 
     * from the synappx admin portal application
     */
    public get Production(): boolean {
        return this._authEnv.production;
    }

    /**
     * Get the google scopes from the environment
     */
    public get googleScopes(): Array<string> {
        return this._authEnv.googleScopes;
    }

    /**
     * Get the base url for SSP Common API
     */
    public get sspCommonApiBaseUrl(): string {
        return this._authEnv.sspCommonApiBaseUrl;
    }

    /**
     * Get the region where the current stack is located (whether US or EU)
     */
    public get stackRegion(): string {
        return this._authEnv.stackRegion;
    }

    /**
     * Get the logout url for sharp start
     */
    public get sharpstart_logout_url(): string {
        return this._authEnv.sharpstart_logout_url;
    }

    /**
     * Get the url for SSP Common Tenant API
     */
    public get sspCommonTenantUrl(): string {
        return this._authEnv.sspCommonTenantUrl;
    }
}
