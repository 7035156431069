import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BaseComponent } from '../../../shared/components/base.component';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss'],
})
export class NoticeComponent extends BaseComponent implements OnInit {
  @Input()
  noticeinfo = [];
  constructor(public noticeDialogRef: MatDialogRef<NoticeComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    super();
  }

  ngOnInit() {
    this.noticeinfo = this.data.noticeinfo;
  }
  close() {
    this.noticeDialogRef.close(0);
  }
}
