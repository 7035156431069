import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseComponent } from '../../../shared/components/base.component';
import { ReportsExecuteComponent } from '../reports-execute/reports-execute.component';
import { ReportsListUpsertComponent } from '../reports-list-upsert/reports-list-upsert.component';
import { SubDialogInfo } from '../../../scp-shared/interfaces/dialog';
import { DialogService } from '../../../scp-shared/services/dialog.service';

const PAGE_NAME = 'REPORTS_LIST';

@Component({
  selector: 'app-reports-list',
  templateUrl: './reports-list.component.html',
})
export class ReportsListComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  selection = new SelectionModel<any>(true, []);
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['name', 'type', 'actions'];

  constructor(private dialogService: DialogService) {
    super();
  }

  ngOnInit() {
    this.getReportsData();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = this.defaultSortingDataAccessor;

    // Reset the page number
    this.sort.sortChange.pipe(this.takeUntilDestroyed()).subscribe(() => (this.paginator.pageIndex = 0));

    Promise.resolve().then(() => {
      this.paginator.pageSize = this.appService.getPageSizeInfo(PAGE_NAME);
    });

    this.paginator.page.pipe(this.takeUntilDestroyed()).subscribe((evt) => {
      this.appService.savePageSizeInfo(PAGE_NAME, evt.pageSize);
    });
  }

  applyFilter(filterString = '') {
    this.dataSource.filter = filterString.trim().toLowerCase();
  }

  hasTranslation(key) {
    const translation = this.translate.instant(key);
    return translation !== key && translation !== '';
  }

  showUpsertCustomReportDialog(rowData?) {
    this.dialog
      .open(ReportsListUpsertComponent, {
        data: rowData,
        width: '700px',
        disableClose: true,
      })
      .afterClosed()
      .pipe(this.takeUntilDestroyed<boolean>())
      .subscribe((shouldUpdateView) => {
        if (shouldUpdateView) {
          this.getReportsData();
        }
      });
  }

  showExecuteReportDialog(rowData?) {
    this.dialog
      .open(ReportsExecuteComponent, {
        data: rowData,
        width: '500px',
        disableClose: true,
      })
      .afterClosed()
      .pipe(this.takeUntilDestroyed<boolean>())
      .subscribe((shouldUpdateView) => {
        if (shouldUpdateView) {
          this.getReportsData();
        }
      });
  }

  getReportsData() {
    forkJoin([this.appService.getReports(), this.appService.getUsers()])
      .pipe(
        this.takeUntilDestroyed(),
        map(([reportsData, usersData]) => {
          const getDisplayNameForUser = (userGuid) => {
            return (usersData || []).find((x) => x.user.guid === userGuid).user.displayname;
          };

          return (reportsData || []).map((item) => {
            return {
              guid: item.guid,
              name: item.name,
              isPredefined: !item.createdUser,
              type: !item.createdUser
                ? this.translate.instant('scp.reports.execute.report_type_predefined')
                : this.translate.instant('scp.reports.execute.report_type_custom', { value: getDisplayNameForUser(item.createdUser.guid) }),
            };
          });
        })
      )
      .subscribe((data) => {
        this.dataSource.data = data;
      });
  }

  // deleteReport(data) {
  //   if (confirm(this.translate.instant('scp.reports.execute.confirm_delete_message', { value: data.name }))) {
  //     this.appService
  //       .deleteReport(data.guid)
  //       .pipe(this.takeUntilDestroyed())
  //       .subscribe(
  //         () => {
  //           this.getReportsData();
  //         },
  //         () => {
  //           this.appService.showError(this.translate.instant('scp.reports.execute.delete_error_message', { value: data.name }));
  //         }
  //       );
  //   }
  // }

  deleteReport(data) {
    return new Promise((resolve, reject) => {
      const subDialogInfo: SubDialogInfo = {
        title: this.translate.instant('scp.common.confirm'),
        mainMessage: this.translate.instant('scp.reports.execute.confirm_delete_message', { value: data.name }),
        cancelText: this.translate.instant('scp.common.cancel_button_text'),
        confirmText: this.translate.instant('scp.common.ok_button_text'),
        infoMode: false,
        placeHolderVariables: this.translate.instant('scp.reports.execute.confirm_delete_message', { value: data.name }),
      };
      this.dialogService.open(subDialogInfo);
      this.dialogService.confirmed().subscribe((result) => {
        if (result === 1) {
          //Indicates Action Confirmed
          this.appService
            .deleteReport(data.guid)
            .pipe(this.takeUntilDestroyed())
            .subscribe(
              () => {
                this.getReportsData();
              },
              () => {
                this.appService.showError(this.translate.instant('scp.reports.execute.delete_error_message', { value: data.name }));
              }
            );
        } else {
          //Indicates Action Cancelled
          resolve(0);
        }
      });
    });
  }
}
