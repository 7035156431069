<mat-card>
  <mat-card-actions fxLayoutAlign="space-between end" class="mb-0">
    <span fxLayout="row wrap" fxLayoutAlign="start center">
      <button
        class="large"
        mat-icon-button
        color="primary"
        matTooltip="{{ 'scp.reports.schedule.add_schedule_button_text' | translate }}"
        (click)="showUpsertReportScheduleDialog()"
      >
        <mat-icon>add_circle</mat-icon>
      </button>

      <mat-form-field class="mx-3">
        <mat-label>{{ 'scp.reports.schedule.filter_schedule_reports_search_text' | translate }}</mat-label>
        <input matInput (keyup)="applyFilter($event.target.value)" />
      </mat-form-field>
    </span>

    <button mat-icon-button matTooltip="{{ 'scp.common.refresh_data_button_text' | translate }}" (click)="getReportSchedulesData()">
      <mat-icon>refresh</mat-icon>
    </button>
  </mat-card-actions>

  <mat-card-content>
    <div class="table-responsive mat-elevation-z8">
      <table mat-table matSort matSortActive="name" matSortDirection="desc" [dataSource]="dataSource" class="w-100">
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.reports.schedule.columns.schedule_status' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            {{ row.status === 'Active' ? ('scp.common.active' | translate) : ('scp.common.inactive' | translate) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.reports.schedule.columns.schedule_name' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            {{ row.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="reportName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.reports.schedule.columns.report_name' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            {{
              hasTranslation('scp.report.' + row.reportName.replaceAll(' ', '').toLowerCase())
                ? ('scp.report.' + row.reportName.replaceAll(' ', '').toLowerCase() | translate)
                : row.reportName
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="schedule">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.reports.schedule.columns.schedule_period' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            {{ row.schedule }}
          </td>
        </ng-container>

        <ng-container matColumnDef="nextRun">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.reports.schedule.columns.next_run' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            {{ row.nextRun || '-' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="until">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.reports.schedule.columns.end_date' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            {{ row.until || '-' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="exportFormat">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.reports.schedule.columns.export_format_type' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            {{ row.exportFormat === 'pdf' ? 'PDF' : 'Excel(xlsx)' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.reports.schedule.columns.created_by' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            {{ row.createdBy }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef>{{ 'scp.reports.schedule.columns.actions' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button [matMenuTriggerFor]="rowMenu">
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #rowMenu="matMenu">
              <button mat-menu-item (click)="showUpsertReportScheduleDialog(row)">{{ 'scp.common.edit_button_text' | translate }}</button>
              <button mat-menu-item (click)="deleteReportSchedule(row)">{{ 'scp.common.delete_button_text' | translate }}</button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons aria-label="Select page"> </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
