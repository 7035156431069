import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild,ChangeDetectorRef, ElementRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { User } from '../../../shared/interfaces/User';
import { UsersDataSource } from '../users-data-source';
import { LocalAppDataService } from '../../../shared/services/local-app-data.service';
import { UserService } from '../../../shared/services/ssp/user.service';
import { TenantService } from '../../../shared/services/ssp/tenant.service';
import { TokenStorageService } from '../../../shared/services/ssp/token-storage.service';
import { NotificationService } from '../../../shared/services/ssp/notification.service';
import { ProgressLoaderService } from '../../../shared/services/ssp/progress-loader.service';
import { SspGenericService } from '../../../shared/services/ssp/ssp-generic.service';
import { AddAdminComponent } from '../dialogs/add-admin/add-admin.component';
import { ConfirmDeleteComponent } from '../dialogs/confirm-delete/confirm-delete.component';
import { AdminInfo } from '../AdminInfo';
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AdminApprovalComponent } from '../admin-approval/admin-approval.component';
import { IdpCommonService } from '../../../shared/services/ssp/idp-common.service';

/**
 * The list of user types for Cloud Print
 */
const CP_USER_TYPES = [
    {
        role: 'Tenant Admin',
        userType: 'tenant_admin'
    }, {
        role: 'Tenant User',
        userType: 'tenant_user'
    }
];
const CP_ADMIN_TYPE = CP_USER_TYPES[0];

const ROLE_MAP = {
    tenant_admin: 'Admin',
    tenant_user: 'User'
};

const RMM_ADMIN_ROLES = ['IT Main', 'IT Helpdesk'];
const GO_ADMIN_ROLES = ['Admin', 'Support Admin', 'Primary Admin'];
const PAGINATION_SERVICE = 'scp';
@Component({
    selector: 'lib-admin-users',
    templateUrl: './admin-users.component.html',
    styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit {
    public deleteButtonDisabled: boolean = true;
    displayedColumns: string[] = ['Select', 'Admin', 'Role', 'Email', 'Actions'];
    dataSource: UsersDataSource | null;
    hideContent = false;
    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;
    @ViewChild(MatSort, { static: true })
    sort: MatSort;
    public loadingStatus: boolean;
    public initialrolevalue = '';
    public IsAllSelectedProp: boolean = false;
    public selection = new SelectionModel<User>(true, []);
    disableTooltip: boolean;
    defaultDialogConfig = new MatDialogConfig();
    adminAddedForLicence: any = {};
    name: string;
    email: string;
    auth: any;
    idp: any;
    accessToken: any;
    tenantTier: string;
    public dbUserType;
    public goAdminType;
    public rmmAdminType;
    public cpAdminType;
    public GrantPermissions: SafeHtml;
    showInfoMsg: boolean = false;
    public showGrantMsg = false;
    adminApprovalDialogRef: MatDialogRef<AdminApprovalComponent>;

    constructor(
        private appDataService: LocalAppDataService,
        public userService: UserService,
        private tenantService: TenantService,
        public dialog: MatDialog,
        public token: TokenStorageService,
        private notificationService: NotificationService,
        private sspGenericService: SspGenericService,
        private progressLoader: ProgressLoaderService,
        private translate: TranslateService,
        protected sanitizer: DomSanitizer,
        private changeDetectorRef: ChangeDetectorRef,
        private elementRef: ElementRef,
        private idpCommonService: IdpCommonService
    ) {
        this.defaultDialogConfig.autoFocus = false;
    }

    ngOnInit() {
        if (!this.token.getSynappxCloudPrintAdminPageSize(PAGINATION_SERVICE)) {
            this.token.setSynappxCloudPrintAdminPageSize(25, PAGINATION_SERVICE);
        }
        this.paginator.pageSize = this.token.getSynappxCloudPrintAdminPageSize(PAGINATION_SERVICE);
        this.paginator.pageIndex = 0;
        this.appDataService.debug();
        this.name = this.appDataService.UserName;
        this.email = this.appDataService.Email;
        this.tenantService.getTenantInfo();
        this.refreshUsers();
                this.idpCommonService.getIdpToken().subscribe((token) => {
            const portalScopes = ['Group.Read.All', 'Directory.Read.All', 'User.Read'];
            if (token) {
                const grantedScopes = token.scope.split(" ");
                const isGranted = portalScopes.every(scope => grantedScopes.indexOf(scope) > -1);
                if (!isGranted && (this.appDataService.scpRole == 'Tenant Admin')) {
                    this.showGrantLink();
                }
            }
        });
    }

    /**
     * handle the + button click.
     * @description This method will show the dialog to import Admin User from office 365
     */
    addNew() {
        this.disableTooltip = true;
        const addDialogRef = this.dialog.open(
            AddAdminComponent,
            this.defaultDialogConfig
        );
        addDialogRef.afterClosed().subscribe((dialogResult) => {
            if (dialogResult !== null || dialogResult !== undefined) {
                if (dialogResult === 1) {
                    this.refreshUsers();
                }
            }
            this.clearSelectionAfterAction();
            this.disableTooltip = false;
        });
    }
    showGrantLink() {
        this.showGrantMsg = true;
        this.translate.get('GrantPermissionsMessage', {
            ahref_start: `<b><a style="color: #12a7bb;cursor: pointer" class="grant_link"><u>`,
            ahref_end: `</u></a></b>`,
        }).subscribe((GrantPermissions: string) => {
            this.GrantPermissions = this.sanitizer.bypassSecurityTrustHtml(GrantPermissions);
        });
        this.changeDetectorRef.detectChanges();
        const anchor = this.elementRef.nativeElement.querySelector('.grant_link');
        if (anchor) {
            anchor.addEventListener('click', () => {
                this.openAdminApprovalDialog();
            });
        }
    }

    /**
     * @description opens Permissions Required dialog
     */
    openAdminApprovalDialog() {
        this.adminApprovalDialogRef = this.dialog.open(AdminApprovalComponent, {
            width: '40%',
            minHeight: '20%',
            disableClose: true
        });
    }

    /**
     * Get the role (to Display) for given user
     * @param user The user record
     * @returns The user role to display in the UI
     */
    getUserRole(user: any) {
        // console.log(`userType: ${user.cpInfo.userType}`);
        if (user.userInfo.isRootAdmin) {
            return this.translate.instant('PrimaryAdmin');
        }
        return this.translate.instant(ROLE_MAP[user.cpInfo.userType]);
    }

    /**
     *
     * @param adminUser // selected admin user to delete
     * @returns Details of admin user
     */
    adminUserDetails(adminUser) {
        // Prepare the userInfo part for current user (re-use existing data, except name and email)
        let userInfo = null;
        if (adminUser) {
            userInfo = adminUser.userInfo;
        } else {
            userInfo = {
                userName: adminUser?.userInfo.userName,
                email: adminUser?.userInfo.email,
                isRootAdmin: adminUser?.userInfo.isRootAdmin || false,
                userType: adminUser?.userInfo.userType || '',
                adminType: adminUser?.userInfo.adminType || ''
            };
        }
        // The cpInfo is regular admin
        const cpInfo = {
            role: 'Tenant Admin',
            userType: 'tenant_admin'
        };
        // For rmmInfo, re-use existing data
        return new AdminInfo(userInfo, adminUser?.rmmInfo, cpInfo);
    }

    /**
     * @description This methods handles table data refresh after admin user is added or deleted.
     */
    refreshUsers() {
        this.hideContent = false;
        this.progressLoader.show();
        this.paginator.pageIndex = 0;
        this.sort.active = 'Admin';
        this.sort.direction = 'asc';
        this.dataSource = new UsersDataSource(
            this.userService,
            this.paginator,
            this.sort,
            this.cpAdminRolesToRetrieve()
        );
        this.userService.dataChangeStatus.subscribe(() => {
            this.loadingStatus = false;
            this.hideContent = false;
            this.progressLoader.hide();
        });

        // });
    }

    /**
     * This method is used to determine if delete button should be displayed for a given row
     * @param element The data (user element) of the target row
     * @returns false if the user element is primary admin, or currently logged in user. true for all other users
     */
    showDelete(element) {
        if (element.userInfo.email.toLowerCase() == this.email.toLowerCase() || element.userInfo.isRootAdmin == true) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * @description This method is used to clear checkbox selection after admin user is deleted.
     */
    clearSelectionAfterAction() {
        this.showInfoMsg = false;
        this.selection.clear();
        this.isAllSelected();
        this.progressLoader.hideNavigationProgress();
    }

    /**
     * @param event event is either true/false depending upon checked/unchecked checkbox.
     * @param row element selected/not selected when corresponding row is checked/unchecked.
     */
    checkSelectedUser(event, row) {
        if (event.checked === true) {
            this.selection.select(row);
        } else {
            this.selection.deselect(row);
        }
        this.isAllSelected();
    }

    /**
     * @description checks if all the ckeckboxes is selected in admin user page table
     */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.renderedData.length;
        const hasValue = this.selection.hasValue();
        this.IsAllSelectedProp = numSelected === numRows && hasValue;
        // Disable if Primary Admin and Current User are in selected list
        let primaryOrCurrentAdmin = this.selection.selected.filter(
            (adminUser) =>
                adminUser.userInfo.isRootAdmin ||
                adminUser.userInfo.email.toLowerCase() == this.email.toLowerCase()
        );
        // Disable if admin roles are higher than Service Main
        let nonRemovableAdmins = [];
        if (
            numSelected == 0 ||
            primaryOrCurrentAdmin.length > 0 ||
            nonRemovableAdmins.length > 0
        ) {
            this.deleteButtonDisabled = true;
        } else {
            this.deleteButtonDisabled = false;
        }
    }

    /**
     * @description selects all the check box in admin user page table.
     */
    selectAllButton() {
        if (this.IsAllSelectedProp) {
            this.selection.clear();
        } else {
            this.dataSource.renderedData.forEach((row) => {
                this.selection.select(row);
            });
        }
        this.isAllSelected();
    }

    /**
     * @description remove admin users based on selected checkbox in admin user page table.
     */
    removeSelectedAdminUser() {
        // Remove Primary Admin and Current User
        const deleteAdmins = this.selection.selected.filter(
            (adminUser) =>
                !adminUser.userInfo.isRootAdmin || // remove primary admin from the list
                adminUser.userInfo.email.toLowerCase() !== this.email.toLowerCase() // remove currently logged-in user from the list
        );
        // If after filtering, there is no admin user to delete, return
        if (!deleteAdmins || deleteAdmins.length == 0) {
            return;
        }

        // Show the common delete confirmation warning for selected users
        this.deleteButtonDisabled = true;
        this.defaultDialogConfig.data = {
            title: [this.translate.instant('RemoveAdminUser')],
            showOption: false,
            message: [this.translate.instant('Confirmuserdeletion')],
            otherServices: []
        };
        const removeDialogRef = this.dialog.open(ConfirmDeleteComponent, this.defaultDialogConfig);
        removeDialogRef.afterClosed().subscribe((result) => {
            if (result?.action === true) {
                // User Clicked OK button on delete user(s) confirmation dialog
                const nextAdmin = deleteAdmins.shift();
                this.deleteAdminUser(nextAdmin, true, deleteAdmins);
            } else {
                // User clicked 'Cancel' button, or Esc for delete Confirmation dialog
                this.clearSelectionAfterAction();
            }
        });
    }

    /**
     * Update the user type properties based on the user data from server
     * @param queryResult The query result from query user API call
     * @returns none
     */
    updateUserTypeFromQueryResult(queryResult: Array<any>) {
        let user = null;
        if (queryResult && queryResult.length > 0) {
            user = queryResult[0];
        }
        this.dbUserType = user?.userInfo?.userType || '';
        this.goAdminType = user?.userInfo?.adminType || '';
        this.rmmAdminType = user?.rmmInfo?.role || '';
        this.cpAdminType = user?.cpInfo?.role || '';
    }

    /**
     * Check if the selected user is a valid admin in any other service
     * @returns true if the selected user an 'IT Main' in RMM or 'Admin' in Go/Meeting
     */
    isSelectedUserAnAdminInOtherService() {
        this.tenantTier = this.appDataService.TenantTier;
        if ((this.tenantTier.indexOf('rmm') >= 0 && this.rmmAdminType == 'IT Main') ||
            ((this.tenantTier.indexOf('go') >= 0 || this.tenantTier.indexOf('meeting') >= 0) && this.goAdminType == 'Admin')) {
            return true;
        }
        return false;
    }

    /**
     * Get the list of other services where the target user is already an admin and also
     * the currently logged-in user has enough access to remove the admin rights
     * @param selectedUser The user to be removed
     * @returns An array of string containing service names (of other services as string. Value values: rmm, go)
     */
    availableServicesToDelete(selectedUser: any): Array<string> {
        const otherServicesToDelete = [];

        // If the queryResult for user does not return a valid user, then there is no service to delete
        // return empty array
        if (!selectedUser) {
            return otherServicesToDelete;
        }

        const tenantData = this.appDataService.TenantInfoAtLogin;
        // Current tenant has Synappx Manage License & target user has Synappx Manage admin role &
        // the currently logged-in user is an admin in Synappx Manage
        if (tenantData.tier?.rmm && this.appDataService.ViewRMM &&
            RMM_ADMIN_ROLES.includes(selectedUser?.rmmInfo?.role)) {
            otherServicesToDelete.push('rmm');
        }
        // Current tenant has Synappx Go License & target user has Synappx Go admin role &
        // the currently logged-in user is an admin in Synappx Go
        if ((tenantData.tier?.rocketStart || tenantData.tier?.marvel) && (this.appDataService.ViewGo || this.appDataService.ViewMeeting)) {
            // 'userType' field does not have 'ba' and 'adminType' field is empty
            if ((selectedUser?.userInfo?.userType || '').toLowerCase().indexOf('ba') >= 0 &&
                (GO_ADMIN_ROLES.includes(selectedUser?.userInfo?.adminType))) {
                otherServicesToDelete.push('synappx');
            }
        }

        // return the list of other services this can be added to
        return otherServicesToDelete;
    }

    /**
     * Delete a single admin user after showing either select services dialog, or delete confirmation dialog
     * @param selectedUser user details of user to be deleted
     * @param multiDelete If check box selected in admin user page and click on minus button
     *  value will be true or false if click on delete button for any adim in the table of admin user page.
     * @param remainingAdmins (optional) remaining admin users to delete. default value is empty array ([])
     *
     * @description This method will first show a select services dialog (if current user and target user both have access to other services) or
     * delete confirmation dialog and then call the delete user API to delete the user
     *
     * In the server side, the user record may either be updated to remove admin role for the current user. In case after removal of admin roles,
     * if the user does not have any other admin/user roles in current/other services, the user record itself will be removed.
     *
     */
    deleteAdminUser(selectedUser: any, multiDelete: boolean, remainingAdmins = []) {
        this.progressLoader.hideNavigationProgress();
        // Find if the target user is also admin in other services (Go, Manage, etc)
        let otherServices = this.availableServicesToDelete(selectedUser);

        // Get the list of other services where this user can be added as admin
        // showOption is true to show the 'select other services part' of the ConfirmDeleteComponent
        // showOption is false to show only title,confirm delete message, ok, cancel buttons in ConfirmDeleteComponent
        let showOption = (otherServices && otherServices.length) ? true : false;

        // The dialog configuration data for the ConfirmDelete component
        this.defaultDialogConfig.data = {
            title: [this.translate.instant('RemoveAdminUser')],
            showOption: showOption,
            otherServices: otherServices,
            message: (this.translate.instant('ConfirmRemoveAdmin', {
                UserName: selectedUser.userInfo.userName,
            }))
        };

        const removeDialogRef = this.dialog.open(ConfirmDeleteComponent, this.defaultDialogConfig);
        removeDialogRef.afterClosed().subscribe((result) => {
            // Ok button clicked on ConfirmDeleteComponent.
            if (result?.action) {
                const services = (result?.toDelete || []).join(',');
                // Call deleteAdmin method
                this.deleteAdmin(services, selectedUser, multiDelete, remainingAdmins);
            } else {
                console.log(`ConfirmDeleteComponent 'Cancel' for ${selectedUser.userInfo.email} / remaining: ${remainingAdmins.length} ...`);
                // Cancel button clicked, or mouse click on outside ConfirmDeleteComponent, or Esc key pressed
                // Cancel deletion of current user only. But proceed with delete of next user
                if (remainingAdmins && remainingAdmins.length > 0) {
                    // remove first item in array and assign is as nextAdmin
                    // remainingAdmins array will be updated automatically by .shift() method
                    const nextAdmin = remainingAdmins.shift();
                    this.deleteAdminUser(nextAdmin, true, remainingAdmins);
                } else {
                    if (this.showInfoMsg) {
                        this.notificationService.showNotification(`AdminRemoved`);
                    }
                    this.clearSelectionAfterAction();
                    this.refreshUsers();
                }
            }
        });
    }

    /**
     * @description Service call to delete preferences before synappx admin is removed.
     * @param services The comma separated list of services from where the target user should be removed as admin
     * @param element user to be deleted.
     * @param multiDelete Its either true/false. True if checkbox is selected in admin user page table
     * and false if delete button is clicked to remove single user.
     * @param remainingAdmins remaining admin to be deleted.
     */
    deleteAdmin(services: string, selectedUser, multiDelete: boolean, remainingAdmins?) {
        this.progressLoader.showNavigationProgress();
        const findUserQuery = { email: selectedUser.userInfo.email };
        this.userService.queryUser(findUserQuery, 'ba', 'Admin', 'asc').subscribe({
            next: (queryResult) => {
                // If user with same email not found in DB, cancel operation
                if (!queryResult || queryResult.length == 0) {
                    console.error(`Failed to remove admin user: ${selectedUser.userInfo.email}. User not found.`);
                    this.notificationService.showNotification(`AdminDeleteFailure`);
                    this.clearSelectionAfterAction();
                    this.refreshUsers();
                    return;
                }
                const userResult = queryResult[0];
                // User should be in Database, and cannot be RootAdmin.
                if (userResult.userInfo.isRootAdmin) {
                    console.error(`Failed to remove admin user: ${selectedUser.userInfo.email}. Invalid user type: ${userResult.userInfo.userType}.`);
                    this.notificationService.showNotification(`AdminDeleteFailure`);
                    this.clearSelectionAfterAction();
                    this.refreshUsers();
                }
                if (services.indexOf('synappx') > -1) {
                    // This is for deleting the preferences before synappx admin is removed.
                    this.sspGenericService.deletePreferences(selectedUser.userInfo.email).subscribe({
                        next: (res) => {
                            this.removeAdminUserFromDB(userResult, services, selectedUser, multiDelete, remainingAdmins);
                        },
                        error: (error) => {
                            console.error(`Failed to remove admin user: ${selectedUser.userInfo.email}. Delete operation failed due error in deleting the subscriptions`);
                            console.log(error);
                            this.notificationService.showNotification(`AdminDeleteFailure`);
                            this.clearSelectionAfterAction();
                            this.refreshUsers();
                        }
                    });
                } else {
                    this.removeAdminUserFromDB(userResult, services, selectedUser, multiDelete, remainingAdmins);
                }
            },
            error: (error) => {
                console.error(`Failed to remove admin user: ${selectedUser.userInfo.email}. Query user failed`);
                console.log(error);
                this.notificationService.showNotification(`AdminDeleteFailure`);
                this.clearSelectionAfterAction();
                this.refreshUsers();
            }
        });
    }

    /**
     *
     * @description Service call to delete admin user.
     * @param userResult User data fetched from Database.
     * @param services The comma separated list of services from where the target user should be removed as admin
     * @param element user to be deleted.
     * @param multiDelete Its either true/false. True if checkbox is selected in admin user page table
     * and false if delete button is clicked to remove single user.
     * @param remainingAdmins remaining admin to be deleted.
     */
    removeAdminUserFromDB(userResult: any, services: string, selectedUser, multiDelete: boolean, remainingAdmins?) {
        this.userService.deleteUser(userResult, 'ba', services).subscribe({
            next: (deleteResult) => {
                this.showInfoMsg = true;
                if (remainingAdmins && remainingAdmins.length == 0) {
                    this.notificationService.showNotification(`AdminRemoved`);
                    this.clearSelectionAfterAction();
                    this.refreshUsers();
                }
                if (remainingAdmins && remainingAdmins.length > 0) {
                    const nextAdmin = remainingAdmins.shift();
                    this.deleteAdminUser(nextAdmin, true, remainingAdmins);
                }
                if (!multiDelete) {
                    this.notificationService.showNotification(`AdminRemoved`);
                    this.clearSelectionAfterAction();
                    this.refreshUsers();
                }
            },
            error: (error) => {
                console.error(`Failed to remove admin user: ${selectedUser.userInfo.email}. Delete operation failed`);
                console.log(error);
                this.notificationService.showNotification(`AdminDeleteFailure`);
                this.clearSelectionAfterAction();
                this.refreshUsers();
            }
        });
    }

    onPagination(pageEvent: PageEvent) {
        this.loadingStatus = true;
        const previousPageSize = this.token.getSynappxCloudPrintAdminPageSize(PAGINATION_SERVICE);
        if (previousPageSize != pageEvent.pageSize) {
            this.token.setSynappxCloudPrintAdminPageSize(pageEvent.pageSize, PAGINATION_SERVICE);
            this.clearSelectionAfterAction();
            // Retrive 1st set of items if the previous page size is different from the selected page size
            this.dataSource.getUsersList(pageEvent.pageSize, 0, true, this.cpAdminRolesToRetrieve());
        } else {
            this.clearSelectionAfterAction();
            this.dataSource.getUsersList(pageEvent.pageSize, pageEvent.pageIndex, false, this.cpAdminRolesToRetrieve());
        }
    }

    /**
     * @description role list of cloud print.
     * @returns returns role list of cloud print as an object of string.
     */
    cpAdminRolesToRetrieve() {
        return { scpUserType: CP_ADMIN_TYPE.userType };
    }

    // Call User API to get the items on sorting
    onSorting() {
        this.clearSelectionAfterAction();
        if (this.sort) {
            this.dataSource.getUsersList(this.paginator.pageSize, 0, false, this.cpAdminRolesToRetrieve());
        }
    }

}
