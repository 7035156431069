import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Directive, OnDestroy, inject } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppService } from '../../layout/app.service';

@Directive()
export abstract class BaseComponent implements OnDestroy {
  platform = inject(Platform);
  window = inject(DOCUMENT).defaultView;
  dialog = inject(MatDialog);
  appService = inject(AppService);
  translate = inject(TranslateService);

  destroy$: Subject<void> = new Subject();

  constructor() {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  takeUntilDestroyed<T>() {
    return takeUntil<T>(this.destroy$);
  }

  validateAllFormFields(form: FormGroup | FormArray, parentCtrlPath = 'root'): boolean {
    let hasErrors = false;

    Object.entries(form.controls).forEach(([formCtrlName, formCtrl]: [string, FormGroup | FormArray]) => {
      const formCtrlPath = `${parentCtrlPath}.${formCtrlName}`;

      formCtrl.markAsTouched();

      if (formCtrl.errors) {
        hasErrors = true;
        console.warn('validateAllFormFields', formCtrlPath, formCtrl.errors);
      }

      if (formCtrl.controls) {
        hasErrors = this.validateAllFormFields(formCtrl, formCtrlPath) || hasErrors;
      }
    });

    return hasErrors;
  }

  defaultSortingDataAccessor(data: any, sortHeaderId: string) {
    const currentValue = data[sortHeaderId];

    if (typeof currentValue === 'string') {
      return currentValue.toLocaleLowerCase();
    }

    return currentValue;
  }

  scrollElementIntoView(element: Element | HTMLElement) {
    if (element) {
      // TODO: Check for latest scrollIntoView support
      if (this.platform.TRIDENT || this.platform.SAFARI) {
        window.scrollBy(element.scrollLeft, element.getBoundingClientRect().top - window.innerHeight / 2);
      } else {
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }

      if (element instanceof HTMLElement) {
        element.focus();
      }
    }
  }

  validationRegExp = {
    all: /^[\s\S\d\D]*$/,

    alpha: /^[a-zA-Z]*$/,
    alphaEx: /^[a-zA-Z .,'-]*$/,
    alphaEx1: /^[a-zA-Z '-]*$/,
    alphaNumeric: /^[a-zA-Z0-9]*$/,
    alphaNumericEx: /^[a-zA-Z0-9 .,'-]*$/,
    alphaNumericEx1: /^[a-zA-Z0-9 .,'&/-]*$/,
    alphaSymbols: /^\D*$/, // Any non-digit

    numeric: /^\d*$/, // Any digit
    integer: /^\d\-/,
    positiveInteger: /^\d/,
    decimal: /^\d\.\-/,
    positiveDecimal: /^\d\./,

    phoneNumber: /^\(?\+?[\d()-]{0,15}$/,
    ukMobilePhone: /^(07)\d{9}$/,

    macAddress: /^([0-9A-Fa-f]{2}[:-\s]){5}([0-9A-Fa-f]{2})$/, // NOTE: Only : and - are allowed as a separator, but the SCP API is sending space as separator
    ipAddress: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
    email:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  };
}
