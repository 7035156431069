<div class="scp_theme mat-typography">
  <h2 mat-dialog-title>{{ 'scp.setup_guide.heading' | translate }}</h2>

  <mat-dialog-content>
    <mat-list role="list" fxLayout="column wrap" fxLayoutGap="8px">
      <mat-list-item role="listitem">
        <h3 matListItemTitle>{{ 'scp.setup_guide.step1.heading' | translate }}</h3>
        <p matListItemLine>{{ 'scp.setup_guide.step1.description' | translate }}</p>

        <span matListItemMeta class="my-1">
          <a [href]="'scp.setup_guide.step1.ms_store_link_url' | translate" target="_blank">
            <img
              width="130px"
              [src]="'scp.setup_guide.step1.ms_store_link_img_url' | translate"
              [alt]="'scp.setup_guide.step1.ms_store_link_img_alt_text' | translate"
            />
          </a>
        </span>
      </mat-list-item>
      <mat-divider></mat-divider>

      <mat-list-item role="listitem">
        <h3 matListItemTitle>{{ 'scp.setup_guide.step2.heading' | translate }}</h3>
        <p matListItemLine>{{ 'scp.setup_guide.step2.description' | translate }}</p>

        <span matListItemMeta class="my-2">
          <a mat-flat-button color="primary" mat-dialog-close="yes" [routerLink]="SCPRouterLinks.devices">
            <mat-icon>print</mat-icon> {{ 'scp.setup_guide.step2.link_text' | translate }}
          </a>
        </span>
      </mat-list-item>
      <mat-divider></mat-divider>

      <mat-list-item role="listitem">
        <h3 matListItemTitle>{{ 'scp.setup_guide.step3.heading' | translate }}</h3>
        <p matListItemLine>{{ 'scp.setup_guide.step3.description' | translate }}</p>

        <span matListItemMeta class="my-2">
          <a mat-dialog-close="yes" [href]="'scp.setup_guide.step3.link_url' | translate" target="_blank">
            {{ 'scp.setup_guide.step3.link_text' | translate }}
          </a>
        </span>
      </mat-list-item>
      <mat-divider></mat-divider>

      <mat-list-item role="listitem">
        <h3 matListItemTitle>{{ 'scp.setup_guide.step4.heading' | translate }}</h3>
        <p matListItemLine>{{ 'scp.setup_guide.step4.description' | translate }}</p>

        <span matListItemMeta class="my-2">
          <a mat-flat-button color="primary" mat-dialog-close="yes" [routerLink]="SCPRouterLinks.users">
            <mat-icon>supervised_user_circle</mat-icon> {{ 'scp.setup_guide.step4.link_text' | translate }}
          </a>
        </span>
      </mat-list-item>
      <mat-divider></mat-divider>

      <mat-list-item role="listitem">
        <h3 matListItemTitle>{{ 'scp.setup_guide.step5.heading' | translate }}</h3>
        <p matListItemLine>{{ 'scp.setup_guide.step5.description' | translate }}</p>

        <span matListItemMeta class="my-2">
          <a mat-flat-button color="primary" mat-dialog-close="yes" [routerLink]="SCPRouterLinks.security">
            <mat-icon>settings</mat-icon> {{ 'scp.setup_guide.step5.link_text' | translate }}
          </a>
        </span>
      </mat-list-item>
      <mat-divider></mat-divider>

      <mat-list-item role="listitem">
        <p matListItemLine class="pt-2">{{ 'scp.setup_guide.step6.description' | translate }}</p>

        <span matListItemMeta class="my-2 pr-4">
          <a mat-dialog-close="yes" [href]="'scp.setup_guide.step6.link_url' | translate" target="_blank">
            {{ 'scp.setup_guide.step6.link_text' | translate }}
          </a>
        </span>
      </mat-list-item>
    </mat-list>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <span>{{ 'scp.setup_guide.see_guide_again_text' | translate }}</span>
    <button mat-flat-button color="primary" mat-dialog-close="yes" class="ml-3">{{ 'scp.common.yes_button_text' | translate }}</button>
    <button mat-flat-button mat-dialog-close="no">{{ 'scp.common.no_button_text' | translate }}</button>
  </mat-dialog-actions>
</div>
