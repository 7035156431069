import { Injectable } from '@angular/core';
import { SubDialogInfo } from '../../interfaces/dialog';
import { DialogService } from './dialog.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SspGenericService } from './ssp-generic.service';
import { NotificationService } from './notification.service';

@Injectable({
    providedIn: 'root'
})
export class ManageDeviceService {

    constructor(
        private dialogService: DialogService,
        private http: HttpClient,
        private sspApiService: SspGenericService,
        private notificationService: NotificationService,
    ) { }


    deleteAgentDevice(ids, type: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-type': 'application/json',
                'devicetype': type
            }),
            body: ids,
        };
        return this.http.delete<any>(
            this.sspApiService.deleteAgentDeviceURL, httpOptions,
        );
    }

    /// This function is used to remove or delete devices such as Display devices, MFP devices, Check In devices, Orphan Devices and Agents.
    public removeDevices(data: any) {
        return new Promise((resolve, reject) => {
            const subDialogInfo: SubDialogInfo = {
                title: data.title ? data.title : '',
                mainMessage: data.mainMessage,
                placeHolderVariables: data.placeHolderVariables,
            }
            this.dialogService.open(subDialogInfo);
            this.dialogService.confirmed().subscribe((result) => {
                if (result === 1) {
                    //Indicates Action Confirmed
                    this.deleteAgentDevice(data.params, data.deviceType).subscribe(
                        (result) => {
                            this.notificationService.showNotification(data.successNotificationMsg ? data.successNotificationMsg : 'MvlDeviceDelete');
                            resolve(1);
                        },
                        (err) => {
                            if (err.error && err.error.errorCode === 56 && data.failureNotificationMsg) {
                                //Indicates NO_IOT_DEVICE_FOUND
                                this.notificationService.showNotification(data.failureNotificationMsg);
                            } else {
                                this.notificationService.showNotification(data.failureNotificationMsg ? data.failureNotificationMsg : 'DeleteDeviceFailure');
                            }
                            reject(0);
                        },
                    )
                }
                else {
                    //Indicates Action Cancelled
                    resolve(0);
                }
            });
        })
    }
}
