import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenStorageService } from './token-storage.service';
import dayjs from 'dayjs';
import { LocalAppDataService } from '../local-app-data.service';
import { SspGenericService } from './ssp-generic.service';
import { CloudPrintEnvService } from '../cloud-print-env.service';
@Injectable({
    providedIn: 'root'
})
export class LogHandlerService {
    logSchema: any;
    timezone: string;
    public userName;
    public userEmail;
    public tenantId;
    public userRole;
    public rmmRole;
    public cpUserRole;
    private loggedInRole;

    public opDetails: any;
    public opDetailsPattern: any;
    public serviceMeeting: any;
    public serviceGo: any;
    public service: any;
    constructor(
        protected http: HttpClient,
        protected token: TokenStorageService,
        protected appDataService: LocalAppDataService,
        protected sspApiService: SspGenericService,
        private scpEnvService: CloudPrintEnvService,
    ) { }

    public logTemplate(user?, email?, role?) {
        const timeZone = dayjs().format('Z');
        const logDateTime = dayjs().format('YYYY-MM-DDTHH:mm:ss') + timeZone;
        const newLog = {
            tenant_id: this.appDataService.ConnectionName,
            log_generated_date: logDateTime,
            time_zone: dayjs().format('Z'),
            user_name: this.appDataService.UserName,
            user_mail: this.appDataService.Email,
            user_role: (this.appDataService.scpRole === 'Tenant Admin') ? 'Admin' : 'User', 
            application: 'Admin Portal',
            app_version: this.scpEnvService.appVersion,
            hostname: '',
            device_type: navigator.userAgent,
            device_name: '',
        };
        return newLog;
    }

    /**
  * @param category The category of that we are logging the action for (USER, WORKSPACE, etc)
  * @param type The type of action performed
  * @param detail The details of the action performed
  */
    addSystemLogCustom(detail, category, type, log_type_id) {
        const sysLogCustom = this.logTemplate();
        sysLogCustom['schema'] = 'synappx_system_v1';
        sysLogCustom['detail'] = detail;
        sysLogCustom['log_category'] = category;
        sysLogCustom['log_type'] = 'Failed to ' + type;
        sysLogCustom['log_type_id'] = log_type_id;
        sysLogCustom['device_id'] = '';
        sysLogCustom['user_role'] = sysLogCustom['user_role'].replace('Tenant Admin', 'Admin');
        this.addToQueue(sysLogCustom);
    }

    /**
     * @param category The category of that we are logging the action for (USER, WORKSPACE, etc)
     * @param action The action performed
     * @param detail The details of the action performed
     */
    addAdminLog(category, action, actionId, detail, service?) {
        const serviceName = "Cloud Print";
        const adminLog = this.logTemplate();
        let usrRole = this.userRole;
        if (service) {
            if (service == 'Cloud Print') {
                usrRole = this.cpUserRole;
            }
            adminLog['user_role'] = usrRole;
        }
        adminLog['user_role'] = adminLog['user_role'].replace('Tenant Admin', 'Admin');
        switch (service) {
            case 'rocketstart':
            case 'Meeting':
                service = 'Go';
                break;
            case 'marvel':
                service = 'Go';
                break;
            case 'rmm':
                service = 'Manage';
                break;
            case 'scp':
                service = 'Cloud Print';
        }
        adminLog['schema'] = 'synappx_admin_v1';
        adminLog['service_name'] = service || serviceName;
        adminLog['log_category'] = category;
        adminLog['action'] = action;
        adminLog['action_id'] = actionId;
        adminLog['detail'] = detail;
        this.addToQueue(adminLog);
    }

    addToQueue(logData) {
        this.postLog(logData).subscribe(result => {
            //console.log(`logged: ${logData.log_type}`);
        }, err => console.log(err));
    }

    postLog(logData) {
        // Post this log information to log server
        const httpOptions: Object = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            responseType: 'text'
        };
        const additionalData = {};
        const requestBody = Object.assign({}, logData, additionalData);
        return this.http.post<any>(this.sspApiService.logstashUrl, requestBody, httpOptions);
    }

}
