import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProgressLoaderService } from '../../services/progress-loader.service';
import { ProgressLoaderState, NavigationProgressState } from '../../interfaces/Iprogressloader';

@Component({
  selector: 'app-progress-loader',
  templateUrl: './progress-loader.component.html',
  styleUrls: ['./progress-loader.component.scss'],
})
export class ProgressLoaderComponent implements OnInit {
  show = false;
  private enableProgressLoaderInPortal = true; //Set this flag to 'false' to disbale progress loader in portal
  private subscription: Subscription;

  navigationProgress = false;
  private navigationSubscription: Subscription;

  constructor(
    private progressLoader: ProgressLoaderService,
  ) { }

  ngOnInit() {
    this.subscription = this.progressLoader.loaderState.subscribe((state: ProgressLoaderState) => {
      this.show = this.enableProgressLoaderInPortal ? state.show : false;
    });
      this.navigationSubscription = this.progressLoader.navigationLoaderState.subscribe((state: NavigationProgressState) => {
        this.navigationProgress = this.enableProgressLoaderInPortal ? state.navigationProgress : false;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.navigationSubscription.unsubscribe();
  }

}
