import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { BaseComponent } from '../../../shared/components/base.component';

@Component({
  selector: 'app-reports-execute-option',
  templateUrl: './reports-execute.component.html',
})
export class ReportsExecuteComponent extends BaseComponent {
  isSubmitting = false;

  reportName: string;
  reportGuid: string;

  executeFormGroup: FormGroup;
  fromDateFormCtrl: FormControl;
  toDateFormCtrl: FormControl;
  exportTypeFormCtrl: FormControl;

  currentDate;
  maxDate;
  monthStart;
  asOfDate;

  constructor(@Inject(MAT_DIALOG_DATA) data: any, private dialogRef: MatDialogRef<ReportsExecuteComponent>) {
    super();

    this.currentDate = moment();
    this.maxDate = moment(this.currentDate);
    if (this.currentDate.date() === 1) {
      this.monthStart = moment().subtract(1, 'days').startOf('month').toDate();
      this.asOfDate = moment().subtract(1, 'days').toDate();
    } else {
      this.monthStart = moment().startOf('month').toDate();
      this.asOfDate = moment().subtract(1, 'days').toDate();
    }
    this.monthStart.setHours(0, 0, 0, 0);
    this.asOfDate.setHours(23, 59, 59, 0);

    this.reportName = this.hasTranslation('scp.report.' + data?.name.replaceAll(' ', '').toLowerCase())
      ? this.translate.instant('scp.report.' + data?.name.replaceAll(' ', '').toLowerCase())
      : data?.name;
    this.reportGuid = data?.guid;
    this.fromDateFormCtrl = new FormControl(this.monthStart, [Validators.required]);
    this.toDateFormCtrl = new FormControl(this.asOfDate, [Validators.required]);
    this.exportTypeFormCtrl = new FormControl('pdf', [Validators.required]);

    this.executeFormGroup = new FormGroup({
      fromDttm: this.fromDateFormCtrl,
      toDttm: this.toDateFormCtrl,
      format: this.exportTypeFormCtrl,
    });
  }

  hasTranslation(key) {
    const translation = this.translate.instant(key);
    return translation !== key && translation !== '';
  }

  exportReport() {
    if (this.executeFormGroup.valid) {
      this.isSubmitting = true;

      var fromDttm = this.fromDateFormCtrl.value;
      var toDttm = this.toDateFormCtrl.value;
      fromDttm.setHours(0, 0, 0, 0);
      toDttm.setHours(23, 59, 59, 0);

      this.appService
        .exportReport(
          {
            fromDttm: moment(fromDttm).utc(),
            toDttm: moment(toDttm).utc(),
            timezoneOffset: moment().utcOffset(),
            locale: navigator.language,
            format: this.exportTypeFormCtrl.value,
          },
          this.reportGuid
        )
        .pipe(this.takeUntilDestroyed())
        .subscribe(
          (response: any) => {
            if (response == null) {
              // 204: No Content - https://httpstatuses.io/204
              this.appService.showNotification(this.translate.instant('scp.reports.execute.export.no_record_found'));

              this.isSubmitting = false;
            } else {
              let downloadLink = document.createElement('a');

              downloadLink.href = window.URL.createObjectURL(response);
              document.body.appendChild(downloadLink);
              downloadLink.download = this.reportName;
              downloadLink.click();
              document.body.removeChild(downloadLink);

              this.dialogRef.close(true);
            }
          },
          (err: HttpErrorResponse) => {
            this.isSubmitting = false;

            let errMsg = 'scp.reports.execute.export.export_error_message';

            // 409: Conflict error - https://httpstatuses.io/409

            this.appService.showError(this.translate.instant(errMsg));
          }
        );
    }
  }
}
