import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LogHandlerService {
    public userName;
    public userEmail;
    public tenantId;
    public userRole;
    public rmmRole;
    private loggedInRole;

    constructor() { }

    /**
       * @param category The category of that we are logging the action for (USER, WORKSPACE, etc)
       * @param action The action performed
       * @param detail The details of the action performed
       */
    public addAdminLog(category, action, actionId, detail, service?) {
        console.warn('AuthN.LogHandlerService.addAdminLog is not implemented');
        return;
    }

    /**
     *
     * @param httpMethod The httpMethod of the failed url (GET, POST, DELETE, PUT, etc)
     * @param httpUrl The httpUrl that failed
     * @param source The source of this request (can be null, or something like workspace, user, login, etc)
     * @param details The details of the error
     */
    public addSystemLog(httpMethod, httpUrl, details, featureType?, graph?, source?, operation?, userName?, email?, role?, logTypeId?) {
        // this.logHandlerService.addSystemLog('', '', 'Not Valid Administrator', '', '', 'Login', 'Login', loggedInUser, emailId, 'Unknown', 'MST20033');
        console.warn('AuthN.LogHandlerService.addSystemLog is not implemented');
        return;
    }

    public configureUserIdentity(options: {userName?: string, userEmail?: string, tenantId?: string, userRole?: string, rmmRole?: string}) {

    }
}
