import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class MeetingServiceGuard implements CanActivate, CanLoad {


    public isRocketStartActive = true;

    constructor(private authService: AuthService) {
        this.isRocketStartActive = this.authService.viewMeeting || this.authService.viewGo;
    }

    canActivate() {

        if (!this.isRocketStartActive) {
            return false;
        } else {
            return true;
        }
    }

    canLoad() {
        if (!this.isRocketStartActive) {
            return false;
        } else {
            return true;
        }
    }


}
