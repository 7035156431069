import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'notification-messages-component',
  templateUrl: 'notification-messages-component.html',
  styleUrls: ['./notification-messages-component.scss'],
})
export class NotificationMessageComponent {

  message: string;
  action: string;
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBarRef: MatSnackBarRef<NotificationMessageComponent>,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.message = this.translate.instant(this.data.message, this.data.interpolateParams);
    this.action = this.data.action;
  }

    /**
     * Ok butotn clicked on the snackbar
     */
    okClick() {
        // fire the okClick event in the notification service
        this.notificationService.okClickOnSnackBar.next(true);
        // Since ok button is handled explicitly, need to dismiss the dialog also explicitly
        this.snackBarRef.dismiss();
    }
}