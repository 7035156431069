<div [hidden]="isLoaded">
    <div *ngIf="!(differentRoleSection || removeRoleSection)" class="container custom_add_room">
        <h1 mat-dialog-title>{{ 'SelectServices' | translate }}</h1>
        <div mat-dialog-content class="font_15">
            <p>{{ 'SelectServiceMessage' | translate:{ email: this.email} }}</p>
            <div [ngClass]="{ disabled: addCloudPrint }">
                <mat-checkbox #synappx [disabled]="addCloudPrint" type="checkbox" id="synappx_cloud_print"
                    name="synappx_cloud_print" value="synappx_cloud_print" [checked]="addCloudPrint"
                    (change)="selectionChange($event.checked, 'scp')">
                    <span class="inner_label">{{ 'SynappxCloudPrintOrAdmin' | translate }}</span>
                </mat-checkbox>
            </div>
            <div *ngIf="showSynappxGo" [ngClass]="{ disabled: alreadyAddedGo }">
                <mat-checkbox #synappx [disabled]="alreadyAddedGo" type="checkbox" id="synappx" name="synappx"
                    value="synappx" [checked]="alreadyAddedGo" (change)="selectionChange($event.checked, 'synappx')">
                    <span class="inner_label">{{ 'SynappxGoOrAdmin' | translate }}</span>
                </mat-checkbox>
            </div>
            <div *ngIf="showRMM" [ngClass]="{ disabled: alreadyAddedRMM }">
                <mat-checkbox #rmm [disabled]="alreadyAddedRMM" type="checkbox" id="rmm" name="rmm" value="rmm"
                    [checked]="alreadyAddedRMM" (change)="selectionChange($event.checked, 'rmm')">
                    <span class="inner_label">{{ 'SynappxManageOrITMain' | translate }}</span>
                </mat-checkbox>
            </div>
        </div>
        <div class="text_right">
            <button class="scp_primary_btn" mat-raised-button [type]="submit" id="id-deviceEdit-SaveButtton"
                (click)="saveClicked()">{{'Save' | translate}}</button>
        </div>
    </div>
    <div *ngIf="differentRoleSection" class="container custom_add_room">
        <h1 mat-dialog-title>{{ 'DifferentRole' | translate }}</h1>
        <p>{{ roleAlreadyAssignedMessage }}</p>
        <p>{{ changeRoleMessage }}</p>
        <div class="text_right">
            <button mat-button type="submit" (click)="okClicked()" id="id-ok" class="primary_btn">OK</button>
        </div>
    </div>
    <div *ngIf="removeRoleSection" class="container custom_add_room">
        <h1 mat-dialog-title>{{ 'AdminAlreadyExists' | translate }}</h1>
        <p>{{ 'AdminAlreadyAssigned' | translate: { email: email, role: (changeRole), service: otherServiceName } }}</p>
        <p>{{ 'DeleteRole' | translate : { service: otherServiceName } }}</p>
        <div class="text_right">
            <button mat-button type="submit" (click)="okClicked()" id="id-ok" class="primary_btn">{{"Ok" |translate}}</button>
        </div>
    </div>
</div>