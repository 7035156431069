import { ComponentType } from '@angular/cdk/overlay';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Component, Injectable, TemplateRef } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import jwtDecode from 'jwt-decode';
import { Observable, forkJoin, from, of } from 'rxjs';
import { mergeMap, switchMap, map, tap } from 'rxjs/operators';

import { IDevice, IPrintFile, ITenantUser, IUserUpsert, IUserGroupUpsert } from '../shared/models/scp-model';
import { AppDataService } from '../shared/services/app-data.service';
import { CloudPrintEnvService } from '../shared/services/cloud-print-env.service';

const SCP_PAGE_SIZE_INFO = 'SCP_PAGE_SIZE_INFO';

export interface GoogleToken {
  access_token: String;
  expiry_date: Number;
  server_time: String;
  id_token: String;
  scope: String;
  token_type: String;
}

@Injectable({
  providedIn: 'root',
})
export class AppService {
  userDetails: any;
  tenantInfo: any;
  supportedDomains: any;
  isSCPAdminUser = false;
  isSCPUser = false;
  isSCPUserAccountActivated = true;
  pageSizeInfo;

  constructor(
    private http: HttpClient, // prettier-ignore
    private snackBar: MatSnackBar,
    private globalAppData: AppDataService,
    private envService: CloudPrintEnvService
  ) {}

  getIdpToken() {
    return this.globalAppData.getDynamic('idp-token').obj;
  }

  getSSPAuthToken() {
    return this.globalAppData.getDynamic('auth-info').obj;
  }

  getSCPAuthToken() {
    return this.getSSPAuthToken().pipe(
      switchMap((authToken) => {
        return this.http.get<any>(`${this.envService.scpCommonApiBaseUrl}/identity/user/auth0?service=AdminPortal`, {
          headers: { Authorization: `Bearer ${authToken}` },
        });
      }),
      tap((userDetails) => {
        this.userDetails = userDetails;
        this.isSCPUserAccountActivated = userDetails.enabled;
        this.tenantInfo = this.globalAppData.getStatic('tenant-info').obj;
        this.refreshSupportedDomain();
        this.pageSizeInfo = JSON.parse(window.localStorage.getItem(SCP_PAGE_SIZE_INFO)) || {};
      })
    );
  }

  validateAuthToken() {
    return this.getSSPAuthToken().pipe(
      switchMap((authToken) => {
        const { 'https://marvel.sharp.com/cpInfo': cpInfo } = jwtDecode<any>(authToken);

        if (cpInfo) {
          this.isSCPAdminUser = ['tenant_admin'].includes(cpInfo.userType);
          this.isSCPUser = ['tenant_user'].includes(cpInfo.userType);

          if (this.isSCPAdminUser || this.isSCPUser) {
            return of({ isSCPAdminUser: this.isSCPAdminUser, isSCPUser: this.isSCPUser, cpInfo });
          }
        }

        // No cpInfo / is neither isSCPAdminUser nor isSCPUser
        throw new HttpErrorResponse({ status: 401, statusText: 'No valid details in Auth0 token' });
      })
    );
  }

  isGoogleWorkspace() {
    if (this.globalAppData.getStatic('auth0-application-type').obj === 'google-apps') {
      return true;
    } else {
      return false;
    }
  }

  is365() {
    if (this.globalAppData.getStatic('auth0-application-type').obj === 'waad') {
      return true;
    } else {
      return false;
    }
  }

  refreshSupportedDomain() {
    this.supportedDomains = (this.tenantInfo.domainAliases || []).slice();
    let tenantDomain = this.tenantInfo.customerContactEmail.split('@')[1];
    tenantDomain = tenantDomain.toLowerCase();
    this.supportedDomains.push(tenantDomain);
  }

  getPageSizeInfo(pageName: string): number {
    return this.pageSizeInfo[pageName] || 25;
  }

  savePageSizeInfo(pageName: string, pageSize: number) {
    this.pageSizeInfo[pageName] = pageSize;

    window.localStorage.setItem(SCP_PAGE_SIZE_INFO, JSON.stringify(this.pageSizeInfo));
  }

  getSettings() {
    return this.http.get<any>(`${this.envService.scpCommonApiBaseUrl}/tenant/setting`);
  }

  updateSettings(data: any) {
    return this.http.put<any>(`${this.envService.scpCommonApiBaseUrl}/tenant/setting`, data);
  }

  getPrinters() {
    return this.http.get<IDevice[]>(`${this.envService.scpCommonApiBaseUrl}/tenant/printer`);
  }

  activatePrinter(guid) {
    return this.getSSPAuthToken().pipe(
      switchMap((authToken) => {
        return this.http.patch<any>(`${this.envService.scpCommonApiBaseUrl}/tenant/printer/${guid}/activate`, null, {
          headers: { 'X-Authorization': `Bearer ${authToken}` },
        });
      })
    );
  }

  deActivatePrinter(guid) {
    return this.getSSPAuthToken().pipe(
      switchMap((authToken) => {
        return this.http.patch<any>(`${this.envService.scpCommonApiBaseUrl}/tenant/printer/${guid}/deactivate`, null, {
          headers: { 'X-Authorization': `Bearer ${authToken}` },
        });
      })
    );
  }

  addMFPPrinterByIPRange(ipAddress: string) {
    return this.http.post<any>(
      `${this.envService.scpCommonApiBaseUrl}/tenant/printersearch?tenantPrinterSearchId=${this.userDetails.tenantGuid}&range=${ipAddress}`,
      null
    );
  }

  createPrinter(data, type) {
    return this.http.post<any>(`${this.envService.scpCommonApiBaseUrl}/tenant/printer/type/${type}`, data);
  }

  updatePrinter(data) {
    return this.http.put<any>(`${this.envService.scpCommonApiBaseUrl}/tenant/printer`, data);
  }

  deletePrinter(guid) {
      return this.http.delete<any>(`${this.envService.scpCommonApiBaseUrl}/tenant/printer/${guid}`);
  }

  getUsers() {
    return this.http.get<ITenantUser[]>(`${this.envService.scpCommonApiBaseUrl}/tenant/user`);
  }

  getUser(guid) {
    return this.http.get<ITenantUser>(`${this.envService.scpCommonApiBaseUrl}/tenant/user/${guid}`);
  }

  createUser(data: IUserUpsert[]) {
    return this.http.post<any>(`${this.envService.scpCommonApiBaseUrl}/tenant/user`, data);
  }

  createUserFromGroup(data: IUserGroupUpsert): Promise<any> {
    if (this.isGoogleWorkspace()) {
      const url = `https://www.googleapis.com/admin/directory/v1/groups/${data.groupid}/members`;

      return this.getIdpToken()
        .pipe(
          switchMap((idpInfo) => {
            return this.http
              .get<any>(url, {
                headers: { Authorization: `Bearer ${idpInfo.access_token}` },
              })
              .pipe(
                mergeMap((res) => {
                  if (res.members) {
                    const validType = res.members.filter((result) => result.type == 'USER');
                    const validEmailList = validType.map((ele) => ele.email);
                    // For each email, make a call to get additional user info
                    const $obsArr = validEmailList.map((ele) => this.getGoogleUserByEmail(ele));
                    // Combine results from all observables
                    return forkJoin($obsArr);
                  } else {
                    return from([]);
                  }
                })
              );
          })
        )
        .toPromise()
        .then((response) => {
          return this.extractGroupMembersFromGoogle(response);
        })
        .catch((error) => console.log(error));
    } else {
      const url = `https://graph.microsoft.com/v1.0/groups/${data.groupid}/members`;

      return this.getIdpToken()
        .pipe(
          switchMap((idpInfo) => {
            return this.http
              .get<any>(url, {
                headers: { Authorization: `Bearer ${idpInfo.access_token}` },
              })
              .pipe(
                mergeMap((res) => {
                  if (res.value) {
                    const $obsArr = res.value.map((ele) => ele);
                    return of($obsArr);
                  } else {
                    return from([]);
                  }
                })
              );
          })
        )
        .toPromise()
        .then((response) => {
          return this.extractGroupMembersFrom365(response);
        })
        .catch((error) => console.log(error));
    }
  }

  getGoogleUserByEmail(email): Observable<any> {
    return new Observable((observer) => {
      this.getIdpToken().subscribe(
        (token) => {
          const URI = `https://www.googleapis.com/admin/directory/v1/users/${email}`;
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token.access_token,
              accept: '*/*',
            }),
          };
          this.http.get<any>(URI, httpOptions).subscribe(
            (res) => {
              observer.next(res);
              observer.complete();
            },
            (error) => {
              observer.error(error);
            }
          );
        },
        (err) => {
          console.error(`error occurred while obtaining google token`);
          console.log(err);
          observer.error(err);
        }
      );
    });
  }

  extractGroupMembersFromGoogle(res) {
    res = res || [];
    const body = res.map((val) => {
      return {
        displayname: val.name.fullName,
        firstname: val.name.givenName ?? '',
        lastname: val.name.familyName ?? '',
        username: val.emails.filter((data) => data.primary)[0].address,
      };
    });
    return Promise.resolve(body || []);
  }

  extractGroupMembersFrom365(res) {
    res = res || [];
    const body = res.map((val) => {
      return {
        displayname: val.displayName,
        firstname: val.givenName ?? '',
        lastname: val.surname ?? '',
        username: val.userPrincipalName,
      };
    });
    return Promise.resolve(body || []);
  }

  updateUser(guid, data: Partial<IUserUpsert>) {
    return this.http.put<any>(`${this.envService.scpCommonApiBaseUrl}/tenant/user/${guid}`, data);
  }

  deleteUser(guid) {
    return this.http.delete<any>(`${this.envService.scpCommonApiBaseUrl}/tenant/user/${guid}`);
  }

  activateUser(guid) {
    return this.http.patch<any>(`${this.envService.scpCommonApiBaseUrl}/tenant/user/${guid}/activate`, null);
  }

  deActivateUser(guid) {
    return this.http.patch<any>(`${this.envService.scpCommonApiBaseUrl}/tenant/user/${guid}/deactivate`, null);
  }

  removeUserCard(guid) {
    return this.http.delete<any>(`${this.envService.scpCommonApiBaseUrl}/tenant/user/${guid}/card`);
  }

  getUserPaperSaved(guid) {
    return this.http.get<any>(`${this.envService.scpCommonApiBaseUrl}/cachedata/userpagecount/${guid}`);
  }

  getTenantPaperSaved(guid) {
    return this.http.get<any>(`${this.envService.scpCommonApiBaseUrl}/cachedata/tenantpagecount/${guid}`);
  }

  getDepartments() {
    return this.http.get<any>(`${this.envService.scpCommonApiBaseUrl}/tenant/department`);
  }

  createDepartment(data) {
    return this.http.post<any>(`${this.envService.scpCommonApiBaseUrl}/tenant/department`, data);
  }

  updateDepartment(guid, data) {
    return this.http.put<any>(`${this.envService.scpCommonApiBaseUrl}/tenant/department/${guid}`, data);
  }

  getPrintQueueJobs() {
    return this.http.get<IPrintFile[]>(`${this.envService.scpCommonApiBaseUrl}/tenant/user/printfile`);
  }

  getPrintHistoryJobs(startDate, endDate) {
    return this.http.get<any>(`${this.envService.scpCommonApiBaseUrl}/tenant/user/job?fromDate=${startDate}&toDate=${endDate}`);
  }

  deletePrintJob(fileName: string, userGuid: string) {
    return this.http.delete<any>(`${this.envService.scpCommonApiBaseUrl}/tenant/user/printfile/${fileName}?userGuid=${userGuid}&fileName=${fileName}`);
  }

  deletePrintJobRemotely(data) {
    return this.http.put<any>(`${this.envService.scpCommonApiBaseUrl}/printerevent/publish`, data);
  }

  getLicenseInfo() {
    return this.http.get<any>(`${this.envService.sspLicenseApi}/subscription/details`, {
      headers: { feature_type: 'scp' }, // NOTE: Authorization header will be added by SSP HTTP interceptor.
    });
  }

  replaceAll(text, old, next) {
    const parts = text.split(old);
    return parts.join(next);
  }

  escapeQuotes(text) {
    text = this.replaceAll(text, "'", "\\'");
    text = this.replaceAll(text, '"', '\\"');
    return text;
  }

  findUsers(isGroupSearch, isEmailSearch, filterString) {
    if (this.isGoogleWorkspace()) {
      // Google Users API does not allow space followed by *.
      // So Remove * when the query contains space
      filterString = filterString.replace(/[&#"[\\\]+]/g, '_');
      filterString = this.escapeQuotes(filterString); // handling apostrophe in query
      // Return observable, if query has empty.
      if (filterString === '') {
        return of([]);
      }
      let url = ['https://www.googleapis.com/admin/directory/v1/'];

      let queryURL;

      if (isGroupSearch) {
        if (isEmailSearch) {
          queryURL = encodeURI(`email:${filterString.includes(' ') ? filterString : filterString + '*'}`);
        } else {
          queryURL = encodeURI(`name:${filterString.includes(' ') ? filterString : filterString + '*'}`);
        }
        url.push(`groups?customer=my_customer&maxResults=99&query=${queryURL}`);
      } else {
        if (isEmailSearch) {
          queryURL = encodeURI(`email:${filterString.includes(' ') ? filterString : filterString + '*'}`);
        } else {
          queryURL = encodeURI(`givenName:${filterString.includes(' ') ? filterString : filterString + '*'}`);
        }
        url.push(`users?customer=my_customer&maxResults=500&query=${queryURL}`);
      }

      return this.getIdpToken().pipe(
        switchMap((idpInfo) => {
          return this.http
            .get<any>(url.join(''), {
              headers: { Authorization: `Bearer ${idpInfo.access_token}` },
            })
            .pipe(
              map((res) => {
                if (isGroupSearch) {
                  console.log(res.groups);
                  return this.mapGoogleResultList(isGroupSearch, res.groups);
                } else {
                  return this.mapGoogleResultList(isGroupSearch, res.users);
                }
              })
            );
        })
      );
    } else {
      let url = ['https://graph.microsoft.com/v1.0/'];

      if (isGroupSearch) {
        const searchColumn = isEmailSearch ? 'userPrincipalName' : 'displayName';

        url.push(`groups?$expand=members&$top=99&$filter=startswith(${searchColumn},'${filterString}')`);
      } else {
        const searchColumn = isEmailSearch ? 'userPrincipalName' : 'displayName';

        url.push(
          `users?$select=id,displayName,givenName,surname,userPrincipalName,mail,userType,officeLocation&$top=999&$filter=startswith(${searchColumn},'${filterString}')`
        );
      }

      return this.getIdpToken().pipe(
        switchMap((idpInfo) => {
          return this.http
            .get<any>(url.join(''), {
              headers: { Authorization: `Bearer ${idpInfo.access_token}` },
            })
            .pipe(
              map((res) => {
                if (isGroupSearch) {
                  return this.map365ResultList(isGroupSearch, res.value);
                } else {
                  return this.map365ResultList(isGroupSearch, res.value);
                }
              })
            );
        })
      );
    }
  }

  mapGoogleResultList(isGroupSearch, arr) {
    arr = arr || [];
    const mappedList = arr.map((item) => {
      if (isGroupSearch) {
        return {
          groupname: item.name,
          groupemail: item.email,
          groupmembercount: item.directMembersCount,
          groupid: item.email,
        };
      } else {
        return {
          displayname: item.name.fullName,
          firstname: item.name.givenName,
          lastname: item.name.familyName,
          username: item.emails.filter((data) => data.primary)[0].address,
        };
      }
    });
    return mappedList || [];
  }

  map365ResultList(isGroupSearch, arr) {
    arr = arr || [];
    const mappedList = arr.map((item) => {
      if (isGroupSearch) {
        return {
          groupname: item.displayName,
          groupemail: item.mail,
          groupmembercount: item.members.length,
          groupid: item.id,
        };
      } else {
        return {
          displayname: item.displayName,
          firstname: item.givenName,
          lastname: item.surname,
          username: item.userPrincipalName,
        };
      }
    });
    return mappedList || [];
  }

  getReports() {
    return this.http.get<any>(`${this.envService.scpCommonApiBaseUrl}/report`);
  }

  getReportSchema() {
    return this.http.get<any>(`${this.envService.scpCommonApiBaseUrl}/report/schema`);
  }

  getReportTemplate(guid: string) {
    return this.http.get<any>(`${this.envService.scpCommonApiBaseUrl}/report/${guid}`);
  }

  createReport(data) {
    return this.http.post<any>(`${this.envService.scpCommonApiBaseUrl}/report`, data);
  }

  updateReport(data, guid: string) {
    return this.http.post<any>(`${this.envService.scpCommonApiBaseUrl}/report/${guid}`, data);
  }

  deleteReport(guid: string) {
    return this.http.delete<any>(`${this.envService.scpCommonApiBaseUrl}/report/${guid}`);
  }

  exportReport(data, guid: string) {
    return this.http.post<any>(`${this.envService.scpCommonApiBaseUrl}/report/${guid}/run`, data, {
      headers: { accept: `*/*` },
      responseType: 'blob' as 'json',
    });
  }

  getReportSchedules() {
    return this.http.get<any>(`${this.envService.scpCommonApiBaseUrl}/reportSchedule`);
  }

  getReportSchedule(guid: string) {
    return this.http.get<any>(`${this.envService.scpCommonApiBaseUrl}/reportSchedule/${guid}`);
  }

  createReportSchedule(data) {
    return this.http.post<any>(`${this.envService.scpCommonApiBaseUrl}/reportSchedule`, data);
  }

  updateReportSchedule(data, guid: string) {
    return this.http.put<any>(`${this.envService.scpCommonApiBaseUrl}/reportSchedule/${guid}`, data);
  }

  deleteReportSchedule(guid: string) {
    return this.http.delete<any>(`${this.envService.scpCommonApiBaseUrl}/reportSchedule/${guid}`);
  }

  getNewPin(guid) {
    return this.http.get(`${this.envService.scpCommonApiBaseUrl}/tenant/${guid}/pin`, { responseType: 'text' });
  }

  showSuccess(message: string, config?: MatSnackBarConfig) {
    this.showNotification(message, {
      panelClass: ['scp_theme', 'mat-typography', 'info'],
      ...config,
    });
  }

  showError(message: string, config?: MatSnackBarConfig) {
    this.showNotification(message, {
      panelClass: ['scp_theme', 'mat-typography', 'error'],
      ...config,
    });
  }

  showNotification(message: string | TemplateRef<any> | Component, config?: MatSnackBarConfig, action?: string) {
    const finalConfig: MatSnackBarConfig = {
      panelClass: ['scp_theme', 'mat-typography', 'info'],
      verticalPosition: 'bottom',
      duration: 5000,
      ...config,
    };

    if (message instanceof TemplateRef) {
      return this.snackBar.openFromTemplate(message, finalConfig);
    }

    if (message instanceof Component) {
      return this.snackBar.openFromComponent(message as ComponentType<any>, finalConfig);
    }

    return this.snackBar.open(message, action, finalConfig);
  }
}
