import { Component, OnInit } from '@angular/core';
import { concatMap, tap } from 'rxjs/operators';
import { DateFormatService } from '../../shared/services/jadatetime-format.service';
import { LocalAppDataService } from '../../shared/services/local-app-data.service';
import { TenantService } from '../../shared/services/ssp/tenant.service';
import { SspGenericService } from './../../shared/services/ssp/ssp-generic.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-subscriptions',
    templateUrl: './subscriptions.component.html',
    styleUrls: ['./subscriptions.component.scss'],
})
export class SubscriptionsComponent implements OnInit {
    public status: string;
    public featuretype: string;
    public SubscriptionInfo: ProductSubscription[];
    public subsForMarvel: ProductSubscription;
    public subsForRocket: ProductSubscription;
    public activatedRoute: string;
    public tier: any;

    public tenantInfo: any;
    // public tier: ITier;
    public viewSelected;
    isSubsDetails: boolean;
    constructor(
        private sspApiService: SspGenericService,
        private tenantService: TenantService,
        private appDataService: LocalAppDataService,
        private dateFormatService: DateFormatService,
        private translate: TranslateService,
    ) {
    }

    formatDate(dt: string) {
        return this.dateFormatService.parseDateSubscription(dt);
    }

    ngOnInit() {
        this.subsForMarvel = null;
        this.subsForRocket = null;
        this.SubscriptionInfo = [];
        this.appDataService.usingAuthInfo().subscribe(() => {
            this.tenantInfo = this.appDataService.TenantInfoAtLogin;
            this.tier = this.tenantInfo.tier;
            this.getAllSubscriptions();
        });
    }

    /**
     * Gets a list of all subscriptions for both products to be displayed in UI
     * @param featuretype string: The feature Type currently active in UI (User/Room)
    */
    getAllSubscriptions() {
        this.sspApiService.getSubscriptionDetails().subscribe(response => {
            this.isSubsDetails = true;
            if (this.tier.scp) {
                response.subscriptions.scp.name = 'SynappxCloudPrintDeviceLicense';
                this.SubscriptionInfo.push(response.subscriptions.scp)
            }
            if (this.tier.marvel) {
                response.subscriptions.marvel.name = 'SynappxGoUserLicense';
                this.SubscriptionInfo.push(response.subscriptions.marvel);
            }
            if (this.tier.rocketStart) {
                response.subscriptions.rocket.name = 'SynappxGoWorkspaceLicense';
                this.SubscriptionInfo.push(response.subscriptions.rocket);
            }
           
            for (const item of this.SubscriptionInfo) {
                // scheduled items should be sorted based on expiry field (end date for the entitlement)
                item.scheduled = item.scheduled.sort((a, b) => a.expiry.toString().localeCompare(b.expiry.toString()));
                // expired items should be sorted based on endDate (end of 45 days grace period of entitlement)
                item.expired = item.expired.sort((a, b) => a.endDate.toString().localeCompare(b.endDate.toString()));
            }
        });
    }

}
class EntitlementView {
    id: string;
    count: number;
    renewal: string;
    expiry: string;
    endDate: string;
    referenceSourceID: string;
    startDate: string;
    collab: boolean;
}
class ProductSubscription {
    name: string;
    totalLicenses: number;
    assigned: number;
    archiveLicenses: number;
    unassigned: number;
    grace: number;
    autoRenewals: EntitlementView[];
    scheduled: EntitlementView[];
    expired: EntitlementView[];
    futureEnts: EntitlementView[];
}
// export interface ITier {
//     rocketStart: boolean,
//     marvel: boolean,
//     rmm: boolean
// }