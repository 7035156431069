import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import * as MicrosoftGraphClient from '@microsoft/microsoft-graph-client/lib/src';

import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { Office365Token } from '../interfaces/user';
import { timer, Observable, of} from 'rxjs';
import { Helper } from './helper';
import { IdpBaseService } from './idpBaseService';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import jwt_decode from 'jwt-decode';
// link to MSDN documentation: <https://docs.microsoft.com/en-us/azure/active-directory/roles/permissions-reference>
const AZURE_ADMIN_TEMPLATE_ID = '62e90394-69f5-4237-9190-012177145e10';

export class O365AuthService extends IdpBaseService {

    public addform = false;
    public access_token = null;
    public user;
    public isAuthenticated = false;
    public msGraphclient: any;
    renewalTimer:any;
    /**
     * The office 365 access token
     */
    private office365Token: Office365Token;

    /**
     * The Jwt Helper Service for decoding any JWT
     */
    private helper = new JwtHelperService();

    constructor(
        protected http: HttpClient,
        protected authService: AuthService,
    ) {
        super(http, authService);
    }

    /**
     * Get the Office365 access token.
     * If the token is already available, it will returned as it is.
     */
    public getIdpToken(): Observable<any> {
        const ADVANCE_REFRESH = 30;
        return new Observable<any>((observer) => {
            let isTokenValid = false;
            if (this.tokenInfo && this.tokenInfo.access_token) {
                const expiry = this.helper.getTokenExpirationDate(this.tokenInfo.access_token as string);
                const duration = (expiry.valueOf() - Date.now()) / 1000;
                if (duration <= ADVANCE_REFRESH && duration > 0) {
                    console.log(`Idp token expiration in ${duration} seconds`);
                } else if (duration < 0) {
                    console.log(`Idp token expired ${duration * -1} seconds ago`);
                }
                isTokenValid = (duration > ADVANCE_REFRESH);
            }

            if (isTokenValid) {
                setTimeout(() => {
                    observer.next(this.tokenInfo);
                    observer.complete();
                }, 100);
                return;
            } else {
                // Generate an Office 365 token by invoking the endpoint /api/token
                const httpOptions = {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.authService.accessToken,
                        'Request-Source': 'portal'
                    }),
                };
                const body = {
                    userid: this.authService.auth0userId,
                };
                this.getToken(observer, body, httpOptions, 3);
            }
        });
    }

    /**
     * Get the Token after 3 retries
     * @param observable The observable
     * @param body The request body for the getIdpAccessToken call
     * @param httpOptions The http options for the getIdpAccessToken call
     * @param retryCount Number of retries remaining
     */
    getToken(observable, body, httpOptions, retryCount) {
        this.http.post(environment.rmpEndPointToken.url, body, httpOptions)
            .subscribe(
                (token) => {
                    this.tokenInfo = token as any;
                    observable.next(this.tokenInfo);
                    observable.complete();
                },
                (error) => {
                    console.error(`Error renewal of token`);
                    console.warn(error);
                    if (error.status !== 429) {
                        observable.error(error);
                        observable.complete();
                    } else if (retryCount <= 0) {
                        this.authService.logout();
                        observable.error(error);
                        observable.complete();
                    } else {
                        setTimeout(() => {
                            this.getToken(observable, body, httpOptions, retryCount - 1);
                        }, environment.retryIntervalForHttp429);
                    }
                },
            );
    }

    /**
     * Get the office 365 access token.
     */
    public getIdpAccessToken() {
        this.getIdpToken().subscribe((token: Office365Token) => {
                this.office365Token = token;
        });
    }

    RenewIdpAccessToken(scope?: any): Observable<any> {
        if (this.renewalTimer) {
            this.renewalTimer.unsubscribe();
        }
        const apiUrl = environment.rmpEndPointToken.url;
        // Generate an Office 365 token by invoking the endpoint /api/token
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.authService.accessToken,
                'Request-Source': 'portal'
            }),
        };
        const body = {
            userid: this.authService.auth0userId,
        };
        return this.http.post(apiUrl, body, httpOptions)
            .pipe(
                tap({
                    next: (tokenInfo) => {
                        this.triggerRenewedToken(tokenInfo);
                    },
                    error: (error) => {
                        console.error(`Error renewal of token`);
                        console.warn(error);
                    }
                })
            );
    }

    triggerRenewedToken(tokenInfo) {
        this.authService.renewIdpToken.next(tokenInfo);
        const expiry = this.helper.getTokenExpirationDate(tokenInfo.access_token as string);
        const duration = (expiry.valueOf() - Date.now()) / 1000;
        const renewalTokenTime = duration - 45;
        this.renewalTimer = timer(renewalTokenTime * 1000).subscribe(() => {
            this.RenewIdpAccessToken().subscribe({
                next: (value) => {},
                error: (err) => { console.log("Error in triggerRenewedToken ", err)}
            }
            );
        })
    }

    /**
     * Renew the office 365 access_token by calling the token api
     * @param scope: (optional) parameter to request access_token with only specific scopes. 
     * Currently not implemented for office 365
     * @returns The new token information obtained from server.
     */
    renewToken(scope?: any): Observable<any> {
        const apiUrl = environment.rmpEndPointToken.url;
        // Generate an Office 365 token by invoking the endpoint /api/token
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.authService.accessToken,
                'Request-Source': 'portal'
            }),
        };
        const body = {
            userid: this.authService.auth0userId,
        };
        return this.http.post(apiUrl, body, httpOptions)
            .pipe(
                tap({
                    next: (token) => {
                        this.tokenInfo = token as any;
                    },
                    error: (error) => {
                        console.error(`Error renewal of token`);
                        console.warn(error);
                    }
                })
            );
    }

    /**
     * Get the Azure user permission details.
     */
    public checkOffice365Admin(): Observable<any> {
        return of(true).pipe(
            concatMap(() => this.getIdpToken()),
            concatMap(tokenInfo => this.getClientId(tokenInfo)),
            concatMap(users => this.getUserDetails(users)),
            concatMap((groupInfo) => {
                const adminEntry = groupInfo.value.find(group => group.roleTemplateId === AZURE_ADMIN_TEMPLATE_ID);
                return of(adminEntry);
            }),
            catchError(err => { 
                console.log(err); 
                return of(null)})
        );
    }
    /**
     * Get the Azure Configured Permissions details.
     */
    public findMissingPermissionsInAzure(portalScopes): Observable<any> {
        return of(true).pipe(
            concatMap(() => this.getIdpToken()),
            concatMap(tokenInfo => this.getClientId(tokenInfo)),
            concatMap(objPrincipalInfo => this.getPermissionStatus(objPrincipalInfo)),
            concatMap((grants) => {
                let allowedScopes = grants.value.map(item => item.scope);
                allowedScopes = allowedScopes.join(' ');
                const grantedScopes = allowedScopes.split(' ');
                console.log(`getAuthPermissions():portalScopes: ${JSON.stringify(portalScopes)}`);
                const missed = portalScopes.filter(item => !grantedScopes.includes(item));
                console.log(`getAuthPermissions():missed: ${JSON.stringify(missed)}`);
                const scopeInfo = {"missed": missed, "granted": grantedScopes};
                return of(scopeInfo);
            }),
            catchError(err => {
                console.log(err);
                return of({ "missed": [], "granted": [] })})
        );
    }
    /**
     * Get the Azure user permission details.
     * Calling the memberof graph api for getting user permission details.
     */
    public getUserDetails(users): Observable<any> {
        const userId = jwt_decode('Bearer ' + this.office365Token.access_token);
        const URI = `https://graph.microsoft.com/v1.0/users/{${userId['oid']}}/memberOf`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.office365Token.access_token,
                'accept': '*/*',
            }),
        };
        return this.http.get<any>(URI, httpOptions);
    }
    /**
     * Method for getting Auth Permission status
     */
    public getAuthPermissions() {
        const scope = jwt_decode('Bearer ' + this.office365Token.access_token);
        return scope['scp'];
    }

    public checkGrantReadWorkspaceAndGroup(): Observable<[boolean, boolean]> {
        const data = this.getAuthPermissions();
        let [isGroupReadGranted, isUserReadGranted] = [false, false];
        let scopes = data.split(' ');
        isGroupReadGranted = scopes.includes('Group.Read.All');
        isUserReadGranted = scopes.includes('User.ReadBasic.All');
        return of([isGroupReadGranted, isUserReadGranted]);
    }

    public checkGrantReadUsersAndGroup(): Observable<[boolean, boolean]> {
       return this.checkGrantReadWorkspaceAndGroup();
    }

    public checkGrantReadUsers(): Observable<any> {
        const data = this.getAuthPermissions();
        let isUserReadGranted = false;
        let scopes = data.split(' ');
        isUserReadGranted = scopes.includes('User.ReadBasic.All');
        return of(isUserReadGranted);
    }

    public checkGrantReadUserGroups() {
        const data = this.getAuthPermissions();
        let isGroupReadGranted = false;
        let scopes = data.split(' ');
        isGroupReadGranted = scopes.includes('Group.Read.All');
        return of(isGroupReadGranted);
    }

    /**
     * Gets the id of client service principal
     */
    public getClientId(tokenInfo): Observable<any> {
        this.office365Token = tokenInfo;
        const clientId = environment.AuthConfig.azure_app_client_id;
        const URI = `https://graph.microsoft.com/v1.0/serviceprincipals?$select=id,appId,appDisplayName&$filter=appId eq '${clientId}'`
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.office365Token.access_token,
                'accept': '*/*',
            }),
        };
        return this.http.get<any>(URI, httpOptions);
    }

    /**
     * Gets the status of permissions granted by admin based on client Id
     */
    public getPermissionStatus(objPrincipalInfo): Observable<any> {
        if (objPrincipalInfo.value.length > 0) {
            const URI = `https://graph.microsoft.com/v1.0/oauth2PermissionGrants?$filter=clientId eq '${objPrincipalInfo.value[0].id}' and consentType eq 'AllPrincipals'`;
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.office365Token.access_token,
                    'accept': '*/*',
                }),
            };
            return this.http.get<any>(URI, httpOptions);
        }
        else {
            return of({ "value": [] });
        }
    }

    /**
     * Splits the given address list into allowed/blocked parts.
     * @param addrList The Office356Room instance that needs to be splitted into allowed or blocked list.
     * @param domains The List of domains supported
     * @returns an array with two entries: [validAddr, blockedAddr]. Both entries in the array are array themselves extracted
     * from input parameter addrList
     */
    filterEmailIDs(addrList, domains) {
        const validAddr = addrList.filter(entry => (Helper.isValidDomain(entry.userPrincipalName, domains)));
        const blockedAddr = addrList.filter(entry => (!Helper.isValidDomain(entry.userPrincipalName, domains)));
        return [validAddr, blockedAddr];
    }

    public getUserOrGroupListByEmail(searchString: string, type: string, domains: string[], groupType: string): Observable<any> {
        return new Observable(observer => {

           this.getIdpToken().subscribe((result: Office365Token) => {
                // Create the msGraphClient to communicate with Office 365 online services
                const access_token = <string>result.access_token;
                if (access_token) {
                    this.msGraphclient = MicrosoftGraphClient.Client.init({
                        authProvider: (done) => {
                            done(null, access_token); // first parameter takes an error if you can't get an access token
                        }
                    });
                }

               let queryString = '';

               if (type === 'users') {
                   if (searchString != null && searchString.length > 0 && searchString.toLowerCase().trim() !== 'all') {
                       searchString = encodeURIComponent(searchString);
                       queryString = '$select=id,displayName,userPrincipalName,mail,userType,officeLocation&$top=999' + `&$filter = startswith(userPrincipalName, '${searchString}')`;
                   }
               } else {
                   if (searchString != null && searchString.length > 0 && searchString.toLowerCase().trim() !== 'all') {
                       searchString = encodeURIComponent(searchString);
                       queryString = '$expand=members&$top=99' + `&$filter = startswith(mail, '${searchString}')`;
                   }
               }
               // Invoke Office 365 query
               this.msGraphclient.api(`${type}?${queryString}`).version('v1.0').get((err, res) => {
                   if (err != null) {
                       observer.error(err);
                   } else {
                       const results = res || {}; // ensure results in not null
                       results.value = results.value || []; // ensure results.value is not null
                       if (type === 'users') {
                           const allowed = this.filterEmailIDs(results.value, domains)[0];
                           const rooms = allowed.map((entry) => ({
                               displayName: entry.displayName.toString(),
                               name: entry.displayName.toString(),
                               emailId: entry.userPrincipalName.toString(),
                               place: (entry.officeLocation || '').toString() // if officeLocation is null, use empty string
                           }));
                           observer.next(rooms);
                       } else {
                           const groups = results.value.map((entry) => ({
                               id: entry.id,
                               displayName: entry.displayName.toString() + ' (' + entry.members.length + ((entry.members.length >= 20) ? '+ ' : ' ') + groupType + ')',
                               name: entry.displayName.toString(),
                               emailId: (entry.mail || '').toString(), // assigning group mail value to emailId
                               place: (entry.officeLocation || '').toString(), // if officeLocation is null, use empty string
                               members: []
                           }));
                           observer.next(groups);
                       }
                   }
               });
            }, (error) => {
                observer.error(error);
            });
        });
    }

    /**
     * Query Office 365 for rooms starting with given text
     * @param searchString The string to search for
     * @param apiName The API type of API call either search based on user or Groups
     * @param domains The domains which are supported
     */
    public getRoomOrGroupList(searchString: string, apiName: string, domains: string[], groupType: string): Observable<any> {
        return Observable.create((observer) =>
        this.getIdpToken().subscribe((result: Office365Token) => {
            // Create the msGraphClient to communicate with Office 365 online services
            const access_token = <string>result.access_token;
            if (access_token) {
                this.msGraphclient = MicrosoftGraphClient.Client.init({
                    authProvider: (done) => {
                        done(null, access_token); // first parameter takes an error if you can't get an access token
                    }
                });
            }

            let queryString = '';

            if (apiName === 'users') {
                if (searchString != null && searchString.length > 0 && searchString.toLowerCase().trim() !== 'all') {
                    searchString = encodeURIComponent(searchString);
                    queryString = '$select=id,displayName,userPrincipalName,mail,userType,officeLocation&$top=999' + `&$filter = startswith(displayName, '${searchString}')`;
                }
            } else {
                if (searchString != null && searchString.length > 0 && searchString.toLowerCase().trim() !== 'all') {
                    searchString = encodeURIComponent(searchString);
                    queryString = '$expand=members&$top=99' + `&$filter = startswith(displayName, '${searchString}')`;
                }
            }
            // Invoke Office 365 query
            this.msGraphclient.api(`${apiName}?${queryString}`).version('v1.0').get((err, res) => {
                if (err != null) {
                    observer.error(err);
                } else {
                    const results = res || {}; // ensure results in not null
                    results.value = results.value || []; // ensure results.value is not null
                    if (apiName === 'users') {
                        const allowed = this.filterEmailIDs(results.value, domains)[0];
                        const rooms = allowed.map((entry) => ({
                                displayName: entry.displayName.toString(),
                                name: entry.displayName.toString(),
                                emailId: entry.userPrincipalName.toString(),
                                place: (entry.officeLocation || '').toString() // if officeLocation is null, use empty string
                            }));
                        observer.next(rooms);
                    } else {
                        const groups = results.value.map((entry) => ({
                            id: entry.id,
                            displayName: entry.displayName.toString() + ' (' + entry.members.length + ((entry.members.length >= 20) ? '+ ' : ' ') + groupType + ')',
                            name: entry.displayName.toString(),
                            emailId: (entry.mail || '').toString(), // assigning group mail value to emailId
                            place: (entry.officeLocation || '').toString(), // if officeLocation is null, use empty string
                            members: []
                        }));
                        observer.next(groups);
                    }
                 }
            });
        }, (error) => {
            observer.error(error);
        }));
    }

    /**
     * Query Office 365 for Users starting with given text
     * @param searchString The string to search for
     * @param type The type of API call either search based on user or Groups
     * @param domains The domains which are supported
     */
    public getUserOrGroupList(searchString: string, type: string, domains: string[], groupType: string): Observable<any> {
        return this.getRoomOrGroupList(searchString, type, domains, groupType);
    }

    getRoomOrGroupListByEmail(searchString: string, type: string, domains: string[], groupType: string): Observable<any> {
        return this.getUserOrGroupListByEmail(searchString, type, domains, groupType);
    }

    /**
     * Get the Worspaces listed in Office365 Workspace Gorup
     * @param groupId The Groudp ID
     * @param domains The domains which are supported
     */
    public getWorkspaceGroupMembers(groupId, domain): Promise<any> {
        return this.getGroupMembers(groupId, domain);
    }

    public async getGroupMembers(groupId, domains) {
        return new Promise<any[]>((resolve, reject) => {
            this.msGraphclient.api(`groups/${groupId}/members`).version('v1.0').get((err, res) => {
                if (err != null) {
                    console.warn(`Failed to retrieve members of group ${groupId}`);
                    reject(err);
                } else {
                    const [allowed, blocked] = this.filterEmailIDs(res.value, domains);
                    const allowedMembers = allowed.map(member => ({
                        name: member.displayName.toString(),
                        emailId: member.userPrincipalName.toString(),
                        place: (member.officeLocation || '').toString()
                    }));
                    const blockedMembers = blocked.map(member => ({
                        name: member.displayName.toString(),
                        emailId: member.userPrincipalName.toString(),
                        place: (member.officeLocation || '').toString()
                    }));
                    resolve([allowedMembers, blockedMembers]);
                }
            });
        });
    }

    /**
     * Get all the users from Office 365 that match the given name
     * @param userToSearch The user name to search for
     */
    public searchUsers(userToSearch: string, domains): Observable<any> {
        return new Observable((observer) => {
            this.getIdpToken().subscribe((result: Office365Token) => {
                // Create the msGraphClient to communicate with Office 365 online services
                const access_token = <string>result.access_token;
                this.msGraphclient = MicrosoftGraphClient.Client.init({
                    authProvider: done => {
                        done(null, access_token); // first parameter takes an error if you can't get an access token
                    },
                });
                if (userToSearch === '' || userToSearch === undefined) {
                    observer.next([]);
                    observer.complete();
                }
                // Construct the user search query
                let userQuery = 'users?$select=id,displayName,userPrincipalName,mail,userType&$top=999';
                if (userToSearch != null && userToSearch.length > 0
                    && userToSearch.toLowerCase().trim() !== 'all') {
                    userToSearch = userToSearch.replace(/'/g, "''"); // handling apostrophe in query
                    userToSearch = encodeURIComponent(userToSearch);
                    userQuery += `&$filter = startswith(displayName, '${userToSearch}')`;
                }
                // Execute the user search query on 'Office 365 System' using MS Graph Client (v1.0)
                this.msGraphclient.api(userQuery).version('v1.0').get((err, res) => {
                    if (err != null) {
                        // user search query on 'Office 365 System' failed
                        console.error(`error occured while retrieving users from MS Graph Client`);
                        console.log(err);
                        observer.error(err);
                    } else {
                        // user search on 'Office 365 system' returned results
                        res.value = res.value || [];
                        const allowed = this.filterEmailIDs(res.value, domains)[0];
                        const userList = allowed.map(entry => ({
                            userName: entry.displayName.toString(),
                            userEmail: entry.userPrincipalName.toString()
                        }));
                        // Resolve the promise with the userList
                        observer.next(userList);
                        observer.complete();
                    }
                });
            },
            err => {
                console.error(`error occured while obtaining office 365 token`);
                console.log(err);
                observer.error(err);
            });
        });
    }

    /**
     * Get all the users from Office 365 that match the given userPrincipalname
     * @param userToSearch The user upn to search for
     */
    public searchUsersByEmail(userToSearch: string, domains): Observable<any> {
        return new Observable((observer) => {
            this.getIdpToken().subscribe((result: Office365Token) => {
                // Create the msGraphClient to communicate with Office 365 online services
                const access_token = <string>result.access_token;
                this.msGraphclient = MicrosoftGraphClient.Client.init({
                    authProvider: done => {
                        done(null, access_token); // first parameter takes an error if you can't get an access token
                    },
                });
                if (userToSearch === '' || userToSearch === undefined) {
                    observer.next([]);
                    observer.complete();
                }
                // Construct the user search query
                let userQuery = 'users?$select=id,displayName,userPrincipalName,mail,userType&$top=999';
                if (userToSearch != null && userToSearch.length > 0
                    && userToSearch.toLowerCase().trim() !== 'all') {
                    userToSearch = userToSearch.replace(/'/g, "''"); // handling apostrophe in query
                    userToSearch = encodeURIComponent(userToSearch);
                    userQuery += `&$filter = startswith(userPrincipalName, '${userToSearch}')`;
                }
                // Execute the user search query on 'Office 365 System' using MS Graph Client (v1.0)
                this.msGraphclient.api(userQuery).version('v1.0').get((err, res) => {
                    if (err != null) {
                        // user search query on 'Office 365 System' failed
                        console.error(`error occured while retrieving users from MS Graph Client`);
                        console.log(err);
                        observer.error(err);
                    } else {
                        // user search on 'Office 365 system' returned results
                        res.value = res.value || [];
                        const allowed = this.filterEmailIDs(res.value, domains)[0];
                        const userList = allowed.map(entry => ({
                            userName: entry.displayName.toString(),
                            userEmail: entry.userPrincipalName.toString()
                        }));
                        // Resolve the promise with the userList
                        observer.next(userList);
                        observer.complete();
                    }
                });
            },
                err => {
                    console.error(`error occured while obtaining office 365 token`);
                    console.log(err);
                    observer.error(err);
                });
        });
    }

    public syncRooms() {
        let access_token = null;
        this.getIdpToken().subscribe((result: Office365Token) => {
            access_token = result.access_token;

            if (access_token) {
                this.msGraphclient = MicrosoftGraphClient.Client.init({
                    authProvider: done => {
                        done(null, access_token); // first parameter takes an error if you can't get an access token
                    },
                });

                // Fetch room information
                //
                this.msGraphclient
                    .api("/me/people?$top=1000&$filter=personType/subclass eq 'Room'")
                    .version('v1.0')
                    .get((err, res) => {
                        // const rooms: [MicrosoftGraph.EmailAddress] = res.value;
                        for (const resIter of res.value) {
                            const room: MicrosoftGraph.EmailAddress = resIter.userPrincipalName;
                        } // Iterate Rooms
                    });
            }
        });
    }

    /**
     * Get all the domains in the AD where the currently logged in user belongs to
     */
    public async getDomains() {
        return new Promise<any[]>((resolve, reject) => {
            this.getIdpToken().subscribe((result: Office365Token) => {
                // Create the msGraphClient to communicate with Office 365 online services
                const access_token = <string>result.access_token;
                this.msGraphclient = MicrosoftGraphClient.Client.init({
                    authProvider: done => {
                        done(null, access_token); // first parameter takes an error if you can't get an access token
                    },
                });
                this.msGraphclient.api(`domains`).version('v1.0').get((err, res) => {
                    if (err != null) {
                        console.warn(`Failed to retrieve domins`);
                        reject(err);
                    } else {
                        const rawDomains = res.value;
                        const filteredDomains = rawDomains
                        .filter(ele => ele.isVerified === true)
                        .map(e => e.id);
                        resolve(filteredDomains);
                    }
                });
            }, err => {
                reject(err);
            });
        });
    }

    /**
     * Get the logged in user details from Office 365
     */
    public getLoggedInUserDetails(): Observable<any> {
        return new Observable((observer) => {
            this.getIdpToken().subscribe((result: Office365Token) => {
                // Create the msGraphClient to communicate with Office 365 online services
                const access_token = <string>result.access_token;
                this.msGraphclient = MicrosoftGraphClient.Client.init({
                    authProvider: done => {
                        done(null, access_token); // first parameter takes an error if you can't get an access token
                    },
                });
                // Execute the query on 'Office 365 System' using MS Graph Client (v1.0) to fetch the logged in user details
                this.msGraphclient.api('me').version('v1.0').get((err, res) => {
                    if (err != null) {
                        console.error(`error occured while retrieving logged in user details from MS Graph Client`);
                        console.log(err);
                        observer.error(err);
                    } else {
                        observer.next(res);
                        observer.complete();
                    }
                });
            }, (err) => {
                console.error(`error occured while obtaining office 365 token`);
                console.log(err);
                observer.error(err);
            });
        });
    }

    public getRoomListDetails(resourceId: any): Observable<any> {
        throw new Error('Method not implemented.');
    }

    public getCalendarAccountResources(): Observable<any> {
        console.info(`Implemented is NOT needed`);
        return of([]);
    }

}
