import { Component, Inject, OnInit, Input, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-logout-notification.dialog',
    templateUrl: './logout-notification.dialog.html',
    styleUrls: ['./logout-notification.dialog.scss'],
})
export class LogoutNotificationComponent implements OnInit {
    @Input()
    notificationinfo = '';
    
    // handle event on escape key
    @HostListener('document:keydown.escape', ['$event'])
    onEscapeKeyHandler(event: KeyboardEvent) {
        this.dialogNotificationRef.close(0);
    }

    constructor(
        public dialogNotificationRef: MatDialogRef<LogoutNotificationComponent>,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}
    
    ngOnInit() {
        if (this.data) {
            this.notificationinfo = this.translate.instant(this.data.notificationinfo , this.data.interpolateParams);
        }
    }
    
    onCancel() {
        this.dialogNotificationRef.close(0);
    }

    onOk() {
        this.dialogNotificationRef.close(1);
    }
}
