import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { SubDialogInfo, DialogInfo } from '../../interfaces/dialog';
import { take, map } from 'rxjs/operators';
import { ConfirmActionDialogComponent } from '../../dialogs/confirm-action-dialog/confirm-action-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    constructor(private dialog: MatDialog) { }
    dialogComp: any;
    dialogRef: MatDialogRef<any>;
    private modals = {
        'ConfirmActionDialogComponent': ConfirmActionDialogComponent,
    };
    public open(options: SubDialogInfo) {
        const dialogComp = this.modals[this.dialogComp] ? this.modals[this.dialogComp] : ConfirmActionDialogComponent;
        this.localizePlaceHolderVar(options)
        const dialogInfo: DialogInfo = {
            title: options.title ? options.title : '',
            mainMessage: (options.mainMessage, options.placeHolderVariables),
            subMessage1: options.subMessage1 ? (options.subMessage1) : '',
            subMessage2: options.subMessage2 ? (options.subMessage2) : '',
            isMultiLine: options.isMultiLine ? options.isMultiLine : false,
            cancelText: options.cancelText ? options.cancelText : 'Cancel',
            confirmText: options.confirmText ? options.confirmText : 'Ok',
            infoMode: options.infoMode ? options.infoMode : false,
        }
        this.dialogRef = this.dialog.open(dialogComp, { data: dialogInfo });
    }
    private localizePlaceHolderVar(data: any) {
        if (data.placeHolderVariables && data.placeHolderVariables.hasOwnProperty('deviceText')) {
            data.placeHolderVariables.deviceText = (data.placeHolderVariables.deviceText);
            // Below condition is added to support finnish language
            if (data.placeHolderVariables.deviceText === 'Laite') {
                data.placeHolderVariables.deviceText = 'Laitteet';
            }
            data.placeHolderVariables.deviceText = data.placeHolderVariables.deviceText.toLowerCase();
        }
    }
    public confirmed(): Observable<any> {
        return this.dialogRef.afterClosed().pipe(take(1), map(res => {
            return res;
        }));
    }
}
