import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SynappxEnvService } from './synappx-env.service';

@Injectable({
    providedIn: 'root'
})
export class TenantService {

    constructor(private sspEnv: SynappxEnvService,
        private httpClient: HttpClient,) {

    }

    /**
     * Get the tenant info
     * @param acessToken The auth0 access token
     * @returns An observable that resolves to current tenant
     */
    getTenantInfo(acessToken): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + acessToken
            }),
        };
        return this.httpClient.get<any>(this.sspEnv.sspCommonTenantUrl, httpOptions);
    }

    /**
     *  Convert an object to query string
     * @param {any} json The query params as an object
     */
    jsonToQueryString(json: any): String {
        const entries = Object.keys(json).map(key => {
            return encodeURIComponent(key) + '=' +
                encodeURIComponent(json[key]);
        });
        return entries.join('&');
    }

    /**
     * Query the user details from DB
     * @param {any} queryOptions The query parameters as an object
     */
    queryUser(queryOptions: any, accessToken): Observable<any> {
        const queryString = this.jsonToQueryString(queryOptions);
        const queryUserUrl = `${this.sspEnv.sspCommonApiBaseUrl}/users?${queryString}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }),
        };
        return this.httpClient.get<any>(queryUserUrl, httpOptions);
    }
}
