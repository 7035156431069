export const ADMIN_ROLES: IAdminRoles = {
    SCP_ADMIN_ROLES: ['Primary Admin', 'Tenant Admin'],
    GO_ADMIN_ROLES: ['Primary Admin', 'Admin', 'Support Admin'],
    GO_ADMIN_TYPES: ['ba', 'ba user'],
    RMM_ADMIN_ROLES: ['Primary Admin', 'IT Main', 'IT Helpdesk', 'Service Main', 'Service Support', 'Service View Only']
};
interface IAdminRoles {
    SCP_ADMIN_ROLES: Array<string>,
    GO_ADMIN_ROLES: Array<string>,
    GO_ADMIN_TYPES: Array<string>,
    RMM_ADMIN_ROLES: Array<string>
}