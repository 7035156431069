import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocalAppDataService } from '../../../../shared/services/local-app-data.service';
import { UserService } from '../../../../shared/services/ssp/user.service';
import { AdminInfo } from '../../AdminInfo';

@Component({
    selector: 'lib-confirm-delete',
    templateUrl: './confirm-delete.component.html',
    styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent implements OnInit {
    /**
     * @description Title of Confirm Delete dialog box.
     */
    title: string;

    /**
     * @description Message of Confirm Delete dialog box.
     */
    message: string;

    /**
     * @description Data type is boolean if true show checkbox to delete user from other service.
     */
    showOption: boolean;

    /**
     * true to show Synappx Go. target user is also admin in Synappx Go). 
     * false to hide the 'Synappx Go' checkbox. target user is not an admin in Synappx Go). 
     */
    showSynappxGo: boolean;

    /**
     * true to show Synappx Manage. Target user is also admin (IT Main) in Synappx Manage
     * false to hide the 'Synappx Manage' checkbox. target user is not an (IT Main) in Synappx Manage
     */
    showSynappxManage: boolean;

    /**
     * The other services (go/rmm) where this user can be added
     */
    private otherServices: Array<string>;

    /**
     * The admin info object
     */
    adminInfo: AdminInfo;

    /**
     * The list of services, this selected user will be deleted as admin
     * @description This array will contain values like go, rmm, cp
     * 
     * 'cp' is always by default present
     * go and rmm will be deleted based on checkbox state.
     * 
     */
    toDelete: Array<string>;

    constructor(
        public confirmDeleteDialogRef: MatDialogRef<ConfirmDeleteComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.title = this.data.title;
        this.message = this.data.message;
        this.showOption = this.data.showOption;
        this.otherServices = this.data.otherServices;
    }

    /**
     * @description override the ngOnInit to set visibility and enable/disable of checkboxes of other products (Synappx Go / Synappx Manage)
     */
    ngOnInit(): void {
        this.toDelete = ['scp'];
        this.showSynappxGo = this.otherServices.includes('synappx');
        this.showSynappxManage = this.otherServices.includes('rmm');
        // handle event on click of background
        this.confirmDeleteDialogRef.backdropClick().subscribe(() => {
            // close this dialog with {action: false, toDelete: null}
            this.confirmDeleteDialogRef.close({ action: false, toDelete: null });
        });
    }

    /**
     * @description Add the product to the final result
     * @param product The product name to add
     */
    addProduct(product: string) {
        if (!this.toDelete.includes(product)) {
            this.toDelete.push(product);
        }
    }

    /**
     * @description Remove the product name from the final result
     * @param product The product name to remove
     */
    removeProduct(product: string) {
        this.toDelete = this.toDelete.filter(entry => entry != product);
    }

    /**
     * handle the selection change event on the checkboxes in the UI
     * @param event The selection change event for the checkbox from browser
     * @param product The product/service name corresponding to the checkbox
     */
    selectionChange(event, product: string) {
        // add / remove the product name in `toDelete` array
        if ((product == 'synappx' && this.showSynappxGo) ||
            (product == 'rmm' && this.showSynappxManage)) {
            if (event) {
                // checked - add go/rmm to result
                this.addProduct(product);
            } else {
                // unchecked - remove go/rmm from the result
                this.removeProduct(product);
            }
        }
    }

    /**
     * Handle the click event of Cancel button
     * @description close this dialog with {action: false, toDelete: null}
     */
    onCancel(): void {
        this.confirmDeleteDialogRef.close({action: false, toDelete: null});
    }

    /**
     * Handle the click event of OK button
     * @description close this dialog with {action: true, toDelete: this.toDelete}
     */
    onOk(): void {
        // Close the dialog with the result
        this.confirmDeleteDialogRef.close({
            action: true,
            toDelete: this.toDelete
        });
    }

}