import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import MiscUtilService from './misc-util.service';
import { DateTimeTimezoneService } from './../../services/rmmDateTimeFormat/dateTimeTimezone-format.service';

const dmyFormat1 = 'd.M.yyyy';
const dmyFormat2 = 'd/M/yyyy';
const dmyFormat3 = 'd-M-yyyy';
const ymdFormat = 'yyyy-M-d';
const mdyFormat = 'M/d/yyyy';
const ddmmyFormat = 'dd.MM.yyyy';
const ddmmyFormat1 = 'dd/MM/yyyy'

const dateFormats = {
    'en': mdyFormat,
    'da': ddmmyFormat,
    'de': ddmmyFormat,
    'fi': ddmmyFormat,
    'nb': ddmmyFormat,
    'pl': ddmmyFormat,
    'es': ddmmyFormat1,
    'fr': ddmmyFormat1,
    'it': ddmmyFormat1,
    'nl': ddmmyFormat,
    'sv': ymdFormat,
    'en-GB': dmyFormat2,
};

@Injectable({
  providedIn: 'root'
})
export default class DateUtilService {

    constructor(private translateService: TranslateService,
        private dateTimeTimezoneService: DateTimeTimezoneService,) { }
    static timeZoneOffsetMapping = new Map([
        [720, "Etc/GMT+12"],
        [660, "Etc/GMT+11"],
        [600, "Pacific/Honolulu"],
        [570, "Pacific/Marquesas"],
        [540, "America/Anchorage"],
        [480, "America/Los_Angeles"],
        [420, "America/Phoenix"],
        [360, "America/Chicago"],
        [300, "America/New_York"],
        [240, "America/Halifax"],
        [210, "America/Caracas"],
        [180, "America/Buenos_Aires"],
        [120, "Etc/GMT+2"],
        [60, "Atlantic/Azores"],
        [0, "Europe/London"],
        [-60, "Africa/Lagos"],
        [-120, "Africa/Johannesburg"],
        [-180, "Asia/Riyadh"],
        [-240, "Asia/Tbilisi"],
        [-270, "Asia/Kabul"],
        [-300, "Asia/Karachi"],
        [-330, "Asia/Calcutta"],
        [-360, "Asia/Dhaka"],
        [-390, "Asia/Rangoon"],
        [-420, "Asia/Bangkok"],
        [-480, "Australia/Perth"],
        [-540, "Asia/Tokyo"],
        [-570, "Australia/Adelaide"],
        [-600, "Australia/Hobart"],
        [-660, "Asia/Magadan"],
        [-660, "Pacific/Norfolk"],
        [-720, "Pacific/Fiji"],
        [-780, "Pacific/Enderbury"],
        [-840, "Pacific/Kiritimati"]
    ]);
    timeZoneOffSetMap = {
        "Etc/GMT+12": 720,
        "Etc/GMT+11": 660,
        "Etc/GMT+10": 600,
        "Pacific/Marquesas": 570,
        "Etc/GMT+9": 540,
        "Etc/GMT+8": 480,
        "Etc/GMT+7": 420,
        "Etc/GMT+6": 360,
        "Etc/GMT+5": 300,
        "America/Caracas": 240,
        "Etc/GMT+4": 240,
        "Etc/GMT+3": 180,
        "Etc/GMT+2": 120,
        "Etc/GMT+1": 60,
        "Etc/GMT+0": 0,
        "Etc/GMT-1": -60,
        "Etc/GMT-2": -120,
        "Etc/GMT-3": -180,
        "Etc/GMT-4": -240,
        "Asia/Kabul": -270,
        "Etc/GMT-5": -300,
        "IST": -330,
        "Etc/GMT-6": -360,
        "Asia/Rangoon": -390,
        "Etc/GMT-7": -420,
        "Etc/GMT-8": -480,
        "Etc/GMT-9": -540,
        "ACT": -570,
        "Etc/GMT-10": -600,
        "Etc/GMT-11": -660,
        "Pacific/Norfolk": -660,
        "Etc/GMT-12": -720,
        "Etc/GMT-13": -780,
        "Etc/GMT-14": -840
    }

    static convertTimeStampToTime(dateValue, timezone) {
        let temp = new Date(parseInt(dateValue))
        return temp
    }
    getTimeZoneValueFromOffset(value) {
    let stringTodisplay = "Etc/GMT+0";
    value = this.roundOffTimezone(value);
        let key = Object.keys(this.timeZoneOffSetMap).find(k => this.timeZoneOffSetMap[k] === value);
        stringTodisplay = key
    return MiscUtilService.timeZoneUIType[stringTodisplay];
    }
    roundOffTimezone(value) {
    let rValue = value;
        if (Object.values(this.timeZoneOffSetMap).includes(value)) {
        rValue = value;
    } else {
        value = value + 15;//To avoid the unsupported time zones
        rValue = this.roundOffTimezone(value);
    }
    return rValue;
  }

    // function to get timezone from offset and update for any unsupported time zone
    // timeZoneOffSetMap is used for supported timezones validation
    // stdTimezoneOffset is used for standard timezone
    // isDstObserved is used for validating DST is in effect or not

    getTimeZone() {
        let date = new Date();
        let offSetState = false
        let finalUTC = ''
        let offset = this.stdTimezoneOffset(date);
        // console.log(offset)
        if (offset < 0 || offset == 0) {
            offSetState = true
            offset = Math.abs(offset)
        }
        // let offset = this.roundOffTimezone(date.getTimezoneOffset());
        // console.log(offset, this.roundOffTimezone(date.getTimezoneOffset()))
        let hours = String(Math.trunc(offset / 60)).padStart(2, '0');
        let minutes = String(offset % 60).padStart(2, '0');

        if (offSetState) {
            finalUTC = 'UTC+' + hours + ':' + minutes
        } else {
            finalUTC = 'UTC-' + hours + ':' + minutes
        }
        // console.log('-------->'+finalUTC)

        return finalUTC
    }

    stdTimezoneOffset(data) {
        let jan = new Date(data.getFullYear(), 0, 1);
        let jul = new Date(data.getFullYear(), 6, 1);
        return Math.max(this.roundOffTimezone(jan.getTimezoneOffset()), this.roundOffTimezone(jul.getTimezoneOffset()));
    }

    isDstObserved(data) {
        return data.getTimezoneOffset() < this.stdTimezoneOffset(data);
    }


    // to check data coming for date format is valid or not
    // if not valid,we are returning blank
    // else if valid we are calling date format conversion function
    checkdate(date: any) {
        if (date == '' || date == null || date == 'null' || date == undefined || date == 'undefined' || date == 0) {
            return "";
        } else {
            let formatedDateTime = this.dateTimeTimezoneService.formatDateTimeLocaly(date);
            let lang:any = this.translateService.getBrowserLang();
            if (lang !== 'en' || lang !== 'en-GB') {
                return formatedDateTime;
            } else {
                let time = formatedDateTime.split(" ");
                let formatted = time[0] + " " + time[1] + " " + this.translateService.instant(time[2].toLowerCase())
                return formatted;
            }
        }
    }


    // new function specific to device cloning schedule list

    /**
     * @param date formatDateTimeUTC takes date as input, parse to UTC date
     * and formats to the required date format for subscription page
     */


    checkdateDC(date: any) {
        if (date == '' || date == null || date == 'null' || date == undefined || date == 'undefined' || date == 0) {
            return "";
        } else {
            let formatedDateTime = this.formatDateTimeUTC(date);
            let lang: any = this.translateService.getBrowserLang();
            if (lang !== 'en' || lang !== 'en-GB') {
                return formatedDateTime;
            } else {
                let time = formatedDateTime.split(" ");
                let formatted = time[0] + " " + time[1] + " " + this.translateService.instant(time[2].toLowerCase())
                return formatted;
            }
        }
    }

    formatDateTimeUTC(date: Date) {
        let lang: any = this.translateService.getBrowserLang();
        let dateFormat: any = dateFormats[lang]
        if (lang == 'en' || lang == 'en-GB') {
            const englishDateTimeFormat = DateTime.fromJSDate(date).toUTC().toFormat(dateFormat + ' ' + 'h:mm a');
            return englishDateTimeFormat;
        }
        // Display date time in 24 hr format for 'da', 'de', 'es', 'fi', 'fr', 'it', 'nl', 'nb', 'pl', 'sv' Language
        const formatedDateTime = DateTime.fromJSDate(date).toUTC().toFormat(dateFormat + ' ' + 'HH:mm');
        return formatedDateTime.toString();
    }
}
