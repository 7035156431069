<mat-card class="custom-tenant">
    <div class="fx_space-between-center">
        <mat-card-title>{{ 'TenantSetting' | translate }}</mat-card-title>
    </div>
    <div  class="custom-tenant-content mat-dialog-content">
        <form [formGroup]="tenantForm" id='addUserForm' name='addUserForm' class="pt_15 fx_500px fx_column" #addUserForm>
            <mat-form-field floatPlaceholder="never" color="accent">
                <input id='Tenant' formControlName="tenant" #tenant name='FirstName' [maxlength]="tenantNameMax" (keyup)="validateUserName()" value={{tenantName}} #Tenant matInput placeholder="">
                <mat-label>{{'TenantName' | translate }} (*)</mat-label>
                <mat-error>{{'EnterValue' | translate }}</mat-error>
            </mat-form-field>
        </form>
    </div>
    <div class="mandatory pl_390 fx_startcenter">(*) {{ 'Mandatory' | translate }}</div>

    <div class="custom-tenant-content mat-dialog-content fx_endcenter ">
        <button mat-button [disabled] = "tenantForm.invalid || isSaveDisabled" class="primary_btn btn-alignment" (click)="confirmAdd()" id="space-add-dilogue-button-id">{{'Save'| translate }}</button>
    </div>
</mat-card>
