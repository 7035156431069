import * as dayjs from 'dayjs';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
dayjs.extend(utc)

const dmyFormat2 = 'd/M/yyyy';
const ymdFormat = 'yyyy-M-d';
const mdyFormat = 'M/d/yyyy';
const ddmmyFormat = 'dd.mm.yyyy';
const ddmmyFormat1 = 'dd/MM/yyyy';

const dateFormats = {
    'en': mdyFormat,
    'da': ddmmyFormat,
    'de': ddmmyFormat,
    'fi': ddmmyFormat,
    'nb': ddmmyFormat,
    'pl': ddmmyFormat,
    'es': ddmmyFormat1,
    'fr': ddmmyFormat1,
    'it': ddmmyFormat1,
    'nl': ddmmyFormat,
    'sv': ymdFormat,
    'en-GB': dmyFormat2,
};

@Injectable({
    providedIn: 'root',
})
export class DateFormatService {

    private lang = 'en';
    private dateFormat = mdyFormat;

    constructor(private translateService: TranslateService) {
        this.lang = this.getLanguage();
        this.dateFormat = dateFormats[this.lang].toUpperCase();
    }

    /**
     * Get the language using translate service
     * This method will get the language.
     * If the specific culture is supported (e.g: en-GB), then the
     * return value will be culture specific
     */
    getLanguage(): any {
        let lang = this.translateService.getBrowserLang();
        const langCul = this.translateService.getBrowserCultureLang();
        let supportedLanguages = ['en'];
        if (environment.stackRegion === 'EU') {
            // Change the list of supported languages for EU stacks
            supportedLanguages = ['da', 'de', 'en-GB', 'es', 'fi', 'fr', 'it', 'nl', 'nb', 'pl', 'sv'];
        }
        // All norwegian cultures default to 'nb'
        if (['nb', 'no', 'nn'].includes(lang)) {
            lang = 'nb';
        }
        // All spanish languages default to 'es'
        const spanishLangCode = ['ca', 'es'];
        if (spanishLangCode.includes(lang)) {
            lang = 'es';
        }
        let result = 'en';
        if (supportedLanguages.includes(langCul)) {
            // If language+culture combo is in supported list use it
            result = langCul;
        } else if (supportedLanguages.includes(lang)) {
            // If language (culture independent) is in supported list use it
            result = lang;
        }
        return result;
    }

    /**
     * @param date parseDateLocale takes date and locale code ex: 'es' as input,
     * and formats to the required date format for graph and export
     * url param values
     * @param date calendar date selected
     * @param locale
     */
    parseDateLocale(date: string) {
        const formatedDate = dayjs(date, this.dateFormat).format('YYYY-MM-DD');
        return formatedDate;
    }

    /**
     *
     * @param date formatDateTimeLocale takes date and locale code ex: 'es' as input,
     * and formats to the required date_time format for Audit log UI table
     * @param date Long Date
     * @param locale browser locale
     * @param locale
     */
    formatDateTimeLocale(date: Date) {
        if (this.lang == 'en' || this.lang == 'en-GB') {
            const englishDateTimeFormat = dayjs(date).format(this.dateFormat + ' ' + 'h:mm A');
            return englishDateTimeFormat;
        }
        // Display date time in 24 hr format for 'da', 'de', 'es', 'fi', 'fr', 'it', 'nl', 'nb', 'pl', 'sv' Language
        const formatedDateTime = dayjs(date).format(this.dateFormat + ' ' + 'HH:mm');
        return formatedDateTime.toString();
    }
    /**
     *
     * @param date
     * formatDate takes date as input,and formats to the required date format for Grace period UI table
     * @returns
     */
    formatDate(date: Date) {
        if (this.lang == 'en' || this.lang == 'en-GB') {
            const englishFormatedDate = dayjs(date).format(this.dateFormat);
            return englishFormatedDate;
        }
        // Display date for 24 hr format for 'da', 'de', 'es', 'fi', 'fr', 'it', 'nl', 'nb', 'pl', 'sv' Language
        const dateFormat = dayjs(date).format(this.dateFormat);
        return dateFormat.toString();
    }
    /**
     * @param date parseDateLocale takes date and locale code ex: 'es' as input,
     * and formats to the required date format for subscription page
     */
    parseDateSubscription(date: string) {
        const formatedDate = dayjs(date).format(this.dateFormat);
        return formatedDate;
    }

    /**
     * @param date parseDateUTC takes date as input, parse to UTC date
     * and formats to the required date format for subscription page
     */
    parseDateUTC(date: string) {
        const formatedDate = dayjs(date).utc().format(this.dateFormat);
        return formatedDate;
    }
}
