<mat-card>
  <div fxLayoutAlign="space-between baseline">
    <mat-card-title>{{ 'scp.common.jobs' | translate }}</mat-card-title>

    <div fxLayoutAlign="start stretch" fxLayoutGap="16px" class="scp_sustainability">
      <div
        *ngIf="savedPaperData?.userPaperSavedAgainstLastMonth >= 0"
        class="scp_sustainability_saved_paper"
        [innerHTML]="
          'scp.jobs.sustainability.saved_paper_message_more'
            | translate : { value: savedPaperData?.userTotalPaperSavedThisMonth || 0, value1: savedPaperData?.userPaperSavedAgainstLastMonth || 0 }
        "
      ></div>
      <div
        *ngIf="savedPaperData?.userPaperSavedAgainstLastMonth < 0"
        class="scp_sustainability_saved_paper"
        [innerHTML]="
          'scp.jobs.sustainability.saved_paper_message_less'
            | translate
              : { value: savedPaperData?.userTotalPaperSavedThisMonth || 0, value1: savedPaperData?.userPaperSavedAgainstLastMonth || 0 | AbsoluteValue }
        "
      ></div>
      <div
        class="scp_sustainability_tenant_position"
        [innerHTML]="'scp.jobs.sustainability.company_position_message' | translate : { value: savedPaperData?.userPaperSavedRankInTenantPercentage || 0 }"
      ></div>
      <div
        class="scp_sustainability_community_position"
        [innerHTML]="'scp.jobs.sustainability.community_position_message' | translate : { value: savedPaperData?.userPaperSavedRankInSynappxPercentage || 0 }"
      ></div>
    </div>
  </div>

  <mat-tab-group preserveContent animationDuration="0" mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab label="{{ 'scp.jobs.queue.tab_title' | translate }}">
      <ng-template matTabContent>
        <app-jobs-queue></app-jobs-queue>
      </ng-template>
    </mat-tab>

    <mat-tab label="{{ 'scp.jobs.history.tab_title' | translate }}">
      <ng-template matTabContent>
        <app-jobs-history></app-jobs-history>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</mat-card>
