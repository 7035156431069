import { NgModule, inject } from '@angular/core';
import { ResolveFn, Router, RouterModule, Routes } from '@angular/router';
import { switchMap } from 'rxjs/operators';

import { ErrorComponent } from '../shared/components/error/error.component';
import { OfflineComponent } from '../shared/components/offline/offline.component';
import { PageNotFoundComponent } from '../shared/components/page-not-found/page-not-found.component';
import { SessionExpiredComponent } from '../shared/components/session-expired/session-expired.component';
import { UnAuthorizedComponent } from '../shared/components/un-authorized/un-authorized.component';
import { SCPRouterLinks } from '../shared/models/router-model';
import { AdminLogComponent } from './admin-log/admin-log.component';
import { AdminUsersComponent } from './admin/admin-users/admin-users.component';
import { AppService } from './app.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DepartmentsComponent } from './departments/departments.component';
import { DevicesComponent } from './devices/devices.component';
import { JacloudLayoutComponent } from './jacloud-layout/jacloud-layout.component';
import { JobsComponent } from './jobs/jobs.component';
import { ReportsComponent } from './reports/reports.component';
import { SettingsComponent } from './settings/settings.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { SupportedDomainsComponent } from './supported-domains/supported-domains.component';
import { SystemLogComponent } from './system-log/system-log.component';
import { TenantComponent } from './tenant/tenant.component';
import { UsersComponent } from './users/users.component';

const SCPAuthGuard: ResolveFn<any> = () => {
  const appService = inject(AppService);

  return appService.validateAuthToken().pipe(
    switchMap(() => {
      return appService.getSCPAuthToken();
    })
  );
};

const SCPAdminGuard: ResolveFn<any> = () => {
  const router = inject(Router);
  const appService = inject(AppService);

  if (appService.isSCPAdminUser) {
    return true;
  }

  return router.parseUrl(SCPRouterLinks.unAuthorized);
};

const SCPRedirectGuard: ResolveFn<any> = () => {
  const router = inject(Router);
  const appService = inject(AppService);
  const redirectUrl = appService.isSCPAdminUser ? SCPRouterLinks.dashboard : SCPRouterLinks.jobs;

  return router.parseUrl(redirectUrl);
};

const SCPDeactivatedUserGuard: ResolveFn<any> = () => {
  const router = inject(Router);
  const appService = inject(AppService);

  if (appService.isSCPUserAccountActivated) {
    return true;
  }

  return router.navigate([SCPRouterLinks.unAuthorized]);
};

const routes: Routes = [
  { path: 'error', component: ErrorComponent },
  { path: 'offline', component: OfflineComponent },
  { path: 'un-authorized', component: UnAuthorizedComponent },
  { path: 'session-expired', component: SessionExpiredComponent },
  {
    path: '',
    component: JacloudLayoutComponent,
    canActivate: [SCPAuthGuard],
    children: [
      { path: '', pathMatch: 'full', children: [], canActivate: [SCPRedirectGuard] },
      { path: 'dashboard', component: DashboardComponent, canActivate: [SCPAdminGuard, SCPDeactivatedUserGuard] },
      { path: 'users', component: UsersComponent, canActivate: [SCPAdminGuard, SCPDeactivatedUserGuard] },
      { path: 'departments', component: DepartmentsComponent, canActivate: [SCPAdminGuard, SCPDeactivatedUserGuard] },
      { path: 'devices', component: DevicesComponent, canActivate: [SCPAdminGuard, SCPDeactivatedUserGuard] },
      { path: 'jobs', component: JobsComponent, canActivate: [SCPDeactivatedUserGuard] },
      { path: 'reports', component: ReportsComponent, canActivate: [SCPAdminGuard, SCPDeactivatedUserGuard] },
      { path: 'security', component: SettingsComponent, canActivate: [SCPDeactivatedUserGuard] },
      { path: 'admin-users', component: AdminUsersComponent, canActivate: [SCPAdminGuard, SCPDeactivatedUserGuard] },
      { path: 'subscriptions', component: SubscriptionsComponent, canActivate: [SCPAdminGuard, SCPDeactivatedUserGuard] },
      { path: 'admin-log', component: AdminLogComponent, canActivate: [SCPAdminGuard, SCPDeactivatedUserGuard] },
      { path: 'system-log', component: SystemLogComponent, canActivate: [SCPAdminGuard, SCPDeactivatedUserGuard] },
      { path: 'tenant', component: TenantComponent, canActivate: [SCPAdminGuard, SCPDeactivatedUserGuard] },
      { path: 'supported-domains', component: SupportedDomainsComponent, canActivate: [SCPAdminGuard, SCPDeactivatedUserGuard] },
    ],
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {}
