import { Injectable } from '@angular/core';
import { Route, CanLoad, CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
    constructor(private authService: AuthService) {}

    canActivate() {
        if (this.authService.isAuthenticated()) {
            return true;
        }
        this.authService.login();
        return false;
    }

    canLoad(_route: Route): boolean {
        return this.canActivate();
    }
}
