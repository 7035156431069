<mat-card class="scp_theme mat-typography container mt-5 mat-elevation-z8">
  <h2>{{ 'scp.pages.un_authorized.heading' | translate }}</h2>
  <mat-divider></mat-divider>

  <mat-card-content>
    <div class="my-3" [innerHTML]="'scp.pages.un_authorized.message' | translate"></div>
  </mat-card-content>

  <mat-card-actions>
    <a mat-flat-button color="primary" [routerLink]="nextRouterLink">{{ 'scp.common.try_again_button_text' | translate }}</a>
  </mat-card-actions>
</mat-card>
