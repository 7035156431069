import { Component, OnInit } from '@angular/core';

import { SCPRouterLinks } from '../../models/router-model';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
})
export class SessionExpiredComponent extends BaseComponent implements OnInit {
  nextRouterLink = SCPRouterLinks.root;

  ngOnInit() {
    this.dialog.closeAll();

    this.window.sessionStorage.removeItem('currentUrl');
  }
}
