import { NgModule } from '@angular/core';
import { AuthNComponent } from './auth-n.component';


@NgModule({
    declarations: [
        AuthNComponent
    ],
    imports: [
    ],
    exports: [
        AuthNComponent
    ]
})
export class AuthNModule { }
