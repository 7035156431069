import { Component } from '@angular/core';
import { SCPRouterLinks } from '../../../shared/models/router-model';

@Component({
  selector: 'app-setup-guide',
  templateUrl: './setup-guide.component.html',
  styleUrls: ['./setup-guide.component.scss'],
})
export class SetupGuideComponent {
  SCPRouterLinks = SCPRouterLinks;
}
