import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { BaseComponent } from '../../../shared/components/base.component';
import { SettingsPinReplacementComponent } from '../settings-pin-replacement/settings-pin-replacement.component';

@Component({
  selector: 'app-settings-login',
  templateUrl: './settings-login.component.html',
  styleUrls: ['./settings-login.component.scss'],
})
export class SettingsLoginComponent extends BaseComponent implements OnInit {
  settingsFormGroup: FormGroup;
  enablePinFormCtrl: FormControl;
  enablePinAutoGenerateFormCtrl: FormControl;
  pinMinDigitFormCtrl: FormControl;
  pinMaxAttemptsFormCtrl: FormControl;
  enableCardFormCtrl: FormControl;
  cardMaxAttemptsFormCtrl: FormControl;
  enable2FAFormCtrl: FormControl;
  hasError = false;
  isAdminUser = false;

  constructor() {
    super();

    this.enablePinFormCtrl = new FormControl(null, [Validators.required]);
    this.enablePinAutoGenerateFormCtrl = new FormControl(null, [Validators.required]);
    this.pinMinDigitFormCtrl = new FormControl(null, [Validators.required, Validators.min(4), Validators.max(6)]);
    this.pinMaxAttemptsFormCtrl = new FormControl(null, [Validators.required, Validators.min(1), Validators.max(10)]);

    this.enableCardFormCtrl = new FormControl(null, [Validators.required]);
    this.cardMaxAttemptsFormCtrl = new FormControl(null, [Validators.required, Validators.min(1), Validators.max(10)]);

    this.enable2FAFormCtrl = new FormControl(null, [Validators.required]);

    this.settingsFormGroup = new FormGroup({
      enable_pin: this.enablePinFormCtrl,
      enable_pin_auto_generate: this.enablePinAutoGenerateFormCtrl,
      pin_min_digit: this.pinMinDigitFormCtrl,
      pin_max_attempts: this.pinMaxAttemptsFormCtrl,
      enable_card: this.enableCardFormCtrl,
      card_max_attempts: this.cardMaxAttemptsFormCtrl,
      enable_2FA: this.enable2FAFormCtrl,
    });
  }

  ngOnInit() {
    this.isAdminUser = this.appService.isSCPAdminUser;

    if (this.isAdminUser) {
      this.getSettingsData();
    }
  }

  update2faSetting() {
    if (!(this.enablePinFormCtrl.value && this.enableCardFormCtrl.value)) {
      this.enable2FAFormCtrl.setValue(false);
    }
    if (!(this.enablePinFormCtrl.value)) {
      this.enablePinAutoGenerateFormCtrl.setValue(false);
    }
  }

  updatePinCardSetting() {
    this.enablePinFormCtrl.setValue(true);
    this.enablePinAutoGenerateFormCtrl.setValue(true);
    this.enableCardFormCtrl.setValue(true);
  }

  updatePinAutoGenerate() {
    if (this.enablePinAutoGenerateFormCtrl.value) {
        this.enablePinFormCtrl.setValue(true);
    }
  }

  saveSettings() {
    if (this.settingsFormGroup.valid) {
      this.appService
        .updateSettings({
          ...this.settingsFormGroup.value,
          pin_max_digit: this.pinMinDigitFormCtrl.value,
        })
        .pipe(this.takeUntilDestroyed())
        .subscribe(
          () => {
            this.getSettingsData();

            this.appService.showSuccess(this.translate.instant('scp.settings.save_success_message'));
          },
          () => {
            this.appService.showError(this.translate.instant('scp.settings.save_error_message'));
          }
        );
    }
  }

  showPinReplacementDialog() {
    this.dialog.open(SettingsPinReplacementComponent, {
      width: '500px',
      disableClose: true,
    });
  }

  getSettingsData() {
    this.appService
      .getSettings()
      .pipe(this.takeUntilDestroyed<[]>())
      .subscribe(
        (data) => {
          (data || []).forEach(({ key, value, type }: { key: string; value: string; type: string }) => {
            const ctrl = this.settingsFormGroup.get(key);

            if (ctrl) {
              if (type === 'bool') {
                ctrl.setValue(value === 'true'); // Convert to javascript boolean
              } else {
                ctrl.setValue(value);
              }
            }
          });
        },
        () => {
          this.hasError = true;
        }
      );
  }
}
