import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { TenantService } from './tenant.service';
import * as dayjs from 'dayjs';
import { TokenStorage } from '../../core/token.storage';

@Injectable({ providedIn: 'root' })
export class LogHandlerService {
    logSchema: any;
    timezone: string;
    public userName;
    public userEmail;
    public tenantId;
    public userRole;
    public rmmRole;
    public cpUserRole;
    private loggedInRole;

    public opDetails: any;
    public opDetailsPattern: any;
    public serviceMeeting: any;
    public serviceGo: any;
    public service: any;
    // This is a temporary URL
    // All calls to logging server must go through a intermediate wrapper in roomandusers API
    // The intermediate wrapper implementation can be similar to 'https://dev-api.smartmeetingapp.com/rmi/v1/csv/so'
    /**
     * Make the following declaration in environment.js:
     *     rmpSendLogUrl: { url: 'https://dev-api.smartmeetingapp.com/rmi/v1/logs/system' }
     * Implement the same url in roomsandusers API
     * Use the above url like this:
     *     private loggingUrl = environment.rmpSendLogUrl.url;
     */

    constructor(
        protected http: HttpClient,
        protected tenantService: TenantService,
        private token: TokenStorage,
    ) {
        this.opDetails = {};
        this.opDetailsPattern = {};
        this.initDetails();
        this.logTemplate();
    }

    public initDetails() {
        // Users and Admin
        this.opDetails[`GET|${environment.rmpEndPointUser.url}/users?userType=User,BA%20User&&eId=REVOKED_IN_CANCELLATION`.toLowerCase()] = ['User', 'get User list', 'MST20007'];
        this.opDetailsPattern[`GET|${environment.rmpEndPointUser.url}/users?userType=User,BA%20User`.toLowerCase()] = ['User', 'get User list', 'MST20007'];
        this.opDetailsPattern[`GET|${environment.rmpEndPointUser.url}/users?userType=BA%2CBA%20user`.toLowerCase()] = ['Admin', 'get Admin list', 'MST20012'];
        this.opDetails[`UserPUT|${environment.rmpEndPointLicense.url}/assign`.toLowerCase()] = ['User', 'assign license', 'MST20010'];
        this.opDetails[`UserPOST|${environment.rmpEndPointLicense.url}/remove`.toLowerCase()] = ['User', 'remove license', 'MST20009'];
        this.opDetails['PUT|#/users'.toLowerCase()] = ['User', 'create User', 'MST20008'];
        this.opDetails['DELETE|#/users'.toLowerCase()] = ['User', 'delete User', 'MST20011'];
        this.opDetails['PUT|#/admin'.toLowerCase()] = ['Admin', 'create Admin', 'MST20013'];
        this.opDetails['DELETE|#/admin'.toLowerCase()] = ['Admin', 'delete Admin', 'MST20014'];
        // Workspaces
        this.opDetails[`POST|${environment.rmpEndPoint.url}list?type=fetchall`.toLowerCase()] = ['Workspace', 'get Room List', 'MST20001'];
        this.opDetailsPattern[`PUT|${environment.rmpEndPoint.url}`.toLowerCase()] = ['Workspace', 'edit Workspace', 'MST20004'];
        this.opDetails[`POST|${environment.rmpEndPoint.url}`.toLowerCase()] = ['Workspace', 'Add Workspace', 'MST20005'];
        this.opDetailsPattern[`DELETE|${environment.rmpEndPoint.url}`.toLowerCase()] = ['Workspace', 'delete Workspace', 'MST20006'];
        this.opDetails[`roomPUT|${environment.rmpEndPointLicense.url}/assign`.toLowerCase()] = ['Workspace', 'assign license', 'MST20002'];
        this.opDetails[`roomPOST|${environment.rmpEndPointLicense.url}/remove`.toLowerCase()] = ['Workspace', 'remove license', 'MST20003'];
        // Subscription
        this.opDetails[`GET|${environment.rmpEndPointSubscription.url}/details`.toLowerCase()] = ['Subscription', 'get Subscription list', 'MST20019'];
        // DomainAliases
        this.opDetails[`GET|${environment.domainsURL.url}`.toLowerCase()] = ['Supported Domain', 'get Supported Domain list', 'MST20015'];
        this.opDetails[`PUT|${environment.rmpEndPointTenant.url}`.toLowerCase()] = ['Supported Domain', 'edit Supported Domain list', 'MST20016'];
        // Notification Preferences
        this.opDetails[`GET|${environment.rmpEndPointNotificationPreferences.url}me`.toLowerCase()] = ['Notification Preferences', 'get Notification Preference list', 'MST20017'];
        this.opDetails[`POST|${environment.rmpEndPointNotificationPreferences.url}me`.toLowerCase()] = ['Notification Preferences', 'edit Notification Preference list', 'MST20018'];
        // Reports
        this.opDetails[`getGraphs`.toLowerCase()] = ['Analytics', 'show Graph of Report Page', 'MST20020'];
        this.opDetails[`exportGraph`.toLowerCase()] = ['Analytics', 'export Analytics', 'MST20021'];
        // System Logs
        this.opDetails[`exportSys`.toLowerCase()] = ['System Log', 'export System Log', 'MST20022'];
        this.opDetails[`getSystemLogs`.toLowerCase()] = ['System Log', 'show System Logs', 'MST20023'];
        // Admin Logs
        this.opDetails[`exportAdmin`.toLowerCase()] = ['Admin Log', 'export Admin Log', 'MST20024'];
        this.opDetails[`getAdminLogs`.toLowerCase()] = ['Admin Log', 'show Admin Logs', 'MST20025'];
        // Connetion Failure LMS or ES
        this.opDetails[`connectionFailureES`.toLowerCase()] = ['Connect to service', 'connect to ES', 'MST20026'];
        this.opDetails[`connectionFailureLMS`.toLowerCase()] = ['Connect to service', 'connect to LMS', 'MST20027'];
        this.opDetails[`connectionFailureLMSAudit`.toLowerCase()] = ['Connect to service', 'connect to LMS', 'MST20027'];

        this.serviceMeeting = ['roomlist', 'roomdetail', 'adminrs', 'adminrs/multi-domain', 'subscriptionsrs', 'logreport', 'auditlogsrs', 'systemlogsrs'];
        this.serviceGo = ['users', 'spaces', 'spacedetail', 'admin', 'notificationpreferences', 'admin/multi-domain', 'subscriptions', 'reports', 'auditlogs', 'systemlogs', 'downloads', 'agentupdates','checkinlogs'];
        
        //SCP Logs
        this.opDetailsPattern[`GET|${environment.rmpEndPointUser.url}/users?scpUserType=tenant_admin`.toLowerCase()] = ['Admin', 'get Admin list', 'MST20012'];

        this.opDetails['PUT|#/cloudprint/admin-users'.toLowerCase()] = ['Admin', 'create Admin', 'MST20013'];
        this.opDetails['DELETE|#/cloudprint/admin-users'.toLowerCase()] = ['Admin', 'delete Admin', 'MST20014'];
        
    }

    /**
     *
     * @param user logged in user name
     * @param email logged in user's email id
     * @param role adminType
     * Note: 1. For invalid user login, user_role should be 'Unknown'
     *       2. For valid admins before V2.2 , user_role should be 'Admin'
     *       3. For admins of V2.2 and above, user_role can be 'Primary Admin', 'Admin' or 'Support Admin'
     */

    public logTemplate(user?, email?, role?) {
        const timeZone =  dayjs().format('Z');
        const logDateTime = dayjs().format('YYYY-MM-DDTHH:mm:ss') + timeZone;
        const newLog = {
            tenant_id: this.tenantId,
            log_generated_date: logDateTime,
            time_zone: dayjs().format('Z'),
            user_name: user || this.userName,
            user_mail: email || this.userEmail,
            user_role: role || this.loggedInRole || 'Admin',
            application: 'Admin Portal',
            app_version: environment.version,
            hostname: '',
            device_type: navigator.userAgent,
            device_name: '',
        };
        return newLog;
    }

    /**
     *
     * @param httpMethod The httpMethod of the failed url (GET, POST, DELETE, PUT, etc)
     * @param httpUrl The httpUrl that failed
     * @param source The source of this request (can be null, or something like workspace, user, login, etc)
     * @param details The details of the error
     */
    addSystemLog(httpMethod, httpUrl, details, featureType?, graph?, source?, operation?, userName?, email?, role?, logTypeId? ) {
        const exemptedUrls = [
            '/checkVersion',
            'https://graph.microsoft.com/',
            'https://www.googleapis.com/',
            environment.logstashUrl.url,
        ];
        const exemptList = exemptedUrls.find((item) => httpUrl.toLowerCase().includes(item.toLowerCase()));
        if (exemptList && exemptList.length > 0) {
            // The httpUrl belongs to exemption list. So return without loggging
            return;
        }

        const userType = window.location.hash;
        const userTypeKey = ((userType === '#/admin' || userType === '#/adminrs') ? `${httpMethod}|#/admin`.toLowerCase() : `${httpMethod}|${userType}`.toLowerCase());
        const otherKey = ((graph) ? `${graph}`.toLowerCase() : `${featureType}${httpMethod}|${httpUrl}`.toLowerCase());
        const key = ((otherKey in this.opDetails) ? otherKey : (userTypeKey in this.opDetails) ? userTypeKey : '');
        const urlPattern = Object.keys(this.opDetailsPattern).find(key => otherKey.startsWith(key));
        if (key || urlPattern) {
            const [log_category, log_type, log_type_id] = key ? this.opDetails[key] : this.opDetailsPattern[urlPattern];
            // change message in details for 'Unknown error'
            const error = '0 Unknown Error'. toLowerCase();
            if (details.toLowerCase().includes(error)) {
                details = 'connection failure';
            }

            const systemLog = this.logTemplate(userName, email, role);
            systemLog['schema'] = 'synappx_system_v1';
            systemLog['log_category'] = source || log_category;
            systemLog['log_type'] = 'Failed to ' + log_type;
            systemLog['log_type_id'] = log_type_id;
            systemLog['detail'] = details;
            systemLog['device_id'] = '';
            systemLog['user_role'] = systemLog['user_role'].replace('Tenant Admin', 'Admin');
            this.addToQueue(systemLog);

        } else if (source && operation) {
            const sysLog = this.logTemplate(userName, email, role);
            sysLog['schema'] = 'synappx_system_v1';
            sysLog['log_category'] = source;
            sysLog['log_type'] = 'Failed to ' + operation;
            sysLog['log_type_id'] = logTypeId;
            sysLog['detail'] = details;
            sysLog['device_id'] = '';
            sysLog['user_role'] = sysLog['user_role'].replace('Tenant Admin', 'Admin');
            this.addToQueue(sysLog);
        } else {
            // Handle URLs that have additional path
            // example: https://dev-api.smartmeetingapp.com/rmi/v1/<room-id>
        }
    }

    /**
     * @param category The category of that we are logging the action for (USER, WORKSPACE, etc)
     * @param type The type of action performed
     * @param detail The details of the action performed
     */
    addSystemLogCustom(detail, category, type, log_type_id) {
        const sysLogCustom = this.logTemplate();
        sysLogCustom['schema'] = 'synappx_system_v1';
        sysLogCustom['detail'] = detail;
        sysLogCustom['log_category'] = category;
        sysLogCustom['log_type'] = 'Failed to ' + type;
        sysLogCustom['log_type_id'] = log_type_id;
        sysLogCustom['device_id'] = '';
        sysLogCustom['user_role'] = sysLogCustom['user_role'].replace('Tenant Admin', 'Admin');
        this.addToQueue(sysLogCustom);
    }

    /**
     * @param category The category of that we are logging the action for (USER, WORKSPACE, etc)
     * @param action The action performed
     * @param detail The details of the action performed
     */
    addAdminLog(category, action, actionId, detail, service?) {
        const serviceName = this.getService();
        const adminLog = this.logTemplate();
        let usrRole = this.userRole;
        if(service){
            if (service == 'Manage') {
                usrRole = this.rmmRole;
            }
            if (service == 'Cloud Print') {
                usrRole = (this.cpUserRole === 'Tenant Admin') ? 'Admin' : 'User';
            }
            adminLog['user_role'] = usrRole;
        }
        switch (service) {
            case 'rocketstart':
            case 'Meeting':
                service = 'Go';
                break;
            case 'marvel':
                service = 'Go';
                break;
            case 'rmm':
                service = 'Manage';
                break;
            case 'scp':
                service = 'Cloud Print';
                break;
        }
        adminLog['schema'] = 'synappx_admin_v1';
        adminLog['service_name'] = service || serviceName;
        adminLog['log_category'] = category;
        adminLog['action'] = action;
        adminLog['action_id'] = actionId;
        adminLog['detail'] = detail;
        adminLog['user_role'] = adminLog['user_role'].replace('Tenant Admin', 'Admin');
        this.addToQueue(adminLog);
        }

    private getService() {
        this.service = this.token.getMarvelRMPService();
        let service = this.service;
        this.loggedInRole = this.userRole;
        switch (service) {
            case 'rocketstart':
            case 'Meeting':
                service = 'Go';
                break;
            case 'marvel':
                service = 'Go';
                break;
            case 'rmm':
                service = 'Manage';
                this.loggedInRole = this.rmmRole;
                break;
            case 'scp':
                service = 'Cloud Print';
                this.loggedInRole = this.cpUserRole
                break;
            default:
                service = '';              
        }
        return service ;
    }



    addToQueue(logData) {
        this.postLog(logData).subscribe(result => {
            //console.log(`logged: ${logData.log_type}`);
        }, err => console.log(err));
    }

     postLog(logData) {
        // Post this log information to log server
        const httpOptions: Object = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            responseType: 'text'
        };
        const additionalData = {};
        const requestBody = Object.assign({}, logData, additionalData);
        return this.http.post<any>(environment.logstashUrl.url, requestBody, httpOptions);
     }

}
