import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { map } from 'rxjs/operators';

import { BaseComponent } from '../../../shared/components/base.component';
import { SubDialogInfo } from '../../../scp-shared/interfaces/dialog';
import { DialogService } from '../../../scp-shared/services/dialog.service';

const PAGE_NAME = 'JOBS_QUEUE';

@Component({
  selector: 'app-jobs-queue',
  templateUrl: './jobs-queue.component.html',
})
export class JobsQueueComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('sustainabilitySnackbarTemplate') sustainabilitySnackbarTemplate: TemplateRef<any>;

  selection = new SelectionModel<any>(true, []);
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['fileCreatedTime', 'userDisplayName', 'fileName', 'jobLocation', 'pageCount', 'colorMode', 'duplex', 'actions'];

  constructor(private dialogService: DialogService) {
    super();
  }

  ngOnInit() {
    this.getPrintQueueJobsData();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = this.defaultSortingDataAccessor;

    // Reset the page number
    this.sort.sortChange.pipe(this.takeUntilDestroyed()).subscribe(() => (this.paginator.pageIndex = 0));

    Promise.resolve().then(() => {
      this.paginator.pageSize = this.appService.getPageSizeInfo(PAGE_NAME);
    });

    this.paginator.page.pipe(this.takeUntilDestroyed()).subscribe((evt) => {
      this.appService.savePageSizeInfo(PAGE_NAME, evt.pageSize);
    });
  }

  applyFilter(filterString = '') {
    this.dataSource.filter = filterString.trim().toLowerCase();
  }

  convertASCIIToHex(stringValue) {
    return stringValue
      .split('')
      .map((c) => c.charCodeAt(0).toString(16).padStart(2, '0'))
      .join('');
  }

  convertHexToASCII(hexValue) {
    const hex = (hexValue || '').toString();
    let str = '';

    for (let n = 0; n < hex.length; n += 2) {
      str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }

    // Remove non printable characters
    return str.replace(/[\u0000-\u0008,\u000A-\u001F,\u007F-\u00A0]+/g, '');
  }

  showDeleteJobDialog(job) {
    return new Promise((resolve, reject) => {
      const subDialogInfo: SubDialogInfo = {
        title: this.translate.instant('scp.common.confirm'),
        mainMessage: this.translate.instant('scp.jobs.queue.confirm_delete_message', { value: job.fileName }),
        cancelText: this.translate.instant('scp.common.cancel_button_text'),
        confirmText: this.translate.instant('scp.common.ok_button_text'),
        infoMode: false,
        placeHolderVariables: this.translate.instant('scp.jobs.queue.confirm_delete_message', { value: job.fileName }),
      };
      this.dialogService.open(subDialogInfo);
      this.dialogService.confirmed().subscribe((result) => {
        if (result === 1) {
          //Indicates Action Confirmed
          this.appService
            .deletePrintJobRemotely({
              filename: this.convertASCIIToHex(this.appService.userDetails.username.toUpperCase()) + '/' + job.originalFileName,
              print: false,
              delete: true,
              duplex: true,
              mono: true,
              staple: false,
              copies: 0,
              printerUuid: '',
            })
            .pipe(this.takeUntilDestroyed())
            .subscribe(
              () => {
                this.getPrintQueueJobsData();

                this.appService.showNotification(this.sustainabilitySnackbarTemplate);
              },
              () => {
                this.appService.showError(this.translate.instant('scp.jobs.queue.delete_error_message'));
              }
            );
        } else {
          //Indicates Action Cancelled
          resolve(0);
        }
      });
    });
  }

  getPrintQueueJobsData() {
    this.appService
      .getPrintQueueJobs()
      .pipe(
        this.takeUntilDestroyed(),
        map((data) => {
          return (data || []).map((item) => {
            let formattedFileCreatedTime = '-';

            if (item.fileCreatedTime) {
              formattedFileCreatedTime = moment(item.fileCreatedTime + 'Z')
                .local()
                .locale(navigator.language)
                .format('L LTS');
            }

            return {
              ...item,
              formattedFileCreatedTime,
              fileCreatedTime: item.fileCreatedTime,
              originalFileName: item.fileName,
              fileName: this.convertHexToASCII(item.fileName),
              jobLocation: 'Office',
            };
          });
        })
      )
      .subscribe((data) => {
        this.dataSource.data = data;
      });
  }
}
