import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, concat, throwError } from 'rxjs';
import { catchError, delay, map, retryWhen, switchMap, take } from 'rxjs/operators';

import { SCPRouterLinks } from '../shared/models/router-model';
import { CloudPrintEnvService } from '../shared/services/cloud-print-env.service';
import { AppService } from './app.service';

@Injectable()
export class CloudPrintHttpInterceptor implements HttpInterceptor {
  constructor(private router: Router, private envService: CloudPrintEnvService, private appService: AppService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const isSCPApiUrl = req.url.startsWith(this.envService.scpCommonApiBaseUrl);
    const isSCPAuthTokenApiUrl = req.url.startsWith(`${this.envService.scpCommonApiBaseUrl}/identity/user/auth0?service=AdminPortal`);

    // Pass all non SCP http requests to SSP HTTP_INTERCEPTOR.
    if (!isSCPApiUrl) {
      return next.handle(req);
    }

    // Handle only SCP http requests
    if (!req.headers.has('Authorization')) {
      // Add SCP's own custom auth token for SCP API requests
      req = req.clone({ setHeaders: { Authorization: `Bearer ${this.appService.userDetails?.token}` } });
    }

    return next.handle(req).pipe(
      retryWhen((errors) =>
        errors.pipe(
          map((errorResponse: HttpErrorResponse) => {
            if (isSCPAuthTokenApiUrl) {
              console.warn('Trying to recover from SCPAuthTokenApi error', errorResponse);

              return errorResponse;
            }

            throw errorResponse;
          }),
          delay(2000),
          take(4),
          (error) => concat(error, throwError({ error: { message: `Retries exceeded for ${req.url}` } }))
        )
      ),
      catchError((errorResponse: HttpErrorResponse) => {
        // NOTE: Temporary fix for multi browser/tab Unauthorized issue or multiple users using the same username/pwd combination.
        // TODO: Fix must be removed after SCP API starts using the SSP Auth0 token.
        if (!isSCPAuthTokenApiUrl && [401, 403].includes(errorResponse.status)) {
          return this.appService.getSCPAuthToken().pipe(
            switchMap((userDetails: any) => {
              return next.handle(req.clone({ setHeaders: { Authorization: `Bearer ${userDetails?.token}` } }));
            }),
            catchError((errorResponse: HttpErrorResponse) => {
              return this.handleSCPApiError(errorResponse);
            })
          );
        }

        return this.handleSCPApiError(errorResponse);
      })
    );
  }

  handleSCPApiError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ProgressEvent) {
      if (!navigator.onLine) {
        this.router.navigate([SCPRouterLinks.offline], { state: { fromPage: this.router.url } });
      } else {
        // NOTE: If typeof errorResponse.error is ProgressEvent the error obj cannot be serialized & passed around.
        this.router.navigate([SCPRouterLinks.error], { state: { fromPage: this.router.url, error: { message: errorResponse.message } } });
      }

      return EMPTY;
    }

    // 401: Unauthorized error - https://httpstatuses.io/401
    // 403: Forbidden error - https://httpstatuses.io/403
    if ([401, 403].includes(errorResponse.status)) {
      this.router.navigate([SCPRouterLinks.unAuthorized]);
      return EMPTY;
    }

    // 5××: Server error's - https://httpstatuses.io/500
    if (errorResponse.status >= 500) {
      this.router.navigate([SCPRouterLinks.error], { state: { fromPage: this.router.url, error: errorResponse.error } });
      return EMPTY;
    }

    return throwError(errorResponse);
  }
}
