import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class GuestService {
    constructor(private http: HttpClient) {}

    getGuestproviderDetails(token: string, name: string, role:string): Observable<any> {
        const URI = environment.rmpEndPointUser.url + '/guest/providerDetails';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': token,
                'name': name,
                'role': role
            }),
        };
        return this.http.get<any>(URI, httpOptions);
    }

    createGuestProvider(token: string, provider: string, signUpInfo: any, name: string, role: string, reselectProvider: boolean): Observable<any> {
        const URI = environment.rmpEndPointUser.url + '/guest/providerDetails';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': token,
                'name': name,
                'role': role
            }),
        };
        const body = { 'provider': provider, 'signUpInfo': signUpInfo, 'reselectProvider': reselectProvider };
        return this.http.post<any>(URI, body, httpOptions);
    }
}
