import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'lib-admin-confirm',
    templateUrl: './admin-confirm.component.html',
    styleUrls: ['./admin-confirm.component.scss']
})
export class AdminConfirmComponent implements OnInit {

    title: string;
    message: string;
    defaultDialogConfig = new MatDialogConfig();
    constructor(
        private dialog: MatDialog,
        public confirmDialogRef: MatDialogRef<AdminConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.defaultDialogConfig.autoFocus = false;
        this.title = this.data.title;
        this.message = this.data.message;
    }

    ngOnInit(): void { }

    onCancel(): void {
        this.confirmDialogRef.close(false);
    }

    onOk(): void {
        this.confirmDialogRef.close(true);
    }
}
