import { IdpBaseService } from './idp-base.service';
import { Observable, of, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocalAppDataService } from '../local-app-data.service';
import { HttpClient } from '@angular/common/http';
import { Office365Token } from '../../interfaces/User';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import * as MicrosoftGraphClient from '@microsoft/microsoft-graph-client/lib/src';
import { CloudPrintEnvService } from '../cloud-print-env.service';

export class O365ApiService extends IdpBaseService {
    public msGraphclient: any;

    /**
     * constructor
     * 
     * @description currently not implemented. Will be extended in future
     */
    constructor(http: HttpClient, localAppData: LocalAppDataService, envServive: CloudPrintEnvService) {
        super(http, localAppData, envServive);
    }

    /**
     * Get the idp access token
     * @returns An observable that resolves to idp access token
     * @description currently not implemented. Will be extended in future
     */
    getIdpToken(): Observable<any> {
        return super.getIdpToken().pipe(
            tap({
                next: (token: any) => { }
            })
        );
    }


    /**
     * Search for users by name within given list of domains
     * @param userToSearch The user name pattern to search for
     * @param domains The domains to restrict the search
     * @returns An Observable that resolves into a list of users
     * 
     * @description currently not implemented. Will be extended in future
     */
    public searchUsers(userToSearch: string, domains): Observable<any> {
        return new Observable((observer) => {
            this.getIdpToken().subscribe((result: Office365Token) => {
                // Create the msGraphClient to communicate with Office 365 online services
                const access_token = <string>result.access_token;
                this.msGraphclient = MicrosoftGraphClient.Client.init({
                    authProvider: done => {
                        done(null, access_token); // first parameter takes an error if you can't get an access token
                    },
                });
                if (userToSearch === '' || userToSearch === undefined) {
                    observer.next([]);
                    observer.complete();
                }
                // Construct the user search query
                let userQuery = 'users?$select=id,displayName,userPrincipalName,mail,userType&$top=999';
                if (userToSearch != null && userToSearch.length > 0
                    && userToSearch.toLowerCase().trim() !== 'all') {
                    userToSearch = userToSearch.replace(/'/g, "''"); // handling apostrophe in query
                    userToSearch = encodeURIComponent(userToSearch);
                    userQuery += `&$filter = startswith(displayName, '${userToSearch}')`;
                }
                // Execute the user search query on 'Office 365 System' using MS Graph Client (v1.0)
                this.msGraphclient.api(userQuery).version('v1.0').get((err, res) => {
                    if (err != null) {
                        // user search query on 'Office 365 System' failed
                        console.error(`error occured while retrieving users from MS Graph Client`);
                        console.log(err);
                        observer.error(err);
                    } else {
                        // user search on 'Office 365 system' returned results
                        res.value = res.value || [];
                        const allowed = this.filterEmailIDs(res.value, domains)[0];
                        const userList = allowed.map(entry => ({
                            userName: entry.displayName.toString(),
                            userEmail: entry.userPrincipalName.toString()
                        }));
                        // Resolve the promise with the userList
                        observer.next(userList);
                        observer.complete();
                    }
                });
            },
                err => {
                    console.error(`error occured while obtaining office 365 token`);
                    console.log(err);
                    observer.error(err);
                });
        });
    }

    /**
     * Search for users by email within given list of domains
     * @param userToSearch The user email pattern to search for
     * @param domains The domains to restrict the search
     * @returns An Observable that resolves into a list of users
     * 
     * @description currently not implemented. Will be extended in future
     */
    public searchUsersByEmail(userToSearch: string, domains): Observable<any> {
        return new Observable((observer) => {
            this.getIdpToken().subscribe((result: Office365Token) => {
                // Create the msGraphClient to communicate with Office 365 online services
                const access_token = <string>result.access_token;
                this.msGraphclient = MicrosoftGraphClient.Client.init({
                    authProvider: done => {
                        done(null, access_token); // first parameter takes an error if you can't get an access token
                    },
                });
                if (userToSearch === '' || userToSearch === undefined) {
                    observer.next([]);
                    observer.complete();
                }
                // Construct the user search query
                let userQuery = 'users?$select=id,displayName,userPrincipalName,mail,userType&$top=999';
                if (userToSearch != null && userToSearch.length > 0
                    && userToSearch.toLowerCase().trim() !== 'all') {
                    userToSearch = userToSearch.replace(/'/g, "''"); // handling apostrophe in query
                    userToSearch = encodeURIComponent(userToSearch);
                    userQuery += `&$filter = startswith(userPrincipalName, '${userToSearch}')`;
                }
                // Execute the user search query on 'Office 365 System' using MS Graph Client (v1.0)
                this.msGraphclient.api(userQuery).version('v1.0').get((err, res) => {
                    if (err != null) {
                        // user search query on 'Office 365 System' failed
                        console.error(`error occured while retrieving users from MS Graph Client`);
                        console.log(err);
                        observer.error(err);
                    } else {
                        // user search on 'Office 365 system' returned results
                        res.value = res.value || [];
                        const allowed = this.filterEmailIDs(res.value, domains)[0];
                        const userList = allowed.map(entry => ({
                            userName: entry.displayName.toString(),
                            userEmail: entry.userPrincipalName.toString()
                        }));
                        // Resolve the promise with the userList
                        observer.next(userList);
                        observer.complete();
                    }
                });
            },
                err => {
                    console.error(`error occured while obtaining office 365 token`);
                    console.log(err);
                    observer.error(err);
                });
        });
    }


    /**
     * Splits the given address list into allowed/blocked parts.
     * @param addrList The Office356Room instance that needs to be splitted into allowed or blocked list.
     * @param domains The List of domains supported
     * @returns an array with two entries: [validAddr, blockedAddr]. Both entries in the array are array themselves extracted
     * from input parameter addrList
     */
    public filterEmailIDs(addrList, domains) {
        const validAddr = addrList.filter(entry => (O365ApiService.isValidDomain(entry.userPrincipalName, domains)));
        const blockedAddr = addrList.filter(entry => (!O365ApiService.isValidDomain(entry.userPrincipalName, domains)));
        return [validAddr, blockedAddr];
    }

    /**
     * Checks if the given email address belongs to the allowed domains
     * @param email The email address that needs to be validated
     * @param domains The domains the email needs to be validated against
     *
     * @returns true if the email address belongs to the allowed domains (this.domains).
     * false otherwise.
     */
    protected static isValidDomain(email, domains) {
        const supportedDomains = domains;
        email = email.trim().toLowerCase();
        if (email && email.indexOf('@') > 0) {
            const [id, domain] = email.split('@');
            if (id && domain && supportedDomains.includes(domain)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Get all the domains in the AD where the currently logged in user belongs to
     */
    public async getDomains() {
        return new Promise<any[]>((resolve, reject) => {
            this.getIdpToken().subscribe((result: Office365Token) => {
                // Create the msGraphClient to communicate with Office 365 online services
                const access_token = <string>result.access_token;
                this.msGraphclient = MicrosoftGraphClient.Client.init({
                    authProvider: done => {
                        done(null, access_token); // first parameter takes an error if you can't get an access token
                    },
                });
                this.msGraphclient.api(`domains`).version('v1.0').get((err, res) => {
                    if (err != null) {
                        console.warn(`Failed to retrieve domins`);
                        reject(err);
                    } else {
                        const rawDomains = res.value;
                        const filteredDomains = rawDomains
                        .filter(ele => ele.isVerified === true)
                        .map(e => e.id);
                        resolve(filteredDomains);
                    }
                });
            }, err => {
                reject(err);
            });
        });
    }

    public checkGrantReadUsers(): Observable<any> {
        return new Observable((observer) => {
            this.getIdpToken().subscribe(tokenInfo => {
                let scopes = (tokenInfo?.scope || "").split(' ');
                observer.next(scopes.includes('User.ReadBasic.All'));
                observer.complete();
            });
        })
    }
}
