import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../../../material/material.module';
import { ProgressLoaderService } from '../../services/progress-loader.service';
import { ProgressLoaderComponent } from './progress-loader.component';

@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
  ],
  exports: [ProgressLoaderComponent],
  declarations: [ProgressLoaderComponent],
  providers: [ProgressLoaderService],
})
export class ProgressLoaderModule { }
