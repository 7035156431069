import { Observable, throwError } from 'rxjs';
import { LocalAppDataService } from '../local-app-data.service';
import { HttpClient } from '@angular/common/http';
import { CloudPrintEnvService } from '../cloud-print-env.service';

export abstract class IdpBaseService {

    /**
     * The cached idp token information (same as response from token API)
     */
    tokenInfo: any

    protected localAppData: LocalAppDataService;
    protected http: HttpClient;
    protected envService: CloudPrintEnvService;

    /**
     * constructor
     * 
     * @description Implemented in derived classes
     */
    constructor(http: HttpClient, localAppData: LocalAppDataService, envService: CloudPrintEnvService) {
        // this is an abstract base class
        this.localAppData = localAppData;
        this.http = http;
        this.envService = envService;
    }

    /**
     * Get the idp access token
     * @returns An observable that resolves to idp access token
     */
    public getIdpToken(): Observable<any> {
        return this.localAppData.usingIdpToken();
    }

    /**
     * Search for users by name within given list of domains
     * @param userToSearch The user name pattern to search for
     * @param domains The domains to restrict the search
     * @returns An Observable that resolves into a list of users
     * 
     */
    public abstract searchUsers(userToSearch: string, domains): Observable<any>;

    /**
     * Search for users by email within given list of domains
     * @param userToSearch The user email pattern to search for
     * @param domains The domains to restrict the search
     * @returns An Observable that resolves into a list of users
     * 
     */
    public abstract searchUsersByEmail(userToSearch: string, domains): Observable<any>;

    public abstract getDomains();

    public abstract checkGrantReadUsers(): Observable<boolean>;
}
