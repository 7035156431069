
export abstract class Helper {

constructor(
) { }


    /**
     * Checks if the given email address belongs to the allowed domains
     * @param email The email address that needs to be validated
     * @param domains The domains the email needs to be validated against
     *
     * @returns true if the email address belongs to the allowed domains (this.domains).
     * false otherwise.
     */
    static isValidDomain(email, domains) {
        const supportedDomains = domains;
        email = email.trim().toLowerCase();
        if (email && email.indexOf('@') > 0) {
            const [id, domain] = email.split('@');
            if (id && domain && supportedDomains.includes(domain)) {
                return true;
            }
        }
        return false;
    }


    static handlePromiseError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error);
    }

    static downloadFile(fileName, blob) {
                const element = document.createElement('a');
                element.setAttribute('href', self.URL.createObjectURL(blob));
                element.setAttribute('download', fileName);
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
    }

    static removeLastChar(str) {
            return str.slice(0, -1);
    }


    static uuidv4(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }
}
