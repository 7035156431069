<div class="scp_theme mat-typography">
  <h1 mat-dialog-title>{{ 'Notice' | translate }}</h1>
  <div mat-dialog-content class="mat-mdc-dialog-content">
    <ul>
      <li *ngFor="let notice of noticeinfo">{{ this.notice }}</li>
    </ul>
  </div>
  <mat-dialog-actions align="end">
    <button mat-flat-button color="primary" id="id-deviceEdit-SaveButtton" (click)="close()">{{ 'Ok' | translate }}</button>
  </mat-dialog-actions>
</div>
