
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { SynappxEnvService } from '../public-api';
import { BaseAuth } from './base-auth';
import { BrowserStorage } from './browser-storage';
import { LogHandlerService } from './Services/log-handler.service';
import { TenantService } from './Services/tenant.service';
import { TokenStorage } from './Services/token.storage';

@Injectable({
    providedIn: 'root'
})
export class SynappxAuthService extends BaseAuth {
    constructor(sspEnv: SynappxEnvService,
        bnIdle: BnNgIdleService,
        httpClient: HttpClient,
        router: Router,
        logHandlerService: LogHandlerService,
        //private translate: TranslateService,
        browserStorage: BrowserStorage,
        tenantService: TenantService,
        token: TokenStorage,
    ) {
        super(sspEnv, bnIdle, httpClient, router, logHandlerService, browserStorage, tenantService, token);
    }
}
