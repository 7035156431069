<div class="scp_theme mat-typography">
  <h2 mat-dialog-title>{{ (isAdd ? 'scp.reports.execute.upsert.dialog_add_title' : 'scp.reports.execute.upsert.dialog_edit_title') | translate }}</h2>

  <form novalidate autocomplete="off" [formGroup]="addReportFormGroup">
    <mat-dialog-content>
      <div fxLayout="column wrap" fxLayoutGap="16px">
        <mat-form-field>
          <mat-label>
            {{ (isAdd ? 'scp.reports.execute.upsert.fields.add_report_name' : 'scp.reports.execute.upsert.fields.edit_report_name') | translate }}
          </mat-label>
          <input matInput [formControl]="newReportFormCtrl" />
          <mat-error *ngIf="newReportFormCtrl.hasError('required')">{{ 'scp.common.validations.required' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="isAdd">
          <mat-label>{{ 'scp.reports.execute.upsert.fields.duplicate_existing_report' | translate }}</mat-label>
          <mat-select [formControl]="originalReportFormCtrl" (selectionChange)="onOriginalReportChange($event.value)">
            <mat-option *ngFor="let report of reportList" [value]="report.guid">
              {{
                hasTranslation('scp.report.' + report.name.replaceAll(' ', '').toLowerCase())
                  ? ('scp.report.' + report.name.replaceAll(' ', '').toLowerCase() | translate)
                  : report.name
              }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="originalReportFormCtrl.hasError('required')">{{ 'scp.common.validations.required' | translate }}</mat-error>
        </mat-form-field>

        <mat-accordion>
          <mat-expansion-panel *ngFor="let template of reportTemplate; first as isFirst" [expanded]="isFirst">
            <mat-expansion-panel-header>
              <mat-panel-title> {{ 'scp.report.table.' + template.name.replaceAll(' ', '').toLowerCase() | translate }} </mat-panel-title>
              <mat-panel-description *ngIf="template.limit != null">
                {{ 'scp.reports.execute.upsert.fields.limit_rows' | translate : { value: template.limit } }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
              {{ 'scp.reports.execute.upsert.dimensions' | translate }} :
              {{ 'scp.report.tablecolumn.' + getColumnName(template.dimensions[0].column).replaceAll(' ', '').toLowerCase() | translate }}
            </div>

            <div fxLayout="row" fxFlexFill fxLayoutAlign="start center">
              <mat-form-field class="spacing">
                <mat-label>{{ 'scp.reports.execute.upsert.fields.column' | translate }}</mat-label>
                <mat-select [formControl]="newColumnFormCtrl" (selectionChange)="onNewColumnChange($event.value)">
                  <mat-option
                    *ngFor="let column of getColumData(template.name, getColumnName(template.dimensions[0].column).replaceAll(' ', '').toLowerCase())"
                    [value]="column.key"
                  >
                    {{ 'scp.report.tablecolumn.' + column.name.replaceAll(' ', '').toLowerCase() | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="spacing">
                <mat-label>{{ 'scp.reports.execute.upsert.fields.aggregation' | translate }}</mat-label>
                <mat-select [formControl]="newAggregationFormCtrl">
                  <mat-option *ngFor="let aggregation of aggregationsData" [value]="aggregation.key">
                    {{
                      aggregation.name === '-' ? aggregation.name : ('scp.report.aggregation.' + aggregation.name.replaceAll(' ', '').toLowerCase() | translate)
                    }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <button
                mat-icon-button
                color="primary"
                matTooltip="{{ 'scp.reports.execute.upsert.add_template_column_button_text' | translate }}"
                (click)="addTemplateColumn(template.name)"
              >
                <mat-icon>add_circle</mat-icon>
              </button>
            </div>

            <table #columnTable mat-table [dataSource]="template.columns" class="w-100">
              <ng-container matColumnDef="reportColumn">
                <th mat-header-cell *matHeaderCellDef>{{ 'scp.reports.execute.upsert.columns.column' | translate }}</th>
                <td mat-cell *matCellDef="let row">
                  {{ 'scp.report.tablecolumn.' + getColumnName(row.reportColumn).replaceAll(' ', '').toLowerCase() | translate }}
                </td>
              </ng-container>

              <ng-container matColumnDef="aggregation">
                <th mat-header-cell *matHeaderCellDef>{{ 'scp.reports.execute.upsert.columns.aggregation' | translate }}</th>
                <td mat-cell *matCellDef="let row">
                  {{
                    getAggregationName(row.aggregation) === undefined
                      ? getAggregationName(row.aggregation)
                      : ('scp.report.aggregation.' + getAggregationName(row.aggregation).replaceAll(' ', '').toLowerCase() | translate)
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>{{ 'scp.reports.execute.upsert.columns.actions' | translate }}</th>
                <td mat-cell *matCellDef="let row">
                  <!-- <ng-container *ngIf="row.aggregation != null">
                    <button mat-icon-button color="warn" (click)="deleteTemplateColumn(template.name, row.reportColumn)">
                      <mat-icon class="mat-18">delete</mat-icon>
                    </button></ng-container
                  > -->
                  <button mat-icon-button color="warn" (click)="deleteTemplateColumn(template.name, row.reportColumn)">
                    <mat-icon class="mat-18">delete</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-flat-button mat-dialog-close>{{ 'scp.common.cancel_button_text' | translate }}</button>
      <button mat-flat-button color="primary" [disabled]="isSubmitting" (click)="upsertReport()">
        {{ (isAdd ? 'scp.common.add_button_text' : 'scp.common.save_button_text') | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
