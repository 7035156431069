<mat-card>
    <div class="fx_space-between-center">
        <mat-card-title>{{ 'AdminUsers' | translate }}</mat-card-title>
    </div>
    <div>
        <label class="font_16" *ngIf="showGrantMsg" id="ssp-allRooms"><span
                [innerHTML]="GrantPermissions"><b></b></span></label>
    </div>
    <div style="display: flex;">
        <button class="scp-add-delete-button" mat-icon-button color="primary" (click)="addNew()" matTooltip="{{ 'AddAdmin' | translate }}" matTooltipPosition="above">
            <mat-icon class="scp-add-delete-icon">add_circle</mat-icon>
        </button>
        <button class="scp-add-delete-button" mat-icon-button color="primary" (click)="removeSelectedAdminUser()" [disabled]="deleteButtonDisabled"
            matTooltip="{{ 'RemoveAdminUser' | translate }}" matTooltipPosition="above">
            <mat-icon class="scp-add-delete-icon">remove_circle</mat-icon>
        </button>
    </div>
    
    <mat-card-content>
        <div class="example-container mat-elevation-z8 mt_15" [style.display]="!loadingStatus ? '' : 'none'">
            <mat-table #table [dataSource]="dataSource" matSort class="scp-mat-cell" matSortActive="users"
                matSortDirection="asc" matSortDisableClear>
                <ng-container matColumnDef="Select">
                    <mat-header-cell *matHeaderCellDef>
                        <mat-checkbox [disabled]="dataSource.renderedData.length === 0"
                            (change)="$event ? selectAllButton() : null" [checked]="IsAllSelectedProp"></mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkSelectedUser($event, element)"
                            [checked]="selection.isSelected(element)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Admin">
                    <mat-header-cell  mat-sort-header
                        (click)="onSorting()" class="scp-remove-space scp-word_break fx_space-between-center" *matHeaderCellDef>
                        <span class="fx_startcenter"
                            matTooltip="{{ ( sort && sort.active === 'Admin' && sort.direction === 'asc' ? 'SortInDescendingOrder' : 'SortInAscendingOrder' ) | translate }}"
                            matTooltipPosition=above> {{ 'AdminUsers' | translate }} </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="scp-mat-cell-color scp-word_break">{{element.userInfo.userName}}</mat-cell>
                </ng-container>

                <!-- Role Column -->
                <ng-container matColumnDef="Role">
                    <mat-header-cell  mat-sort-header
                        (click)="onSorting()" class="scp-remove-space fx_space-between-center" *matHeaderCellDef>
                        <span class="fx_startcenter"
                            matTooltip="{{ ( sort && sort.active === 'Role' && sort.direction === 'asc' ? 'SortInDescendingOrder' : 'SortInAscendingOrder' ) | translate }}"
                            matTooltipPosition=above> {{'Role' | translate }} </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="scp-mat-cell-color scp-word_break">{{ (getUserRole(element) || 'Other')
                        }}</mat-cell>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="Email">
                    <mat-header-cell  mat-sort-header
                        (click)="onSorting()" class="scp-remove-space scp-word_break fx_space-between-center" *matHeaderCellDef>
                        <span class="fx_startcenter"
                            matTooltip="{{ ( sort && sort.active === 'Email' && sort.direction === 'asc' ? 'SortInDescendingOrder' : 'SortInAscendingOrder' ) | translate }}"
                            matTooltipPosition=above> {{'EmailAddress' | translate }} </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="scp-mat-cell-color scp-word_break">{{ element.userInfo.email }}</mat-cell>
                </ng-container>

                <!-- Actions Column -->
                <ng-container matColumnDef="Actions">
                    <mat-header-cell class="fx_row" *matHeaderCellDef> </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="fx_endcenter">
                        <button *ngIf="showDelete(element)" mat-icon-button class="scp_icon_btn" color="block"
                            (click)="deleteAdminUser(element, false)">
                            <mat-icon aria-label="Delete" tabindex="0">delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
            <mat-paginator #paginator class="custom_mat_paginator" [ngClass]="{ disabled: loadingStatus == true }"
                [length]="dataSource.totalCount" [pageIndex]="0" [pageSizeOptions]="[5, 10, 25, 100]"
                (page)="onPagination($event)">
            </mat-paginator>
        </div>
    </mat-card-content>
</mat-card>