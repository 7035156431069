import { Directive, HostListener, Self } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

import { BaseComponent } from '../components/base.component';

@Directive({
  selector: 'form[validateFormAndScrollToFirstError]',
})
export class ValidateFormAndScrollToFirstErrorDirective extends BaseComponent {
  constructor(@Self() private ctrlContainer: FormGroupDirective) {
    super();
  }

  @HostListener('submit', ['$event.target'])
  onSubmit($targetElm: Element) {
    if (this.ctrlContainer?.form?.invalid && this.validateAllFormFields(this.ctrlContainer.form)) {
      setTimeout(() => {
        this.scrollElementIntoView($targetElm.querySelector('.ng-invalid'));
      });

      return false;
    }

    return true;
  }
}
