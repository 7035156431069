import { IDynamicItem, IStaticItem } from 'lib-ja-cloud';
import { Observable, of, Subject } from 'rxjs';

export class StaticItem implements IStaticItem {
    private _data: any;

    /**
     * Create the static item with given data
     * @param data The original data held inside this static item
     */
    constructor(data: any) {
        this._data = data;
    }

    /**
     * get the static item as string
     */
    get str() : string {
        return this._data as string;
    }

    /**
     * get the static item as object
     */
    get obj() : any {
        return this._data;
    }

    /**
     * get the static item as number
     */
    get num() : number {
        return this._data as number;
    }
};


export class DynamicItem implements IDynamicItem {
    private _data: Observable<any>;
    private _available: Observable<any>;

    /**
     * Create the static item with given data
     * @param data The original data held inside this static item
     */
    constructor(data: Observable<any>, available: Observable<any>) {
        this._data = data;
        this._available = available;
    }

    /**
     * get the static item as string
     */
    public get str(): Observable<string> {
        return this._data as Observable<string>;
    }

    /**
     * get the static item as object
     */
    get obj(): Observable<any> {
        return this._data;
    }

    /**
     * get the static item as number
     */
    get num(): Observable<number> {
        return this._data as Observable<number>;
    }

    /**
     * Get the subject that is used to check if the item is available
     */
    get available(): Observable<any> {
        return this._available;
    }
};
