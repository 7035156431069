import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EMPTY, from, iif } from 'rxjs';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';

import { BaseComponent } from '../../shared/components/base.component';
import { IUser } from '../../shared/models/scp-model';
import { ImportUsersComponent } from './import-users/import-users.component';

const PAGE_NAME = 'USERS';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  filterFormGroup: FormGroup;
  statusFilterFormCtrl: FormControl;
  searchFilterFormCtrl: FormControl;

  selection = new SelectionModel<string>(true, []);
  dataSource = new MatTableDataSource<IUser>();
  displayedColumns: string[] = ['select', 'activated', 'userRole', 'displayname', 'username', 'actions'];

  isSubmitting = false;
  shouldDisableDeleteButton = true;
  shouldDisableActivateButton = true;
  shouldDisableDeactivateButton = true;

  constructor() {
    super();

    this.statusFilterFormCtrl = new FormControl('all');
    this.searchFilterFormCtrl = new FormControl();

    this.filterFormGroup = new FormGroup({
      status: this.statusFilterFormCtrl,
      searchTerm: this.searchFilterFormCtrl,
    });
  }

  ngOnInit() {
    this.getUsersData();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = this.defaultSortingDataAccessor;

    // Reset the page number
    this.sort.sortChange.pipe(this.takeUntilDestroyed()).subscribe(() => (this.paginator.pageIndex = 0));

    Promise.resolve().then(() => {
      this.paginator.pageSize = this.appService.getPageSizeInfo(PAGE_NAME);
    });

    this.paginator.page.pipe(this.takeUntilDestroyed()).subscribe((evt) => {
      this.appService.savePageSizeInfo(PAGE_NAME, evt.pageSize);
    });

    this.filterFormGroup.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(() => {
      this.dataSource.filter = this.filterFormGroup.value;
    });

    this.dataSource.filterPredicate = (data, filter: any) => {
      const { activated, ...otherFields } = data;
      let statusFilter = true;
      let searchTermFilter = true;

      // Clear all checkbox selections
      this.selection.clear();

      if (filter.status !== 'all') {
        statusFilter = activated === filter.status;
      }

      if (filter.searchTerm) {
        searchTermFilter = Object.values(otherFields).toString().toLowerCase().includes(filter.searchTerm.toLowerCase());
      }

      return statusFilter && searchTermFilter;
    };

    this.selection.changed.pipe(this.takeUntilDestroyed()).subscribe(() => {
      this.shouldDisableDeleteButton = true;
      this.shouldDisableActivateButton = true;
      this.shouldDisableDeactivateButton = true;

      if (this.selection.hasValue()) {
        this.shouldDisableDeleteButton = false;

        const [selectedActiveUsers, selectedInactiveUsers] = this.dataSource.filteredData.reduce<[IUser[], IUser[]]>(
          (acc, user) => {
            if (this.selection.isSelected(user.guid)) {
              acc[user.activated ? 0 : 1].push(user);
            }

            return acc;
          },
          [[], []]
        );

        if (selectedActiveUsers.length > 0 && selectedInactiveUsers.length > 0) {
          return;
        }

        this.shouldDisableActivateButton = selectedInactiveUsers.length === 0 && selectedActiveUsers.length > 0;
        this.shouldDisableDeactivateButton = selectedActiveUsers.length === 0 && selectedInactiveUsers.length > 0;
      }
    });
  }

  isAllRowsSelected() {
    return this.selection.selected.length === this.dataSource.filteredData.length;
  }

  toggleAllRows() {
    if (this.isAllRowsSelected()) {
      return this.selection.clear();
    }

    this.selection.select(...this.dataSource.filteredData.map((user) => user.guid));
  }

  showImportUsersDialog() {
    this.dialog
      .open(ImportUsersComponent, {
        width: '650px',
        disableClose: true,
      })
      .afterClosed()
      .pipe(this.takeUntilDestroyed())
      .subscribe((shouldUpdateView) => {
        if (shouldUpdateView) {
          this.getUsersData();
        }
      });
  }

  updateActivationStatus(isActivation) {
    this.isSubmitting = true;

    let hasError = false;

    from(this.selection.selected)
      .pipe(
        this.takeUntilDestroyed(),
        mergeMap((guid) =>
          iif(
            () => isActivation, // prettier-ignore
            this.appService.activateUser(guid),
            this.appService.deActivateUser(guid)
          ).pipe(
            catchError(() => {
              hasError = true;
              return EMPTY;
            })
          )
        ),
        finalize(() => {
          this.getUsersData();
          this.selection.clear();
          this.isSubmitting = false;

          if (hasError) {
            this.appService.showError(this.translate.instant(isActivation ? 'scp.users.activation_error_message' : 'scp.users.deactivation_error_message'));
          } else {
            this.appService.showSuccess(
              this.translate.instant(isActivation ? 'scp.users.activation_success_message' : 'scp.users.deactivation_success_message')
            );
          }
        })
      )
      .subscribe();
  }

  deleteUsers() {
    this.isSubmitting = true;
    let hasError = false;

    if (this.selection.selected.includes(this.appService.userDetails.guid)) {
      this.isSubmitting = false;
      this.appService.showError(this.translate.instant('scp.users.delete_own_user_error_message'));
    } else {
      from(this.selection.selected)
        .pipe(
          this.takeUntilDestroyed(),
          mergeMap((guid) =>
            this.appService.deleteUser(guid).pipe(
              catchError(() => {
                hasError = true;
                return EMPTY;
              })
            )
          ),
          finalize(() => {
            this.getUsersData();
            this.selection.clear();
            this.isSubmitting = false;

            if (hasError) {
              this.appService.showError(this.translate.instant('scp.users.delete_error_message'));
            } else {
              this.appService.showSuccess(this.translate.instant('scp.users.delete_success_message'));
            }
          })
        )
        .subscribe();
    }
  }

  unregisterUserCard(row) {
    this.appService
      .removeUserCard(row.guid)
      .pipe(this.takeUntilDestroyed())
      .subscribe(
        () => {
          this.getUsersData();

          this.appService.showSuccess(this.translate.instant('scp.users.unregister_user_card_success_message', { value: row.displayname }));
        },
        () => {
          this.appService.showError(this.translate.instant('scp.users.unregister_user_card_error_message', { value: row.displayname }));
        }
      );
  }

  getUsersData() {
    this.appService
      .getUsers()
      .pipe(
        this.takeUntilDestroyed(),
        map((data) => {
          return (data || []).map((tenantUser) => {
            return {
              ...tenantUser.user,
              userRole: tenantUser.role,
              activated: tenantUser.enabled, // Top level enabled flag is used for activation & deactivation of users
              hasCard: !!tenantUser.card,
            };
          });
        })
      )
      .subscribe((data) => {
        this.dataSource.data = data;
      });
  }
}
