import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../interfaces/User';
import { CloudPrintEnvService } from '../cloud-print-env.service';
import { LogHandlerService } from './log-handler.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    // @Output()
    // change: EventEmitter<string> = new EventEmitter();
    // @Output()
    // showChildList: EventEmitter<string> = new EventEmitter();

    constructor(
        private http: HttpClient,
        protected envService: CloudPrintEnvService,
        private logHandlerService: LogHandlerService) {

    }

    /**
     * Gets the url for call SSP Common User API
     */
    get UserApiUrl(): string {
        return this.envService.sspRoomsApi + '/users';
    }

    dataChange: BehaviorSubject<any> = new BehaviorSubject<any>({
        Users: [],
        PaginationHeaders: {},
    });
    dataChangeStatus: Subject<boolean> = new Subject<boolean>();
    private sort_field_mapping = {
        Admin: 'userName',
        Role: 'adminType',
        Email: 'email',
        users: 'userName',
        emailAddress: 'email',
        role: 'scpRole'
    };

    // Return Users and response headers
    get entireData(): any {
        return this.dataChange.value;
    }

    /**
     * Get the Users from Server based on page size, page token
     * @param pageSize The page size
     * @param paginationToken The page token for cosmos DB query
     * @param sortBy Sort by field
     * @param sortOrder sort order
     * @param queryOptions Query options as object
     * @returns An observable that resolves to a list of users
     */
    getUsersWithPagination(pageSize: string, paginationToken: any, sortBy: string, sortOrder: string, queryOptions: any) {

        const headers = {
            'Content-Type': 'application/json',
            'Pagination-Supported': 'true',
            'user-role': 'ba',
        };

        // Add page-size header only if pageSize argument has valid value
        if (pageSize) {
            headers['Page-Size'] = pageSize.toString();
        }
        // Add page-token header only if paginationToken argument has valid value
        if (paginationToken) {
            headers['Page-Token'] = paginationToken;
        }

        let queryString = this.jsonToQueryString(queryOptions);

        // Add 'sortBy' in the query string
        if (sortBy) {
            let mappingField = this.sort_field_mapping[sortBy];
            if (sortBy == 'Role') {
                mappingField = 'scpRole';
            }
            queryString += `&sortBy=${mappingField}`;
        }

        // Add 'sortOrder' in the query string
        if (sortBy && sortOrder) {
            queryString += `&sortOrder=${sortOrder}`;
        }

        const httpOptions = {
            headers: new HttpHeaders(headers),
            observe: 'response' as 'body',
        };
        return this.http.get<any>(`${this.UserApiUrl}?${queryString}`, httpOptions)
            .subscribe((data) => {
                const response_headers = {
                    'page-token': data.headers.get('page-token'),
                    'total-count': data.headers.get('total-count'),
                };
                // Assign Users and Headers
                data.Users = data.body;
                data.PaginationHeaders = response_headers;
                this.dataChangeStatus.next(true);
                this.dataChange.next(data);
            });
    }

    /**
     *  Convert an object to query string
     * @param {any} json The query params as an object
     */
    jsonToQueryString(json: any): String {
        const entries = Object.keys(json).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
        });
        return entries.join('&');
    }

    /**
     * Query the user from Server based on query options
     * @param queryOptions Query options as object (name-value pairs)
     * @param role The role of the user who is issuing this query
     * @param sortBy The field used to sort the results
     * @param sortOrder The sorting order
     * @returns An observable that resolves to a list of user
     */
    queryUser(queryOptions: any, role = 'ba', sortBy = '', sortOrder = ''): Observable<any> {
        // console.log(`function queryUser(queryOptions: ${JSON.stringify(queryOptions)})`);
        let queryString = this.jsonToQueryString(queryOptions);
        // Add 'sortBy' in the query string
        if (sortBy) {
            queryString += `&sortBy=${this.sort_field_mapping[sortBy]}`;
        }
        // Add 'sortOrder' in the query string
        if (sortBy && sortOrder) {
            queryString += `&sortOrder=${sortOrder}`;
        }
        const queryUserUrl = `${this.UserApiUrl}?${queryString}`;
        const userRole = role || 'user';
        console.log(`queryUserUrl: ${queryUserUrl}`);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'user-role': userRole,
                // Authorization: 'Bearer ' + this.authService.accessToken, // auth0 access_token injected in app-interceptor
            }),
        };
        return this.http.get<any>(queryUserUrl, httpOptions);
    }

    /**
     * Create user(s) in server side
     * @param users The list of users to create
     * @param role The role for these users (default value is 'ba')
     * @param service The comma separated list of services (synappx,rmm,scp). The list is determined by end-user clicking on the checkboxes in the UI in service selection dialog
     * @returns An observable that resolves to a user record
     */
    createUsers(users: User[], role: string = 'ba', service: string = 'scp'): Observable<User> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'user-role': role,
                // Authorization: 'Bearer ' + this.authService.accessToken, // auth0 access_token injected in app-interceptor
                feature_type: 'scp', // This service is only for cloud-print. value is 'scp'
                'user-limit': (5000).toString(),
                'cache-control': 'no-cache',
                service: service,
            }),
        };
        return this.http.put<User>(this.UserApiUrl, users, httpOptions).pipe(
            map((resp) => {
                // Used to add 'Admin user added' logs to LMS
                const adminAdded = users.map((item) => item.userInfo.email).join(', ');
                this.logHandlerService.addAdminLog('Admin', 'Admin user added', 'MAA00027', adminAdded);
                return resp;
            })
        );
    }

    /**
     * Delete the user. This may remove the user record, or update the user record by removing only given services.
     * @param user The user instance
     * @param role The role of the user to remove
     * @param service The comma separated list of services for which this user should be removed. 
     * @returns 
     * 
     * @description If we are removing all services from the user, then user record itself will be deleted in the server side.
     */
    deleteUser(user: User, role = 'ba', service = 'scp'): Observable<any> {
        const userRole = role || 'user';
        const body = { userIds: [user.id] };
        const contentLength = JSON.stringify(body).length;
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'user-role': userRole,
            // Authorization: 'Bearer ' + this.authService.accessToken, // auth0 access_token injected in app-interceptor
            feature_type: 'scp', // This service is only for cloud-print. value is 'scp'
            'cache-control': 'no-cache',
            'content-length': contentLength.toString(),
            service: service,
        });
        // Make the API to delete the user(s)
        return this.http.delete<any>(`${this.UserApiUrl}/`, { headers, body }).pipe(
            map((resp) => {
                // Used to add 'Admin user deleted' logs to LMS
                this.logHandlerService.addAdminLog('Admin', 'Admin user deleted', 'MAA00028', user.userInfo.email);
                return resp;
            })
        );
    }
}
