import { Injectable} from '@angular/core';
import { NavigationProgressState, ProgressLoaderState } from '../interfaces/Iprogressloader';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()

export class ProgressLoaderService {
    private loaderSubject = new BehaviorSubject<ProgressLoaderState>({show: false});
    loaderState = this.loaderSubject.asObservable();

    private navigationLoaderSubject = new BehaviorSubject<NavigationProgressState>({ navigationProgress: false });
    navigationLoaderState = this.navigationLoaderSubject.asObservable();

    constructor() { }

    show() {
        this.loaderSubject.next(<ProgressLoaderState>{show: true});
    }

    hide() {
        this.loaderSubject.next(<ProgressLoaderState>{show: false});
    }

    showNavigationProgress() {
        this.navigationLoaderSubject.next(<NavigationProgressState>{ navigationProgress: true });
    }

    hideNavigationProgress() {
        this.navigationLoaderSubject.next(<NavigationProgressState>{ navigationProgress: false });
    }
}
