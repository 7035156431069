import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_DATA, IAppData, IDynamicItem, IStaticItem } from '../interfaces/AppData';

@Injectable()
export class AppDataService implements IAppData {
    /**
     * The application data injected form application
     */
    private _appData: IAppData;

    constructor(@Inject(APP_DATA) appData: IAppData) {
        this._appData = appData;
    }

    /**
     * Get the static data entry injected by application based on keyname
     * @param name The name of the data (e.g: user_name, )
     * @returns A IStaticItem instance
     */
    public getStatic(name: string): IStaticItem {
        return this._appData.getStatic(name);
    }

    /**
     * Check if the application has inserted the given static item
     * @param name The key-name of the static item
     * @returns true if the static item is inserted by application. false otherwise
     */
    public hasStatic(name: string): boolean {
        return this._appData.hasStatic(name);
    }

    /**
     * gets the list of keys of static items injected from application
     */
    public staticEntries(): Array<string> {
        return this._appData.staticEntries();
    }

    /**
     * Get the dynamic data entry injected by application based on keyname
     * @param name The key-name of the item (e.g: auth_access_token, idp_access_token)
     * @returns A IDynamicItem instance
     */
    public getDynamic(name: string): IDynamicItem {
        return this._appData.getDynamic(name);
    }

    /**
     * Check if the application has inserted the given dynamic item
     * @param name The key-name of the dynamic item
     * @returns true if the dynamic item is inserted by application. false otherwise
     */
    public hasDynamic(name: string): boolean {
        return this._appData.hasDynamic(name);
    }

    /**
     * gets the list of keys of dynamic items injected from application
     */
    public dynamicEntries(): Array<string> {
        return this._appData.dynamicEntries();
    }

    public debug() {
        this._appData.debug();
    }
};
