import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lib-export.dialog',
  templateUrl: './export.dialog.component.html',
  styleUrls: ['./export.dialog.component.scss']
})
export class ExportDialogComponent implements OnInit {

    public errors: string[];
    public status: boolean;
    protected reportCount: number;
    constructor(
        public exportDialogRef: MatDialogRef<ExportDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.errors = [];
    }
    AddError(msg: string) {
        this.errors.push(msg);
    }
    downloadComplete(status: boolean) {
        this.status = status;
    }
    setReportCount(reportCount: number) {
        this.reportCount = reportCount;
    }
    ngOnInit() {
    }
    onOk() {
        this.exportDialogRef.close(0);
    }
}
