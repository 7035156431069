import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';


/**
 * The factory method that instantiates the custom resource loader
 * @param http The instance of HttpClient
 */
export function HttpTranslateLoaderFactory(http: HttpClient) {
    return new MultiResourceLoader(http, './assets', '.json');
}

/**
 * A helper method to combine an array of dicitonaries into a single dictionary
 * @param listDict The array of dictionaries
 */
function combineDicts(listDict) {
    let ret = {};
    for (let item of listDict) {
        Object.assign(ret, item);
    }
    return ret;
}
/**
 * A custom implementation of the TranslateLoader interface
 */
class MultiResourceLoader implements TranslateLoader {
    constructor(private http: HttpClient, private prefix: string = "/assets", private suffix: string = ".json") { }

    /**
     * Implement the getTranslation method to load all required resources and
     * return the combined resource file
     * @param lang The language for which the resources should be loaded
     * An observable that returns the combined resource file as a single dictionary
     */
    public getTranslation(lang: string): any {
        const i18n = this.http.get(`${this.prefix}/i18n/${lang}${this.suffix}`);
        const logs = this.http.get(`${this.prefix}/logs/${lang}${this.suffix}`);
        const rmmlogs = this.http.get(`${this.prefix}/rmm/i18n/${lang}${this.suffix}`);
        const goEula = this.http.get(`${this.prefix}/i18n/eula/${lang}${this.suffix}`);
        let translations = forkJoin([i18n, logs, rmmlogs, goEula]).pipe(map(combineDicts));
        return translations;
    }
}
