import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CloudPrintEnvService } from '../cloud-print-env.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TenantService {
    public tenantNameSub = new BehaviorSubject('tenant');
    constructor(protected envService: CloudPrintEnvService,
        protected http: HttpClient) {
        this._tenantInfo = null;
    }

    protected _tenantInfo: any;
    /**
     * Gets the tenant info cached in this service
     */
    public get TenantInfo(): any {
        return this._tenantInfo;
    }

    /**
     * Gets the url for call SSP Common Tenant API
     */
    get TenantApiUrl(): string {
        return this.envService.sspLicenseApi + '/tenantInfo';
    }

    /**
     * Gets the tenant information record from SSP Server
     * @param acessToken The auth0 access token
     * @returns An observable that resolves to tenant record
     */
    getTenantInfo(acessToken?: string): Observable<any> {
        const URI = this.TenantApiUrl;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                // Authorization: 'Bearer ' + acessToken,
            }),
        };
        return this.http.get<any>(URI, httpOptions).pipe(
            tap({
                next: (tenant) => {
                    this._tenantInfo = tenant;
                }
            }));
    }

    getGuestTenantInfo(authCon): Observable<any> {
        const URI = this.TenantApiUrl + '/guest';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'guest-authCon': authCon
            }),
        };
        return this.http.get<any>(URI, httpOptions);
    }
    /**
    * Update the record for the tenant in DB
    * @param {tenant} tenant The tenant instance
    */
    updateTenantName(tenant: any): Observable<any> {
        const URI = `${this.TenantApiUrl}/name`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
        };
        return this.http.put<any>(URI, tenant, httpOptions);
    }
}
