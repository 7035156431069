import { IdpBaseService } from './idp-base.service';
import { Observable, of, throwError } from 'rxjs';
import { LocalAppDataService } from '../local-app-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Helper } from './helper';
import { map, concatMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CloudPrintEnvService } from '../cloud-print-env.service';
import { GoogleToken } from '../../interfaces/User';

@Injectable()
export class GoogleApiService extends IdpBaseService {

    constructor(http: HttpClient, localAppData: LocalAppDataService, envServive: CloudPrintEnvService) {
        super(http, localAppData, envServive);
    }

    /**
     * Get the idp access token
     * @returns An observable that resolves to idp access token
     */
    public getIdpToken(): Observable<any> {
        return this.localAppData.usingIdpToken();
    }

    public googleProfileDetails: any = {
        customerId: 'my_customer',
    };

    escapeQuotes(text) {
        text = this.replaceAll(text, "\'", "\\\'");
        text = this.replaceAll(text, '\"', '\\\"');
        return text;
    }

    replaceAll(text, old, next) {
        const parts = text.split(old);
        return parts.join(next);
    }

    private mapAdminList(arr, domains) {
        arr = arr || [];
        const mappedList = arr.map((item) => {
            return {
                userName: item.name.fullName,
                userEmail: item.emails.filter((data) => data.primary)[0].address,
            };
        }).filter((entry) => (Helper.isValidDomain(entry.userEmail, domains)));
        return mappedList || [];
    }

    private mapUserList(arr, domains) {
        arr = arr || [];
        const mappedList = arr.map((item) => {
            return {
                displayName: item.name.fullName,
                name: item.name.fullName,
                emailId: item.emails.filter((data) => data.primary)[0].address,
            };
        }).filter((entry) => Helper.isValidDomain(entry.emailId, domains));
        return mappedList || [];
    }

    private extractDomains(res) {
        res = res || [];
        const body = res.domains.map((val) => {
            return val.domainName;
        });
        return Promise.resolve(body || []);
    }


    /**
     * Search for users by name within given list of domains
     * @param userToSearch The user name pattern to search for
     * @param domains The domains to restrict the search
     * @returns An Observable that resolves into a list of users

     */
    public searchUsers(userToSearch: string, domains, isAdmin = true): Observable<any> {

        let googleToken = '';
        userToSearch = userToSearch.replace(/[&#"[\\\]+]/g, '_');
        userToSearch = this.escapeQuotes(userToSearch); // handling apostrophe in query
        // Return observable, if query has empty.
        if (userToSearch === '') {
            return of([]);
        }

        const queryURL = encodeURI(`givenName:${userToSearch.includes(' ') ? userToSearch : userToSearch + '*'}`);
        const URI = `https://www.googleapis.com/admin/directory/v1/users?customer=${this.googleProfileDetails.customerId}&query=${queryURL}`;
        this.getIdpToken().subscribe((result) => {
            googleToken = result.access_token

        })
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + googleToken,
                'accept': '*/*',
            }),
        }

        return this.http.get<any>(URI, httpOptions)
            .pipe(map((res) => {
                if (isAdmin) {
                    return this.mapAdminList(res.users, domains);
                } else {
                    return this.mapUserList(res.users, domains);
                }
            }));
    };

    /**
     * Search for users by email within given list of domains
     * @param userToSearch The user email pattern to search for
     * @param domains The domains to restrict the search
     * @returns An Observable that resolves into a list of users
     */
    public searchUsersByEmail(userToSearch: string, domains, isAdmin = true): Observable<any> {
        let googleToken = '';
        userToSearch = userToSearch.replace(/[&#"[\\\]+]/g, '_');
        userToSearch = this.escapeQuotes(userToSearch); // handling apostrophe in query
        // Return observable, if query has empty.
        if (userToSearch === '') {
            return of([]);
        }
        // Google Users API does not allow space followed by *.
        // So Remove * when the query contains space
        const queryURL = encodeURI(`email:${userToSearch.includes(' ') ? userToSearch : userToSearch + '*'}`);
        const URI = `https://www.googleapis.com/admin/directory/v1/users?customer=${this.googleProfileDetails.customerId}&query=${queryURL}`;
        this.getIdpToken().subscribe((result) => {
            googleToken = result.access_token

        })
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + googleToken,
                'accept': '*/*',
            }),
        };
        return this.http.get<any>(URI, httpOptions)
            .pipe(map((res) => {
                if (isAdmin) {
                    return this.mapAdminList(res.users, domains);
                } else {
                    return this.mapUserList(res.users, domains);
                }
            }));
    }

    public async getDomains(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getIdpToken().subscribe((token: GoogleToken) => {
                const URI = `https://www.googleapis.com/admin/directory/v1/customer/${this.googleProfileDetails.customerId}/domains`;
                const httpOptions = {
                    headers: new HttpHeaders({
                        Authorization: 'Bearer ' + token.access_token,
                        accept: '*/*',
                    }),
                };
                return this.http.get<any>(URI, httpOptions)
                    .toPromise()
                    .then((res) => resolve(this.extractDomains(res)))
                    .catch((err) => reject(err));
            }, (err) => {
                console.error(`error occured while obtaining google token`);
                console.log(err);
                reject(err);
            });
        });

    }

    public checkGrantReadUsers(): Observable<any> {
        let isUserReadGranted = false;
        const usersScope = 'https://www.googleapis.com/auth/admin.directory.user.readonly';
        return this.getToken(usersScope).pipe(
            map(data => {
                if (data) {
                    isUserReadGranted = true;
                }
                return isUserReadGranted;
            }));
    }

    getToken(scope: any): Observable<any> {
        let googleToken = "";
        this.getIdpToken().subscribe((result) => {
            googleToken = result.access_token
        });
        /*
        const apiUrl = this.envService.sspRoomsApi + '/token';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + googleToken,
                'gsuite-scope': scope
            }),
        };
        return this.http.post(apiUrl, {}, httpOptions);
        */
        return of(googleToken);
    }
}
