import { RmmInfo, CpInfo, UserInfo, User, LicenseInfo } from '../../shared/interfaces/User';
export class AdminInfo {
    public userInfo: UserInfo;
    public rmmInfo: RmmInfo;
    public cpInfo: CpInfo;
    public licenseInfo: LicenseInfo;
    constructor(userInfo?: UserInfo | any, rmmInfo?: RmmInfo | any, cpInfo?: CpInfo | any, licenseInfo?: LicenseInfo) {
        this.userInfo = {
            isRootAdmin: userInfo?.isRootAdmin || false,
            userName: userInfo?.userName || '',
            email: userInfo?.email || '',
            userType: userInfo?.userType || '',
            adminType: userInfo?.adminType || '',
            isPortalEulaAccepted: userInfo?.isPortalEulaAccepted || false,
            isMarvelEulaAccepted: userInfo?.isMarvelEulaAccepted || false,
            marvelEulaVersion: userInfo?.marvelEulaVersion || '1.5',
            portalEulaVersion: userInfo?.portalEulaVersion || '1.5'
        };
        this.rmmInfo = {
            role: rmmInfo?.role || '',
            userType: rmmInfo?.userType || ''
        };
        this.cpInfo = {
            role: cpInfo?.role || '',
            userType: cpInfo?.userType || ''
        };
        this.licenseInfo = {
            eId: licenseInfo?.eId || null,
            aId: licenseInfo?.aId || null,
            isActivated: licenseInfo?.isActivated || false,
        };
    }
};
