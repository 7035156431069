
import { ScpEnv } from 'lib-ja-cloud';
import { environment } from '../environments/environment';


// Provide a value for Environment class defined in the library
// The values will be taken from the actual environment of this application
export const SCP_ENV: ScpEnv = {
    production: environment.production,
    scpCommonApiBaseUrl: environment.scpCommonApiBaseUrl.url,
    sspRoomsApi: environment.sspCommonEndPoint.url,
    sspLicenseApi: environment.sspLicenseApi.url,
    sspMarvelApi: environment.marvelEndPoint.url,
    stackRegion: environment.stackRegion,
    userLimit: environment.userLimit,
    appVersion: environment.version
}
