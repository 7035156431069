<mat-card>
  <div fxLayoutAlign="space-between baseline">
    <mat-card-title>{{ 'scp.common.departments' | translate }}</mat-card-title>

    <span [innerHTML]="'scp.departments.departments_count' | translate : { value: paginator?.length || 0 }"> </span>
  </div>

  <mat-card-actions fxLayoutAlign="space-between end" class="mb-0">
    <span fxLayout="row wrap" fxLayoutAlign="start center">
      <button class="large" mat-icon-button color="primary" matTooltip="{{ 'scp.departments.add_department_button_text' | translate }}" (click)="showUpsertDepartmentDialog()">
        <mat-icon>add_circle</mat-icon>
      </button>

      <button class="large" mat-icon-button color="primary" matTooltip="{{ 'scp.departments.delete_user_button_text' | translate }}" disabled>
        <mat-icon>do_not_disturb_on</mat-icon>
      </button>

      <mat-form-field class="mx-3">
        <mat-label>{{ 'scp.departments.filter_departments_search_text' | translate }}</mat-label>
        <input matInput (keyup)="applyFilter($event.target.value)" />
      </mat-form-field>
    </span>

    <button mat-icon-button matTooltip="{{ 'scp.common.refresh_data_button_text' | translate }}" (click)="getDepartmentsData()">
      <mat-icon>refresh</mat-icon>
    </button>
  </mat-card-actions>

  <mat-card-content>
    <div class="table-responsive mat-elevation-z8">
      <table mat-table matSort matSortActive="name" matSortDirection="asc" [dataSource]="dataSource" class="w-100">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllRowsSelected()"
              [indeterminate]="selection.hasValue() && !isAllRowsSelected()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.departments.columns.department_name' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.name }}</td>
        </ng-container>

        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef>{{ 'scp.departments.columns.actions' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button [matMenuTriggerFor]="rowMenu">
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #rowMenu="matMenu">
              <button mat-menu-item (click)="showUpsertDepartmentDialog(row)">{{ 'scp.common.edit_button_text' | translate }}</button>
              <button mat-menu-item disabled>{{ 'scp.common.delete_button_text' | translate }}</button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons aria-label="Select page"> </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
