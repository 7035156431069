import { Injectable } from '@angular/core';
import { Route, CanLoad, CanActivate, RouterStateSnapshot, UrlTree, UrlSegment, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { RmmValidateUserService } from '../services/rmm-validate-user.service';
@Injectable()
export class PERMISSION_GUARD implements CanActivate, CanLoad {
    mappingRoute: any
    constructor(private validateUserService: RmmValidateUserService, public router: Router) {
        if(this.validateUserService.filterRoutes){
            this.mappingRoute = this.extractViewLinks(this.validateUserService.filterRoutes)
        }
       
    }
    //canLoad is used for Module Basis Auth
    canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.mappingRoute) {
            if (this.mappingRoute.includes('rmm/' + route.path)) {
                return true
            }
            else if (route.path == '/') {
                return true
            }
            else {
                this.router.navigate(['rmm/dashboard'])
                return false

            }
        }
    }
    //canActivate is used for Component Based Auth
    canActivate(route: Route, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.mappingRoute) {
            if (this.mappingRoute.includes(state.url.slice(1))) {
                return true
            }
            else if (route.path == '/') {
                return true
            }
            else {
                this.router.navigate(['rmm/dashboard'])
                return false
            }
        }
    }

    // below function is extract the user based routes.
    extractViewLinks(menuItems: any, result: string[] = []): string[] {
        for (const item of menuItems) {
            result.push(item.viewLink);
            if (item.children) {
                this.extractViewLinks(item.children, result);
            }
        }
        return result;
    }

}