import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayWorkspacePipe } from './display-workspace.pipe';
import { DisplayUserPipe } from './display-user.pipe';

@NgModule({
   imports: [
      CommonModule,
   ],
   declarations: [
      DisplayWorkspacePipe,
      DisplayUserPipe,
   ],
   exports: [
        DisplayWorkspacePipe,
        DisplayUserPipe,
   ],
})
export class SharedPipesModule { }
