import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error-link',
  templateUrl: './error-link.component.html',
  styleUrls: ['./error-link.component.scss']
})
export class ErrorLinkComponent implements OnInit {

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit() {
  }

}
