<div class="container">
  <h1 mat-dialog-title class="mat-dialog-title font_20">{{ title }}</h1>
  <div>
    <div class="mb_16">{{ message }}</div>
  </div>
  <div mat-dialog-actions>
    <button mat-button type="submit" (click)="onOk()" id="id-ok" class="scp_primary_btn mr_10">OK</button>
    <button mat-button (click)="onCancel()" id="id-cancel" class="scp_secondary_btn">Cancel</button>
  </div>
</div>
