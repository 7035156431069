import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LogoutNotificationService {
   private preLogoutOperationStatus: BehaviorSubject<void> = new BehaviorSubject<void>(void 0);
  
  // Observable to notify when the logout is acknowledged
  preLogoutOperationStatus$ = this.preLogoutOperationStatus.asObservable();

  constructor() { }

  /**
   * @description perform any pre logout operations like clear data / complete any critical operation before logout.      
   */
  performPreLogoutOperation() {
    console.log('Inside performPreLogoutOperation() function of LogoutNotificationService of LibJACloud library.');
    console.log('logout notification received from Admin Portal....');
    this.preLogoutOperationStatus.next();
  }
}
