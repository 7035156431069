import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'lib-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
    // handle event on escape key
    @HostListener('document:keydown.escape', ['$event'])
    onEscapeKeyHandler(event: KeyboardEvent) {
        this.confirmDialogRef.close(3);
    }

    public title: string;
    public message: string;
    public okButton: string;
    public alertMode: boolean;

    constructor(
        public confirmDialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.title = this.data.title;
        this.message = this.data.message;
        this.alertMode = this.data.alertMode;
        this.okButton = this.data.okButton;
        this.confirmDialogRef;
        // handle event on click of background
        this.confirmDialogRef.backdropClick().subscribe(() => {
            this.confirmDialogRef.close(2);
        });
    }

    ngOnInit() {
    }

    onCancel(): void {
        this.confirmDialogRef.close(0);
    }

    onOk(): void {
        this.confirmDialogRef.close(1);
    }
}
