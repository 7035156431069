import { InjectionToken } from '@angular/core';


export class ScpEnv {
    production: boolean = false;

    /**
     * The base url for SCP Common API
     */
    public scpCommonApiBaseUrl: string;

    /**
     * The base url for Users/Rooms API (SSP)
     */
    public sspRoomsApi: string;

    /**
       * The base url for License API (SSP)
       */
    public sspLicenseApi: string;

    /**
       * The base url for Marvel API (SSP)
       */
    public sspMarvelApi: string;

    /**
     * The region where the current stack is deployed EUDev, EUDev2, EUQA etc.,
     */
    public stackRegion: string;

    /**
     * The maximum number of users (regular + admin) that can be added per tenant/customer
     */
    public userLimit: number;

    /**
     * The Version of the application
     */
    public appVersion: string;

}

/**
 * Define an InjectionToken for ScpEnv. This is requried for injecting runtime data into a service.
 * Refer: https://angular.io/guide/dependency-injection-providers#using-an-injectiontoken-object
 */
export const SCP_ENV = new InjectionToken<ScpEnv>('SCP_ENV');

