import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class RmmServiceGuard implements CanActivate, CanLoad {


    public isRmmActive = true;

    constructor(private authService: AuthService) {
        this.isRmmActive = this.authService.viewRmm;
    }

    canActivate() {
        if (this.isRmmActive) {
            return true;
        } else {
            return false;
        }
    }

    canLoad() {
        if (!this.isRmmActive) {
            return false;
        } else {
            return true;
        }
    }


}
