<mat-card>
  <mat-card-title>{{ 'scp.common.security' | translate }}</mat-card-title>

  <mat-tab-group preserveContent animationDuration="0" mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab label="{{ 'scp.settings.login.tab_title' | translate }}">
      <ng-template matTabContent>
        <app-settings-login></app-settings-login>
      </ng-template>
    </mat-tab>

    <mat-tab label="{{ 'scp.settings.schedule.tab_title' | translate }}" *ngIf="isAdminUser">
      <ng-template matTabContent>
        <app-settings-schedule></app-settings-schedule>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</mat-card>
