<div class="container">
    <h1 mat-dialog-title class="text_left">{{ data.title}}</h1>
    <div class="font_16 mb_26">
        <!-- data.message is not translated here, as it is already translated considering the possibility of variable strings within it. -->
        <div innerHTML="{{ data.mainMessage }}"> </div>
        <div *ngIf="data.isMultiLine && data.subMessage1" innerHTML="{{ data.subMessage1 }}"></div>
        <div *ngIf="data.isMultiLine && data.subMessage2" innerHTML="{{ data.subMessage2 }}"> </div>
    </div>
    <div mat-dialog-actions class="display_inline">
        <button mat-button type="submit" (click)="confirm()" id="id-ok" class="primary_btn">
            {{data.confirmText}}</button>
        <button *ngIf='!data.infoMode' mat-button (click)="cancel()" id="id-cancel"
            class="secondary_btn">{{data.cancelText}}</button>
    </div>
</div>