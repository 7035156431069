import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../shared/interfaces/User';
import { UserService } from '../../shared/services/ssp/user.service';


export class UsersDataSource extends DataSource<User> {
    renderedData: User[] = [];
    // Hold the pagination token to retrive the next set of items.
    paginationTokenMap: any = {
        '0': undefined,
    };
    // Holding the count of no of total and search results
    totalCount = 0;
    searchCount = 0;
    currentPage = 0;

    constructor(
        public userService: UserService,
        public _paginator: MatPaginator,
        public _sort: MatSort,
        public _queryOptions: any
    ) {
        super();
        // Reset to the first page when the user changes the filter.
        // this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
    }

    /**
     * connect method is called by the MatTable to retrieve a stream containing the data to render
     * @returns An observable that resolves to an array of user objects
     */
    connect(): Observable<User[]> {
        // Listen for any changes in the base data, sorting, filtering, or pagination
        return this.getUsersList(this._paginator.pageSize, this._paginator.pageIndex, false, this._queryOptions);
    }

    /**
     * Get the list of users based on page size and page number
     * @param pageSize The page size
     * @param pageNumber The page number
     * @param clearPaginationTokenMap true to clear the pagination token before query
     * @param queryOptions The query options as object (name-value pairs)
     * @returns 
     */
    getUsersList(pageSize, pageNumber, clearPaginationTokenMap, queryOptions): Observable<User[]> {
        this.currentPage = pageNumber;
        this._paginator.pageIndex = pageNumber;

        const displayDataChanges = [
            this.userService.dataChange,
            this._sort.sortChange,
            this._paginator.page,
        ];

        // Clear the pagination token map if any of a action is done
        if (clearPaginationTokenMap) {
            this.paginationTokenMap = { '0': undefined };
        }

        // Sort Information
        const sortBy = this._sort.active;
        let sortOrder = this._sort.direction;
        const paginationToken = this.paginationTokenMap[pageNumber.toString()];

        // get the paginated items with specified page size with pagination token,
        this.userService.getUsersWithPagination(pageSize, paginationToken, sortBy, sortOrder, queryOptions);

        return merge(...displayDataChanges).pipe(
            map(() => {
                // paginated data to display in the UI.
                this.renderedData = this.userService.entireData.Users;
                this.totalCount = this.userService.entireData.PaginationHeaders['total-count'];
                const pageToken = this.userService.entireData.PaginationHeaders['page-token'];
                this.paginationTokenMap[(1 + this.currentPage).toString()] = pageToken;
                // If pagination token is available, enable the next page button
                // Else disable the next page token.
                if (pageToken) {
                    // For first page, this.currentPage is 0.
                    // If we sum with 1, the searchcount will be same as page.
                    // In this case, the next page button will not be enabled.
                    // So sum with 2 to enable next page.
                    this.searchCount = (this.currentPage + 2) * this._paginator.pageSize;
                } else {
                    this.searchCount = this._paginator.pageSize;
                }
                return this.renderedData;
            })
        );
    }
    disconnect() { }
}
