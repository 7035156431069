import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// import { AuthService } from '../../../../../../src/app/shared/services/auth.service';
// import { environment } from '../../../../../../src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LocalAppDataService } from '../../../shared/services/local-app-data.service';

@Component({
    selector: 'lib-admin-approval',
    templateUrl: './admin-approval.component.html',
    styleUrls: ['./admin-approval.component.scss']
})
export class AdminApprovalComponent implements OnInit {
    public tenantDomain: string;
    public azureAppId: string;
    public callbackUrl: string;
    public requiredScopes: string;
    public url: string;
    public userEmail: string;
    public PermissionsRequiredMessage: string;

    constructor(
        public confirmDialogRef: MatDialogRef<AdminApprovalComponent>,
        private localAppDataService: LocalAppDataService,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
        this.userEmail = this.localAppDataService.Upn;
        this.tenantDomain = this.userEmail.toLowerCase().split('@')[1];
        this.azureAppId = this.localAppDataService.AuthConfig.azure_app_client_id;
        this.callbackUrl = encodeURIComponent(this.localAppDataService.AuthConfig.callbackURL);
        // include User.ReadBasic.All scope to Eliminate Handoff to MS Global Admin SHPLT-6143
        this.requiredScopes = 'openid profile email offline_access User.Read User.ReadBasic.All Group.Read.All Directory.Read.All';
        this.url = `https://login.microsoftonline.com/${this.tenantDomain}/v2.0/adminconsent?client_id=${this.azureAppId}&redirect_uri=${this.callbackUrl}&scope=${this.requiredScopes}`;
        this.translate.get('PermissionsRequiredMessage', {
            ahref_start: `<a href='${this.url}'><u>`,
            ahref_end: `</u></a>`,
        }).subscribe((PermissionsRequiredMessage: string) => {
            this.PermissionsRequiredMessage = PermissionsRequiredMessage;
        });
    }

    submit() {
        this.confirmDialogRef.close(0);
        // window.open(this.url);
        window.location.href = this.url;
    }

    cancel() {
        this.confirmDialogRef.close(0);
    }

}