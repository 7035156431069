import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { BaseComponent } from '../../../shared/components/base.component';

@Component({
  selector: 'app-settings-schedule',
  templateUrl: './settings-schedule.component.html',
  styleUrls: ['./settings-schedule.component.scss'],
})
export class SettingsScheduleComponent extends BaseComponent implements OnInit {
  settingsFormGroup: FormGroup;
  deletePrintFilesOlderThan: FormControl;
  hasError = false;
  isAdminUser = false;

  constructor() {
    super();

    this.deletePrintFilesOlderThan = new FormControl(null, [Validators.required, Validators.min(1), Validators.max(365)]);

    this.settingsFormGroup = new FormGroup({
      delete_print_files_older_than: this.deletePrintFilesOlderThan,
    });
  }

  ngOnInit() {
    this.isAdminUser = this.appService.isSCPAdminUser;

    if (this.isAdminUser) {
      this.getSettingsData();
    }
  }

  numericPattern = /^[0-9]*$/;
  numericOnly(event) {
    return this.numericPattern.test(event.key);
  }

  saveSettings() {
    if (this.settingsFormGroup.valid) {
      this.appService
        .updateSettings({
          ...this.settingsFormGroup.value,
        })
        .pipe(this.takeUntilDestroyed())
        .subscribe(
          () => {
            this.getSettingsData();

            this.appService.showSuccess(this.translate.instant('scp.settings.save_success_message'));
          },
          () => {
            this.appService.showError(this.translate.instant('scp.settings.save_error_message'));
          }
        );
    }
  }

  getSettingsData() {
    this.appService
      .getSettings()
      .pipe(this.takeUntilDestroyed<[]>())
      .subscribe(
        (data) => {
          (data || []).forEach(({ key, value, type }: { key: string; value: string; type: string }) => {
            const ctrl = this.settingsFormGroup.get(key);

            if (ctrl) {
              if (type === 'bool') {
                ctrl.setValue(value === 'true'); // Convert to javascript boolean
              } else {
                ctrl.setValue(value);
              }
            }
          });
        },
        () => {
          this.hasError = true;
        }
      );
  }
}
