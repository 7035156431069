<mat-card *ngIf="isSubsDetails" class="scp-subs-container">
    <div class="mt_24" *ngFor="let sub of SubscriptionInfo">
        <div class="scp_total_license">
            <mat-card-content><span class="font_bold">{{ sub.name | translate }}</span></mat-card-content>
            <mat-card-content><span>{{'TotalLicenses' | translate}}:</span> {{ sub.totalLicenses }} </mat-card-content>
            <mat-card-content><span class="pl_35">{{'AssignedLicense' | translate}}:</span> {{ sub.assigned }}
            </mat-card-content>
            <mat-card-content><span class="pl_35">{{'UnassignedLicense' | translate}}:</span> {{ sub.unassigned }}
            </mat-card-content>
            <mat-card-content *ngIf="sub.archiveLicenses > 0 || sub.grace > 0"><span>{{'LicenseinArchivePeriod' |
                    translate}}:</span> {{ sub.archiveLicenses }} </mat-card-content>
            <mat-card-content *ngIf="sub.archiveLicenses > 0 || sub.grace > 0"><span>{{'LicenseinGracePeriod' |
                    translate}}:</span> {{ sub.grace }}
            </mat-card-content>
        </div>

        <div class="scp_id_license" *ngFor="let renewal of sub.autoRenewals">
            <mat-card-content><span>{{'EID' | translate}}:</span> {{ renewal.id + (renewal.collab ? ' *' : '') }}
            </mat-card-content>
            <mat-card-content *ngIf="renewal.referenceSourceID"><span>{{'ReferenceSourceID' | translate}}:</span> {{
                renewal.referenceSourceID }}</mat-card-content>
            <mat-card-content><span>{{'Licenses' | translate}}:</span> {{ renewal.count }}</mat-card-content>
            <mat-card-content><span>{{'Autorenewalsetfor' | translate}}</span> {{ formatDate(renewal.renewal)
                }}</mat-card-content>
        </div>

        <div class="scp_id_license" *ngFor="let future of sub.futureEnts">
            <mat-card-content><span>{{'EID' | translate}}:</span> {{ future.id + (future.collab ? ' *' : '') }}
            </mat-card-content>
            <mat-card-content *ngIf="future.referenceSourceID"><span>{{'ReferenceSourceID' | translate}}:</span> {{
                future.referenceSourceID }}</mat-card-content>
            <mat-card-content>
                <span
                    [innerHTML]="'FutureDateLicense' | translate:{licenses: future.count, startDate: future.startDate | date:'MM/dd/yyyy'}"></span>
                <!-- <mat-card-content><span>{{future.count}} license(s) will be valid on </span>  {{future.startDate | date:'MM/dd/yyyy'}}</mat-card-content> -->
            </mat-card-content>
        </div>

        <div class="scp_id_license" *ngFor="let schedule of sub.scheduled">
            <mat-card-content><span>{{'EID' | translate}}:</span> {{ schedule.id + (schedule.collab ? ' *' : '')}}
            </mat-card-content>
            <mat-card-content *ngIf="schedule.referenceSourceID"><span>{{'ReferenceSourceID' | translate}}:</span> {{
                schedule.referenceSourceID }}</mat-card-content>
            <mat-card-content><span>{{'Licenses' | translate}}:</span> {{ schedule.count }}</mat-card-content>
            <mat-card-content><span>{{'Licensewillexpireon' | translate}}</span> {{ formatDate(schedule.expiry)
                }}</mat-card-content>
        </div>

        <div class="scp_id_license" *ngFor="let expired of sub.expired">
            <mat-card-content><span>{{'EID' | translate}}:</span> {{ expired.id + (expired.collab ? ' *' : '') }}
            </mat-card-content>
            <mat-card-content *ngIf="expired.referenceSourceID"><span>{{'ReferenceSourceID' | translate}}:</span> {{
                expired.referenceSourceID }}</mat-card-content>
            <mat-card-content><span *ngIf="expired.isArchieve; else grace">{{'LicenseinArchivePeriod' |
                    translate}}:</span><ng-template #grace>{{'LicenseinGracePeriod' | translate}}:</ng-template> {{
                expired.count }} </mat-card-content>
            <mat-card-content><span>{{'Licenseexpiredon' | translate}}</span> {{ formatDate(expired.expiry) }}
            </mat-card-content>
            <mat-card-content><span>{{'Portalaccessendson' | translate}}</span> {{ formatDate(expired.endDate) }}
            </mat-card-content>
        </div>
    </div>
</mat-card>