<div class="container">
    <h1 mat-dialog-title>{{ title }}</h1>
    <div>
        <div class="mb_16" innerHTML="{{ message }}"> </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button type="submit" (click)="onOk()" id="id-ok" class="primary_btn">{{"Ok" |translate}}</button>
        <button *ngIf='!alertMode' mat-button (click)="onCancel()" id="id-cancel" class="secondary_btn">{{"Cancel" |translate}}</button>
    </div>
</div>
