import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { TokenStorage } from '../../../../core/token.storage';
import { User, UserInfo, LicenseInfo } from '../interfaces/users';
import { environment } from '../../../../../environments/environment';
import { Observable, BehaviorSubject, Subject, of } from 'rxjs';
import { AuthService } from '../../../../shared/services/auth.service';
import { UserService } from '../../../../shared/services/user.service';
import { LogHandlerService } from '../../../../shared/services/log-handler.service';
import { concatMap, map } from 'rxjs/operators';
@Injectable()
export class UsersListService {
    constructor(private http: HttpClient, private Token: TokenStorage, private authService: AuthService, private logHandlerService: LogHandlerService, private userService: UserService) {
    }

    dataChange: BehaviorSubject<any> = new BehaviorSubject<any>({ Users: [], PaginationHeaders: {} });
    dataChangeStatus: Subject<boolean> = new Subject<boolean>();
    private _userListSettings = { Search: '', View: 'all' };
    private view_filter_mapping = { 'all' : 'all', 'active': 'true', 'inactive': 'false' };
    private sort_field_mapping = { 'License' : 'isActivated', 'User Name': 'userName', 'Email': 'email', 'User Type': 'status', 'Last Activity': 'activity'};

    public get userListSettings() {
        return this._userListSettings;
    }
    public set userListSettings(value) {
        this._userListSettings = value;
    }
    private URI: string;
    private roomsIds: String[] = [];
    private userIds: string[] = [];
    public eulaVersion = '1.1';

    // Return Users. It is used to check duplication when adding/importing users
    get data(): any {
        return this.dataChange.value.Users;
    }

    // Return Users and response headers
    get entireData(): any {
        return this.dataChange.value;
    }

    getUsersRevokedInCancellation(): Observable<User[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                //  'auth0-connection-name': 'sharpsoftwaredev-waad'
            }),
        };
        return this.http.get<User[]>(environment.rmpEndPointUser.url + '/users?userType=User,BA%20User&&eId=REVOKED_IN_CANCELLATION', httpOptions);
    }

    getUsersWithPagination(pageSize: string, paginationToken: any, sortBy:string, sortOrder:string) {
        const headers = {
            'Content-Type': 'application/json',
            'Pagination-Supported': 'true'
        }

        if(pageSize) {
            headers['Page-Size'] =  pageSize.toString();
        }
        if(paginationToken) {
            headers['Page-Token'] =  paginationToken;
        }

        let queryString = 'userType=User,BA%20User,guest'

        // Add 'License' status in the query string
        if(this.view_filter_mapping[this._userListSettings.View] != 'all') {
            queryString += `&licenseStatus=${this.view_filter_mapping[this._userListSettings.View]}`
        }

        // Add 'Search Term' in the query string
        if(this._userListSettings.Search != '') {
            queryString += `&searchTerm=${this._userListSettings.Search}`
        }

        // Add 'sortBy' in the query string
        if(sortBy) {
            queryString += `&sortBy=${this.sort_field_mapping[sortBy]}`
        }

        // Add 'sortOrder' in the query string
        if(sortBy && sortOrder) {
            queryString += `&sortOrder=${sortOrder}`
        }

        const httpOptions = {
            headers: new HttpHeaders(headers),
            observe: 'response' as 'body'
        };
        return this.http
            .get<any>(
                environment.rmpEndPointUser.url + '/users?' + queryString,
                httpOptions
            )
            .subscribe(data => {
                const response_headers = {
                    'page-token': data.headers.get('page-token'),
                    'total-count' : data.headers.get('total-count')
                }
                // Assign Users and Headers
                data.Users = data.body;
                data.PaginationHeaders = response_headers;
                this.dataChangeStatus.next(true);
                this.dataChange.next(data);
            });
    }

    // for implementing  single user
    createUser(userInfo: any): Observable<User> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                //'auth0-connection-name': 'sharpsoftwaredev-waad',
            }),
        };
        const newUser = <User>{
            auth0ConnectionName: this.authService.getConnectionName,
            userInfo: <UserInfo>{
                userName: userInfo.UserName,
                email: userInfo.Account,
                userType: 'User',
                isRootAdmin: false,
                isPortalEulaAccepted: false,
                isMarvelEulaAccepted: false,
                marvelEulaVersion: this.eulaVersion,
                portalEulaVersion: this.eulaVersion
            },
            licenseInfo: <LicenseInfo>{
                isActivated: false,
                aId: null
            },
            //   id: this.uuidv4(),
        };
        const user = [newUser];
        return this.http.put<User>(
            environment.rmpEndPointUser.url + '/users',
            user,
            httpOptions,
        );
    }

    /**
     * Calls the Create Users REST API. The call supports creating multiple users with a single call
     * @param user The arrray of users
     */
    createUsers(user: any, idCard = false): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'user-limit': (environment.userLimit || 5000).toString(),
                'id-card': idCard.toString()
            }),
        };
        return this.http.put<any>(environment.rmpEndPointUser.url + '/users', user, httpOptions);
    }

    deleteUsers(users): Observable<any> {
        this.userIds = [];
        let usersDeleted = '';
        users.forEach(users => {
            this.userIds.push(users.id);
        });

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-type': 'application/json'
            }),
            body: { 'userIds': this.userIds }
        };
        return this.http.delete<any>(environment.rmpEndPointUser.url + '/users/', httpOptions).pipe(map( (resp) => {
            const usersDeleted = users.map((item) => item.userInfo.email).join(', ');
            this.logHandlerService.addAdminLog('Users', 'Users deleted', 'MAA00004', usersDeleted.replace(/,\s*$/, ''));
            return resp;
        }));
    }

    getUserDetails(email: string){
        return this.userService.queryUser({ 'email': email }, 'user', null, null);
    }

    updateCardnumber(result, cardNumber: string): Observable<User> {
        let guest = 'false';
        if(result && result.length > 0 && (result[0].userInfo.userType.toLowerCase() == 'user' || result[0].userInfo.userType.toLowerCase() == 'ba user' || result[0].userInfo.userType.toLowerCase() == 'guest')) {
            const getUserNameUrl = `${environment.rmpEndPointUser.url}/users/${result[0].id}`;
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.authService.accessToken,
                    'guest': guest
                }),
            };
            const patchInfo = { userInfo: { cardNumber: cardNumber } };
            return this.http.patch<User>(getUserNameUrl, patchInfo, httpOptions);
        } else {
            throw Error("user not found");
        }
    }

    updateUserdetails(email: string, cardNumber: string) {
        return of(email).pipe(
            concatMap(roomId => this.getUserDetails(roomId)),
            concatMap(result => this.updateCardnumber(result, cardNumber)));
    }

    getUser(id): Observable<User> {
        const getUserNameUrl = `${environment.rmpEndPointUser.url}/users?id=${id}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.authService.accessToken
            }),
        };
        return this.http.get<User>(getUserNameUrl, httpOptions);
    }

    uuidv4(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    /**
     * Calls the Create Users REST API. The call supports creating multiple users with a single call
     * @param user The arrray of users
     */
    importCardNumbers(user: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'user-limit': (environment.userLimit || 5000).toString(),
                'id-card': 'true'
            }),
        };
        return this.http.put<any>(environment.rmpEndPointUser.url + '/users', user, httpOptions);
    }

    addAndInviteUser(user: any): Observable<any> {
        let URI = `${environment.rmpEndPointUser.url}/guest/inviteGuest`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'service': this.Token.getMarvelRMPService() == 'rmm' ? 'rmm' : 'synappx',
                'user-limit': (environment.userLimit || 5000).toString(),
            }),
        };
        return this.http.post<any>(URI, user, httpOptions);
    }

    /**
     * Calls the resend Invitation REST API. It sends invitation mail to unverified guest user
     * @param userId The Id of guest user
     */
    resendInviteMail(userId: any): Observable<any> {
        let URI = `${environment.rmpEndPointUser.url}/guest/resendInvitationEmail`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'id': userId,
                'service': this.Token.getMarvelRMPService() == 'rmm' ? 'rmm' : 'synappx',
                'inviteruserrole': this.authService.rmmAdminRole,
            }),
        };
        return this.http.get<any>(URI, httpOptions);
    }

    /**
     * Calls the change IDP REST API. It sends Change IDP mail to verified guest user
     * @param userId The Id of guest user
     */
    changeIdpMail(userId: any): Observable<any> {
        let URI = `${environment.rmpEndPointUser.url}/guest/changeIdpEmail`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'id': userId,
                'service': this.Token.getMarvelRMPService() == 'rmm' ? 'rmm' : 'synappx',
            }),
        };
        return this.http.get<any>(URI, httpOptions);
    }
}
