import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'lib-remove-domain-dialog',
  templateUrl: './remove-domain-dialog.component.html',
  styleUrls: ['./remove-domain-dialog.component.scss']
})
export class RemoveDomainDialogComponent implements OnInit {

    public title: string;
    public message: string;
    constructor(public confirmDialogRef: MatDialogRef<RemoveDomainDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.title = this.data.title;
        this.message = this.data.message;
    }

    ngOnInit() {

    }

    onCancel(): void {
        // Set the result for the close operation
        this.confirmDialogRef.close(0);
        return;
    }

    onOk(): void {
        this.confirmDialogRef.close(1);
    }

}
