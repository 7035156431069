import { Component, Inject, OnInit, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit {
    @Input()
    notificationinfo = '';
    constructor(
        public dialogNotificationRef: MatDialogRef<NotificationDialogComponent>,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }
    ngOnInit() {
        if (this.data) {
            this.notificationinfo = this.translate.instant(this.data.notificationinfo, this.data.interpolateParams);
            this.notificationinfo = this.data.notificationinfo;
        }
    }
    close() {
        this.dialogNotificationRef.close(0);
    }

    submit() {
        // emppty stuff
    }

}
