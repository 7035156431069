import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../../../../../src/app/shared/services/auth.service';
import { environment } from '../../../../../../src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { stackUrls } from '../../../../layout/stackUrls'

@Component({
    selector: 'app-grant-permissions',
    templateUrl: './grant-permissions.component.html',
    styleUrls: ['./grant-permissions.component.scss']
})
export class GrantPermissionsComponent implements OnInit {

    public tenantDomain: string;
    public azureAppId: string;
    public callbackUrl: string;
    public requiredScopes: string;
    public url: string;
    public domainwidelURL;
    public gsuiteSupportUrl;
    public userEmail: string;
    public PermissionsRequiredMessage: string;
    public version: string;
    public GooglePermissionMessage: string;
    public GooglePermissionDescription: string;
    public isGoogle: boolean;
    public isO365: boolean;
    public isRMM: boolean = false;
    public hasGoAccess: boolean = false;
    public isStackRegionEU: boolean = false;
    public MoreInformationMessage: string;
    public supportSiteUrl:string;

    constructor(
        public confirmDialogRef: MatDialogRef<GrantPermissionsComponent>,
        private authService: AuthService,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.isGoogle = this.authService.applicationType === 'google-apps';
        this.isO365 = this.authService.applicationType === 'waad';
        this.version = environment.version;
        this.isRMM = (this.authService.tenantTier == "rmm") || false;
        this.hasGoAccess = (this.authService.viewGo || this.authService.viewMeeting);
        }

    ngOnInit() {
        if (environment.stackRegion == 'EU') {
            this.isStackRegionEU = true;
        }
        const browserLang = this.authService.getBrowserLanguage();
        const stackUrl = stackUrls(browserLang)
        this.domainwidelURL = "https://admin.google.com/ac/owl/domainwidedelegation";
        this.gsuiteSupportUrl = stackUrl.gsuiteSupportUrl;
        this.supportSiteUrl = stackUrl.supportSiteUrl;
        this.userEmail = this.authService.userUpn;
        this.tenantDomain = this.userEmail.toLowerCase().split('@')[1];
        this.azureAppId = environment.AuthConfig.azure_app_client_id;
        this.callbackUrl = encodeURIComponent(environment.AuthConfig.callbackURL);
        this.requiredScopes = 'openid profile email offline_access User.Read Group.Read.All Directory.Read.All';
        if(this.hasGoAccess) {
            this.requiredScopes += " User.Read.All";
        }
        this.url = `https://login.microsoftonline.com/${this.tenantDomain}/v2.0/adminconsent?client_id=${this.azureAppId}&redirect_uri=${this.callbackUrl}&scope=${this.requiredScopes}`;
        this.translate.get('PermissionsRequiredMessage', {
            ahref_start: `<a href='${this.url}'><u>`,
            ahref_end: `</u></a>`,
        }).subscribe( (PermissionsRequiredMessage: string) => {
            this.PermissionsRequiredMessage = PermissionsRequiredMessage;
        });
        if (this.isGoogle) {
            this.translate.get('GooglePriviledgeConfigLink', {
                ahref_start: `<a href='${this.gsuiteSupportUrl}' target="_blank"><u>`,
                ahref_end: `</u></a>`,
            }).subscribe((GooglePermissionMessage: string) => {
                this.GooglePermissionMessage = GooglePermissionMessage;
            });
            this.GooglePermissionDescription = this.translate.instant('GoogleAdminPriviledgeInfo');
            if (this.isStackRegionEU) {
                this.translate.get('MoreInformation', {
                    ahref_start: `<a href='${this.supportSiteUrl}' target="_blank" class="primary-link"><u>`,
                    ahref_end: `</u></a>`,
                }).subscribe((MoreInformationMessage: string) => {
                    this.MoreInformationMessage = MoreInformationMessage;
                });
            }
        }
    }
    submit() {
        this.confirmDialogRef.close(0);
    }
}

