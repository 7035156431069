import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'lib-notification-messages',
    templateUrl: './notification-messages.component.html',
    styleUrls: ['./notification-messages.component.scss']
})
export class NotificationMessagesComponent implements OnInit {
    message: string;
    action: string;
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        public snackBarRef: MatSnackBarRef<NotificationMessagesComponent>,
        public translate: TranslateService
    ) { }

    ngOnInit() {
        this.message = this.translate.instant(this.data.message, this.data.interpolateParams);
        this.action = this.data.action;
    }
}
