import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { TokenStorage } from '../../core/token.storage';
import { User } from '../../layout/room-list/interfaces/room';
@Injectable()
export class TenantService {
    public tenantNameSub = new BehaviorSubject('tenant');
    private SERVICETYPE = {
        'rocketstart': 'room',
        'marvel': 'user',
        'rmm': 'rmm',
        'scp': 'scpbase'
    }
    constructor(private http: HttpClient, private token: TokenStorage) {
    }
    getTenantInfo(acessToken): Observable<any> {
        const URI = environment.rmpEndPointTenant.url;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + acessToken
            }),
        };
        return this.http.get<any>(URI, httpOptions);
    }

    // call to tenantList api
    getTenantList(accessToken): Observable<any> {
        const URI = `${environment.rmpEndPointUser.url}/guest/tenantList`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }),
        };
        return this.http.get<any>(URI, httpOptions);
    }

    // call to switchTenant api
    switchTenant(tenant, accessToken) {
        const URI = `${environment.rmpEndPointUser.url}/guest/switchTenant`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': 'Bearer ' + accessToken,
            }),
        };
        const body = { 'tenant': tenant };
        return this.http.post<any>(URI, body, httpOptions);
    }

    createTenantForSelectedProvider(provider, token): Observable<any> {
        const URI = environment.selectProviderURL.url;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': token,
            }),
        };
        const body = { 'provider': provider };
        return this.http.post<any>(URI, body, httpOptions);
    }

    getTenantInfoWithOutToken(token): Observable<any> {
        const URI = environment.selectProviderURL.url;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'token': token,
            }),
        };
        return this.http.get<any>(URI, httpOptions);
    }

    /**
     * Query the user details from DB
     * @param {any} queryOptions The query parameters as an object
     */
    queryUser(queryOptions: any, accessToken): Observable<any> {
        const queryString = this.jsonToQueryString(queryOptions);
        const queryUserUrl = `${environment.rmpEndPointUser.url}/users?${queryString}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }),
        };
        return this.http.get<any>(queryUserUrl, httpOptions);
    }

    /**
     * Patch the record for the currently logged-in user in DB
     * @param {User} user The user instance
     */
    patchUser(userId: string, userInfo: any, accessToken): Observable<User> {
        const guest = 'false';
        const userRole = "user";
        const patchUserUrl = `${environment.rmpEndPointUser.url}/users/${userId}`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "user-role": userRole,
                Authorization: "Bearer " + accessToken,
                feature_type: this.SERVICETYPE[
                    this.token.getMarvelRMPService()
                ],
                'guest' : guest
            }),
        };
        return this.http.patch<User>(patchUserUrl, userInfo, httpOptions);
    }

    /**
     *  Convert an object to query string
     * @param {any} json The query params as an object
     */
    jsonToQueryString(json: any): String {
        const entries = Object.keys(json).map(key => {
            return encodeURIComponent(key) + '=' +
                encodeURIComponent(json[key]);
        });
        return entries.join('&');
    }


    getGuestTenantInfo(authCon): Observable<any> {
        const URI = environment.rmpEndPointTenant.url+ '/guest';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'guest-authCon': authCon
            }),
        };
        return this.http.get<any>(URI, httpOptions);
    }

    /**
     * Update the record for the tenant in DB
     * @param {tenant} tenant The tenant instance
     */
    updateTenantName(tenant: any): Observable<any> {
        const URI = `${environment.rmpEndPointTenant.url}/name`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
        };
        return this.http.put<any>(URI, tenant, httpOptions);
    }

    getCalendarAccountInfo(): Observable<any> {
        const URI = environment.rmpEndPointUser.url + '/calendarAccount';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
        };
        return this.http.get<any>(URI, httpOptions);
    }

    deleteCalendarAccount(email: Array<string>): Observable<any> {
        const URI = environment.rmpEndPointUser.url + '/calendarAccount';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            body: { 'email': email },
        };
        return this.http.delete<any>(URI,httpOptions);
    }
}
