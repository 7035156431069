import { IAppData, IDynamicItem, IStaticItem } from 'lib-ja-cloud';
import { Observable } from 'rxjs';
import { StaticItem, DynamicItem } from './DynamicItem';

export class AppData implements IAppData {

    protected staticItems: Map<string, StaticItem>;
    protected dynamicItems: Map<string, DynamicItem>;

    constructor() {
        this.staticItems = new Map<string, StaticItem>();
        this.dynamicItems = new Map<string, DynamicItem>();
    }

    getStatic(name: string): IStaticItem | undefined {
        // console.log(`get static method of AppData`);
        return this.staticItems.get(name);
        // return this.staticItems.get(name);
    }
    hasStatic(name: string): boolean {
        return this.staticItems.has(name);
    }
    staticEntries(): string[] {
        return Array.from(this.staticItems.keys());
    }
    getDynamic(name: string): IDynamicItem | undefined {
        return this.dynamicItems.get(name);
    }
    hasDynamic(name: string): boolean {
        return this.dynamicItems.has(name);
    }
    dynamicEntries(): string[] {
        return Array.from(this.dynamicItems.keys());
    }

    setStatic(key: string, data: any) {
        this.staticItems.set(key, new StaticItem(data));
    }

    setDynamic(key: string, data: Observable<any>, available?: Observable<any>) {
        this.dynamicItems.set(key, new DynamicItem(data, available || data));
    }

    debug() {
        console.log(`inside debug method`);
    }

}

export const globalAppData = new AppData();

