import { EventEmitter, Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { NotificationService } from '../../../../shared/services/notification.service'
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DateTimeTimezoneService } from '../../services/rmmDateTimeFormat/dateTimeTimezone-format.service';
const dmyFormat1 = 'd.M.yyyy HH:mm';
const dmyFormat2 = 'd/M/yyyy HH:mm';
const dmyFormat3 = 'd-M-yyyy HH:mm';
const ymdFormat = 'yyyy-M-d HH:mm';
const mdyFormat = 'M/d/yyyy HH:mm';
const ddmmyFormat = 'dd.mm.yyyy HH:mm';

const dateFormats = {
    'en': mdyFormat,
    'da': dmyFormat1,
    'de': dmyFormat1,
    'fi': dmyFormat1,
    'nb': dmyFormat1,
    'pl': ddmmyFormat,
    'es': dmyFormat2,
    'fr': dmyFormat2,
    'it': dmyFormat2,
    'nl': dmyFormat3,
    'sv': ymdFormat,
    'en-GB': dmyFormat2,
};
@Injectable({
    providedIn: 'root'
})
export default class MiscUtilService {
    static IPV4_REG_EXP_CONTAINS: any;
    static IPV4_REG_EXP_START: any;
    static IPV4_REG_EXP_END: any;
    static IPV4_REG_EXP: any;
    static macAddressRegExp: any;
    static letter_r = "LETTER-R";
    static ledger_r = "LEDGER-R";
    static foolscap = "FOOLSCAP";
    static UNKNOWN = "UNKNOWN";
    static CUSTOM = "CUSTOM";
    static LEDGER = "LEDGER";
    static LEGAL = "LEGAL";
    static LEGALR = "LEGAL-R";
    static LETTER = "LETTER";
    static INVOICE = "INVOICE";
    static INVOICER = "INVOICE-R";
    static A3 = "A3";
    static A3R = "A3-R";
    static A4 = "A4";
    static A4R = "A4-R";
    static A5 = "A5";
    static A5R = "A5-R";
    static A6 = "A6";
    static A6R = "A6-R";
    static B4 = "B4";
    static B4R = "B4-R";
    static B5 = "B5";
    static B5R = "B5-R";
    static B6 = "B6";
    static B6R = "B6-R";
    static _8K = "8K";
    static _8KR = "8K-R";
    static _16K = "16K";
    static _16KR = "16K-R";
    static DBLPOST = "DBLPOST";
    static DBLPOSTR = "DBLPOST-R";
    static POSTALCARD = "POSTALCARD";
    static POSTALCARDR = "POSTALCARD-R";
    static QUADPOST = "QUADPOST";
    static QUADPOSTR = "QUADPOST-R";
    static CHOKEI40 = "CHOKEI40";
    static CHOKEI40R = "CHOKEI40-R";
    static CHOKEI3 = "CHOKEI3";
    static CHOKEI3R = "CHOKEI3-R";
    static CHOKEI4 = "CHOKEI4";
    static CHOKEI4R = "CHOKEI4-R";
    static CHOKEI5 = "CHOKEI5";
    static KAKUKEI2 = "KAKUKEI2";
    static KAKUKEI2R = "KAKUKEI2-R";
    static KAKUKEI3 = "KAKUKEI3";
    static KAKUKEI3R = "KAKUKEI3-R";
    static KAKUKEI8 = "KAKUKEI8";
    static YOUKEI1 = "YOUKEI1";
    static YOUKEI2 = "YOUKEI2";
    static YOUKEI2R = "YOUKEI2-R";
    static YOUKEI3 = "YOUKEI3";
    static YOUKEI4 = "YOUKEI4";
    static YOUKEI4R = "YOUKEI4-R";
    static YOUKEI5 = "YOUKEI5";
    static YOUKEI6 = "YOUKEI6";
    static YOUKEI7 = "YOUKEI7";
    static YOUKEI7R = "YOUKEI7R";
    static MONARCH = "MONARCH";
    static MONARCHR = "MONARCH-R";
    static DL = "DL";
    static DLR = "DL-R";
    static C5 = "C5";
    static C5R = "C5-R";
    static C6 = "C6";
    static C6R = "C6-R";
    static C65 = "C65";
    static C65R = "C65-R";
    static ISOB5 = "ISOB5";
    static ISOB5R = "ISOB5-R";
    static COM10 = "COM10";
    static COM10R = "COM10-R";
    static FOLIO = "FOLIO";
    static FOLIOR = "FOLIO-R";
    static FOOLSCAPR = "FOOLSCAP-R";
    static EXEC = "EXEC";
    static EXECR = "EXEC-R";
    static _12x18 = "12x18";
    static A3W = "A3W";
    static _8pt5into13pt5 = "8.5x13.5";
    static _8pt5into13pt4 = "8.5x13.4";
    static translation: TranslateService;
    static timeZone = [
        'UTC-12:00', 'UTC-11:00', 'UTC-10:00', 'UTC-09:30', 'UTC-09:00', 'UTC-08:00', 'UTC-07:00', 'UTC-06:00', 'UTC-05:00', 'UTC-04:00', 'UTC-03:00',
        'UTC-02:00', 'UTC-01:00', 'UTC+00:00', 'UTC+01:00', 'UTC+02:00', 'UTC+03:00', 'UTC+04:00', 'UTC+04:30', 'UTC+05:00', 'UTC+05:30', 'UTC+06:00',
        'UTC+06:30', 'UTC+07:00', 'UTC+08:00', 'UTC+09:00', 'UTC+09:30', 'UTC+10:00', 'UTC+11:00', 'UTC+12:00', 'UTC+13:00', 'UTC+14:00'
    ];
    static timeZoneDBType = {
        "UTC-12:00": "Etc/GMT+12",
        "UTC-11:00": "Etc/GMT+11",
        "UTC-10:00": "Etc/GMT+10",
        "UTC-09:30": "Pacific/Marquesas",
        "UTC-09:00": "Etc/GMT+9",
        "UTC-08:00": "Etc/GMT+8",
        "UTC-07:00": "Etc/GMT+7",
        "UTC-06:00": "Etc/GMT+6",
        "UTC-05:00": "Etc/GMT+5",
        "UTC-04:00": "Etc/GMT+4",
        "UTC-03:00": "Etc/GMT+3",
        "UTC-02:00": "Etc/GMT+2",
        "UTC-01:00": "Etc/GMT+1",
        "UTC+00:00": "Etc/GMT+0",
        "UTC+01:00": "Etc/GMT-1",
        "UTC+02:00": "Etc/GMT-2",
        "UTC+03:00": "Etc/GMT-3",
        "UTC+04:00": "Etc/GMT-4",
        "UTC+04:30": "Asia/Kabul",
        "UTC+05:00": "Etc/GMT-5",
        "UTC+05:30": "IST",
        "UTC+06:00": "Etc/GMT-6",
        "UTC+06:30": "Asia/Rangoon",
        "UTC+07:00": "Etc/GMT-7",
        "UTC+08:00": "Etc/GMT-8",
        "UTC+09:00": "Etc/GMT-9",
        "UTC+09:30": "ACT",
        "UTC+10:00": "Etc/GMT-10",
        "UTC+11:00": "Etc/GMT-11",
        "UTC+12:00": "Etc/GMT-12",
        "UTC+13:00": "Etc/GMT-13",
        "UTC+14:00": "Etc/GMT-14"
    };
    static timeZoneUIType = {
        "Etc/GMT+12": "UTC-12:00",
        "Etc/GMT+11": "UTC-11:00",
        "Etc/GMT+10": "UTC-10:00",
        "Pacific/Marquesas": "UTC-09:30",
        "Etc/GMT+9": "UTC-09:00",
        "Etc/GMT+8": "UTC-08:00",
        "Etc/GMT+7": "UTC-07:00",
        "Etc/GMT+6": "UTC-06:00",
        "Etc/GMT+5": "UTC-05:00",
        "Etc/GMT+4": "UTC-04:00",
        "Etc/GMT+3": "UTC-03:00",
        "Etc/GMT+2": "UTC-02:00",
        "Etc/GMT+1": "UTC-01:00",
        "Etc/GMT+0": "UTC+00:00",
        "Etc/GMT-1": "UTC+01:00",
        "Etc/GMT-2": "UTC+02:00",
        "Etc/GMT-3": "UTC+03:00",
        "Etc/GMT-4": "UTC+04:00",
        "Asia/Kabul": "UTC+04:30",
        "Etc/GMT-5": "UTC+05:00",
        "IST": "UTC+05:30",
        "Etc/GMT-6": "UTC+06:00",
        "Asia/Rangoon": "UTC+06:30",
        "Etc/GMT-7": "UTC+07:00",
        "Etc/GMT-8": "UTC+08:00",
        "Etc/GMT-9": "UTC+09:00",
        "ACT": "UTC+09:30",
        "Etc/GMT-10": "UTC+10:00",
        "Etc/GMT-11": "UTC+11:00",
        "Etc/GMT-12": "UTC+12:00",
        "Etc/GMT-13": "UTC+13:00",
        "Etc/GMT-14": "UTC+14:00"
    }
    //Here According To Tickects #19713 Added A New Sim "Sim26-29"
    static simListMappingJson = {
        '2602': 'Sim26-02',
        '2603': 'Sim26-03',
        '2605': 'Sim26-05',
        '2618': 'Sim26-18',
        '2629': 'Sim26-29',
        '2635': 'Sim26-35',
        '2638': 'Sim26-38',
        '2649': 'Sim26-49',
        '2653': 'Sim26-53',
        '2665': 'Sim26-65',
        '2669': 'Sim26-69',
        '2704': 'Sim27-04',
        '2707': 'Sim27-07',
        '2709': 'Sim27-09',
        '4404': 'Sim44-04',
        '4428': 'Sim44-28',
        '1400': 'Sim14',
        '1500': 'Sim15',
        '1600': 'Sim16',
        '2401': 'Sim24-01',
        '2402': 'Sim24-02',
        '2403': 'Sim24-03',
        '2710': 'Sim27-10',
        '4406': 'Sim44-06',
        '4417': 'Sim44-17',
        '4426': 'Sim44-26',
        '4462': 'Sim44-62',
        '5022': 'Sim50-22',
        '6206': 'Sim62-06',
        '6308': 'Sim63-08',
        '6728': 'Sim67-28',
        '10010': 'Sim44-06 & Sim44-26',
    }
    static emitAgentStatus: EventEmitter<string> = new EventEmitter();
    static emitFssStatus: EventEmitter<string> = new EventEmitter();
    // added for capturing the swal fire dialogue close in rop page
    static emitROPErrorStatus: EventEmitter<string> = new EventEmitter();
    static setTranslationService(translation: TranslateService) {
        MiscUtilService.translation = translation;
    }
    public browserLang: string;
    constructor(private notificationService: NotificationService,
        private _snackBar: MatSnackBar,
        private dateAdapter: DateAdapter<Date>,
        private dateTimeTimezoneService: DateTimeTimezoneService,) {
        this.browserLang = this.dateTimeTimezoneService.getLanguage();
        this.dateAdapter.setLocale(this.browserLang);
    }
    IPV4_REG_EXP_CONTAINS = "([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\";
    IPV4_REG_EXP_START = "^([01]?\\d\\d?|2[0-4]\\d|25[0-5])";
    IPV4_REG_EXP_END = "([01]?\\d\\d?|2[0-4]\\d|25[0-5])$";
    IPV4_REG_EXP = "^([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\.([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\."
        + "([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\.([01]?\\d\\d?|2[0-4]\\d|25[0-5])$";
    macAddressRegExp = "^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}\\.[0-9a-fA-F]{4}\\.[0-9a-fA-F]{4})$"
    static equalsIgnoreCase(string1, string2) {
        if (string1.toLowerCase() == string2.toLowerCase()) {
            return true;
        }
        else {
            return false
        }
    }
    static getIntegerIpAddress(ipaddress) {
        if (ipaddress == null) {
            return ""
        }
        else if (ipaddress == "" && this.ValidateIPaddress(ipaddress)) {
            return ipaddress
        }
        else {
            //TBD
            return ipaddress
        }
    }
    static isValidIpRange(start, end) {
        let ip1 = this.convertIpToNumeric(start)
        let ip2 = this.convertIpToNumeric(end)
        if (ip2 <= ip1) {
            return false
        }
        return true
    }
    static convertIpToNumeric(ipAddress) {
        let arrIp = ipAddress.split(".");

        let segment1 = parseInt(arrIp[0]);
        let segment2 = parseInt(arrIp[1]);
        let segment3 = parseInt(arrIp[2]);
        let segment4 = parseInt(arrIp[3]);

        //reverse order calc
        //eg. 255.255.240.0     &lt;-- start at the end
        // 0 + (240*256) + (255*65536) + (255*16777216)
        let calc = segment4 + (segment3 * 256) + (segment2 * 65536) + (segment1 * 16777216);

        return calc;

    }
    static getIpAddressRegExp(operator) {
        if ((operator == "contains" || operator == "contains")) {
            return this.IPV4_REG_EXP_CONTAINS
        }
        else if (operator == "startsWith") {
            return this.IPV4_REG_EXP_START
        }
        else if (operator == "endsWith") {
            return this.IPV4_REG_EXP_END
        }
        else if (operator == "equal" || operator == "notEqual" || operator == "subnet") {
            return this.IPV4_REG_EXP
        }
        else {
            return this.IPV4_REG_EXP
        }
    }
    static reqExpTest(reg, value) {
        return value.match(reg)
    }
    static convertToLong(value) {
        try {
            if (value.matches("^[a-z].*") || value.contains("#")
                || (0 == value.length())) {
                return 0;
            }
            return Number(value);
        } catch (error) {
            return 0;
        }
    }
    static checkOctetStartWithZero(ip) {
        let tempipList = ip.split("\\.")
        tempipList.forEach(element => {
            if (element.length >= 1) {

            }
            else {
                return false
            }
        });
        return true
    }
    static ValidateIPaddress(ipaddress) {
        // /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
        if (/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(ipaddress)) {
            return (true)
        }
        return (false)
    }
    static ValidateIPv6address(ipaddress) {
        if (/^((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*::((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*|((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4})){7}$/.test(ipaddress)) {
            return (true)
        }
        return (false)
    }

    static ValidateCommaSeparatedIP(ipaddress) {
        if (/^(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?))(?:(,|-|,\s*)(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)))*$/.test(ipaddress)) {
            return (true)
        }

        return (false)
    }
    static ValidateObjectID(objectId) {
        if (/^[0-9]+([.][0-9]+)*[.]?$/.test(objectId)) {
            return (true)
        }
        return (false)
    }
    static checkDuplicateExists(arrayList) {
        return new Set(arrayList).size !== arrayList.length
    }

    static getMACAddressRegExp(operator, value) {
        let valuesplit = value.split("-")
        let macRegEXP = "^([0-9a-fA-F]|[0-9a-fA-F][0-9a-fA-F]|[0-9a-fA-F][0-9a-fA-F]-){0," + valuesplit.length + "}$";
        if (this.equalsIgnoreCase(operator, "contains") || this.equalsIgnoreCase(operator, "notContains")) {
            return macRegEXP;
        } else if (this.equalsIgnoreCase(operator, "startsWith") || this.equalsIgnoreCase(operator, "endsWith")) {
            return macRegEXP;
        } else if (this.equalsIgnoreCase(operator, "equal") || this.equalsIgnoreCase(operator, "notEqual")) {
            return this.macAddressRegExp;
        }
        return "";
    }

    // isDarwinDevice added to identify darwin device
    static getLocalizationTrayMediaSizeName(trayMediaSizeName, isDarwinDevice) {
        if (this.equalsIgnoreCase(this.letter_r, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('letter_r')
        } else if (this.equalsIgnoreCase(this.ledger_r, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('ledger_r')
        } else if (this.equalsIgnoreCase(this.foolscap, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('foolscap')
        } else if (this.equalsIgnoreCase(this.LEDGER, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('ledger')
        } else if (this.equalsIgnoreCase(this.LEGAL, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('legal')
        } else if (this.equalsIgnoreCase(this.LEGALR, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('legalR')
        } else if (this.equalsIgnoreCase(this.LETTER, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('letter')
        } else if (this.equalsIgnoreCase(this.INVOICE, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('invoice')
        } else if (this.equalsIgnoreCase(this.INVOICER, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('invoiceR')
        } else if (this.equalsIgnoreCase(this.A3, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('a3')
        } else if (this.equalsIgnoreCase(this.A3R, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('a3R')
        } else if (this.equalsIgnoreCase(this.A4, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('a4')
        } else if (this.equalsIgnoreCase(this.A4R, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('a4R')
        } else if (this.equalsIgnoreCase(this.A5, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('a5')
        } else if (this.equalsIgnoreCase(this.A5R, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('a5R')
        } else if (this.equalsIgnoreCase(this.A6, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('a6')
        } else if (this.equalsIgnoreCase(this.A6R, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('a6R')
        } else if (this.equalsIgnoreCase(this.B4, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('b4')
        } else if (this.equalsIgnoreCase(this.B4R, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('b4R')
        } else if (this.equalsIgnoreCase(this.B5, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('b5')
        } else if (this.equalsIgnoreCase(this.B5R, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('b5R')
        } else if (this.equalsIgnoreCase(this.B6, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('b6')
        } else if (this.equalsIgnoreCase(this.B6R, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('b6R')
        } else if (this.equalsIgnoreCase(this._8K, trayMediaSizeName) || (isDarwinDevice && this.equalsIgnoreCase("8KTW", trayMediaSizeName))) {
            trayMediaSizeName = this.translation.instant('_8K')
        } else if (this.equalsIgnoreCase(this._8KR, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('_8KR')
        } else if (this.equalsIgnoreCase(this._16K, trayMediaSizeName) || (isDarwinDevice && this.equalsIgnoreCase("16KTW", trayMediaSizeName))) {
            trayMediaSizeName = this.translation.instant('_16K')
        } else if (this.equalsIgnoreCase(this._16KR, trayMediaSizeName) || (isDarwinDevice && this.equalsIgnoreCase("16KTW-R", trayMediaSizeName))) {
            trayMediaSizeName = this.translation.instant('_16KR')
        } else if (this.equalsIgnoreCase(this.DBLPOST, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('wPostCard')
        } else if (this.equalsIgnoreCase(this.DBLPOSTR, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('wPostCardR')
        } else if (this.equalsIgnoreCase(this.POSTALCARD, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('postcard')
        } else if (this.equalsIgnoreCase(this.POSTALCARDR, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('postcardR')
        } else if (this.equalsIgnoreCase(this.QUADPOST, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('quadPost')
        } else if (this.equalsIgnoreCase(this.QUADPOSTR, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('quadPost')
        } else if (this.equalsIgnoreCase(this.CHOKEI40, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('chokei40')
        } else if (this.equalsIgnoreCase(this.CHOKEI40R, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('chokei40')
        } else if (this.equalsIgnoreCase(this.CHOKEI3, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('chokei3')
        } else if (this.equalsIgnoreCase(this.CHOKEI3R, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('chokei3R')
        } else if (this.equalsIgnoreCase(this.CHOKEI4, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('chokei4')
        } else if (this.equalsIgnoreCase(this.CHOKEI4R, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('chokei4R')
        } else if (this.equalsIgnoreCase(this.CHOKEI5, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('chokei5')
        } else if (this.equalsIgnoreCase(this.KAKUKEI2, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('kaku_2')
        } else if (this.equalsIgnoreCase(this.KAKUKEI2R, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('kaku_2')
        } else if (this.equalsIgnoreCase(this.KAKUKEI3, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('kaku_3')
        } else if (this.equalsIgnoreCase(this.KAKUKEI3R, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('kaku_3')
        } else if (this.equalsIgnoreCase(this.KAKUKEI8, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('kaku_8')
        } else if (this.equalsIgnoreCase(this.YOUKEI1, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('yOKEI_1')
        } else if (this.equalsIgnoreCase(this.YOUKEI2, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('yOKEI_2')
        } else if (this.equalsIgnoreCase(this.YOUKEI2R, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('yOKEI2R')
        } else if (this.equalsIgnoreCase(this.YOUKEI3, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('yOKEI_3')
        } else if (this.equalsIgnoreCase(this.YOUKEI4, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('yOKEI_4')
        } else if (this.equalsIgnoreCase(this.YOUKEI4R, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('yOKEI_4R')
        } else if (this.equalsIgnoreCase(this.YOUKEI5, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('yOKEI_5')
        } else if (this.equalsIgnoreCase(this.YOUKEI6, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('yOKEI_6')
        } else if (this.equalsIgnoreCase(this.YOUKEI7R, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('yOKEI_7R')
        } else if (this.equalsIgnoreCase(this.MONARCH, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('monarch')
        } else if (this.equalsIgnoreCase(this.MONARCHR, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('monarchR')
        } else if (this.equalsIgnoreCase(this.DL, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('dL')
        } else if (this.equalsIgnoreCase(this.DLR, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('dLR')
        } else if (this.equalsIgnoreCase(this.C5, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('c5')
        } else if (this.equalsIgnoreCase(this.C5R, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('c5R')
        } else if (this.equalsIgnoreCase(this.C6, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('c6')
        } else if (this.equalsIgnoreCase(this.C6R, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('c6R')
        } else if (this.equalsIgnoreCase(this.C65, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('c65')
        } else if (this.equalsIgnoreCase(this.C65R, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('c65R')
        } else if (this.equalsIgnoreCase(this.ISOB5, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('iSO_B5')
        } else if (this.equalsIgnoreCase(this.ISOB5R, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('iSO_B5R')
        } else if (this.equalsIgnoreCase(this.COM10, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('cOM_10')
        } else if (this.equalsIgnoreCase(this.COM10R, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('cOM_10R')
        } else if (this.equalsIgnoreCase(this.FOLIO, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('folio')
        } else if (this.equalsIgnoreCase(this.FOLIOR, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('folioR')
        } else if (this.equalsIgnoreCase(this.FOOLSCAPR, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('foolscapR')
        } else if (this.equalsIgnoreCase(this.EXEC, trayMediaSizeName)) {
            trayMediaSizeName = isDarwinDevice ? '7.25 x 10.5"' : this.translation.instant('executive')
        } else if (this.equalsIgnoreCase(this.EXECR, trayMediaSizeName)) {
            trayMediaSizeName = isDarwinDevice ? '7.25 x 10.5"' : this.translation.instant('executiveR')
        } else if (this.equalsIgnoreCase(this._12x18, trayMediaSizeName)) {
            trayMediaSizeName = '12x18';
        } else if (this.equalsIgnoreCase(this.A3W, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('a3W')
        } else if (this.equalsIgnoreCase(this._8pt5into13pt5, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('_8pt5into13pt5')
        } else if (this.equalsIgnoreCase(this._8pt5into13pt4, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('_8pt5into13pt4')
        } else if (this.equalsIgnoreCase(this.CUSTOM, trayMediaSizeName)) {
            trayMediaSizeName = this.translation.instant('custom')
        } else if (this.equalsIgnoreCase("other", trayMediaSizeName)) { //Handle "other" Media Size
            trayMediaSizeName = this.translation.instant('others')
        }

        // update media name based on isDarwinDevice flag
        if (isDarwinDevice) {
            if (this.equalsIgnoreCase('8x10', trayMediaSizeName) || this.equalsIgnoreCase('8x10R', trayMediaSizeName)) {
                trayMediaSizeName = this.translation.instant('rmmquarto') + ' (8 x 10")'
            } else if (this.equalsIgnoreCase("DTTOKUA3", trayMediaSizeName)) {
                trayMediaSizeName = this.translation.instant('rmmdtspeciala3')
            } else if (this.equalsIgnoreCase("12.6x19.2", trayMediaSizeName)) {
                trayMediaSizeName = '12.6 x 19.2"'
            } else if (this.equalsIgnoreCase("13x18", trayMediaSizeName)) {
                trayMediaSizeName = '13 x 18"'
            } else if (this.equalsIgnoreCase("13x19", trayMediaSizeName)) {
                trayMediaSizeName = '13 x 19"'
            } else if ((this.equalsIgnoreCase("DTTOKUA4", trayMediaSizeName) || this.equalsIgnoreCase("DTTOKUA4-R", trayMediaSizeName))) {
                trayMediaSizeName = this.translation.instant('rmmdtspeciala4')
            } else if ((this.equalsIgnoreCase("PC4x6", trayMediaSizeName) || this.equalsIgnoreCase("PC4x6R", trayMediaSizeName))) {
                trayMediaSizeName = '4 x 6"'
            }
        }
        return trayMediaSizeName;
    }

    static getTrayImage(trayStatus, trayCapacity) {
        let trayStatusImage = "";
        let tray_Status = trayStatus;
        let tray_Capacity = trayCapacity;
        let inTrayListImgFolderURL = "assets/rmm/images/common/RMM_"
        let trayStatusLevel;
        if (tray_Status != null) {
            if (this.equalsIgnoreCase("notempty", tray_Status)) {
                trayStatusImage = inTrayListImgFolderURL + "Tray_NotEmpty.svg"
            }
            else if (this.equalsIgnoreCase("unknown", tray_Status)) {
                trayStatusImage = inTrayListImgFolderURL + "Tray_Unknown.svg"
            }
            else if (this.equalsIgnoreCase("empty", tray_Status)) {
                trayStatusImage = inTrayListImgFolderURL + "Tray_Empty.svg"
            }
            else if (tray_Capacity !== null && !(trayCapacity == "")) {
                let trayStatusLevelFloat = 100 * parseFloat(tray_Status) / parseFloat(tray_Capacity);
                trayStatusLevel = Math.round(trayStatusLevelFloat);
                if (trayStatusLevel > 75) {
                    trayStatusImage = inTrayListImgFolderURL + "Tray_Full.svg"
                }
                else if (trayStatusLevel > 50 && trayStatusLevel <= 75) {
                    trayStatusImage = inTrayListImgFolderURL + "Tray_75.svg"
                }
                else if (trayStatusLevel > 25 && trayStatusLevel <= 50) {
                    trayStatusImage = inTrayListImgFolderURL + "Tray_50.svg"
                }
                else if (trayStatusLevel > 0 && trayStatusLevel <= 25) {
                    trayStatusImage = inTrayListImgFolderURL + "Tray_25.svg"
                }
                else if (trayStatusLevel == -3) {
                    trayStatusImage = inTrayListImgFolderURL + "Tray_25.svg"
                }
                else if (trayStatusLevel == -2) {
                    trayStatusImage = inTrayListImgFolderURL + "Tray_Unknown.svg"
                }
            }
            else {
                trayStatusImage = inTrayListImgFolderURL + "Tray_Empty.svg"
            }
        }
        else {
            trayStatusImage = inTrayListImgFolderURL + "Tray_Empty.svg"
        }
        return trayStatusImage;
    }

    static getInputTrayStatus(trayStatus, trayCapacity) {
        let tray_Status = trayStatus;
        let tray_Capacity = trayCapacity;
        let trayStatusLevel;
        let inTrayStatusLevel = "";
        if (tray_Status != null) {
            if (trayStatus == 'nBya') {
                return this.translation.instant('unknown');
            }
            // For Localization
            if (this.equalsIgnoreCase("notempty", tray_Status)) {
                inTrayStatusLevel = this.translation.instant('trayStatusNotEmpty')
            } else if (this.equalsIgnoreCase("unknown", tray_Status)) {
                inTrayStatusLevel = this.translation.instant('unknown')
            } else if (this.equalsIgnoreCase('empty', tray_Status)) {
                inTrayStatusLevel = this.translation.instant('empty')
            } else if (tray_Capacity != null) {
                // Calculating Status For InputTray
                if (tray_Status != 0) {
                    let trayStatusLevelFloat = 100 * parseFloat(tray_Status) / parseFloat(tray_Capacity);
                    trayStatusLevel = Math.round(trayStatusLevelFloat);
                    if (isNaN(trayStatusLevel)) {
                        inTrayStatusLevel = this.translation.instant('unknown')
                    } else {
                        inTrayStatusLevel = trayStatusLevel + "%";
                    }
                } else {
                    inTrayStatusLevel = this.translation.instant('empty')
                }
            } else {
                inTrayStatusLevel = this.translation.instant('unknown')
            }
        } else {
            inTrayStatusLevel = this.translation.instant('unknown')
        }
        return inTrayStatusLevel;
    }

     // isDarwinDevice added to identify darwin device
    static getInputTrayPaperSize(paperSizeName, mediaDimXFeed, mediaDimFeed, isDarwinDevice) {
        let trayMediaSizeName = paperSizeName;
        let trayMediaDimXFeed = mediaDimXFeed;
        let trayMediaDimFeed = mediaDimFeed;

        // Step1: only Following Paper types support 'R' Direction
        //
            if ((trayMediaSizeName.includes("LETTER") || trayMediaSizeName.includes("INVOICE")
                || trayMediaSizeName.includes("A4") || trayMediaSizeName.includes("A5") || trayMediaSizeName.includes("B5")
                || trayMediaSizeName.includes("16K"))) {
                if (trayMediaDimXFeed >= trayMediaDimFeed) {
                    if (trayMediaSizeName.includes("LETTER")) {
                        if (trayMediaSizeName.endsWith("RR")) {
                            trayMediaSizeName = trayMediaSizeName.substring(0, trayMediaSizeName.length - 1);
                        }

                    } else if (trayMediaSizeName.endsWith("R")) {
                        trayMediaSizeName = trayMediaSizeName.substring(0, trayMediaSizeName.length - 1);
                    }
                }
                else if (trayMediaDimXFeed < trayMediaDimFeed) {
                    if (trayMediaSizeName.includes("LETTER")) {

                        // if LETTER has R at the end
                        // so trim only if RR is there
                        if (trayMediaSizeName.endsWith("RR")) {
                            trayMediaSizeName = trayMediaSizeName.substring(0, trayMediaSizeName.length - 1);
                        }

                    } else if (trayMediaSizeName.endsWith("R")) {
                        trayMediaSizeName = trayMediaSizeName.substring(0, trayMediaSizeName.length - 1);
                    }
                    trayMediaSizeName = trayMediaSizeName + "-R";
                }
            }
            else {
                if (trayMediaSizeName.includes("LEDGER")) {
                    if (trayMediaSizeName.endsWith("RR")) {
                        trayMediaSizeName = trayMediaSizeName.substring(0, trayMediaSizeName.length - 1);
                    }
                } else if (trayMediaSizeName.endsWith("R")) {
                    trayMediaSizeName = trayMediaSizeName.substring(0, trayMediaSizeName.length - 1);
                }
            }

        return this.getLocalizationTrayMediaSizeName(trayMediaSizeName, isDarwinDevice);
    }

    static getTonerImage(tonerColor, tonerLevel) {
        let tonerLevelImage = ""
        let tonerFolderURL = "assets/rmm/images/common/RMM_"
        if (this.equalsIgnoreCase('unknown', tonerLevel)) {
            tonerLevelImage = tonerFolderURL + "Toner_Unknown.svg"
        }
        else {
            let imgURLS = [
                ["TonerBlack_Full.svg", "TonerBlack_75.svg", "TonerBlack_50.svg", "TonerBlack_25.svg"],
                ["TonerCyan_Full.svg", "TonerCyan_75.svg", "TonerCyan_50.svg", "TonerCyan_25.svg"],
                ["TonerYellow_Full.svg", "TonerYellow_75.svg", "TonerYellow_50.svg", "TonerYellow_25.svg"],
                ["TonerMagenta_Full.svg", "TonerMagenta_75.svg", "TonerMagenta_50.svg", "TonerMagenta_25.svg"],
                ["TonerClear_Full.svg", "TonerClear_75.svg", "TonerClear_50.svg", "TonerClear_25.svg"],
                ["TonerGold_Full.svg", "TonerGold_75.svg", "TonerGold_50.svg", "TonerGold_25.svg"],
                ["TonerPink_Full.svg", "TonerPink_75.svg", "TonerPink_50.svg", "TonerPink_25.svg"],
                ["TonerSilver_Full.svg", "TonerSilver_75.svg", "TonerSilver_50.svg", "TonerSilver_25.svg"],
                ["TonerTextured_Full.svg", "TonerTextured_75.svg", "TonerTextured_50.svg", "TonerTextured_25.svg"],
                ["TonerWhite_Full.svg", "TonerWhite_75.svg", "TonerWhite_50.svg", "TonerWhite_25.svg"],
            ];
            let colorCode = 0
            let tonerLevelCode = 0
            if (tonerColor == 'black') {
                colorCode = 0
            }
            else if (tonerColor == 'cyan') {
                colorCode = 1
            }
            else if (tonerColor == 'yellow') {
                colorCode = 2
            }
            else if (tonerColor == 'magenta') {
                colorCode = 3
            }             
            else if (tonerColor == 'clear') {
                colorCode = 4
            }
            else if (tonerColor == 'gold') {
                colorCode = 5
            }
            else if (tonerColor == 'pink') {
                colorCode = 6
            }
            else if (tonerColor == 'silver') {
                colorCode = 7
            } 
            else if (tonerColor == 'textured') {
                colorCode = 8
            }
            else if (tonerColor == 'white') {
                colorCode = 9
            }
            if (tonerLevel > 75) {
                tonerLevelCode = 0
            }
            else if (tonerLevel > 50 && tonerLevel <= 75) {
                tonerLevelCode = 1
            }
            else if (tonerLevel > 25 && tonerLevel <= 50) {
                tonerLevelCode = 2
            }
            else if (tonerLevel <= 25 || this.equalsIgnoreCase('low', tonerLevel) || this.equalsIgnoreCase('notEmpty', tonerLevel)) {
                tonerLevelCode = 3
            }
            else {
                tonerLevelCode = 4
            }
            if (tonerLevelCode == 4) {
                tonerLevelImage = tonerFolderURL + "Toner_Empty.svg"
            }
            else {
                tonerLevelImage = tonerFolderURL + imgURLS[colorCode][tonerLevelCode]
            }
        }
        return tonerLevelImage;
    }
    static isValidMac(fieldName, operator, value) {
        if ("macAddress" == fieldName && !("isNull" == operator || "isNotNull" == operator)) {
            if (value.startsWith("--") || value.endsWith("--") || value.length > 17) {
                return false
            } else {
                if (value.startsWith("-") && !(this.equalsIgnoreCase(operator, "startsWith"))) {
                    return false
                }
                if (value.endsWith("-") && !(this.equalsIgnoreCase(operator, "endsWith"))) {
                    return false
                }
                let reg = this.getMACAddressRegExp(operator, value);
                if (value.match(reg) === null) {
                    return false
                }
                else {
                    return true
                }
            }
        }
        return true
    }
    static onlyWhitespaceValidator(control: AbstractControl) {
        if (control.value.length > 0) {
            const isWhitespace = (control.value).trim().length == 0;
            const isValid = !isWhitespace;
            return isValid ? null : { 'whitespace': true };
        }
    }
    static specialCharacterValidator(control: AbstractControl) {
        const nameRegexp: RegExp = /[*\\[\]:"\\|<>\/?]/;
        if (control.value && nameRegexp.test(control.value)) {
            return { specialCharacter: true };
        }
        return null;
    }
    static updatePageSize(key, value) {
        let pageSizeObj = JSON.parse(localStorage.getItem('RMM_PAGE_SIZE'))
        if (pageSizeObj != null && typeof pageSizeObj !== 'undefined') {
            pageSizeObj[key] = value
            localStorage.setItem("RMM_PAGE_SIZE", JSON.stringify(pageSizeObj))
        } else {
            let pageSizeObj = {}
            pageSizeObj[key] = value
            localStorage.setItem("RMM_PAGE_SIZE", JSON.stringify(pageSizeObj))
        }
    }
    static wholeNumberValidator(control: UntypedFormControl) {
        const wholenumberRegexp: RegExp = /^[+]?[0-9]+$/;
        if (control.value) {
            if (!wholenumberRegexp.test(control.value)) {
                return { wholeNumberError: true };
            }
        }
        return null;
    }
    static isValidFilterIpRange(start, end) {
        if (start == '0.0.0.0' && end == '0.0.0.0') {
            return true
        }
        let ip1 = this.convertIpToNumeric(start)
        let ip2 = this.convertIpToNumeric(end)
        if (ip2 <= ip1) {
            return false
        }
        return true
    }
    static ValidateMacAddress(macaddress) {
        if (/^[0-9a-fA-F]{1,2}([-])[0-9a-fA-F]{1,2}(?:\1[0-9a-fA-F]{1,2}){4}$/.test(macaddress)) {
            return (true)
        }
        return (false)
    }
    static fireWarning(title, text) {
        let translatedText = ''
        if (text.includes("VNCE0001")) {
            if (text.split(',').length > 1) {
                translatedText = this.translation.instant('rmmVNCE0001') + '(' + text.split(',')[1] + ')'
            } else {
                translatedText = this.translation.instant('rmmVNCE0001')
            }
        } else {
            translatedText = this.translation.instant(text)
        }

        Swal.fire({
            customClass: {
                container: 'rmm-mailalert',
                confirmButton: 'primary_btn'
            },
            html: "<div style='font-family: Roboto,Helvetica Neue,sans-serif;font-size: 16px !important;font-weight: 500 !important;line-height: 24px !important;'>" + translatedText + "</div>",
            confirmButtonText: "<div id='rmm-misc-util-ok-button' style='font-family: Roboto,Helvetica Neue,sans-serif;font-size: 15px !important;font-weight: 500 !important;line-height: 24px !important;'>" + this.translation.instant('ok') + "</div>",
            width: 400,
            confirmButtonColor: '#12a7bb',
            allowOutsideClick: true,
            allowEnterKey: true

        }).then((result) => {
            // Here We Pass the Result "Success" For RemoteFSS Alert
            if (result) {
                this.emitFssStatus.emit('success');
                // added for capturing the swal fire dialogue close in rop page
                this.emitROPErrorStatus.emit(text)
            }
        });
    }
    static fireWarningWithTitle(title, text) {
        Swal.fire({
            customClass: {
                container: 'rmm-mailalert',
                confirmButton: 'primary_btn'
            },
            title: title,
            html: "<div style='font-family: Roboto,Helvetica Neue,sans-serif;font-size: 16px !important;font-weight: 500 !important;line-height: 24px !important;'>" + text + "</div>",
            confirmButtonText: "<div style='font-family: Roboto,Helvetica Neue,sans-serif;font-size: 15px !important;font-weight: 500 !important;line-height: 24px !important;'>" + this.translation.instant('ok') + "</div>",
            width: 400,
            confirmButtonColor: '#12a7bb'
        }).then((result) => {

        });
    }
    static getAgentStatus() {
        return this.emitAgentStatus;
    }

    static getFssStatus() {
        return this.emitFssStatus;
    }

    // added for capturing the swal fire dialogue close in rop page
    static getROPErrorStatus() {
        return this.emitROPErrorStatus;
    }

    showNotificationMessage(message) {
        this.notificationService.showNotification(message, undefined, undefined, { verticalPosition: 'bottom', panelClass: 'notification-bg', duration: 5000 });
    }
    // getOperationResultDialog(totalCount, successCount, failureCount, key) {
    //     this.dialog.open(CopymoveDialogComponent, {
    //         width: '30%',
    //         /*disableClose: true,*/
    //         autoFocus: true,
    //         data: {
    //             value: { totalCount: totalCount, successCount: successCount, failureCount: failureCount, operation: key }
    //         }
    //     }).afterClosed()
    //         .subscribe((data) => {
    //         })
    // }
    hasKey(obj, key) {
        if (key in obj) {
            return true
        }
        else {
            return false
        }
    }
    setScheduleSettingsDate(value) {
        let datevalue = this.dateTimeTimezoneService.parseDateLocale(value)
        // let currentDate = new Date()
        // let offset = currentDate.getTimezoneOffset()
        // let tempOffSet = -(offset)
        // let date = Date.parse(datevalue) + tempOffSet * 60000
        let date = Date.parse(datevalue)
        return date
    }

    getCloningType(routeKey) {
        let retValue = ""
        retValue = routeKey == '/rmm/storage-backup' ? 'storageBackup' : 'deviceCloning'
        return retValue
    }
    sortPolicyByName(policy) {
        return policy.sort((a, b) => {
            let A = '';
            let B = '';
            A = a.policyName.toLowerCase();
            B = b.policyName.toLowerCase();
            return this.compare(A, B, true);
        });
    }

    compare(a: number | string, b: number | string, isAsc: boolean) {
        if (a == b) {
            return 0;
        }
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    sortPolicyByID(policy) {
        return policy.sort((a, b) => {
            let A = '';
            let B = '';
            A = a.policyId;
            B = b.policyId;
            return this.compare(A, B, true);
        });
    }

    static cannotContainSpace(value) {
        if (/\s/.test(value)) {
            return (true)
        }
        return (false)
    }

    static validateDoubleByte(objectId) {
        if (/[^\u0000-\u00ff]/.test(objectId)) {
            return (true)
        }
        return (false)
    }

    static fireWarningForAgentSettings(status) {
        let list = ''
        let text0 = this.translation.instant('newlyConnectedPopupMessage1')
        let text1 = ''

        if (status) {
            list = `<ul style='margin-top: 1rem'><li> ${this.translation.instant('rmmSNMPForMfpPrinters')} </li><li> ${this.translation.instant('rmmConnectionSettingForDisplay')} </li><li> ${this.translation.instant('rmmConnectionSettingForTechnical')} </li></ul>`
            text1 = this.translation.instant('newlyConnectedPopupMessage3')
        } else {
            list = `<ul style='margin-top: 1rem'><li> ${this.translation.instant('rmmSNMPForMfpPrinters')} </li><li> ${this.translation.instant('rmmConnectionSettingForDisplay')} </li></ul>`
            text1 = this.translation.instant('newlyConnectedPopupMessage2')
        }

        Swal.fire({
            customClass: {
                container: 'rmm-mailalert',
                confirmButton: 'primary_btn'
            },
            title: this.translation.instant('newlyConnectedPopupTitle'),
            html: "<div style='font-family: Roboto,Helvetica Neue,sans-serif;font-size: 16px !important;font-weight: 500 !important;line-height: 24px !important;text-align: left !important;'>" + text0 + list + text1 + "</div>",
            confirmButtonText: "<div style='font-family: Roboto,Helvetica Neue,sans-serif;font-size: 15px !important;font-weight: 500 !important;line-height: 24px !important;'>" + this.translation.instant('ok') + "</div>",
            width: 400,
            confirmButtonColor: '#12a7bb'
        }).then((result) => {
            if (result) {
                this.emitAgentStatus.emit('success');
            }
        });
    }
    static timeList12 = [
        { id: 0, key: 12, abbriviation: 'AM' },
        { id: 1, key: 1, abbriviation: 'AM' },
        { id: 2, key: 2, abbriviation: 'AM' },
        { id: 3, key: 3, abbriviation: 'AM' },
        { id: 4, key: 4, abbriviation: 'AM' },
        { id: 5, key: 5, abbriviation: 'AM' },
        { id: 6, key: 6, abbriviation: 'AM' },
        { id: 7, key: 7, abbriviation: 'AM' },
        { id: 8, key: 8, abbriviation: 'AM' },
        { id: 9, key: 9, abbriviation: 'AM' },
        { id: 10, key: 10, abbriviation: 'AM' },
        { id: 11, key: 11, abbriviation: 'AM' },
        { id: 12, key: 12, abbriviation: 'PM' },
        { id: 13, key: 1, abbriviation: 'PM' },
        { id: 14, key: 2, abbriviation: 'PM' },
        { id: 15, key: 3, abbriviation: 'PM' },
        { id: 16, key: 4, abbriviation: 'PM' },
        { id: 17, key: 5, abbriviation: 'PM' },
        { id: 18, key: 6, abbriviation: 'PM' },
        { id: 19, key: 7, abbriviation: 'PM' },
        { id: 20, key: 8, abbriviation: 'PM' },
        { id: 21, key: 9, abbriviation: 'PM' },
        { id: 22, key: 10, abbriviation: 'PM' },
        { id: 23, key: 11, abbriviation: 'PM' }
    ]

    static minList = [
        { key: 0, display: '00' },
        { key: 1, display: '01' },
        { key: 2, display: '02' },
        { key: 3, display: '03' },
        { key: 4, display: '04' },
        { key: 5, display: '05' },
        { key: 6, display: '06' },
        { key: 7, display: '07' },
        { key: 8, display: '08' },
        { key: 9, display: '09' },
        { key: 10, display: '10' },
        { key: 11, display: '11' },
        { key: 12, display: '12' },
        { key: 13, display: '13' },
        { key: 14, display: '14' },
        { key: 15, display: '15' },
        { key: 16, display: '16' },
        { key: 17, display: '17' },
        { key: 18, display: '18' },
        { key: 19, display: '19' },
        { key: 20, display: '20' },
        { key: 21, display: '21' },
        { key: 22, display: '22' },
        { key: 23, display: '23' },
        { key: 24, display: '24' },
        { key: 25, display: '25' },
        { key: 26, display: '26' },
        { key: 27, display: '27' },
        { key: 28, display: '28' },
        { key: 29, display: '29' },
        { key: 30, display: '30' },
        { key: 31, display: '31' },
        { key: 32, display: '32' },
        { key: 33, display: '33' },
        { key: 34, display: '34' },
        { key: 35, display: '35' },
        { key: 36, display: '36' },
        { key: 37, display: '37' },
        { key: 38, display: '38' },
        { key: 39, display: '39' },
        { key: 40, display: '40' },
        { key: 41, display: '41' },
        { key: 42, display: '42' },
        { key: 43, display: '43' },
        { key: 44, display: '44' },
        { key: 45, display: '45' },
        { key: 46, display: '46' },
        { key: 47, display: '47' },
        { key: 48, display: '48' },
        { key: 49, display: '49' },
        { key: 50, display: '50' },
        { key: 51, display: '51' },
        { key: 52, display: '52' },
        { key: 53, display: '53' },
        { key: 54, display: '54' },
        { key: 55, display: '55' },
        { key: 56, display: '56' },
        { key: 57, display: '57' },
        { key: 58, display: '58' },
        { key: 59, display: '59' }
    ]

    // minutes limit changed as per ticket #20753
    static minListPrintJobs = [
        { key: 1, display: '01' },
        { key: 2, display: '02' },
        { key: 3, display: '03' },
        { key: 4, display: '04' },
        { key: 5, display: '05' },
        { key: 6, display: '06' },
        { key: 7, display: '07' },
        { key: 8, display: '08' },
        { key: 9, display: '09' },
        { key: 10, display: '10' },
        { key: 11, display: '11' },
        { key: 12, display: '12' },
        { key: 13, display: '13' },
        { key: 14, display: '14' },
        { key: 15, display: '15' },
        { key: 16, display: '16' },
        { key: 17, display: '17' },
        { key: 18, display: '18' },
        { key: 19, display: '19' },
        { key: 20, display: '20' },
        { key: 21, display: '21' },
        { key: 22, display: '22' },
        { key: 23, display: '23' },
        { key: 24, display: '24' },
        { key: 25, display: '25' },
        { key: 26, display: '26' },
        { key: 27, display: '27' },
        { key: 28, display: '28' },
        { key: 29, display: '29' },
        { key: 30, display: '30' },
        { key: 31, display: '31' },
        { key: 32, display: '32' },
        { key: 33, display: '33' },
        { key: 34, display: '34' },
        { key: 35, display: '35' },
        { key: 36, display: '36' },
        { key: 37, display: '37' },
        { key: 38, display: '38' },
        { key: 39, display: '39' },
        { key: 40, display: '40' },
        { key: 41, display: '41' },
        { key: 42, display: '42' },
        { key: 43, display: '43' },
        { key: 44, display: '44' },
        { key: 45, display: '45' },
        { key: 46, display: '46' },
        { key: 47, display: '47' },
        { key: 48, display: '48' },
        { key: 49, display: '49' },
        { key: 50, display: '50' },
        { key: 51, display: '51' },
        { key: 52, display: '52' },
        { key: 53, display: '53' },
        { key: 54, display: '54' },
        { key: 55, display: '55' },
        { key: 56, display: '56' },
        { key: 57, display: '57' },
        { key: 58, display: '58' },
        { key: 59, display: '59' },
        { key: 60, display: '60' }
    ]

    static fireWarningForCloningExecution(title, text1, text2) {
        Swal.fire({
            customClass: {
                container: 'rmm-mailalert',
                confirmButton: 'primary_btn'
            },
            html: "<div style='font-family: Roboto,Helvetica Neue,sans-serif;font-size: 16px !important;font-weight: 500 !important;line-height: 24px !important;text-align: left !important;'>" + this.translation.instant(text1) + "<br><br>" + this.translation.instant(text2) + "</div>",
            confirmButtonText: "<div style='font-family: Roboto,Helvetica Neue,sans-serif;font-size: 15px !important;font-weight: 500 !important;line-height: 24px !important;'>" + this.translation.instant('ok') + "</div>",
            width: 400,
            confirmButtonColor: '#12a7bb',
            allowOutsideClick: true,
            allowEnterKey: true

        }).then((result) => {
        });
    }
    // Used For Remote FSS Setup Execution Alert
    static fireWarningForFSSSetupExecution(title, text1, text2) {
        Swal.fire({
            customClass: {
                container: 'rmm-mailalert',
                confirmButton: 'primary_btn'
            },
            html: "<div style='font-family: Roboto,Helvetica Neue,sans-serif;font-size: 16px !important;font-weight: 500 !important;line-height: 24px !important;text-align: left !important;'>" + this.translation.instant(text1) + "<br><br>" + this.translation.instant(text2) + "</div>",
            confirmButtonText: "<div style='font-family: Roboto,Helvetica Neue,sans-serif;font-size: 15px !important;font-weight: 500 !important;line-height: 24px !important;'>" + this.translation.instant('ok') + "</div>",
            width: 500,
            confirmButtonColor: '#12a7bb',
            allowOutsideClick: true,
            allowEnterKey: true

        }).then((result) => {
        });
    }

     //Added for RMM snackbar
     openSnackBarMessage(message, action) {
        let config = new MatSnackBarConfig();
        config.duration = 5000;
        this._snackBar.open(message, action, {duration:5000});
    }

    static fireWarningRequestFWUpdate(title, text) {

        Swal.fire({
            customClass: {
                container: 'rmm-mailalert',
                confirmButton: 'primary_btn'
            },
            html: "<div style='font-family: Roboto,Helvetica Neue,sans-serif;font-size: 16px !important;font-weight: 500 !important;line-height: 24px !important;'>" + this.translation.instant(text) + "</div>",
            confirmButtonText: "<div id='rmm-misc-util-ok-button' style='font-family: Roboto,Helvetica Neue,sans-serif;font-size: 15px !important;font-weight: 500 !important;line-height: 24px !important;'>" + this.translation.instant('close') + "</div>",
            width: 400,
            confirmButtonColor: '#12a7bb',
            allowOutsideClick: true,
            allowEnterKey: true

        }).then((result) => {
            
        });
    }
}
