import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import {Injectable} from '@angular/core';

@Injectable()
export class ScpCustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    super();
    this.translate.onLangChange.subscribe((e: Event) => {
      this.getTranslations();
    });
    this.getTranslations();
  }

  getTranslations() {
    this.translate.get(['ItemsPerPage', 'NextPage', 'PreviousPage', 'FirstPage', 'LastPage']).subscribe(translation => {
        this.itemsPerPageLabel = translation['ItemsPerPage']+":";
        this.nextPageLabel = translation['NextPage'];
        this.previousPageLabel = translation['PreviousPage'];
        this.firstPageLabel = translation['FirstPage'];
        this.lastPageLabel = translation['LastPage'];
        this.getRangeLabel = this.rangeLabel;
        this.changes.next();
      });
  }

  rangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) { return `0 ${this.translate.instant('RangeLabel')} ${length}`; }
    
    length = Math.max(length, 0);
  
    const startIndex = page * pageSize;
  
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
  
    return `${startIndex + 1} - ${endIndex} ${this.translate.instant('RangeLabel')} ${length}`;
  }

}