import { Injectable } from '@angular/core';

const TOKEN_AUTH_KEY = 'AuthToken';
const TOKEN_USER_KEY = 'UserId';
const TENANT_NAME = 'TENANT_NAME';
const TENANT_VALUE = 'TENANT_VALUE';
const USERNAME = 'USERNAME';
const PASSWORD = 'PASSWORD';
const REMEMBER = 'REMEMBER';
const PAGE_SIZE = 'PAGE_SIZE';
const ISLOGGEDIN = 'isLoggedIn';
const IS_EULA_ACCEPTED = 'isEulaAccepted';
const RMP_MARVEL_SERVICE = 'service';
const CURRENT_URL = 'currentUrl';
const MVL_PAGE_SIZE = 'MVL_PAGE_SIZE';
const ADMIN_PAGE_SIZE = 'ADMIN_PAGE_SIZE';
const IS_ROOM_CANCELLATION_ACCEPTED = 'isRoomCancellationAccepted';
const IS_USER_CANCELLATION_ACCEPTED = 'isUserCancellationAccepted';
const USER_ENTITLEMENT_STATUS = 'userEntitlementStatus';
const ROOM_ENTITLEMENT_STATUS = 'roomEntitlementStatus';
const PREVIOUSTENANT = 'previousTenant';
const WSDETAILSPREVIOUSURL = 'wsDetailsPreviousUrl';

const errorUrls = [
    "/callback", "/error", "/access-denied", "/not-found", "/auth0_error"
]

@Injectable({
    providedIn: 'root'
})
export class TokenStorage {
    constructor() { }

    signOut() {
        window.sessionStorage.removeItem(TOKEN_AUTH_KEY);
        window.sessionStorage.removeItem(TOKEN_USER_KEY);
        window.sessionStorage.removeItem(TENANT_NAME);
        window.sessionStorage.removeItem(TENANT_VALUE);
        window.sessionStorage.clear();
    }

    public saveToken(token: any) {
        window.sessionStorage.removeItem(TOKEN_AUTH_KEY);
        window.sessionStorage.removeItem(TOKEN_USER_KEY);
        window.sessionStorage.setItem(TOKEN_AUTH_KEY, token.sessionId);
        window.sessionStorage.setItem(TOKEN_USER_KEY, token.userId);
    }

    public getToken(): string {
        return 'e9af54a7-c8a9-4e8d-aac8-29c5afa1e9a6';
    }

    public getWsDetailsPrevUrl(): string {
        return window.sessionStorage.getItem(WSDETAILSPREVIOUSURL);
    }

    public setWsDetailsPrevUrl(url: string) {
        window.sessionStorage.setItem(WSDETAILSPREVIOUSURL, url);
    }

    public getUsedId(): string {
        return 'd4056e31-e6cc-4c4f-8a29-4e18e6b8082b';
    }

    public setTenant(tenant: any) {
        localStorage.removeItem(TENANT_NAME);
        localStorage.removeItem(TENANT_VALUE);
        localStorage.setItem(TENANT_NAME, tenant.tenantName);
        localStorage.setItem(TENANT_VALUE, tenant.tenantValue);
    }

    public getTenantName(): string {
        return localStorage.getItem(TENANT_NAME);
    }

    public getTenantValue(): string {
        return 'https://dev-bssol03.to.ideep.com';
        // return localStorage.getItem(TENANT_VALUE);
    }

    public saveUserDetail(userDetail: any) {
        localStorage.setItem(USERNAME, userDetail.userName);
        localStorage.setItem(PASSWORD, btoa(userDetail.passWord));
    }

    public setRememberMe(remember) {
        localStorage.setItem(REMEMBER, remember);
    }

    public setPreviousTenant(previousTenant) {
        localStorage.setItem(PREVIOUSTENANT, previousTenant);
    }

    public getPreviousTenant() {
        return localStorage.getItem(PREVIOUSTENANT);
    }
    public getUserName() {
        return localStorage.getItem(USERNAME);
    }
    public getPassWord() {
        return localStorage.getItem(PASSWORD);
    }
    public getRememberMe() {
        return localStorage.getItem(REMEMBER);
    }

    public setPageSize(pageSize: number, gridName: string) {
        const getItem = localStorage.getItem(PAGE_SIZE);
        if (getItem && typeof JSON.parse(getItem) == 'object') {
            const jsonObj = JSON.parse(getItem);
            if (jsonObj.hasOwnProperty(gridName)) {
                jsonObj[gridName]['pageSize'] = pageSize;
            } else { jsonObj[gridName] = { 'pageSize': pageSize }; }
            localStorage.setItem(PAGE_SIZE, JSON.stringify(jsonObj));
        } else {
            const jsonObj = { [gridName]: { 'pageSize': pageSize } };
            localStorage.setItem(PAGE_SIZE, JSON.stringify(jsonObj));
        }
    }
    public getPageSize(gridName: string): number {
        const getItem = localStorage.getItem(PAGE_SIZE);
        if (getItem != null) {
            const jsonObj = JSON.parse(getItem);
            if (jsonObj[gridName] != null) {
                return jsonObj[gridName]['pageSize'];
            } else { return null; }
        } else { return null; }
    }
    // Maintaining the page size for marvel pages
    public setMvlPageSize(pageSize: number, gridName: string) {
        const getItem = localStorage.getItem(MVL_PAGE_SIZE);
        if (getItem && typeof JSON.parse(getItem) == 'object') {
            const jsonObj = JSON.parse(getItem);
            if (jsonObj.hasOwnProperty(gridName)) {
                jsonObj[gridName]['pageSize'] = pageSize;
            } else { jsonObj[gridName] = { 'pageSize': pageSize }; }
            localStorage.setItem(MVL_PAGE_SIZE, JSON.stringify(jsonObj));
        } else {
            const jsonObj = { [gridName]: { 'pageSize': pageSize } };
            localStorage.setItem(MVL_PAGE_SIZE, JSON.stringify(jsonObj));
        }
    }

    public getMvlPageSize(gridName: string): number {
        const getItem = localStorage.getItem(MVL_PAGE_SIZE);
        if (getItem != null) {
            const jsonObj = JSON.parse(getItem);
            if (jsonObj[gridName] != null) {
                return jsonObj[gridName]['pageSize'];
            } else { return null; }
        } else { return null; }
    }

    // Maintaining the page size for marvel pages
    public setAdminPageSize(pageSize: number, gridName: string) {
        const getItem = localStorage.getItem(ADMIN_PAGE_SIZE);
        if (getItem && typeof JSON.parse(getItem) == 'object') {
            const jsonObj = JSON.parse(getItem);
            if (jsonObj.hasOwnProperty(gridName)) {
                jsonObj[gridName]['pageSize'] = pageSize;
            } else { jsonObj[gridName] = { 'pageSize': pageSize }; }
            localStorage.setItem(ADMIN_PAGE_SIZE, JSON.stringify(jsonObj));
        } else {
            const jsonObj = { [gridName]: { 'pageSize': pageSize } };
            localStorage.setItem(ADMIN_PAGE_SIZE, JSON.stringify(jsonObj));
        }
    }

    public getAdminPageSize(gridName: string): number {
        const getItem = localStorage.getItem(ADMIN_PAGE_SIZE);
        if (getItem != null) {
            const jsonObj = JSON.parse(getItem);
            if (jsonObj[gridName] != null) {
                return jsonObj[gridName]['pageSize'];
            } else { return null; }
        } else { return null; }
    }

    public setLoggedInStatus(isLoggedIn: boolean) {
        localStorage.setItem(ISLOGGEDIN, isLoggedIn.toString());
    }

    public getLoggedInStatus(): boolean {
        return localStorage.getItem(ISLOGGEDIN) === 'true' ? true : false;
    }
    public setEulaAccepted(accepted: boolean) {
        localStorage.setItem(IS_EULA_ACCEPTED, accepted.toString());
    }

    public getEulaAccepted(): boolean {
        return localStorage.getItem(IS_EULA_ACCEPTED) === 'true';
    }

    public setRoomCancellationAccepted(accepted: boolean) {
        localStorage.setItem(IS_ROOM_CANCELLATION_ACCEPTED, accepted.toString());
    }

    public getRoomCancellationAccepted(): boolean {
        return localStorage.getItem(IS_ROOM_CANCELLATION_ACCEPTED) === 'true';
    }

    public setUserCancellationAccepted(accepted: boolean) {
        localStorage.setItem(IS_USER_CANCELLATION_ACCEPTED, accepted.toString());
    }

    public getUserCancellationAccepted(): boolean {
        return localStorage.getItem(IS_USER_CANCELLATION_ACCEPTED) === 'true';
    }

    public setUserEntitlementStatus(status: boolean) {
        localStorage.setItem(USER_ENTITLEMENT_STATUS, status.toString());
    }

    public getUserEntitlementStatus(): boolean {
        return localStorage.getItem(USER_ENTITLEMENT_STATUS) === 'true';
    }

    public setRoomEntitlementStatus(status: boolean) {
        localStorage.setItem(ROOM_ENTITLEMENT_STATUS, status.toString());
    }

    public getRoomEntitlementStatus(): boolean {
        return localStorage.getItem(ROOM_ENTITLEMENT_STATUS) === 'true';
    }
    public setMarvelRMPService(serviceValue: string) {
        localStorage.setItem(RMP_MARVEL_SERVICE, serviceValue);
    }

    public getMarvelRMPService() {
        return localStorage.getItem(RMP_MARVEL_SERVICE);
    }

    public setCurrentUrl(url) {
        if (!errorUrls.includes(url)) {
            sessionStorage.setItem(CURRENT_URL, url);
        }
    }

    public getCurrentUrl() {
        return sessionStorage.getItem(CURRENT_URL);
    }

    public removeSession(name) {
        window.sessionStorage.removeItem(name);
    }
}
