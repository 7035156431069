import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';
import { DateTime } from 'luxon'

const dmyFormat1 = 'd.M.yyyy';
const dmyFormat2 = 'd/M/yyyy';
const dmyFormat3 = 'd-M-yyyy';
const ymdFormat = 'yyyy-M-d';
const mdyFormat = 'M/d/yyyy';
const ddmmyFormat = 'dd.MM.yyyy';
const ddmmyFormat1 = 'dd/MM/yyyy'

const dateFormats = {
    'en': mdyFormat,
    'da': ddmmyFormat,
    'de': ddmmyFormat,
    'fi': ddmmyFormat,
    'nb': ddmmyFormat,
    'pl': ddmmyFormat,
    'es': ddmmyFormat1,
    'fr': ddmmyFormat1,
    'it': ddmmyFormat1,
    'nl': ddmmyFormat,
    'sv': ymdFormat,
    'en-GB': dmyFormat2,
};


const offsetToTimezone = {
    "UTC+00:00": "Africa/Abidjan",
    "UTC+01:00": "Etc/GMT-1",
    "UTC+02:00": "Etc/GMT-2",
    "UTC+03:00": "Etc/GMT-3",
    "UTC+04:00": "Etc/GMT-4",
    "UTC+05:00": "Etc/GMT-5",
    "UTC+06:00": "Etc/GMT-6",
    "UTC+07:00": "Etc/GMT-7",
    "UTC+08:00": "Etc/GMT-8",
    "UTC+09:00": "Etc/GMT-9",
    "UTC+10:00": "Etc/GMT-10",
    "UTC+11:00": "Etc/GMT-11",
    "UTC+12:00": "Etc/GMT-12",
    "UTC+13:00": "Etc/GMT-13",
    "UTC+14:00": "Etc/GMT-14",
    "UTC-01:00": "Etc/GMT+1",
    "UTC-02:00": "Etc/GMT+2",
    "UTC-03:00": "Etc/GMT+3",
    "UTC-04:00": "Etc/GMT+4",
    "UTC-05:00": "Etc/GMT+5",
    "UTC-06:00": "Etc/GMT+6",
    "UTC-07:00": "Etc/GMT+7",
    "UTC-08:00": "Etc/GMT+8",
    "UTC-09:00": "Etc/GMT+9",
    "UTC-10:00": "Etc/GMT+10",
    "UTC-11:00": "Etc/GMT+11",
    "UTC-12:00": "Etc/GMT+12",
    "UTC-09:30": "Pacific/Marquesas",
    "UTC+04:30": "Asia/Kabul",
    "UTC+06:30": "Asia/Yangon",
    "UTC+05:30": "Asia/Calcutta",
    "UTC+09:30": "Australia/Adelaide"
};

@Injectable({
    providedIn: 'root'
})
export class DateTimeTimezoneService {
    private browserLang = 'en';
    private language24Format = true;
    private dateFormat = mdyFormat;
    constructor(private translateService: TranslateService) {
        this.browserLang = this.getLanguage();
        this.dateFormat = dateFormats[this.browserLang];
        if (this.browserLang !== 'en' && this.browserLang !== 'en-GB') {
            this.language24Format = true
        } else {
            this.language24Format = false
        }
    }

    getLanguage(): any {
        let lang = this.translateService.getBrowserLang();
        const langCul = this.translateService.getBrowserCultureLang();
        let supportedLanguages = ['en'];
        if (environment.stackRegion == 'EU') {
            // Change the list of supported languages for EU stacks
            supportedLanguages = ['da', 'de', 'en-GB', 'es', 'fi', 'fr', 'it', 'nl', 'nb', 'pl', 'sv'];
        }
        // All norwegian cultures default to 'nb'
        if (['nb', 'no', 'nn'].includes(lang)) {
            lang = 'nb';
        }
        // All spanish languages default to 'es'
        const spanishLangCode = ['ca', 'es'];
        if (spanishLangCode.includes(lang)) {
            lang = 'es';
        }
        let result = 'en';
        if (supportedLanguages.includes(langCul)) {
            // If language+culture combo is in supported list use it
            result = langCul;
        } else if (supportedLanguages.includes(lang)) {
            // If language (culture independent) is in supported list use it
            result = lang;
        }
        return result;
    }

    getTimeStamp({ date, month, year, hours, minutes, UTC }): any {
        const dateObj = new Date(Date.UTC(year, month, date, 0, 0, 0));
        let offsethours = Number(UTC.substr(4, 2));
        let offsetminutes = Number(UTC.substr(7, 2));

        if (UTC.substr(3, 1) == "-") {

            dateObj.setMinutes(
                dateObj.getMinutes() + (offsethours * 60 + offsetminutes)
            );
            dateObj.setMinutes(dateObj.getMinutes() + hours * 60 + minutes);
            dateObj.setDate(dateObj.getDate());
        } else {
            dateObj.setMinutes(
                dateObj.getMinutes() - (offsethours * 60 + offsetminutes)
            );
            dateObj.setMinutes(dateObj.getMinutes() + hours * 60 + minutes);
        }
        return dateObj.getTime();
    }

    getDateFromTimeStamp(timestamp) {
        let d = new Date(timestamp);
        let date = d.getDate();
        let month = d.getMonth() + 1;
        let year = d.getFullYear();
        switch (this.dateFormat.toUpperCase()) {
            case 'D.M.YYYY':
                return `${date}.${month}.${year}`
            case 'D/M/YYYY':
                return `${date}/${month}/${year}`
            case 'D-M-YYYY':
                return `${date}-${month}-${year}`
            case 'YYYY-M-D':
                return `${year}-${month}-${date}`
            case 'M/D/YYYY':
                return `${month}/${date}/${year}`
            case 'DD.MM.YYYY':
                return `${String(date).padStart(2, "0")}.${String(month).padStart(2, "0")}.${year}`
            case 'DD/MM/YYYY':
                return `${String(date).padStart(2, "0")}/${String(month).padStart(2, "0")}/${year}`
        }
    }

    getDateAndTimeFromTimeStamp(timestamp){
        let result;
        if (timestamp == '' || timestamp == undefined || timestamp == '-') {
            return '-';
        }
        let date = new Date(timestamp)
        result = this.getDateFromTimeStamp(timestamp);
        if(this.language24Format){
            let hours = String(date.getHours()).padStart(2,"0");
            let minutes = String(date.getMinutes()).padStart(2,"0");
            result = result  + " " +hours+":"+minutes
        }else{
            let hours = date.getHours();
            let minutes = String(date.getMinutes()).padStart(2, "0");
            let AMPM = hours >= 12 ? "PM" : "AM"
            if (hours == 0) hours = 12;
            if (hours > 12) hours -= 12;
            result = result + " " + hours + ":" + minutes + " " + AMPM
        }
        return result;
    }

    getTimeZone() {
        let date = new Date();
        let offSetState = false
        let finalUTC = ''
        let offset = date.getTimezoneOffset()
        // console.log(offset)
        if (offset < 0 || offset == 0) {
            offSetState = true
            offset = Math.abs(offset)
        }
        // let offset = this.roundOffTimezone(date.getTimezoneOffset());
        // console.log(offset, this.roundOffTimezone(date.getTimezoneOffset()))
        let hours = String(Math.trunc(offset / 60)).padStart(2, '0');
        let minutes = String(offset % 60).padStart(2, '0');

        if (offSetState) {
            finalUTC = 'UTC+' + hours + ':' + minutes
        } else {
            finalUTC = 'UTC-' + hours + ':' + minutes
        }
        // console.log('-------->'+finalUTC)

        return finalUTC
    }

    getDateFromTimeStampWithUTC(timestamp, UTC) {
        let { date, month, year } = this.decryptTimestamp(timestamp, UTC, this.language24Format)

        date = parseInt(date)
        month = parseInt(month)
        year = parseInt(year)

        switch (this.dateFormat.toUpperCase()) {
            case 'D.M.YYYY':
                return `${date}.${month}.${year}`
            case 'D/M/YYYY':
                return `${date}/${month}/${year}`
            case 'D-M-YYYY':
                return `${date}-${month}-${year}`
            case 'YYYY-M-D':
                return `${year}-${month}-${date}`
            case 'M/D/YYYY':
                return `${month}/${date}/${year}`
            case 'DD.MM.YYYY':
                return `${String(date).padStart(2, "0")}.${String(month).padStart(2, "0")}.${year}`
            case 'DD/MM/YYYY':
                return `${String(date).padStart(2, "0")}/${String(month).padStart(2, "0")}/${year}`
        }
    }

    getDateFromStr(dateStr) {
        let date, month, year;
        switch (this.dateFormat.toUpperCase()) {
            case 'D.M.YYYY':
                [date, month, year] = dateStr.split(".")
                break;
            case 'D/M/YYYY':
                [date, month, year] = dateStr.split("/")
                break;
            case 'D-M-YYYY':
                [date, month, year] = dateStr.split("-")
                break;
            case 'YYYY-M-D':
                [year, month, date] = dateStr.split("-")
                break;
            case 'M/D/YYYY':
                [month, date, year] = dateStr.split("/")
                break;
            case 'DD.MM.YYYY':
                [date, month, year] = dateStr.split(".")
                break;
            case 'DD/MM/YYYY':
                [date, month, year] = dateStr.split("/")
                break;
        }
        return new Date(`${month}/${date}/${year}`)
    }

    decryptTimestamp(timeStamp, UTC, hrFormat): any {
        const d = new Date(timeStamp);
        let timeFormat = '12'

        if (hrFormat) {
            timeFormat = '24'
        }

        let formattedDateStr = new Intl.DateTimeFormat(
            timeFormat == "12" ? "en-us" : "en-GB",
            {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                timeZone: offsetToTimezone[UTC]
            }
        ).format(d);
        let [dateStr, timeStr] = formattedDateStr.split(",");
        timeStr = timeStr.trim();
        let date, month, year, hours, minutes, rest, AMPM;
        // DATE GRAB
        if (timeFormat == "12") {
            [month, date, year] = dateStr.split("/");

            // checking individual month and date if it's less then 10 and if they contain preceeding 0, then removing it.

            if (Number(month) < 10) {
                if (month.startsWith("0", 0)) {
                    month = month.substring(1);
                }
            }

            if (Number(date) < 10) {
                if (date.startsWith("0", 0)) {
                    date = date.substring(1);
                }
            }
        } else {
            [date, month, year] = dateStr.split("/");
        }
        // TIME GRAB
        if (timeFormat == "12") {
            [hours, rest] = timeStr.split(":");
            [minutes, AMPM] = rest.split(" ");
        } else {
            [hours, minutes] = timeStr.split(":");
        }
        if (timeFormat == "12") return { date, month, year, hours, minutes, AMPM };
        else return { date, month, year, hours, minutes };
    }

    formatDateTimeLocaly(date: any) {
        if (this.browserLang == 'en' || this.browserLang == 'en-GB') {
            const englishDateTimeFormat = typeof date == 'number' ? DateTime.fromMillis(date).toFormat(this.dateFormat + ' ' + 'h:mm a') : DateTime.fromJSDate(date).toFormat(this.dateFormat + ' ' + 'h:mm a')
            return englishDateTimeFormat;
        }
        // Display date time in 24 hr format for 'da', 'de', 'es', 'fi', 'fr', 'it', 'nl', 'nb', 'pl', 'sv' Language
        const formatedDateTime = typeof date == 'number' ? DateTime.fromMillis(date).toFormat(this.dateFormat + ' ' + 'HH:mm') : DateTime.fromJSDate(date).toFormat(this.dateFormat + ' ' + 'HH:mm')
        return formatedDateTime.toString();
    }

    formatDateLocaly(date: any) {
        const formatedDateTime = typeof date == 'number' ? DateTime.fromMillis(date).setZone('UTC').toFormat(this.dateFormat) : DateTime.fromJSDate(date).setZone('UTC').toFormat(this.dateFormat)
        return formatedDateTime.toString();
    }

    // to check data coming for date format is valid or not
    // if not valid,we are returning blank
    // else if valid we are calling date format conversion function

    checkDate(date: any) {
        if (date == '' || date == null || date == 'null' || date == undefined || date == 'undefined' || date == 0) {
            return "";
        } else {
            let formatedDateTime = this.formatDateTimeLocaly(date);
            let lang:any = this.translateService.getBrowserLang();
            if (lang != 'en' || lang != 'en-GB') {
                return formatedDateTime;
            } else {
                let time = formatedDateTime.split(" ");
                let formatted = time[0] + " " + time[1] + " " + this.translateService.instant(time[2].toLowerCase())
                return formatted;
            }
        }
        }


    // formats to the required date format
    parseDateSubscriptions(date: string) {
        const formatedDate = DateTime.fromISO(date).toFormat(this.dateFormat);
        return formatedDate;
    }

    //To get the date and time from time stamp according to the timezone for the Edit schedules in getdata
    getDateAndTimeFromTimeStampWithUTC(timestamp, UTC) {
        let { date, month, year, hours, minutes, AMPM } = this.decryptTimestamp(timestamp, UTC, this.language24Format)
        date = parseInt(date)
        month = parseInt(month)
        year = parseInt(year)

        switch (this.dateFormat.toUpperCase()) {
            case 'D.M.YYYY':
                return { date: `${date}.${month}.${year}`, timeHr: hours, timeMnt: minutes, amPm: AMPM }
            case 'D/M/YYYY':
                return { date: `${date}/${month}/${year}`, timeHr: hours, timeMnt: minutes, amPm: AMPM }
            case 'D-M-YYYY':
                return { date: `${date}-${month}-${year}`, timeHr: hours, timeMnt: minutes, amPm: AMPM }
            case 'YYYY-M-D':
                return { date: `${year}-${month}-${date}`, timeHr: hours, timeMnt: minutes, amPm: AMPM }
            case 'M/D/YYYY':
                return { date: `${month}/${date}/${year}`, timeHr: hours, timeMnt: minutes, amPm: AMPM }
            case 'DD.MM.YYYY':
                return { date: `${String(date).padStart(2, "0")}.${String(month).padStart(2, "0")}.${year}`, timeHr: hours, timeMnt: minutes, amPm: AMPM }
            case 'DD/MM/YYYY':
                return { date: `${String(date).padStart(2, "0")}/${String(month).padStart(2, "0")}/${year}`, timeHr: hours, timeMnt: minutes, amPm: AMPM }
        }
    }

    //To get the date from string that have date and time for edit schedules in getdata
    getDateTimeFromStr(dateStr, time) {
        let date, month, year;
        switch (this.dateFormat.toUpperCase()) {
            case 'D.M.YYYY':
                [date, month, year] = dateStr.split(".")
                break;
            case 'D/M/YYYY':
                [date, month, year] = dateStr.split("/")
                break;
            case 'D-M-YYYY':
                [date, month, year] = dateStr.split("-")
                break;
            case 'YYYY-M-D':
                [year, month, date] = dateStr.split("-")
                break;
            case 'M/D/YYYY':
                [month, date, year] = dateStr.split("/")
                break;
            case 'DD.MM.YYYY':
                [date, month, year] = dateStr.split(".")
                break;
            case 'DD/MM/YYYY':
                [date, month, year] = dateStr.split("/")
                break;
        }
        return new Date(`${month}/${date}/${year}` + ' ' + time)
    }

    // convert the 24 hrs Time String to Number for sorting.
    convertTimeStringToMinutes(timeString: string): number {
        const [hr, minutes] = timeString.split(':').map(Number);
        return hr * 60 + minutes;
    }

    // below function is used for getTimeValue with Number from a timeString.
    getTimeValue(timeString: string): number {
        const [time, period] = timeString.split(' ');
        const [hours, minutes] = time.split(':');
        let hoursValue = parseInt(hours, 10);

        if (period == 'PM' && hoursValue !== 12) {
            hoursValue += 12;
        } else if (period == 'AM' && hoursValue == 12) {
            hoursValue = 0;
        }

        return hoursValue * 60 + parseInt(minutes, 10);
    }

    /**
     * @param date parseDateUTC takes date as input, parse to UTC date
     * and formats to the required date format for subscription page
     */
    parseDateUTC(date: any) {
        const formatedDate = DateTime.fromMillis(Number(date), { zone: 'utc' }).toFormat(this.dateFormat);
        return formatedDate;
    }

    /**
    * @param date parseDateLocale takes date and locale code ex: 'es' as input,
    * and formats to the required date format for graph and export
    * url param values
    * @param date calendar date selected
    * @param locale
    */

    parseDateLocale(date: any) {
        if (typeof date == 'string') {
            let dateTime: any = ''
            if (date.includes(".")) {
                dateTime = DateTime.fromFormat(date, 'd.M.yyyy')
            } else if (date.includes("/")) {
                dateTime = this.dateFormat == 'dd/MM/yyyy' ? DateTime.fromFormat(date, 'dd/M/yyyy') :DateTime.fromFormat(date, 'M/d/yyyy')
            } else {
                dateTime = DateTime.fromFormat(date, 'yyyy-M-d')
            }
            // Get the milliseconds
            const milliseconds = dateTime.toMillis();
            return DateTime.fromMillis(milliseconds, this.dateFormat).toFormat('yyyy-MM-dd')
        } else if (typeof date == 'number') {
            return DateTime.fromMillis(date, this.dateFormat).toFormat('yyyy-MM-dd')
        } else {
            return DateTime.fromJSDate(date, this.dateFormat).toFormat('yyyy-MM-dd')
        }
    }

}

//This function assists in selecting the date format according to the chosen language.
function getlanguage(){
    let lang = navigator.language
    let supportedLanguages = ['en'];
    if (environment.stackRegion == 'EU') {
        // Change the list of supported languages for EU stacks
        supportedLanguages = ['da', 'de', 'en-GB', 'es', 'fi', 'fr', 'it', 'nl', 'nb', 'pl', 'sv'];
    }
    // All norwegian cultures default to 'nb'
    if (['nb', 'no', 'nn'].includes(lang)) {
        lang = 'nb';
    }
    // All spanish languages default to 'es'
    const spanishLangCode = ['ca', 'es'];
    if (spanishLangCode.includes(lang)) {
        lang = 'es';
    }
    let result='en'
    if (supportedLanguages.includes(lang)) {
        // If language (culture independent) is in supported list use it
        result = lang;
    }
    return result
}

//this describe the format of date , month and year showed on datepicker
export const datepickerFormat = {
    parse: {
        dateInput: dateFormats[getlanguage()],
    },
    display: {
        dateInput: dateFormats[getlanguage()],
        monthYearLabel: 'MMM yyyy',
        dateA11yLabel: 'DDD',
        monthYearA11yLabel: dateFormats[getlanguage()],
    },
};


