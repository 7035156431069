import { Injectable } from '@angular/core';
// const TOKEN_AUTH_KEY = 'AuthToken';
// const TOKEN_USER_KEY = 'UserId';
// const TENANT_NAME = 'TENANT_NAME';
// const TENANT_VALUE = 'TENANT_VALUE';
// const USERNAME = 'USERNAME';
// const PASSWORD = 'PASSWORD';
// const REMEMBER = 'REMEMBER';
// const PAGE_SIZE = 'PAGE_SIZE';
const ISLOGGEDIN = 'isLoggedIn';
const IS_EULA_ACCEPTED = 'isEulaAccepted';
// const RMP_MARVEL_SERVICE = 'service';
// const CURRENT_URL = 'currentUrl';
// const MVL_PAGE_SIZE = 'MVL_PAGE_SIZE';
// const ADMIN_PAGE_SIZE = 'ADMIN_PAGE_SIZE';
const IS_ROOM_CANCELLATION_ACCEPTED = 'isRoomCancellationAccepted';
const IS_USER_CANCELLATION_ACCEPTED = 'isUserCancellationAccepted';
// const USER_ENTITLEMENT_STATUS = 'userEntitlementStatus';
// const ROOM_ENTITLEMENT_STATUS = 'roomEntitlementStatus';
// const PREVIOUSTENANT = 'previousTenant';
// const WSDETAILSPREVIOUSURL = 'wsDetailsPreviousUrl';

// const errorUrls = [
//     "/callback", "/error", "/access-denied", "/not-found", "/auth0_error"
// ]

@Injectable({
    providedIn: 'root'
})

export class BrowserStorage {

    setLocal(key: string, value: string) {
        window.localStorage.setItem(key, value);
    }

    getLocal(key: string): string {
        return window.localStorage.getItem(key);
    }

    removeLocal(key: string): void {
        window.localStorage.removeItem(key);
    }

    setSession(key: string, value: string) {
        window.sessionStorage.setItem(key, value);
    }

    getSession(key: string): string {
        return window.sessionStorage.getItem(key);
    }

    removeSession(key: string): void {
        window.sessionStorage.removeItem(key);
    }


    public setLoggedInStatus(isLoggedIn: boolean) {
        localStorage.setItem(ISLOGGEDIN, isLoggedIn.toString());
    }

    public getLoggedInStatus(): boolean {
        return localStorage.getItem(ISLOGGEDIN) === 'true' ? true : false;
    }

    public setEulaAccepted(accepted: boolean) {
        localStorage.setItem(IS_EULA_ACCEPTED, accepted.toString());
    }

    public getEulaAccepted(): boolean {
        return localStorage.getItem(IS_EULA_ACCEPTED) === 'true';
    }

    public setRoomCancellationAccepted(accepted: boolean) {
        localStorage.setItem(IS_ROOM_CANCELLATION_ACCEPTED, accepted.toString());
    }

    public getRoomCancellationAccepted(): boolean {
        return localStorage.getItem(IS_ROOM_CANCELLATION_ACCEPTED) === 'true';
    }

    public setUserCancellationAccepted(accepted: boolean) {
        localStorage.setItem(IS_USER_CANCELLATION_ACCEPTED, accepted.toString());
    }

    public getUserCancellationAccepted(): boolean {
        return localStorage.getItem(IS_USER_CANCELLATION_ACCEPTED) === 'true';
    }
}
