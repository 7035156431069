import { Inject, Injectable } from '@angular/core';
import { SCP_ENV, ScpEnv } from './scp-env';

@Injectable({
    providedIn: 'root'
})
export class CloudPrintEnvService {

    private _scpEnv: ScpEnv;

    constructor(@Inject(SCP_ENV) scpEnv: ScpEnv) { this._scpEnv = scpEnv; }

    /**
     * Gets the production flag
     *
     */
    public get production(): boolean {
        return this._scpEnv.production;
    }


    /**
     * Get the base url for SCP Common API
     */
    public get scpCommonApiBaseUrl(): string {
        return this._scpEnv.scpCommonApiBaseUrl;
    }

    /**
    * Get the base url for SSP Rooms/Users API
    */
    public get sspRoomsApi(): string {
        return this._scpEnv.sspRoomsApi;
    }

    /**
     * Get the base url for SSP License API
     */
    public get sspLicenseApi(): string {
        return this._scpEnv.sspLicenseApi;
    }

    /**
     * Get the base url for SSP Marvel API
     */
    public get sspMarvelApi(): string {
        return this._scpEnv.sspMarvelApi;
    }

    /**
     * Get the region where the current stack is located EUDev, EUDev2, EUQA etc.,
     */
    public get stackRegion(): string {
        return this._scpEnv.stackRegion;
    }

    /**
     * Gets the maximum number of users (regular + admin) that can be added per tenant/customer
     */
    public get userLimit(): number {
        return this._scpEnv.userLimit;
    }

    /**
     * Gets the application version.
     */
    public get appVersion(): string {
        return this._scpEnv.appVersion;
    }
}
