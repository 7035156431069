import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { NotificationMessagesComponent } from '../../dialogs/notification-messages/notification-messages.component';
import { TranslateService } from "@ngx-translate/core";


@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(
        public snackBar: MatSnackBar,
        public matDialog: MatDialog,
        private translate: TranslateService
    ) { }

    showNotification(
        message: string,
        interpolateParams?: Object,
        action?: string,
        extraParams?: MatSnackBarConfig
    ) {
        let config = new MatSnackBarConfig();
        if (extraParams) {
            for (let param of Object.keys(extraParams)) {
                config[param] = extraParams[param];
            }
        }

        config.duration = config.duration === 0 ? 5000 : config.duration;

        if (action === undefined) {
            action = this.translate.instant('Ok');
        }
        if (action === 'N/A') {
            action = undefined;
        }
        this.snackBar.openFromComponent(NotificationMessagesComponent, {
            data: {
                message: message,
                interpolateParams: interpolateParams,
                action: action,
            },
            duration: config.duration,
            verticalPosition: config.verticalPosition,
            horizontalPosition: config.horizontalPosition || 'center',
            direction: config.direction,
            panelClass: config.panelClass,
            viewContainerRef: config.viewContainerRef,
            announcementMessage: config.announcementMessage,
            politeness: config.politeness,
        });
    }
}
