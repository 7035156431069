import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { interval, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { TenantService } from '../../shared/services/ssp/tenant.service';
import { TokenStorageService } from '../../shared/services/ssp/token-storage.service';
import { NotificationService } from '../../shared/services/ssp/notification.service';
import { ProgressLoaderService } from '../../shared/services/ssp/progress-loader.service';
import { LogHandlerService } from '../../shared/services/ssp/log-handler.service';
import { LocalAppDataService } from '../../shared/services/local-app-data.service';
import { Office365Token } from '../../shared/interfaces/User';
import { IdpCommonService } from '../../shared/services/ssp/idp-common.service';
import { SspGenericService } from '../../shared/services/ssp/ssp-generic.service';
import { ConfirmationDialogComponent } from '../../shared/dialogs/ssp/confirmation-dialog/confirmation-dialog.component';
import { RemoveDomainDialogComponent } from '../../shared/dialogs/ssp/remove-domain-dialog/remove-domain-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import jwt_decode from 'jwt-decode';
import { GrantPermissionsComponent } from '../admin/grant-permissions/grant-permissions.component';

@Component({
  selector: 'lib-supported-domains',
  templateUrl: './supported-domains.component.html',
  styleUrls: ['./supported-domains.component.scss']
})
export class SupportedDomainsComponent implements OnInit {
    @ViewChild('supportedDomainForm', { static: false }) supportedDomainForm: NgForm;
    domainListFiltered = [];                             // Array of domain filtered domain list used for display
    primaryDomain = '';                                  // primary domain field
    existingDomainAliases = [];                          // existing domain list field
    displayedColumns: string[] = ['Domains', 'Enabled']; // Mat Table Display columns
    dataSource: any[] = [];                              // Mat table dataSource array
    promptURL = window.location.origin + '?prompt=admin_consent';
    //viewDomains: ISupportedDomains;
    domainsList: any = [];
    columnsForNotAccepted: string[] = ['Select', 'Domains', 'Delete']; // Mat Table Display columns
    dataForDomains: MatTableDataSource<any> = new MatTableDataSource<any>([]); //Mat table dataSource array  permissionnotGranted
    public defaultDialogConfig = new MatDialogConfig();
    public isGoogle: boolean;
    public eventType: string;
    public isO365: boolean;
    public isDirectoryReadGranted = false;
    permissionDialogRef: MatDialogRef<GrantPermissionsComponent>;
    public allowedRmmAdminList = ['Service Main', 'Service Support', 'Service View Only'];
    public DeleteButtonDisabled: boolean;
    accessToken: string;
    public isPermissionGranted: boolean = false;
    public initialCardValue = '';
    /** The list of all users, checked in the UI */
    selection = new SelectionModel<any>(true, []);
    public editDomain = false;
    public editedRowId: any;
    public IsAllSelectedProp: boolean = false;
    public deleteButtonDisabled: boolean = true;
    public primaryOrCurrentAdmin: any[] = [];
    eleId: boolean;
    saveButton: boolean;
    Checked: any = [];
    updateTenantlist: any;
    disableAddIcon: any = false;
    lastSelectedIndex: any;
    lastSelectedInputField: any;
    newlyEnteredValue;
    validateandSaveDomain = false;
    tenantInfoAtLogin: any;
    isTokenAvailable: boolean = false;
    ispageLoaded:boolean = false;
    constructor(
        private appDataService: LocalAppDataService,
        public dialog: MatDialog,
        private router: Router,
        private logHandlerService: LogHandlerService,
        private http: HttpClient,
        private tenantService: TenantService,
        private notificationService: NotificationService,
        private idpCommonService: IdpCommonService,
        private sspService: SspGenericService,
        private translate: TranslateService) {
       // this.viewDomains = this.viewManagement.componentStates.SupportedDomains;
        this.isO365 = this.appDataService.ApplicationType === 'waad';
        this.isGoogle = this.appDataService.ApplicationType === 'google-apps';
    }

  ngOnInit(): void {
      this.appDataService.usingAuthInfo().subscribe((token) => {
          if (token) {
              this.accessToken = token;
          }
      }); 
      this.idpCommonService.getIdpToken().subscribe((token) => {
          if (token && !this.isTokenAvailable) {
              this.tenantInfoAtLogin = this.appDataService.TenantInfoAtLogin;
              this.refreshDomain(false);
              this.getDomainAliases();
          }
      }); 
    }

    /*********SUBDOMAINS ********/
    /**** Loading the subDomains Inetially */
    getDomainAliases() {
        this.isTokenAvailable = true;
      this.tenantService.getTenantInfo(this.tenantInfoAtLogin).subscribe((data) => {
          this.ispageLoaded = true;
                let PrimaryDomain = data[0].domain;
                this.domainsList.push({ domain: PrimaryDomain });
                this.updateTenantlist = data[0].domainAliases;
                for (var ele of this.updateTenantlist) {
                this.domainsList.push({ domain: ele, checked: true, enableDelete: true, id: this.updateTenantlist.indexOf(ele), enableDeleteButton: true });
                }
                this.disableAddIcon = false;
                this.dataForDomains.data = this.domainsList;
            });
    }

    // ading The New Row In UI.
    addRow() {
        //clearing the all selected check boxes and disabling the remove domain alias button while clicking the add domain alias button (+).
        this.selection.clear()
        this.deleteButtonDisabled = true;
        this.IsAllSelectedProp = false;
        if (this.dataForDomains.data.length <= 10) {
            this.disableAddIcon = true;
            this.domainsList.push({ domain: '', checked: true, enableDelete: true, id: this.dataForDomains.data.length + 1, enableDeleteButton: true });
            this.dataForDomains.data = this.domainsList;
            this.dataForDomains.filter = "";
        } else {
            this.disableAddIcon = false;
            this.notificationService.showNotification(`LimitExceeded`);
        }
    }

    /** Removing the selected Domains */
    removeSelectedDomain() {
        this.deleteButtonDisabled = true;
        const domainAliase = this.primaryOrCurrentAdmin.filter((ele) => ele.checked).map((ele) => ele.domain);
        if (domainAliase.length === 1 && domainAliase.includes('')) {
            this.loadData()
            this.selection.clear();
            this.deleteButtonDisabled = true;
            return;
        }

        this.defaultDialogConfig.data = {
            title: [this.translate.instant('RemoveDomain')],
            message: (this.translate.instant('ConfirmDomainDeletion'))
        };
        const confirmDialogRef = this.dialog.open(RemoveDomainDialogComponent, this.defaultDialogConfig);
        confirmDialogRef.afterClosed().subscribe((result) => {
            if (result === 1) {
                let updateDomain = this.dataForDomains.filteredData.filter(function (preData) {
                    return !domainAliase.find(function (currentData) {
                        return preData.domain === currentData;
                    })
                })
                const domainAlias = updateDomain.filter((ele) => ele.checked).map((ele) => ele.domain).filter((str) => str !== '');
                const updateTenant = this.tenantInfoAtLogin;
                updateTenant.domain = this.primaryDomain;
                updateTenant.domainAliases = domainAlias.filter((val) => val !== this.primaryDomain);
                updateTenant.isDomainsUpdated = true;
                this.sspService.updateTenant(updateTenant, 'yes', 'scp').toPromise().then((data) => {
                    this.tenantInfoAtLogin = data;
                    this.idpCommonService.supportedDomains = [this.primaryDomain, ...data.domainAliases];
                    this.tenantService.getTenantInfo(this.tenantInfoAtLogin.accessToken).subscribe((data) => {
                        this.updateTenantlist = data[0].domainAliases;
                    });
                    this.loadData();
                    this.notificationService.showNotification(`SubDomainDelete`);
                    domainAliase.forEach((deletedDomain: any) => {
                        this.logHandlerService.addAdminLog('Supported Domain', 'Supported Domain deleted', 'MAA00049', 'Deleted' + " " + deletedDomain);
                    })
                })
                this.IsAllSelectedProp = false;
                this.deleteButtonDisabled = true;
                this.selection.clear();
            }
            else {
                this.selection.clear();
                this.isAllSelected();
            }
        });
    }

    /**
       * Function to call Azure AD Domain List API and get raw domain list
       */
    refreshDomain(log) {
        this.isTokenAvailable = true;
        this.domainListFiltered = [];
        const tenantDomain = this.tenantInfoAtLogin.domain || this.tenantInfoAtLogin.customerContactEmail.split('@')[1];
        this.primaryDomain = tenantDomain.toLowerCase();
        this.existingDomainAliases = this.tenantInfoAtLogin.domainAliases || [];
        const homeDomain = this.appDataService.HomeDomain;
            this.idpCommonService.getIdpDomain()
                .then((res) => {
                    this.ispageLoaded = true;
                    const rawDomainList = res;
                    const rawDomainAliasList = rawDomainList.map((ele) => ele.toLowerCase()).filter((ele) => ele !== this.primaryDomain);
                    this.filterByTenantDomains(rawDomainList, rawDomainAliasList);
                    this.isPermissionGranted = true;
                    if (log) {
                        this.logHandlerService.addAdminLog('Supported Domain', 'Domain aliases selection refreshed', 'MAA00031', 'Domain aliases selection refreshed');
                    }
                }, (err) => {
                    console.warn(err);
                    if (this.isGoogle) {
                        if (err.error && err.error.error === 'unauthorized_client' && err.error.error_description === 'Client is unauthorized to retrieve access tokens using this method, or client not authorized for any of the scopes requested.') {
                           this.openConfirmationDialog();
                        }
                        if (err.status == 403 && ((err?.error || "").includes("requested scope is not granted") || (err?.error || "").includes("refresh token is NOT available for the user"))) {
                            this.openConfirmationDialog();
                        }
                    }

                    if (this.isO365) {
                        // HTTP Interceptor changes the response structure differently b/w Microsoft
                        // and GSuite domain API. The status '403' is common in interceptor for both.
                        // So the same is used in the condtition to show the permission require message.
                        if (err.statusCode === 403 || err.status === 403 || err.code === "Authorization_RequestDenied") {
                            //this.openConfirmationDialog();
                            this.isPermissionGranted = false;
                        }
                    }
                    this.setInitialDomain();
                });
    }


    // opens Permissions Required dialog
    openConfirmationDialog() {
        if (this.permissionDialogRef) {
            return;
        }
        this.permissionDialogRef = this.dialog.open(GrantPermissionsComponent, {
            width: '50%',
            minHeight: '20%',
        });
    }

    setInitialDomain() {
        this.domainListFiltered.push({ domain: this.primaryDomain, isPrimary: true, isUsedByOtherDomain: false, checked: true });
        this.dataSource = this.domainListFiltered;
    }

  toggleDomain(domain, event) {
    if (!event.checked) {
        const okButton = this.translate.instant('Ok');
      this.defaultDialogConfig.data = {
        title: [this.translate.instant('Warning')],
                message: (`${this.translate.instant('DisableDomain', { domain: domain.bold() })}<br>
                <li>${this.translate.instant(this.isGoogle ? 'DomainNotWorkGsuite' : 'DomainNotWork')}<br></li>
                <li>${this.translate.instant(this.isGoogle ? 'DomainAddNotWorkGsuite' : 'DomainAddNotWork')}</li>`),
                alertMode: true,
                okButton: okButton,
      };
      const removeDialogRef = this.dialog.open(ConfirmationDialogComponent, this.defaultDialogConfig);
      removeDialogRef.afterClosed().subscribe(() => {
      });
    }
  }

    /** Selecting The CheckBoxes */
    selectAllButton() {
        if (this.IsAllSelectedProp) {
            this.selection.clear();
        } else {
            this.dataForDomains.data.forEach(row => {
                this.selection.select(row);
            });
        }
        this.isAllSelected();
        if (this.lastSelectedInputField) {
            this.cancelEdit(this.lastSelectedInputField, this.lastSelectedIndex);
        }
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataForDomains.data.length;
        const hasValue = this.selection.hasValue();
        this.IsAllSelectedProp = (numSelected === numRows) && hasValue;
        this.primaryOrCurrentAdmin = this.selection.selected;
        const selectedDomains = this.primaryOrCurrentAdmin.map((ele) => ele.domain);
        if (numSelected == 0 || selectedDomains.includes(this.primaryDomain)) {
            this.deleteButtonDisabled = true;
        } else {
            this.deleteButtonDisabled = false;
        }
    }

    /**
    * @param event event is used to know the checkbox is selected or not
    * @param row row The row entry from this.domainsList
    */
    /** document.getElementsByClassName is Used to get the input DOM elements by using class name "test" */
    checkSelectedUser(event, row) {
        if (event.checked === true) {
            this.Checked.push(row);
            this.selection.select(row);
        } else {
            this.selection.deselect(row);
            this.Checked.splice(this.Checked.indexOf(row), 1);
        }
        this.isAllSelected();
        this.editDomain = false;
        if (this.lastSelectedInputField) {
            this.cancelEdit(this.lastSelectedInputField, this.lastSelectedIndex);
        }
    }

        /**
    * Event handler for focusout. i.e, focus shifted out of domain text box
     * @param row The row entry from this.domainsList for currently edited domain
     * @param domainElement The input text box for currently edited domain
     *
     * @description When focus shifts out of domain text box, we need do the following:
     * enable delete button for current domain,
     * enable add icon if last entry is already saved,
     * save this domain entry as last selected field for future reference.
     */
    focusingOut(row, domainElement,event) {
        // Ignore focusout event, when user is clicking on ok button of snackbar
        if (event && event.relatedTarget && event.relatedTarget.id == 'snackbar-ok') {
            domainElement.focus()
            domainElement.value = this.newlyEnteredValue
            return;
        }

        const index = this.dataForDomains.data.findIndex((ele) => ele.id == row.id);
        this.lastSelectedInputField = domainElement;
        this.lastSelectedIndex = index;
        this.editDomain = false;
        // Double click is treated as two separate clicks.
        // first click is focus out, second click on the control (delete, + button)
        // To avoid this scenario, enable delete & + buttons after 1 second.
        interval(1000).pipe(take(1)).subscribe(() => {
            // Enable delete button for the specific row
            if (index > 0) {
                const editedRow = this.dataForDomains.data[index];
                editedRow.enableDeleteButton = true;
            }
            // If the original domain value of last row is empty,
            // it is a new domain, so do not enable + button
            // If the original domain value of last row is not empty,
            // it is an existing domain, so enable + button
            const lastRow = this.dataForDomains.data[this.dataForDomains.data.length - 1];
            if (lastRow.domain != '') {
                this.disableAddIcon = false;
            }

        });
        if (!this.validateandSaveDomain && this.lastSelectedInputField) {
            this.cancelEdit(this.lastSelectedInputField, this.lastSelectedIndex);
        }
        this.validateandSaveDomain =false

    }

    /**
    * end-user cancels edit operation by clicking the `x` button
    * @param domain The text input element for domain
    * @param index The index of the domain from the master list
    */
    cancelEdit(domain, index) {
        let initialCardValue;
        // updateTenantlist is original list and does not contain primary domain at position 0
        // index from UI array contains primary domain at position 0.
        // to convert index from UI to original list, use index - 1.
        initialCardValue = this.updateTenantlist[index - 1];
        domain.value = initialCardValue || "";
        this.editDomain = false;
        this.editedRowId = -1;
    }

    /*** Moving curser back to the input field */
    curserPosition(domain) {
        domain.focus(); //sets focus to element
        domain.value = this.newlyEnteredValue; // Again asign the value
    }

    /**
    * The text box for a domain has received focus and this method is the event handler for the `focus` action.
    * @param row The domain that is being edited
    * @description When the text box receives focus, it should change the status of
    * other controls like making tick, x buttons visible, disabling delete buttons,
    * cancelling edit of another domain text box, etc.
    */
    startEditDomain(row) {
        //If input is edit mode select all button is un selecting
        this.IsAllSelectedProp = false;
        // Cancel editing of previously edited domain text box (if any) that is not yet saved
        if (this.lastSelectedInputField) {
            this.cancelEdit(this.lastSelectedInputField, this.lastSelectedIndex);
        }
        // Clear the selections in the SelectionModel. This will also reflect in the UI.
        this.selection.clear()
        // Disable the add icon (+) button at the top
        this.deleteButtonDisabled = true;
        const editedRow = this.dataForDomains.data.find(ele => ele.id == row.id);
        if (editedRow) {
            editedRow.enableDeleteButton = false;
            this.disableAddIcon = true;
        }
        // Set the currently edit row id and status as editing true
        this.editedRowId = row.id;
        this.editDomain = true;
    }

    /**
 * Deletes the selected subdomain (single entry). This method is called when the trash icon
 * is clicked in the UI.
 * @param row The row that needs to be deleted
 */
    deleteRow(row: any) {
        if (this.lastSelectedInputField) {
            this.cancelEdit(this.lastSelectedInputField, this.lastSelectedIndex);
        }
        if (row.domain != '') {
            this.defaultDialogConfig.data = {
                title: [this.translate.instant("RemoveDomain")],
                message: this.translate.instant("ConfirmRemoveDomain", {
                    subDomain: row.domain,
                }),
            };

            //Adding dialog box when deleting single sub domains
            const confirmDialogRef = this.dialog.open(RemoveDomainDialogComponent, this.defaultDialogConfig);
            confirmDialogRef.afterClosed().subscribe((result) => {
                if (result === 1) {
                    // Index of selected domain in filteredData is the row.id + 1.
                    // Reason: Primary domain is inserted as first entry in filteredData but not accounted for row.id
                    this.dataForDomains.filteredData.splice(row.id + 1, 1);

                    // Call the saveDomainAlias method with intent to remove selected domain
                    const removeAlias = true;
                    let callbackSuccess = null
                    callbackSuccess = () => {
                        this.logHandlerService.addAdminLog('Supported Domain', 'Supported Domain deleted', 'MAA00049', 'Deleted' + " " + row.domain);
                    };
                    this.saveDomainAlias(removeAlias, callbackSuccess);
                }
            })
        } else {
            this.loadData()
        }
        this.selection.clear()
        this.deleteButtonDisabled = true;
        this.IsAllSelectedProp = false;
    }

    /**
 * Save domain aliases in the tenant record
 * @param removeAlias false to add the domain alias in tenant record.
 *  true to remove the alias from tenant record
 */
    saveDomainAlias(removeAlias: boolean, callbackSuccess: () => {}) {
        const domainAliases = this.dataForDomains.data.map((ele) => ele.domain).filter((str) => str !== '');
        const updatedTenant = this.tenantInfoAtLogin;
        updatedTenant.domain = this.primaryDomain;
        updatedTenant.domainAliases = domainAliases.filter((alias) => alias !== this.primaryDomain);
        updatedTenant.isDomainsUpdated = true;
        this.sspService.updateTenant(updatedTenant, 'yes', 'scp').subscribe({
            next: (data) => {
                // Successfully updated the tenant record
                this.disableAddIcon = false;
                this.tenantInfoAtLogin = data;
                this.idpCommonService.supportedDomains = [this.primaryDomain, ...data.domainAliases];
                this.loadData();
                // Based on removeAlias flag, display appropriate notification message
                if (removeAlias == false) {
                    this.notificationService.showNotification('SubDomainAdd');
                } else {
                    this.notificationService.showNotification(`SubDomainDelete`);
                }
                if (callbackSuccess) {
                    callbackSuccess();
                }

                // Get the latest tenant info from server
                this.tenantService.getTenantInfo(this.tenantInfoAtLogin.accessToken).subscribe({
                    next: (data) => {
                        this.updateTenantlist = data[0].domainAliases;
                    }
                });
            },
            error: (error) => {
                // Failed to update the tenant record
                this.notificationService.showNotification(`FailedToSaveSubDomain`);
                console.warn(error);
            }
        });
        this.selection.clear();
    }

    loadData() {
        this.Checked = [];
        this.domainsList = [];
        const updateTenant = this.tenantInfoAtLogin.domainAliases || [];
        let PrimaryDomain = this.tenantInfoAtLogin.domain;
        this.domainsList.push({ domain: PrimaryDomain });
        for (var ele of updateTenant) {
            this.domainsList.push({ domain: ele, checked: true, enableDelete: true, id: updateTenant.indexOf(ele), enableDeleteButton: true });
        }
        this.disableAddIcon = false;
        this.dataForDomains.data = this.domainsList;
        this.saveButton = false;
        this.IsAllSelectedProp = false;
    }

    ignoreEnterKey(event) {
        if (event.keyCode == 13) {
            event.preventDefault();
        }
    }

    /**
     * Function to filter domain list and set data for display
     * @param rawDomainList list of raw domain list
     * @param domainAliases list of raw domain aliases
     */
    filterByTenantDomains(rawDomainList: string[], rawDomainAliases: string[]) {
        this.sspService.getDomainsAliasInfo(rawDomainAliases).subscribe((result) => {
            const response = result.domains;
            // filtering out domain list for domain aliases for UI display
            rawDomainList.forEach((ele) => {
                if (ele.toLowerCase() !== this.primaryDomain) {
                    this.domainListFiltered.push({ domain: ele.toLowerCase(), isPrimary: false, isUsedByOtherDomain: false, checked: false });
                }
            });
            // make primary domain as first in domainlist to display in UI
            this.domainListFiltered.unshift({ domain: this.primaryDomain, isPrimary: true, isUsedByOtherDomain: false, checked: true });
            this.domainListFiltered.forEach((ele) => {
                if (response.unUsableDomains.includes(ele.domain)) {
                    ele.isUsedByOtherDomain = true;
                    ele.checked = false;
                }
                if (response.usableDomains.includes(ele.domain)) {
                    if (this.existingDomainAliases.includes(ele.domain)) {
                        ele.checked = true;
                    } else {
                        ele.checked = false;
                    }
                }
            });
            this.dataSource = this.domainListFiltered;
        }, (err) => {
            console.warn(err);
        });
    }

    /**
     * Validate and then save the given sub domain
     * @param domain The input element (DOM) where the sub domain value is typed by user
     * @param id The id of the row corresponding to this sub-domain
     * @param removeAlias true to remove domain alias, false to add domain alias
     * @param row The row entry from this.domainsList
     */
    validateAndSaveAlias(domain: any, id: string, removeAlias: boolean, row: any) {
        this.validateandSaveDomain = true
        this.newlyEnteredValue=domain.value
        let subDomain = domain.value;
        let Url = `https://login.microsoftonline.com/${subDomain}/v2.0/.well-known/openid-configuration`
        this.accessToken = this.accessToken;
        let authInfo = jwt_decode(this.accessToken.replace("Bearer ", ""));
        const tenantid = authInfo["https://marvel.sharp.com/tenantid"];
        /**** validating usableDomains OR unUsableDomains ****/
        this.sspService.getDomainsAliasInfo(subDomain).subscribe((result) => {
            let res = result.domains;
            let unusable = res.unUsableDomains;
            if (unusable.includes(subDomain)) {
                this.curserPosition(domain)
                this.notificationService.showNotification(`UnUsableDomain`,undefined, undefined, undefined);
                return;
            } else if (this.updateTenantlist.includes(subDomain)) {
                this.curserPosition(domain)
                this.notificationService.showNotification(`ExistingAlias`,undefined, undefined, undefined,);
                return;
            } else if (subDomain === this.primaryDomain) {
                /**** validating validUsabledomain or not by using "TenantId" and "tid" ****/
                this.curserPosition(domain)
                this.notificationService.showNotification(`PrimaryDomain`,undefined, undefined, undefined);
                return;
            } else {
                let callbackSuccess = null;
                this.http.get(Url).subscribe((res: any) => {
                        let tenantId = res.device_authorization_endpoint;
                    let tid = tenantId.split('/')[3];
                        if (tid == tenantid) {
                        this.dataForDomains.data.map(ele => {
                                if (ele.id == id) {
                                    /** Log will be created at the editing the Existing Domains */
                                if (ele.domain != '') {
                                    const oldValue=ele.domain
                                        callbackSuccess = () => {
                                        this.logHandlerService.addAdminLog('Supported Domain', 'Supported Domain deleted', 'MAA00049', 'Deleted' + " " + oldValue);
                                        this.logHandlerService.addAdminLog('Supported Domain', 'Supported Domain added', 'MAA00048', 'Added' + " " + subDomain);
                                        };
                                    } else {
                                        /** Log will be created at first creation of SubDomain */
                                        callbackSuccess = () => {
                                        this.logHandlerService.addAdminLog('Supported Domain', 'Supported Domain added', 'MAA00048', 'Added' + " " + subDomain);
                                    }
                                    }
                                    this.eleId = ele.id;
                                    ele.domain = subDomain;
                                }
                            });
                            this.saveDomainAlias(removeAlias, callbackSuccess);
                        } else { 
                            this.notificationService.showNotification(`InvalidAlias`,undefined, undefined, undefined)                               
                            this.curserPosition(domain)
                        }
                }, () => {
                    this.curserPosition(domain)
                    this.notificationService.showNotification(`InvalidAlias`, undefined, undefined, undefined) 
                })
            }
        });
        this.editDomain = false;
    }

    saveDomainAliases() {
        const editedDomains = this.dataSource.filter((ele) => !ele.isPrimary && !ele.isUsedByOtherDomain).map((ele) => (ele.checked ? 'Enable' : 'Disable') + ' ' + ele.domain);
        const details = editedDomains.join(', ');
        const domainAliases = this.dataSource.filter((ele) => !ele.isPrimary && ele.checked).map((ele) => ele.domain);
        const updateTenant = this.tenantInfoAtLogin;
        updateTenant.domain = this.primaryDomain;
        updateTenant.domainAliases = domainAliases;
        updateTenant.isDomainsUpdated = true;
        this.sspService.updateTenant(updateTenant, 'yes', 'scp').toPromise().then((data) => {
            this.tenantInfoAtLogin = data;
            this.idpCommonService.supportedDomains = [this.primaryDomain, ...data.domainAliases];
            this.refreshDomain(false);
            this.logHandlerService.addAdminLog('Supported Domain', 'Domain aliases selection edited', 'MAA00030', details.replace(/,\s*$/, ''));
        }).catch((error) => {
            console.warn(error);
        });
    }
}

