<div class="scp_theme mat-typography">
  <h2 mat-dialog-title>{{ 'scp.settings.pin_replacement.dialog_title' | translate }}</h2>

  <ng-container *ngIf="!showSuccessMessage; else successMessageTemplate">
    <mat-dialog-content>
      <ng-container *ngIf="newPin; else generatingPinTemplate">
        <div fxLayout="row" fxFlexFill fxLayoutAlign="center center">
          <span class="scp_new_pin">{{ newPin }}</span>
        </div>
        <p>{{ 'scp.settings.pin_replacement.new_pin_message' | translate : { value: newPin } }}</p>
      </ng-container>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-flat-button mat-dialog-close>{{ 'scp.common.cancel_button_text' | translate }}</button>
      <button type="button" mat-flat-button color="primary" [disabled]="isSubmitting || isNewPinDisabled" (click)="getNewPin()">
        {{ 'scp.settings.pin_replacement.new_pin_button_text' | translate }}
      </button>
      <button type="button" mat-flat-button color="primary" [disabled]="isSubmitting" (click)="updateUser()">
        {{ 'scp.settings.pin_replacement.confirm_pin_button_text' | translate }}
      </button>
    </mat-dialog-actions>
  </ng-container>

  <ng-template #generatingPinTemplate>
    <div fxLayout="column" fxFlexFill fxLayoutAlign="center center">
      <mat-spinner diameter="45" strokeWidth="2"> </mat-spinner>
      <p class="mt-2">{{ 'scp.settings.pin_replacement.generating_new_pin_message' | translate }}</p>
    </div>
  </ng-template>

  <ng-template #successMessageTemplate>
    <mat-dialog-content>{{ 'scp.settings.pin_replacement.save_pin_success_message' | translate }}</mat-dialog-content>

    <mat-dialog-actions align="end">
      <button type="button" mat-flat-button color="primary" [mat-dialog-close]="true">{{ 'scp.common.ok_button_text' | translate }}</button>
    </mat-dialog-actions>
  </ng-template>
</div>
