<div class="scp_theme mat-typography">
  <h2 mat-dialog-title>{{ 'scp.devices.mfp_search.dialog_title' | translate }}</h2>

  <form
    novalidate
    autocomplete="off"
    validateFormAndScrollToFirstError
    [formGroup]="mfpSearchFormGroup"
    (ngSubmit)="upsertDevice()"
    *ngIf="!showSuccessMessage; else successMessageTemplate"
  >
    <mat-dialog-content>
      <div fxLayout="column wrap" fxLayoutGap="16px">
        <mat-form-field class="mb-3">
          <input matInput placeholder="{{ 'scp.devices.mfp_search.fields.search' | translate }}" [formControl]="rangeFormCtrl" />
          <mat-error *ngIf="rangeFormCtrl.hasError('required')"> {{ 'scp.common.validations.required' | translate }} </mat-error>
          <mat-error *ngIf="rangeFormCtrl.hasError('pattern')"> {{ 'scp.devices.mfp_search.validations.ip_address_pattern' | translate }} </mat-error>
        </mat-form-field>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-flat-button mat-dialog-close> {{ 'scp.common.cancel_button_text' | translate }} </button>
      <button type="submit" mat-flat-button color="primary" [disabled]="isSubmitting"> {{ 'scp.common.save_button_text' | translate }} </button>
    </mat-dialog-actions>
  </form>

  <ng-template #successMessageTemplate>
    <mat-dialog-content> {{ 'scp.devices.mfp_search.save_success_message' | translate }} </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-flat-button color="primary" [mat-dialog-close]="true"> {{ 'scp.common.ok_button_text' | translate }} </button>
    </mat-dialog-actions>
  </ng-template>
</div>
