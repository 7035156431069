<mat-card>
  <mat-card-title>{{ 'scp.common.reports' | translate }}</mat-card-title>

  <mat-tab-group preserveContent animationDuration="0ms" mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab label="{{ 'scp.reports.execute.tab_title' | translate }}">
      <ng-template matTabContent>
        <app-reports-list></app-reports-list>
      </ng-template>
    </mat-tab>

    <mat-tab label="{{ 'scp.reports.schedule.tab_title' | translate }}">
      <ng-template matTabContent>
        <app-reports-schedule></app-reports-schedule>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</mat-card>
