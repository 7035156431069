import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
    HttpInterceptor, HttpRequest, HttpHandler,
    HttpEvent, HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import * as dayjs from 'dayjs';
import { TokenStorage } from './core/token.storage';
import { NotificationService } from './shared/services/notification.service';
import { AuthService } from './shared/services/auth.service';
import { ProgressLoaderService } from './shared/services/progress-loader.service';
import { finalize } from "rxjs/operators";
import { LogHandlerService } from './shared/services/log-handler.service';
import { environment } from '../environments/environment';
import { apiGateWay } from '../app/layout/rmm/environments/environment'
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';
import { CloudPrintEnvService } from 'lib-ja-cloud';

const TOKEN_HEADER_KEY = 'Authorization';
const ADMIN_PORTAL_VERSION = 'x-app-version';
const USER_TIMEZONE = 'x-time-zone';

const openAPIs = ["tenantCreation", "checkAuth0State", "providerDetails"];

@Injectable()
export class Interceptor implements HttpInterceptor {

    constructor(
        private token: TokenStorage,
        private notificationService: NotificationService,
        private authService: AuthService,
        private loaderService: ProgressLoaderService,
        private dom: DomSanitizer,
        private logHandler: LogHandlerService,
        private envService: CloudPrintEnvService
    ) {
        this.logHandler.userName = this.authService.UserName;
        this.logHandler.userEmail = this.authService.userEmail;
        this.logHandler.tenantId = this.authService.getConnectionName;
        this.logHandler.userRole = this.authService.adminRole;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // NOTE: Synappx Cloud Print (SCP) app specific http requests are handled by its own HTTP_INTERCEPTOR.
        if (req.url.startsWith(this.envService.scpCommonApiBaseUrl)) {
            return next.handle(req); 
        }

        let authReq = req;
        const isOffice365API = authReq.url.startsWith('https://graph.microsoft.com/');
        const isGoogleAPI = authReq.url.startsWith('https://www.googleapis.com/');
        const urlParts = req.url.split("/");
        const APIUrl = urlParts[urlParts.length - 1];
        if (!openAPIs.includes(APIUrl) && (req.url.endsWith('json') === false) && ((this.authService.accessToken || '') === '')) {
            console.warn('Bad Access Token. So signout and attempt re-login');
            this.token.signOut();
            this.authService.login();
            return next.handle(authReq);
        }

        if (this.authService.isAuthenticated() != null && !isGoogleAPI && !isOffice365API) {

            authReq = req.clone({
                headers: req.headers.set(TOKEN_HEADER_KEY, `Bearer ${this.authService.accessToken}`)
                    .set(ADMIN_PORTAL_VERSION, environment.version)
                    .set(USER_TIMEZONE, dayjs().format('Z')),
            });
        }
        //Added For Url Query Params Checking If any Api Request Contain Null or Undefined with Parameters

        let str = req.urlWithParams
        if (str.includes('rmm') || str.includes('dd') || str.includes('agent') || str.includes('idp') || str.includes('rmmx')) {
            if (str.includes('null') || str.includes('undefined')) {
                console.warn("Some parameters of the URL are not defined. Please recheck", str)
                this.notificationService.showNotification('error503', undefined, undefined, { verticalPosition: 'bottom', panelClass: 'notification-bg', duration: 5000 });
            }
        }


        // Added Fss checkpogress
        // Added Print Driver uploadProgress
        // Added globalRemoteOperationUrl, connectGlobalRemoteOperation and remoteOperationPanelUrl to hide loader
        // Added agent checkprogress & getSSHKeyStatus
        if (!(req.url.includes(apiGateWay.rmm + "db/checkProgress"))
            && !(req.url.includes(apiGateWay.rmmStateful+ "fss/fwupd/uploadProgress"))
            && !(req.url.includes(apiGateWay.rmmStateful + "fss/export/checkProgress"))
            && !(req.url.includes(apiGateWay.rmmAgent + "checkProgress"))
            && !(req.url.includes(apiGateWay.rmmPDM + "common/checkProgress"))
            && !(req.url.includes(apiGateWay.rmmStateful + "dd/file/uploadProgress"))
            && !(req.url.includes(apiGateWay.rmmIdp + "common/checkProgress"))
            && !(req.url.includes(apiGateWay.rmm + "group/getCompanyOrServiceBoardList"))
            && !(req.url.includes(apiGateWay.rmmStateful + 'fss/service/executionProgress'))
            && !(req.url.includes(apiGateWay.rmmStateful + "agent/idp/checkSSHKeyProgress"))
            && !(req.url.includes(apiGateWay.rmmIdp + 'agent/getSSHKeyStatus'))) {
            this.loaderService.show();
        }
        return next.handle(authReq).pipe(
            finalize(() => this.loaderService.hide()),
            tap(() => { },
                (err: HttpErrorResponse) => {
                    console.error('Error captured in Interceptor:' + JSON.stringify(err));
                    const httpMethod = authReq.method;
                    const httpUrl = authReq.url;
                    const status = err.status + ': ';
                    let details = status + (err.message || err.error.message);
                    const featureType = (req.body && req.body.feature_type ? req.body.feature_type : '');
                    const urlMap = [
                        ['graph/so', 'getGraphs', 'connectionFailureLMS'],
                        ['csv/so/', 'exportGraph', 'connectionFailureLMS'],
                        ['csv/so/synappx_audit_system', 'exportSys', 'connectionFailureLMSAudit'],
                        ['csv/so/synappx_audit_admin', 'exportAdmin', 'connectionFailureLMSAudit'],
                        ['elasticsearch/synappx_admin_v1', 'getAdminLogs', 'connectionFailureES'],
                        ['elasticsearch/synappx_system_v1', 'getSystemLogs', 'connectionFailureES']
                    ];
                    let exportUrls = '';
                    for (const pair of urlMap) {
                        const [url, name, name503] = pair;
                        if (authReq.url.includes(url)) {
                            exportUrls = err.status === (503 || 500) ? name503 : name;
                        }
                    }
                    // this section is to get errors related to 'connect to service' logs
                    if (err.status == 503 && err.error == 'MAINTENANCE') {
                        this.token.signOut();
                        this.authService.logoutONMaintenanceMode();
                        return;
                    }
                    if (err.error) {
                        let connectionError = err.statusText;
                        // get es error for displaying audit logs
                        if (exportUrls === 'connectionFailureES') {
                            const errText = JSON.parse(err.error.message.body);
                            connectionError = (errText ? errText.error.reason : err.statusText);
                        }
                        // get lms error for displaying graphs
                        if (exportUrls === 'connectionFailureLMS') {
                            let errText = JSON.parse(err.error);
                            errText = JSON.parse(errText.message.body);
                            connectionError = (errText ? errText.message : err.statusText);
                        }
                        // get lms error for export of audit logs
                        if (exportUrls === 'connectionFailureLMSAudit') {
                            connectionError = err.statusText;
                        }
                        details = status + connectionError;
                    }
                    this.logHandler.addSystemLog(httpMethod, httpUrl, details, featureType, exportUrls);
                    if (err instanceof HttpErrorResponse) {
                        // handling the http request error for RMM Endpoint only
                        if (this.handleRMMerrors(err, req)) {
                            return;
                        }
                        switch (err.status) {
                            case 401:
                            case 403:
                                if (err.status == 401) {
                                    if (err && err.url && (err.url.includes(apiGateWay.rmm) || err.url.includes(apiGateWay.rmmAgent))) {
                                        this.notificationService.showNotification('notAuthorized', undefined, 'N/A', { verticalPosition: 'top', panelClass: 'warning', duration: 5000 });
                                        break;
                                    }
                                }
                                if (req.url.startsWith('https://www.googleapis.com/')) {
                                if ((err?.error?.error?.message || "").includes("Not Authorized to access this resource/api") || err?.error?.error?.code == 401) {
                                    if ((this.authService.viewGo || this.authService.viewMeeting || this.authService.viewRmm || (this.authService.viewScp && this.authService.cpUserRole != 'Tenant User'))) {
                                            this.notificationService.showGoogleAPIFailureNotification();
                                        }
                                    }
                                    break;
                                }
                                if (req.url.includes('/token')) {
                                    if (this.authService.applicationType == 'google-apps') {
                                        if ((err?.error || "").includes("requested scope is not granted") || (err?.error || "").includes("refresh token is NOT available for the user")) {
                                            if ((this.authService.viewGo || this.authService.viewMeeting || this.authService.viewRmm || (this.authService.viewScp && this.authService.cpUserRole != 'Tenant User'))) {
                                                const currentPageUrl = sessionStorage.getItem("currentUrl");
                                                if (currentPageUrl && currentPageUrl !== '/service') {
                                                    this.notificationService.showTokenAPIFailureNotification();
                                                }
                                            }
                                        }
                                    }
                                    break;
                                }
                                if (req.url.includes('/calendarToken')) {
                                    if (this.authService.applicationType == 'google-apps') {
                                        if ((this.authService.viewGo || this.authService.viewMeeting || this.authService.viewRmm || (this.authService.viewScp && this.authService.cpUserRole != 'Tenant User'))) { 
                                            this.notificationService.showNotification('ServiceUnavailable', undefined, 'N/A', { verticalPosition: 'top', panelClass: 'warning', duration: 5000 });
                                            console.log(err);
                                        }
                                    }
                                    break;
                                }
                                if (req.url.includes('https://graph.microsoft.com') && err.status == 403) {
                                    // Microsoft Graph API failed with HTTP 403 due to insufficient permissions for application
                                    // Ignore this error, as it is handled by disabling related features and
                                    // showing a dialog prompting user to grant permission.
                                    break;
                                }
                                this.token.signOut();
                                this.authService.login();
                                break;
                            case 503:
                                if (err.error == 'MAINTENANCE') {
                                    this.token.signOut();
                                    this.authService.logoutONMaintenanceMode();
                                }
                                else if (req.url.includes(apiGateWay.rmm) && !(req.url.includes(apiGateWay.rmm + "db/checkProgress"))) {
                                    this.notificationService.showNotification('error503', undefined, undefined, { verticalPosition: 'bottom', panelClass: 'notification-bg', duration: 5000 });
                                }
                                if (req.url.includes(apiGateWay.rmmFss) && !(req.url.includes(apiGateWay.rmmStateful + "fss/export/checkProgress")) && !(req.url.includes(apiGateWay.rmmStateful + "fss/fwupd/uploadProgress")) && !(req.url.includes(apiGateWay.rmmAgent + "checkProgress"))
                                    && !(req.url.includes(apiGateWay.rmmPDM + "common/checkProgress")) && !(req.url.includes(apiGateWay.rmmStateful + "dd/file/uploadProgress"))) {
                                    this.notificationService.showNotification('error503', undefined, undefined, { verticalPosition: 'bottom', panelClass: 'notification-bg', duration: 5000 });
                                }
                                break;
                            case 0:
                                this.notificationService.showNotification('ServiceUnavailable', undefined, 'N/A', { verticalPosition: 'top', panelClass: 'warning', duration: 5000 });

                                if (req.url.includes(apiGateWay.rmm) && !(req.url.includes(apiGateWay.rmm + "db/checkProgress"))) {
                                    this.notificationService.showNotification('error503', undefined, undefined, { verticalPosition: 'bottom', panelClass: 'notification-bg', duration: 5000 });
                                }
                                if (req.url.includes(apiGateWay.rmmFss) && !(req.url.includes(apiGateWay.rmmStateful + "fss/export/checkProgress")) && !(req.url.includes(apiGateWay.rmmStateful + "fss/fwupd/uploadProgress")) && !(req.url.includes(apiGateWay.rmmAgent + "checkProgress"))
                                    && !(req.url.includes(apiGateWay.rmmPDM + "common/checkProgress")) && !(req.url.includes(apiGateWay.rmmStateful + "dd/file/uploadProgress"))) {
                                    this.notificationService.showNotification('error503', undefined, undefined, { verticalPosition: 'bottom', panelClass: 'notification-bg', duration: 5000 });
                                }
                                break;
                            default:
                                break;
                        }
                    }
                },
            ),
        );
    }


    /**
     * Handle errors from RMM APIs
     * @param err The error response
     * @param req The http request
     * @returns true if error is already handled here. false if error is not handled
     */
    handleRMMerrors(err: HttpErrorResponse, req: HttpRequest<any>): boolean {
        const errorMessages = {
            // Message For The Error in UI
            '400': 'rmmError400', //'Bad request error.'
            '401': 'rmmError401', //'User is not authorized.'
            '403': 'rmmError403', // 'You  don't have permission to access on this resource.'
            '404': 'rmmError404', // 'We couldn't find this page.'
            '500': 'rmmError500', // 'Oops, something went wrong.'
        };
        if (this.isRmmApi(req.url)) {
            if (err.status.toString() in errorMessages) {
                this.notificationService.showNotification(errorMessages[err.status.toString()], undefined, 'N/A', { verticalPosition: 'top', panelClass: 'warning', duration: 5000 });
                if (err.status == 401) {
                    this.token.signOut();
                    if (req.url.includes(apiGateWay.rmm + 'group/groupInfo')) {
                        // User is not allowed access to application if 401 received 
                        this.authService.logout()
                    } else {
                        // User is not allowed access to API if 401 received 
                        this.authService.login();
                    }
                }
                // error status is handled here
                return true;
            }
        }
        //  not handled
        return false;
    }

    /**
     * Check if the URL belongs to RMM API
     * @param url the request url to validate
     * @returns true if the given url belongs to RMM API. false otherwise
     */
    isRmmApi(url: string): boolean {
        const rmmUrls = [apiGateWay.rmm, apiGateWay.rmmPDM, apiGateWay.rmmAgent, apiGateWay.rmmIdp, apiGateWay.rmmTco];
        for (const item of rmmUrls) {
            if (url.toLowerCase().indexOf(item.toLowerCase()) >= 0) {
                return true;
            }
        }
        return false;
    }

}
