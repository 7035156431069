<mat-card class="custom_admin_log">
    <div class="fx_space-between-center">
        <mat-card-title>{{ 'AdminLogTitle' | translate }}</mat-card-title>
    </div>

    <mat-card-content>
        <div  class="p_tb fx_row">
            <form  class="no_wrap fx_90">
                <button class="button" mat-button type="submit" id='id-adminLog-CloudPrintButton'
                    [disableRipple]="isSCPExists" name="fontStyle" aria-label="Font Style"
                    (click)="isButtonClickable && serviceSelected('SCP')" class="mr_10"
                    [ngClass]="{'btn_toggle_on' : isSCP, 'btn_toggle_off': !isSCP, link: isSCPExists, singleButton: isSingleButton }"
                    *ngIf="isSCPExists">
                    <img src="./../../../assets/images/scp_org.svg" width="32" alt="Synappx CloudPrint Icon">
                    {{"SynappxCloudPrint" | translate}}</button>

                <button class="button" mat-button type="submit" id='id-adminLog-GoButton'
                    [disableRipple]="isMarvelExists && isRmpExists" name="fontStyle" aria-label="Font Style"
                    (click)="isButtonClickable && serviceSelected('Go')" class="mr_10"
                    [ngClass]="{'btn_toggle_on' : isGo, 'btn_toggle_off': !isGo, link: isMarvelExists, singleButton: isSingleButton }"
                    *ngIf="isMarvelExists || isRmpExists">
                    <img src="./../../../assets/images/synappx_collabohub.svg" width="32" alt="Synappx Go Icon"> {{"SynappxGo" | translate}}</button>

                <button class="button" mat-button type="submit" [disableRipple]="isRMMExists" id='id-adminLog-GoButton'
                    name="fontStyle" aria-label="Font Style" (click)="isButtonClickable && serviceSelected('RMM')" 
                    class=""
                    [ngClass]="{'btn_toggle_on' : isRMM, 'btn_toggle_off': !isRMM, link: isRMMExists, singleButton: isSingleButton}"
                    *ngIf="isRMMExists">
                    <img src="./../../../assets/images/synappxmanager.svg" width="32" alt="Synappx Manage Icon"> {{"cloudrmm" | translate}}</button>
            </form>

            <div class="text_left fx_space-between-center">
                <mat-form-field class="margin_10">
                    <mat-label>{{'ChooseStartDate' | translate}}</mat-label>
                    <input matInput id="startDate" #startDate (click)="picker.open()" readonly [matDatepicker]="picker"
                         [min]="minDate" [max]="maxDate">
                    <mat-datepicker-toggle matSuffix [for]="picker">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="margin_25">
                    <mat-label>{{'ChooseEndDate' | translate}}</mat-label>
                    <input matInput id="endDate" #endDate (click)="picker1.open()" readonly [matDatepicker]="picker1"
                         [min]="minDate" [max]="maxDate">
                    <mat-datepicker-toggle matSuffix [for]="picker1">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>

                <span>
                    <button class="primary_btn" mat-raised-button type="submit" id='id-deviceEdit-SaveButtton'
                        (click)="getExport(startDate.value,endDate.value)">{{'Export' | translate}}</button>
                </span>

                <div class="errormessage">
                    <div *ngIf="error.isError">{{error.errorMessage}}</div>
                </div>
            </div>
        </div>

        <div class="fx_row">
            <form class="fx_97" [formGroup]="filterForm">
                <mat-form-field>
                    <mat-label>{{ 'View' | translate }}</mat-label>
                    <mat-select id="user-select-type-id" (selectionChange)="selectedUser($event)" formControlName="View"
                        class="list_log_value" color="accent" #view>
                        <mat-option [value]="0">{{ 'AllUsers'| translate }}</mat-option>
                        <mat-option *ngFor="let userOrAgent of userOrAgentList;" [value]="userOrAgent">
                            {{userOrAgent}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>

            <div  class="text_right fx_space-between-center">
                <button class="icon_btn" mat-icon-button id="sspAdminLog" matTooltip="{{'refreshList' | translate}}"
                    (click)="refreshPage()" matTooltipPosition=above>
                    <mat-icon aria-label="Example icon-button with a refresh icon">refresh</mat-icon>
                </button>
            </div>
        </div>

        <div class="example-container mat-elevation-z8 mt_15">
            <mat-table class="lessons-table" [dataSource]="dataSource" matSort class="mat-cell" matSortDisableClear>
                <!-- Log Date Column -->
                <ng-container matColumnDef="log_generated_date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header (click)="onSorting()" class="removeSpace">
                        <span class="fx_startcenter"
                            matTooltip="{{ ( sort && sort.active ==='log_generated_date' && sort.direction === 'asc' ? 'SortInDescendingOrder' : 'SortInAscendingOrder' ) | translate }}"
                            matTooltipPosition=above>{{ 'LogDate' | translate}} </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="removeSpace">
                        {{formatDate(element._source.log_generated_date)}}</mat-cell>
                </ng-container>
                <!-- User Column -->
                <ng-container matColumnDef="user_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header (click)="onSorting()" class="removeSpace">
                        <span class="fx_startcenter"
                            matTooltip="{{ ( sort && sort.active ==='user_name' && sort.direction === 'asc' ? 'SortInDescendingOrder' : 'SortInAscendingOrder' ) | translate }}"
                            matTooltipPosition=above>{{ 'UserName' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell class="word_break removeSpace" *matCellDef="let element">
                        <span matTooltip="{{ element._source.nameRole.replace('|', '\n') }}" matTooltipClass="linefeed-tooltip"
                            matTooltipPosition=above>{{element._source.user_name }}</span>
                    </mat-cell>
                </ng-container>
                <!-- Service Column -->
                <ng-container matColumnDef="service_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header (click)="onSorting()" class="removeSpace">
                        <span class="fx_startcenter"
                            matTooltip="{{ ( sort && sort.active ==='service_name' && sort.direction === 'asc' ? 'SortInDescendingOrder' : 'SortInAscendingOrder' ) | translate }}"
                            matTooltipPosition=above>{{ 'Service' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="removeSpace"> {{ element._source.service_name
                        }}</mat-cell>
                </ng-container>
                <!-- Category Column -->
                <ng-container matColumnDef="log_category">
                    <mat-header-cell *matHeaderCellDef mat-sort-header (click)="onSorting()" class="removeSpace">
                        <span class="fx_startcenter"
                            matTooltip="{{ ( sort && sort.active ==='log_category' && sort.direction === 'asc' ? 'SortInDescendingOrder' : 'SortInAscendingOrder' ) | translate }}"
                            matTooltipPosition=above>{{ 'Category' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="removeSpace"> {{ element._source.log_category
                        }}</mat-cell>
                </ng-container>
                <!-- Action Column -->
                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef mat-sort-header (click)="onSorting()" class="removePreSpace">
                        <span class="fx_startcenter"
                            matTooltip="{{ ( sort && sort.active ==='action' && sort.direction === 'asc' ? 'SortInDescendingOrder' : 'SortInAscendingOrder' ) | translate }}"
                            matTooltipPosition=above>{{ 'Action' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div *ngIf="element._source.detail else no_detail">
                            <span matTooltip="{{ element._source.detail }}" matTooltipClass="linefeed-tooltip"
                                matTooltipPosition=above>{{ element._source.action_id }}</span>
                        </div>
                        <ng-template #no_detail>
                            <span>{{ element._source.action_id }}</span>
                        </ng-template>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

            </mat-table>
            <div class="no-results" *ngIf="dataSource === null || dataSource && dataSource.length === 0">
                <mat-card-content><label>{{ 'NoLogsAvailable' | translate}}</label></mat-card-content>
            </div>
            <div class="no-results" *ngIf="loadingStatus">
                <mat-card-content><label>{{ 'Loading' | translate}}</label>
                </mat-card-content>
            </div>
            <mat-paginator #paginator class="custom_mat_paginator" [length]="totalCount" [pageIndex]="0"
                [pageSizeOptions]="[5, 10, 25, 100]" (page)="onPagination($event)" showFirstLastButtons>
            </mat-paginator>
        </div>
    </mat-card-content>
</mat-card>