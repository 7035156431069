import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { NotificationMessageComponent } from '../components/notifications/notification-messages-component';
import { MatDialog } from '@angular/material/dialog';
import { LogoutNotificationComponent } from '../../layout/components/dialogs/logout-notification/logout-notification.component';
import { GrantPermissionsComponent } from '../../layout/components/dialogs/grant-permissions/grant-permissions.component';
import { AuthService } from './auth.service';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class NotificationService {
    isGoogleDialogOpened: boolean = false;
    isTokenDialogOpened: boolean = false;

    /**
     * This Replay Subject used to fire the ok clicked event from snackbar
     */
    public _okClickOnSnackBar: ReplaySubject<any>;

    /**
     * Get the ReplaySubject used to fire ok click event on snackbar
     */
    public get okClickOnSnackBar(): ReplaySubject<any>{
        return this._okClickOnSnackBar;
    };

    constructor(public snackBar: MatSnackBar,
                private translate: TranslateService,
                public matDialog: MatDialog,
                public authSerive: AuthService) {
        this._okClickOnSnackBar = new ReplaySubject<any>(1);
    }

    showNotification(
        message: string,
        interpolateParams?: Object,
        action?: string,
        extraParams?: MatSnackBarConfig,
        okClicked?: Function
        ) {
            let config = new MatSnackBarConfig();
            if (extraParams) {
                for (let param of Object.keys(extraParams)) {
                    config[param] = extraParams[param];
                }
            }

        config.duration = config.duration === 0 ? 5000 : config.duration;

        if (action === undefined) {
            action = this.translate.instant('Ok');
        }
        if (action === 'N/A') {
            action = undefined;
        }
        this._okClickOnSnackBar = new ReplaySubject<any>(1);
        this._okClickOnSnackBar.subscribe({
            next: (data) => {
                // If a callback function is supplied for okClicked, invoke this function
                if (okClicked) {
                    (okClicked)();
                }
            }
        });
        this.snackBar.openFromComponent(NotificationMessageComponent, {
              data: {
                  message: message,
                  interpolateParams: interpolateParams,
                  action: action
              },
              duration: config.duration,
              verticalPosition: config.verticalPosition,
              horizontalPosition: config.horizontalPosition || 'center',
              direction: config.direction,
              panelClass: config.panelClass,
              viewContainerRef: config.viewContainerRef,
              announcementMessage: config.announcementMessage,
              politeness: config.politeness
          });
    }

    showGoogleAPIFailureNotification() {
        if (!this.isGoogleDialogOpened) {
            this.isGoogleDialogOpened = true;
            const matDialogRef = this.matDialog.open(GrantPermissionsComponent, {
                width: '50%',
                minHeight: '20%',
            });
            matDialogRef.afterClosed().subscribe(() => {
                this.isGoogleDialogOpened = false;
            });
        }
    }

    showTokenAPIFailureNotification() {
        if (!this.isTokenDialogOpened) {
            this.isTokenDialogOpened = true;
            const matDialogRef = this.matDialog.open(LogoutNotificationComponent, {
                minHeight: '15%',
                data: {
                    notificationinfo: 'GooglePriviledgeMissingInfo',
                },
            });
            matDialogRef.afterClosed().subscribe((result) => {
                if(result == 1) {
                    this.isTokenDialogOpened = false;
                    this.authSerive.logout();
                }
            });
        }
    }
}
