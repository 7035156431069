import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { iif } from 'rxjs';

import { BaseComponent } from '../../../shared/components/base.component';

@Component({
  selector: 'app-department-upsert',
  templateUrl: './department-upsert.component.html',
})
export class DepartmentUpsertComponent extends BaseComponent {
  isAdd = false;
  isSubmitting = false;

  departmentFormGroup: FormGroup;
  nameFormCtrl: FormControl;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<DepartmentUpsertComponent>) {
    super();

    this.isAdd = !data;

    this.nameFormCtrl = new FormControl(data?.name, [Validators.required]);

    this.departmentFormGroup = new FormGroup({
      name: this.nameFormCtrl,
    });
  }

  upsertDepartment() {
    if (this.departmentFormGroup.valid) {
      const model = this.departmentFormGroup.value;

      this.isSubmitting = true;

      iif(
        () => this.isAdd, // prettier-ignore
        this.appService.createDepartment(model),
        this.appService.updateDepartment(this.data?.guid, model)
      )
        .pipe(this.takeUntilDestroyed())
        .subscribe(
          () => {
            this.dialogRef.close(true);
          },
          () => {
            this.appService.showError(this.translate.instant('scp.departments.upsert.save_error_message'));

            this.isSubmitting = false;
          }
        );
    }
  }
}
