<form
  novalidate
  autocomplete="off"
  validateFormAndScrollToFirstError
  [formGroup]="settingsFormGroup"
  (ngSubmit)="saveSettings()"
  *ngIf="!hasError; else showInternalErrorMessage"
>
  <mat-card>
    <mat-card-content class="mb-5">
      <div class="scp_form_group">
        <label for="deletePrintFilesOlderThan">{{ 'scp.settings.schedule.fields.delete_print_files_older_than' | translate }}</label>
        <div>
          <input
            type="number"
            min="1"
            max="365"
            id="deletePrintFilesOlderThan"
            matInput
            [formControl]="deletePrintFilesOlderThan"
            (keypress)="numericOnly($event)"
          />
          <mat-hint class="pl-1">{{ 'scp.settings.schedule.fields.delete_print_files_older_than_hint' | translate }}</mat-hint>
          <mat-error *ngIf="deletePrintFilesOlderThan.hasError('required')"> {{ 'scp.common.validations.required' | translate }} </mat-error>
          <mat-error *ngIf="deletePrintFilesOlderThan.hasError('min')"> {{ 'scp.settings.schedule.validations.min' | translate }} </mat-error>
          <mat-error *ngIf="deletePrintFilesOlderThan.hasError('max')"> {{ 'scp.settings.schedule.validations.max' | translate }} </mat-error>
        </div>
      </div>
    </mat-card-content>

    <mat-divider></mat-divider>

    <mat-card-actions align="end" class="pt-4">
      <button mat-flat-button color="primary">{{ 'scp.common.update_button_text' | translate }}</button>
    </mat-card-actions>
  </mat-card>
</form>

<ng-template #showInternalErrorMessage>
  <mat-card>
    <mat-card-content>
      <p class="mb-3">{{ 'scp.settings.data_loading_error_message' | translate }}</p>
    </mat-card-content>

    <mat-card-actions align="end" class="pt-4">
      <button mat-flat-button color="primary" (click)="getSettingsData()">{{ 'scp.common.try_again_button_text' | translate }}</button>
    </mat-card-actions>
  </mat-card>
</ng-template>
