<div class="container">
    <h1 mat-dialog-title>{{ title }}</h1>
    <div>
        <div class="mb_16">{{ message }}</div>
    </div>
    <div *ngIf="showOption == true">
        <p>{{'DeleteConfirm' | translate }}</p>
        <div [ngClass]="{ disabled: true }">
            <mat-checkbox #synappx [disabled]="true" type="checkbox" id="synappx_cloud_print"
                name="synappx_cloud_print" value="synappx_cloud_print" [checked]="true">
                <span class="inner_label">{{ 'SynappxCloudPrint' | translate }}</span>
            </mat-checkbox>
        </div>
        <div *ngIf="showSynappxGo">
            <mat-checkbox #synappx type="checkbox" id="synappx" name="synappx" value="synappx"
                (change)="selectionChange($event.checked, 'synappx')">
                <span class="inner_label">{{ 'SynappxGo' | translate }}</span>
            </mat-checkbox>
        </div>
        <div *ngIf="showSynappxManage">
            <mat-checkbox #rmm type="checkbox" id="rmm" name="rmm" value="rmm"
                (change)="selectionChange($event.checked, 'rmm')">
                <span class="inner_label">{{ 'CloudRMM' | translate }}</span>
            </mat-checkbox>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button type="submit" (click)="onOk()" id="id-ok" class="scp_primary_btn">{{"Ok" |translate}}</button>
        <button mat-button (click)="onCancel()" id="id-cancel" class="scp_secondary_btn">{{"Cancel" |translate}}</button>
    </div>
</div>