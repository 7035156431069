import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared';
import { CallbackComponent } from './callback/callback.component';
import { ErrorLinkComponent } from './error-link/error-link.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
        canActivate: [AuthGuard],
    },
    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    {
        path: 'callback',
        loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
    },
    {
        path: 'service',
        loadChildren: () => import('./login/select-service/select-service.module').then(m => m.SelectServiceModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'error',
        loadChildren: () => import('./server-error/server-error.module').then(m => m.ServerErrorModule),
    },
    {
        path: 'access-denied',
        loadChildren: () => import('./access-denied/access-denied.module').then(m => m.AccessDeniedModule),
    },
    {
        path: 'not-found',
        loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule),
    },
    {
        path: 'selectprovider',
        loadChildren: () => import('./select-provider/select-provider.module').then(m => m.SelectProviderModule),
    },
    {
        path: 'guestprovider',
        loadChildren: () => import('./guest-provider/guest-provider.module').then(m => m.GuestProviderModule),
    },
    {
        path: 'errorlink',
        component: ErrorLinkComponent,
    },
    {
        path: 'access_token',
        component: CallbackComponent,
    },
    {
        path: 'auth0_error',
        loadChildren: () => import('./auth0-error/auth0-error.module').then(m => m.Auth0ErrorModule),
    },
    {
        path: 'calendar-message',
        loadChildren: () => import('./calendar-message/calendar-message.module').then(m => m.CalendarMessageModule),
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
