import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

    constructor(private authService: AuthService) { }

  ngOnInit() {
    //   console.log('call' + window.location.hash);
    //   this.authService.handleAuthentication();
  }

}
