import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  isAdminUser = false;

  constructor(private appService: AppService) {}

  ngOnInit() {
    this.isAdminUser = this.appService.isSCPAdminUser;
  }
}
