import { Component, Inject, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import moment from 'moment';
import { forkJoin, iif } from 'rxjs';

import { BaseComponent } from '../../../shared/components/base.component';

@Component({
  selector: 'app-reports-list-upsert',
  templateUrl: './reports-list-upsert.component.html',
  styleUrls: ['./reports-list-upsert.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportsListUpsertComponent extends BaseComponent implements OnInit {
  @ViewChildren('columnTable') columnTables: QueryList<MatTable<any>>;

  isAdd = false;
  isSubmitting = false;

  displayedColumns: string[] = ['reportColumn', 'aggregation', 'actions'];

  reportList;
  report;
  reportTemplate;

  reportSchema;
  columnsData;
  aggregationsData;

  addReportFormGroup: FormGroup;
  newReportFormCtrl: FormControl;
  originalReportFormCtrl: FormControl;
  newColumnFormCtrl: FormControl;
  newAggregationFormCtrl: FormControl;

  constructor(@Inject(MAT_DIALOG_DATA) data: any, private dialogRef: MatDialogRef<ReportsListUpsertComponent>) {
    super();

    this.isAdd = !data;

    this.newReportFormCtrl = new FormControl(data?.name, [Validators.required]);
    this.originalReportFormCtrl = new FormControl(data?.guid, [Validators.required]);
    this.newColumnFormCtrl = new FormControl('');
    this.newAggregationFormCtrl = new FormControl('');

    this.addReportFormGroup = new FormGroup({
      newReport: this.newReportFormCtrl,
      originalReport: this.originalReportFormCtrl,
      newColumn: this.newColumnFormCtrl,
      newAggregation: this.newAggregationFormCtrl,
    });
  }

  ngOnInit() {
    forkJoin([this.appService.getReports(), this.appService.getReportSchema()])
      .pipe(this.takeUntilDestroyed<[any, any]>())
      .subscribe(([reportsData, reportsSchemaData]) => {
        this.reportList = reportsData;
        this.reportSchema = reportsSchemaData;
      });

    if (this.originalReportFormCtrl.value) {
      this.getReportTemplateData(this.originalReportFormCtrl.value);
    }
  }

  hasTranslation(key) {
    const translation = this.translate.instant(key);
    return translation !== key && translation !== '';
  }

  onOriginalReportChange(value) {
    this.getReportTemplateData(value);
  }

  onNewColumnChange(value) {
    this.getAggregationData(value);
  }

  getColumData(tableName, dimension) {
    let filtered = [];
    let templateColumns = this.reportTemplate.filter((v) => v.name === tableName)[0].columns;

    this.reportSchema.columns.forEach((item) => {
      if (item.type.displayable) {
        if (item.key.includes(dimension) || item.type.key === 'integer') {
          if (!templateColumns.find((x) => x.reportColumn === item.key)) {
            filtered.push(item);
          }
        }
      }
    });

    return filtered;
  }

  getAggregationData(columnKey) {
    let filtered = [];

    if (this.newColumnFormCtrl.value !== '') {
      let column = this.reportSchema.columns.filter((v) => v.key === columnKey);

      this.reportSchema.aggregations.forEach((item) => {
        if (item.columnType.displayable) {
          if (item.key === 'sum') {
            if (item.columnType.key === column[0].type.key) {
              filtered.push(item);
            }
          }
        }
      });

      if (filtered.length === 0) {
        filtered.push({ key: '-', name: '-', columnType: { key: '-', displayable: true } });
      }
    }

    this.aggregationsData = filtered;

    if (this.aggregationsData.length === 1) {
      this.newAggregationFormCtrl = new FormControl(this.aggregationsData[0].key);
    } else {
      this.newAggregationFormCtrl = new FormControl('');
    }
  }

  getColumnName(key) {
    return this.reportSchema.columns.find((item) => item.key === key)?.name;
  }

  getAggregationName(key) {
    return this.reportSchema.aggregations.find((item) => item.key === key)?.name;
  }

  addTemplateColumn(tableName) {
    if (this.newColumnFormCtrl.value !== '' && this.newAggregationFormCtrl.value !== '') {
      let selectedTemplate = this.reportTemplate.find((item) => item.name === tableName);

      selectedTemplate.columns.push({ reportColumn: this.newColumnFormCtrl.value, aggregation: this.newAggregationFormCtrl.value, filters: null });

      let indexToUpdate = this.reportTemplate.findIndex((item) => item.name === tableName);
      this.reportTemplate.splice(indexToUpdate, 1, selectedTemplate);

      this.newColumnFormCtrl = new FormControl('');
      this.newAggregationFormCtrl = new FormControl('');
      this.aggregationsData = [];

      this.columnTables.forEach((child) => {
        child.renderRows();
      });
    }
  }

  deleteTemplateColumn(tableName, columnKey) {
    let selectedTemplate = this.reportTemplate.find((x) => x.name === tableName);
    selectedTemplate.columns = selectedTemplate.columns.filter((x) => x.reportColumn !== columnKey);

    let indexToUpdate = this.reportTemplate.findIndex((x) => x.name === tableName);
    this.reportTemplate.splice(indexToUpdate, 1, selectedTemplate);

    this.columnTables.forEach((child) => {
      child.renderRows();
    });
  }

  getReportTemplateData(guid) {
    this.appService
      .getReportTemplate(guid)
      .pipe(this.takeUntilDestroyed<[any]>())
      .subscribe((data) => {
        this.report = data;
        this.reportTemplate = this.report.templates;
      });
  }

  upsertReport() {
    if (this.addReportFormGroup.valid) {
      this.isSubmitting = true;

      iif(
        () => this.isAdd, // prettier-ignore
        this.appService.createReport({
          guid: '',
          name: this.newReportFormCtrl.value,
          createdUser: { guid: this.appService.userDetails?.guid },
          createdDttm: moment(),
          templates: this.reportTemplate,
        }),
        this.appService.updateReport(
          {
            guid: this.report.guid,
            name: this.newReportFormCtrl.value,
            createdUser: this.report.createdUser,
            createdDttm: moment(),
            templates: this.reportTemplate,
          },
          this.report.guid
        )
      )
        .pipe(this.takeUntilDestroyed())
        .subscribe(
          () => {
            this.dialogRef.close(true);
          },
          () => {
            this.appService.showError(this.translate.instant('scp.reports.execute.upsert.save_error_message'));

            this.isSubmitting = false;
          }
        );
    }
  }
}
