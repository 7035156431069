export enum SCPRouterLinks {
  root = '/cloudprint/',

  dashboard = '/cloudprint/dashboard',
  users = '/cloudprint/users',
  departments = '/cloudprint/departments',
  devices = '/cloudprint/devices',
  jobs = '/cloudprint/jobs',
  reports = '/cloudprint/reports',
  security = '/cloudprint/security',

  adminUsers = '/cloudprint/admin-users',
  tenant = '/cloudprint/tenant',
  subscriptions = '/cloudprint/subscriptions',
  adminLogs = '/cloudprint/admin-log',
  systemLogs = '/cloudprint/system-log',
  supportedDomains = '/cloudprint/supported-domains',

  error = '/cloudprint/error',
  offline = '/cloudprint/offline',
  unAuthorized = '/cloudprint/un-authorized',
  pageNotFound = '/cloudprint/page-not-found',
  sessionExpired = '/cloudprint/session-expired',
}
