import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription, from, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { User } from '../../../../shared/interfaces/User';
import { AdminInfo } from '../../AdminInfo';
import { LocalAppDataService } from '../../../../shared/services/local-app-data.service';
import { CloudPrintEnvService } from '../../../../shared/services/cloud-print-env.service';
import { IdpCommonService } from '../../../../shared/services/ssp/idp-common.service';
import { NotificationService } from '../../../../shared/services/ssp/notification.service';
import { ProgressLoaderService } from '../../../../shared/services/ssp/progress-loader.service';
import { UserService } from '../../../../shared/services/ssp/user.service';
import { AdminConfirmComponent } from '../admin-confirm/admin-confirm.component';
import { SelectServicesComponent } from '../select-services/select-services.component';
import { NotificationDialogComponent } from '../../../../shared/dialogs/notification-dialog/notification-dialog.component';
import { TranslateService } from "@ngx-translate/core";
import { AdminApprovalComponent } from '../../admin-approval/admin-approval.component';
import { GrantPermissionsComponent } from '../../grant-permissions/grant-permissions.component';


@Component({
    selector: 'lib-add-admin',
    templateUrl: './add-admin.component.html',
    styleUrls: ['./add-admin.component.scss']
})
export class AddAdminComponent implements OnInit {
    private subscription: Subscription;
    heading: string = 'AddAdmin';
    licence: string;
    /**
     * The list of users to be displayed in drop down. This property is directly used in the template HTML
     */
    userSearchResult = [];
    /**
     * The form group containing the admin user info (like name, email and auth0connectionName)
     */
    addAdminGroup: UntypedFormGroup;

    /**
     * The form control for text input
     */
    adminName: UntypedFormControl;

    /**
     * The form control for text input
     */
    adminEmail: UntypedFormControl;

    /**
     * The form control for select box
     */
    adminType: UntypedFormControl;

    searchType = 'Username';
    defaultDialogConfig = new MatDialogConfig();
    @ViewChild('adminName', { read: MatAutocompleteTrigger })
    autocomplete: MatAutocompleteTrigger;
    @ViewChild('emailSearch', { read: MatAutocompleteTrigger })
    autocompleteEmail: MatAutocompleteTrigger;
    adminApprovalDialogRef: MatDialogRef<AdminApprovalComponent>;
    permissionDialogRef: MatDialogRef<GrantPermissionsComponent>;
    enableSave = false;
    userName: string;
    isEdit: boolean;
    tenantTier: string;
    service: string = 'scp';
    userList: any;
    isStackRegionUS: boolean = false;
    // authResult: any;
    private selectedUser = null;
    public adminInfo: AdminInfo;
    public isO365 = true;
    public isUserReadGranted = false;
    public ispageLoaded = false;
    constructor(
        private progressLoader: ProgressLoaderService,
        private notificationService: NotificationService,
        private addAdminFB: UntypedFormBuilder,
        private dialog: MatDialog,
        public addDialogRef: MatDialogRef<AddAdminComponent>,
        private idpCommonService: IdpCommonService,
        private appDataService: LocalAppDataService,
        private scpEnvService: CloudPrintEnvService,
        private translate: TranslateService,
        public userService: UserService
    ) {
        this.defaultDialogConfig.autoFocus = false;
    }

    ngOnInit(): void {
        if(this.scpEnvService.stackRegion == 'US' ){
            this.isStackRegionUS = true;
        }
        // TODO: Get IDP Access token
        // this.idpCommonService.getIdpAccessToken();
        this.addAdminGroup = this.addAdminFB.group({
            adminName: new UntypedFormControl('', [Validators.required]),
            adminEmail: new UntypedFormControl('', [Validators.required]),
            adminType: new UntypedFormControl('Admin'),
        });
        this.adminName = this.addAdminGroup.get('adminName') as UntypedFormControl;
        this.adminEmail = this.addAdminGroup.get(
            'adminEmail'
        ) as UntypedFormControl;
        this.adminType = this.addAdminGroup.get('adminType') as UntypedFormControl;
        this.tenantTier = this.appDataService.TenantTier;
        this.configureAutoComplete();
        this.isO365 = (this.appDataService.ApplicationType == 'waad');
        this.idpCommonService.checkGrantReadUsers().subscribe((data) => {
            this.ispageLoaded = true;
            this.isUserReadGranted = data;
        }, (err) => {
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Set the selected admin's role (in the form)
     */
    set selectedAdminType(name: string) {
        this.addAdminGroup.get('adminType').setValue(name);
    }

    /**
     * Get the selected admin's role (from the form)
     */
    get selectedAdminType(): string {
        return this.adminType.value.toString();
    }

    /**
     * Set the selected user's name (in the form)
     */
    set selectedName(name: string) {
        this.addAdminGroup.get('adminName').setValue(name);
    }

    /**
     * Get the selected user's name (from the form)
     */
    get selectedName(): string {
        if (this.adminName.value !== undefined && this.adminName.value !== null) {
            return this.adminName.value.toString();
        } else {
            return null;
        }
    }

    /**
     * Set the selected user's Email (in the form)
     */
    set selectedEmail(email: string) {
        this.addAdminGroup.get('adminEmail').setValue(email);
    }

    /**
     * Get the selected user's Email (from the form)
     */
    get selectedEmail(): string {
        if (this.adminEmail.value !== undefined && this.adminEmail.value !== null) {
            return this.adminEmail.value.toString();
        } else {
            return null;
        }
    }

    selectSearch() {
        this.addAdminGroup.get('adminName').setValue('');
        this.addAdminGroup.get('adminEmail').setValue('');
    }

    onRadioButtonSelect() {
        this.addAdminGroup.markAsUntouched();
        this.addAdminGroup.updateValueAndValidity();
    }

    submit() {
        // emppty stuff
    }

    disableSave(): boolean {
        if (this.enableSave === true && this.addAdminGroup.valid === true) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Get the list of other services this user can be added to
     * @param queryResult The queryResult containing the user data
     * @returns An array of string containing service names (of other services as string. Value values: rmm, go)
     */
    availableServicesToAdd(queryResult: Array<any>): Array<string> {
        const otherServicesToAdd = [];
        let user = queryResult[0];
        const tenantData = this.appDataService.TenantInfoAtLogin;
        const isOnlySCPView: boolean = !(this.appDataService.ViewRMM || this.appDataService.ViewGo || this.appDataService.ViewMeeting);
        const hasAllLicense: boolean = tenantData.tier?.scp && (tenantData.tier?.marvel || tenantData.tier?.rocketStart) && tenantData.tier?.rmm;
        // Curent tenant has Synappx Manage License
        if (
            (
                !isOnlySCPView && this.appDataService.ViewRMM &&
                (!user?.rmmInfo?.role || user?.rmmInfo?.role == '' || user?.rmmInfo?.role == 'none' || user?.rmmInfo?.role !== 'IT Main')
            ) || 
            ((hasAllLicense || isOnlySCPView) && ['IT Helpdesk', 'Service Main', 'Service Support', 'Service View Only'].includes(user?.rmmInfo?.role))
        ) {
            otherServicesToAdd.push('rmm');
        }
        // Curent tenant has Synappx Go License
        // 'userType' field does not have 'ba' and 'adminType' field is empty
        if (
            (!isOnlySCPView && (this.appDataService.ViewGo || this.appDataService.ViewMeeting) &&
                (
                    (user?.userInfo.userType || '').toLowerCase().indexOf('ba') < 0 ||
                    !(user?.userInfo?.adminType || '') || 
                    user?.userInfo?.adminType !== 'Admin'
                )
            ) ||
            ((hasAllLicense || isOnlySCPView) && (user?.userInfo?.adminType === 'Support Admin'))
        ) {
            otherServicesToAdd.push('synappx');
        }
        // return the list of other services this can be added to
        return otherServicesToAdd;
    }

    /**
     * Handle the click event for Save button in the dialog
     * 
     * @description This method will save the selected user after displayed the select-services or confirmation dialog
     * 
     * The select services dialog will be displayed, if the user can be added as admin to other services also
     * The confirmation dialog will be dispalyed, if the user can only be added to current service (cloud print)
     */
    saveClick() {
        if (this.searchType === 'Email') {
            this.selectedName = this.userName;
        }
        const findUserQuery = { email: this.selectedEmail };
        this.userService.queryUser(findUserQuery).subscribe((queryResult) => {
            // Get the selectedUser
            this.selectedUser = null;
            if (queryResult && queryResult.length > 0) {
                this.selectedUser = queryResult[0];
            }

            // Prepare the userInfo part for current user (re-use existing data, except name and email)
            let userInfo = null;
            if (this.selectedUser) {
                userInfo = this.selectedUser.userInfo;
                // Name can be changed while importing from IDP
                userInfo.userName = this.selectedName;
            } else {
                userInfo = {
                    userName: this.selectedName,
                    email: this.selectedEmail,
                    isRootAdmin: this.selectedUser?.userInfo.isRootAdmin || false,
                    userType: this.selectedUser?.userInfo.userType || '',
                    adminType: this.selectedUser?.userInfo.adminType || ''
                };
            }
            // The cpInfo is regular admin
            const cpInfo = {
                role: 'Tenant Admin',
                userType: 'tenant_admin'
            };
            // For rmmInfo, re-use existing data
            this.adminInfo = new AdminInfo(userInfo, this.selectedUser?.rmmInfo, cpInfo);

            // Get the list of other services where this user can be added as admin
            const otherServices = this.availableServicesToAdd(queryResult);
            // console.log(`otherServices: ${otherServices}`);
            // If the selected user is already an admin in another service (go / rmm)
            if (otherServices && otherServices.length > 0) {
                // console.log('Showing Select Services dialog');
                // Add the user as admin to current (synappx cloud print) service and other remaining services (based on checkbox selection)
                this.defaultDialogConfig.data = {
                    adminInfo: this.adminInfo,
                    otherServices: otherServices,
                    email: this.selectedEmail,
                    name: this.selectedName,
                };
                const selectServicesDialogRef = this.dialog.open(SelectServicesComponent, this.defaultDialogConfig);
                selectServicesDialogRef.disableClose = false;
                selectServicesDialogRef.afterClosed().subscribe((result) => {
                    if (result && result.action && result.adminInfo) {
                        // User should be added as admin to Synappx Go
                        if (result.selectedServices.includes('synappx') && !result.alreadyAddedGo) {
                            const existingUserType = (this.adminInfo.userInfo?.userType || '').trim();
                            if (existingUserType.toLowerCase() == 'user') {
                                this.adminInfo.userInfo.userType = 'BA user';
                            } else {
                                this.adminInfo.userInfo.userType = 'BA';
                            }
                            this.adminInfo.userInfo.adminType = 'Admin';
                        }

                        // User should be added as admin to Synappx Manage
                        if (result.selectedServices.includes('rmm') && !result.alreadyAddedRMM) {
                            this.adminInfo.rmmInfo = {
                                role: "IT Main",
                                userType: "regular"
                            };
                        }
                        this.service = result.selectedServices.join(',');
                        this.AddSelectedUserAsAdmin();
                    }
                });

            } else {
                // console.log('Showing Add confirmation dialog');
                // Only add the user as admin to current (synappx cloud print) service
                this.defaultDialogConfig.data = {
                    title: [this.translate.instant('AddAdminUser')],
                    message: [this.translate.instant('ConfirmAddAdmin', { SelectedName: this.selectedName })]
                };
                this.openAdminConfirmDialog();
            }
        });
    }

    /**
     * Add admin user after showing add admin confirmation dialog to end-user
     */
    openAdminConfirmDialog() {
        // Open the confirmation dialog
        const confirmDialogRef = this.dialog.open(AdminConfirmComponent, this.defaultDialogConfig);
        // subscribe to dialog close result
        confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.enableSave = false;
                if (this.isEdit) {
                    this.updateAdmin();
                } else {
                    this.AddSelectedUserAsAdmin();
                }
            } else {
                console.log(`Cannot add ${this.selectedEmail} to administrator list`);
            }
            if (this.searchType === 'Email') {
                this.selectedName = this.adminEmail.value;
            }

        });
    }

    /**
     * Update admin user. Currently not implemented
     */
    updateAdmin() {
        // Not implemented
    }

    /**
     * Add the selected user as admin to database
     * 
     * 
     * @description This method will add the selected user as admin only to cloud-print service
     * The selected user's role in other services will not be affected
     */
    AddSelectedUserAsAdmin() {
        // console.log('Adding User to SSP Database:');
        // Show animation
        this.progressLoader.show();
        // Currently selected user is already present in the server
        // display a warning and return
        if (this.selectedUser && this.selectedUser.cpInfo && this.selectedUser.cpInfo.role && this.selectedUser.cpInfo.userType &&
            ['Tenant Admin', 'Primary Admin'].includes(this.selectedUser.cpInfo.role)) {
            this.warnAdminAlreadyExist('AdminAlreadyExists');
            this.addDialogRef.close();
            this.progressLoader.hide();
            return;
        }
        let userDetails = {
            auth0ConnectionName: this.appDataService.ConnectionName,
            userInfo: this.adminInfo.userInfo,
            rmmInfo: this.adminInfo.rmmInfo,
            cpInfo: this.adminInfo.cpInfo,
            licenseInfo: this.adminInfo.licenseInfo,
        } as User;

        const users = [userDetails];
        this.progressLoader.show();
        this.userService.createUsers(users, 'ba', this.service).subscribe(
            (result) => {
                this.progressLoader.hide();
                this.notificationService.showNotification(`AdminAdded`);
                this.addDialogRef.close(1);
            },
            (createError) => {
                this.progressLoader.hide();
                console.error(`Failed to add admin user: ${this.selectedEmail}`);
                console.log(createError);
                const interpolateParams = { count: this.scpEnvService.userLimit };
                if (createError.status === 409) {
                    this.notificationService.showNotification(`AdminLimitReached`, interpolateParams);
                } else {
                    this.notificationService.showNotification(`AdminAddFailure`);
                }
                this.addDialogRef.close();
            }
        );
    }

    /**
     * Open a dialog to display detailed message
     * @param actions The actions performed in user add
     */
    warnAdminAlreadyExist(actions) {
        // TODO: For displaying warning admin user already exists
        this.dialog.open(NotificationDialogComponent, {
            minHeight: '20%',
            data: { notificationinfo: this.translate.instant(actions), },
        });
    }

    onNoClick() {
        this.addDialogRef.close();
    }

    /**
     * Configure the auto complete option. This is done by add an event handler to the valueChanges event of input text control.
     * The event handler will call the IDP searchUsers to retrieve the list of users based on value in input.
     * The results will assigned to this.userSearchResult which is directly used in the HTML template
     */
    configureAutoComplete() {
        // Add a handler to the valueChange event of text input control
        // This handler will search for userName typed in the text input control
        // The search results will be assigned to the property this.userSearchResult
        this.subscription = this.addAdminGroup
            .get('adminName')
            .valueChanges.pipe(
                debounceTime(450),
                distinctUntilChanged(),
                switchMap((value) => from(this.searchForUser(value)))
            )
            .subscribe(
                (userList: Array<any>) => {
                    const userNames = userList.map((user) => user.userName);
                    let sameList = false;
                    if (userList.length === this.userSearchResult.length) {
                        sameList = true;
                        for (const item of this.userSearchResult) {
                            if (!userNames.includes(item.userName)) {
                                sameList = false;
                                break;
                            }
                        }
                    }
                    this.userSearchResult = userList;
                    // Automatic user selection (only if the search result contains single entry)
                    if (
                        userList.length === 0 &&
                        this.adminName.value.toString().length !== 0
                    ) {
                        this.notificationService.showNotification('UserNotFound');
                        this.addAdminGroup.setErrors({ invalid: true });
                    }
                    if (sameList) {
                        return;
                    }
                    if (userList.length === 1) {
                        this.userSelection(0);
                        if (this.searchType === 'Username' && this.autocomplete) {
                            this.autocomplete.closePanel();
                        }
                        if (this.searchType === 'Email' && this.autocompleteEmail) {
                            this.autocompleteEmail.closePanel();
                        }
                    }
                },
                (error) => {
                    // user Search failed at some point. Log the detailed error to console,
                    // then show a generic 'Network Failure' error to end-user
                    console.error(
                        `Error occured while search for admin users: ${JSON.stringify(
                            error
                        )}`
                    );
                    this.notificationService.showNotification('NetworkFailure');
                }
            );
    }

    openConfirmationDialog() {
        if (this.isO365) {
            this.adminApprovalDialogRef = this.dialog.open(AdminApprovalComponent, {
                width: '40%',
                minHeight: '20%',
                disableClose: true
            });
        } else {
            this.permissionDialogRef = this.dialog.open(GrantPermissionsComponent, {
                width: '50%',
                minHeight: '20%',
            });
        }
    }

    /**
     * Search for the given user name in the IDP directory
     * @param userToSearch The keyword (user name) to search in GSuite/Office365
     */
    searchForUser(userToSearch): Observable<any> {
        if (this.searchType === 'Email' && this.enableSave) {
            return of(this.userSearchResult);
        }
        if (!this.checkPermission()) {
            return of([]);
        }
        // TODO: Uncomment this code to search for user
        if (this.searchType === 'Username') {
            return this.idpCommonService.searchForUserIdp(userToSearch);
        } else {
            return this.idpCommonService.searchForUserIdpByEmail(userToSearch);
        }
    }

    public checkPermission() {
        const isAdminGranted = this.isUserReadGranted;
        if (!isAdminGranted) {
            this.addAdminGroup.get('adminName').setValue("");
            this.userSearchResult = [];
            this.addDialogRef.close(0);
            this.openConfirmationDialog();
            return false;
        }
        return true;
    }

    /**
     * Callback for Manual User selection in the drop down menu
     * @param userIndex The index of the manually selected user in the userList search results
     */
    userSelection(userIndex: number): void {
        this.enableSave = true;
        const user = this.userSearchResult[userIndex];
        this.selectedEmail = user.userEmail;
        this.selectedName =
            this.searchType === 'Email' ? user.userEmail : user.userName;
        this.userName = user.userName;
    }

    adminNameChange(event) {
        const matchingUser = this.userSearchResult.find(
            (user) => user.userName === event.target.value
        );
        if (!matchingUser) {
            this.enableSave = false;
        }
    }
}