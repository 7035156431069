<mat-card>
  <div fxLayoutAlign="space-between baseline">
    <mat-card-title>{{ 'scp.common.users' | translate }}</mat-card-title>

    <span [innerHTML]="'scp.users.users_count' | translate : { value: paginator?.length || 0 }"> </span>
  </div>

  <mat-card-actions fxLayoutAlign="space-between end" class="mb-0">
    <span fxLayout="row wrap" fxLayoutAlign="start center">
      <button class="large" mat-icon-button color="primary" matTooltip="{{ 'scp.users.add_user_button_text' | translate }}" (click)="showImportUsersDialog()">
        <mat-icon>add_circle</mat-icon>
      </button>

      <button
        class="large"
        mat-icon-button
        color="primary"
        matTooltip="{{ 'scp.users.delete_user_button_text' | translate }}"
        (click)="deleteUsers()"
        [disabled]="isSubmitting || shouldDisableDeleteButton"
      >
        <mat-icon>do_not_disturb_on</mat-icon>
      </button>

      <button mat-flat-button color="primary" class="mx-3" (click)="updateActivationStatus(true)" [disabled]="isSubmitting || shouldDisableActivateButton">
        {{ 'scp.users.activate_user_button_text' | translate }}
      </button>

      <button mat-flat-button color="primary" class="mx-0" (click)="updateActivationStatus(false)" [disabled]="isSubmitting || shouldDisableDeactivateButton">
        {{ 'scp.users.deactivate_user_button_text' | translate }}
      </button>

      <span fxLayoutAlign="start end" fxLayoutGap="16px" class="mx-3">
        <mat-form-field>
          <mat-label>{{ 'scp.users.filter_view_text' | translate }}</mat-label>
          <mat-select [formControl]="statusFilterFormCtrl">
            <mat-option value="all">{{ 'scp.users.filter_view_option_all' | translate }}</mat-option>
            <mat-option [value]="true">{{ 'scp.users.filter_view_option_active_users' | translate }}</mat-option>
            <mat-option [value]="false">{{ 'scp.users.filter_view_option_inactive_users' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label> {{ 'scp.users.filter_users_search_text' | translate }}</mat-label>
          <input matInput [formControl]="searchFilterFormCtrl" />
        </mat-form-field>
      </span>
    </span>

    <button mat-icon-button matTooltip="{{ 'scp.common.refresh_data_button_text' | translate }}" (click)="getUsersData()">
      <mat-icon>refresh</mat-icon>
    </button>
  </mat-card-actions>

  <mat-card-content>
    <div class="table-responsive mat-elevation-z8">
      <table mat-table matSort matSortActive="displayname" matSortDirection="asc" [dataSource]="dataSource" class="w-100">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="toggleAllRows()"
              [checked]="selection.hasValue() && isAllRowsSelected()"
              [indeterminate]="selection.hasValue() && !isAllRowsSelected()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (change)="selection.toggle(row.guid)" [checked]="selection.isSelected(row.guid)"> </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="activated">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.users.columns.status' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.activated ? ('scp.common.active' | translate) : ('scp.common.inactive' | translate) }}</td>
        </ng-container>

        <ng-container matColumnDef="userRole">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.users.columns.user_role' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.userRole === 'tenant_admin' ? 'Tenant Admin' : 'Tenant User' }}</td>
        </ng-container>

        <ng-container matColumnDef="displayname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.users.columns.user_name' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.displayname }}</td>
        </ng-container>

        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.users.columns.email' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.username }}</td>
        </ng-container>

        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef>{{ 'scp.users.columns.actions' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button [matMenuTriggerFor]="rowMenu" *ngIf="row.hasCard">
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #rowMenu="matMenu">
              <button mat-menu-item (click)="unregisterUserCard(row)">{{ 'scp.users.unregister_user_card_button_text' | translate }}</button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons aria-label="Select page"> </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
