<div class="container">
    <h1 mat-dialog-title>{{'PermissionsRequired' | translate}}</h1>
    <div class="permission_grant_msg">
        <span>{{'GlobalAdminGrantMessage' | translate}}: </span>
        <ul class="line-space">
            <li>{{'ADDomainListingMessage' | translate}}</li>
            <li>{{'GroupSearchMessageForUsers' | translate}}</li>
        </ul>
        <span> {{'GlobalAdminMessage' | translate}}
        </span>
    </div>
    <div mat-dialog-actions class="Center">
        <button class="primary_btn" mat-raised-button type="submit" (click)='submit();'>{{'GrantPermission' |
            translate}}</button>
        <button mat-button class="secondary_btn" id='id-deviceEdit-CancelButton' mat-raised-button
            (click)='cancel();'>{{'NotNow' |
            translate}}</button>
    </div>
</div>