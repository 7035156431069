
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, forkJoin, from , of, observable, timer} from 'rxjs';
import { AuthService } from './auth.service';
import { GoogleToken } from '../interfaces/user';
import { map, mergeMap, retryWhen, catchError, delay, take, concatMap, tap } from 'rxjs/operators';
import { Helper } from './helper';
import { IdpBaseService } from './idpBaseService';

const ADMINSCOPES = 'https://www.googleapis.com/auth/admin.directory.user.readonly,https://www.googleapis.com/auth/admin.directory.domain.readonly,https://www.googleapis.com/auth/admin.directory.resource.calendar.readonly,https://www.googleapis.com/auth/admin.directory.group.readonly,https://www.googleapis.com/auth/userinfo.profile';

const RMMADMINSCOPES = 'https://www.googleapis.com/auth/admin.directory.user.readonly,https://www.googleapis.com/auth/admin.directory.domain.readonly,https://www.googleapis.com/auth/userinfo.profile';

const scopeDictionary = {
    'https://www.googleapis.com/auth/admin.directory.user.readonly': 'users',
    'https://www.googleapis.com/auth/admin.directory.domain.readonly': 'domain',
    'https://www.googleapis.com/auth/admin.directory.resource.calendar.readonly': 'WorkspaceBuildings',
    'https://www.googleapis.com/auth/admin.directory.group.readonly': 'userGroups'
};
export class GoogleAPIService extends IdpBaseService {

    public googleAPIToken: GoogleToken;
    public calendarAccToken: GoogleToken;
    private calendarResourceIds = [] ;
    renewalTimer: any;

    public featureAPIToken = {
        'users' : null,
        'userGroups' : null,
        'WorkspaceBuildings': null,
        'domain': null
    };

    public googleProfileDetails: any = {
        customerId: 'my_customer',
    };    
    
    constructor(
        protected http: HttpClient,
        protected authService: AuthService,
    ) {
        super(http, authService);
        this.googleAPIToken = null;
    }

    /**
     * Get the Google access token.
     * If the token is already available, it will returned as it is.
     */
    public getIdpToken(googleScope?, checkscope: boolean = false): Observable<any> {
        const ADVANCE_REFRESH = 30;
        const googleAllScopes = this.authService.googleAllScopes;
        let tokenInfo = (googleAllScopes) ? this.googleAPIToken : this.featureAPIToken[scopeDictionary[googleScope]];

        // When the requested scope is NOT specified,
        // Set only required scopes for RMM when tenant has only RMM license
        let adminScopes = (this.authService.viewGo || this.authService.viewMeeting) ? ADMINSCOPES : RMMADMINSCOPES;
        googleScope = googleScope || adminScopes;

        return new Observable<any>((observer) => {
            let isTokenValid = false;
            if (tokenInfo && tokenInfo.expiry_date) {
                const expiry = tokenInfo.expiry_date;
                const duration = (expiry.valueOf() - Date.now()) / 1000;
                if (duration <= ADVANCE_REFRESH && duration > 0) {
                    console.log(`Idp token expiration in ${duration} seconds`);
                } else if (duration < 0) {
                    console.log(`Idp token expired ${duration * -1} seconds ago`);
                }
                isTokenValid = (duration > ADVANCE_REFRESH);
            }

            if (isTokenValid) {
                setTimeout(() => {
                    observer.next(tokenInfo);
                    observer.complete();
                }, 10);
                return;
            } else {
                // Generate an Google Workspace token by invoking the endpoint /api/token
                const httpOptions = {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.authService.accessToken,
                        'Request-Source': 'portal',
                        'gsuite-scope': googleScope
                    }),
                };
                const body = {
                    userid: this.authService.auth0userId,
                };
                this.getToken(observer, body, httpOptions, 3);
            }
        });
    }

    /**
     * Get the Token after 3 retries
     * @param observable The observable
     * @param body The request body for the getIdpAccessToken call
     * @param httpOptions The http options for the getIdpAccessToken call
     * @param retryCount Number of retries remaining
     */
    getToken(observable, body, httpOptions, retryCount) {
        this.http.post(environment.rmpEndPointToken.url, body, httpOptions)
            .subscribe(
                (token) => {
                    this.tokenInfo = token as any;
                    observable.next(this.tokenInfo);
                    observable.complete();
                },
                (error) => {
                    console.error(`Error renewal of token`);
                    console.warn(error);
                    if (error.status !== 429) {
                        observable.error(error);
                        observable.complete();
                    } else if (retryCount <= 0) {
                        this.authService.logout();
                        observable.error(error);
                        observable.complete();
                    } else {
                        setTimeout(() => {
                            this.getToken(observable, body, httpOptions, retryCount - 1);
                        }, environment.retryIntervalForHttp429);
                    }
                },
            );
    }

    /**
     * Get the google access token.
     */
    public getIdpAccessToken() {
        this.getIdpToken().subscribe((token: GoogleToken) => {
            this.googleAPIToken = token;
        });
    }

    RenewIdpAccessToken(scope?: any): Observable<any> {
        if (this.renewalTimer) {
            this.renewalTimer.unsubscribe();
        }
        const scpScopes = 'https://www.googleapis.com/auth/admin.directory.user.readonly,https://www.googleapis.com/auth/admin.directory.domain.readonly,https://www.googleapis.com/auth/admin.directory.group.readonly,https://www.googleapis.com/auth/userinfo.profile';
        const apiUrl = environment.rmpEndPointToken.url;
        // Generate a g-suite access_token by invoking the endpoint /api/token
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.authService.accessToken,
                'Request-Source': 'portal',
                'gsuite-scope': scpScopes
            }),
        };
        const body = {
            userid: this.authService.auth0userId,
        };
        return this.http.post(apiUrl, body, httpOptions)
            .pipe(
                tap({
                    next: (tokenInfo) => {
                        this.triggerRenewedToken(tokenInfo);
                    },
                    error: (error) => {
                        console.error(`Error renewal of token`);
                        console.warn(error);
                    }
                })
            );
    }

    triggerRenewedToken(tokenInfo) {
        this.authService.renewIdpToken.next(tokenInfo);
        const expiry = tokenInfo.expiry_date
        const duration = (expiry.valueOf() - Date.now()) / 1000;
        const renewalTokenTime = duration - 45;
        this.renewalTimer = timer(renewalTokenTime * 1000).subscribe(() => {
            this.RenewIdpAccessToken().subscribe({
                next: (value) => { },
                error: (err) => { console.log("Error in triggerRenewedToken ", err) }
            }
            );
        })
    }

    /**
     * Renew the g-suite access_token by calling the token api
     * @param scope: (optional) parameter to request access_token with only specific scopes.
     * If value not passed, admin scopes will be assumed
     * @returns The new token information obtained from server.
     */
    renewToken(scope?: any): Observable<any> {
        let adminScopes = (this.authService.viewGo || this.authService.viewMeeting) ? ADMINSCOPES : RMMADMINSCOPES;
        const apiUrl = environment.rmpEndPointToken.url;
        // Generate a g-suite access_token by invoking the endpoint /api/token
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.authService.accessToken,
                'Request-Source': 'portal',
                'gsuite-scope': scope || adminScopes
            }),
        };
        const body = {
            userid: this.authService.auth0userId,
        };
        return this.http.post(apiUrl, body, httpOptions)
            .pipe(
                tap({
                    next: (token) => {
                        // this.tokenInfo = token as any;
                    },
                    error: (error) => {
                        console.error(`Error renewal of token`);
                        console.warn(error);
                    }
                })
            );
    }

    /**
     * Method for getting Auth Permission status
     */
    public getAuthPermissions(googleScope?, checkscope?) {
        return of(true).pipe(
            concatMap(() => this.getIdpToken(googleScope, checkscope)),
            concatMap(tokenInfo => this.googleTokenInfo(googleScope, tokenInfo))
        );
    }

    public findMissingPermissionsInAzure() {
        console.error(`invalid call to findMissingPermissionsInAzure() method`);
        return of([]);
    }

    public checkOffice365Admin() {
        console.error(`invalid call to findMissingPermissionsInAzure() method`);
        return of([]);
    }

    public getUserOrGroupListByEmail(query, type, domains, groupType) {
        if (type === 'users') {
            return this.searchUsersByEmail(query, domains, false);
        } else {
            return this.getGoogleGroupsByEmail(query, groupType);
        }
    }

    public getRoomOrGroupListByEmail(query, type, domains, groupType) {
        query = query.replace(/[&#"'[\\\]+]/g,'_');
        if (type === 'users') {
            return this.searchRoomAPIByEmail(query, domains);
        }
    }

    /**
     * Gets the token info of google
     */
    public googleTokenInfo(googleScope, tokenInfo): Observable<any> {
        const featureType = scopeDictionary[googleScope];
        if (featureType) {
            this.featureAPIToken[featureType] = tokenInfo;
        }
        return of(tokenInfo);
    }

    public getRoomOrGroupList(query, type, domains): Observable<any> {
        query = query.replace(/[&#"'[\\\]+]/g,'_');
        if (type === 'users') {
            return this.searchRoomAPI(query, domains);
        } else {
            return this.searchBuildingAPI(query, domains);
        }
    }

    public checkGrantReadWorkspaceAndGroup(): Observable<any> {
        let [isGroupReadGranted, isUserReadGranted] = [false, false];
        const buildingsScope = 'https://www.googleapis.com/auth/admin.directory.resource.calendar.readonly';
        return this.getAuthPermissions(buildingsScope).pipe(
            map(data => {
                if (data !== null) {
                    isGroupReadGranted = true;
                    isUserReadGranted = true;
                }
                return [isGroupReadGranted, isUserReadGranted];
            }));
    }

    public checkGrantReadUsersAndGroup(): Observable<any> {
        let [isGroupReadGranted, isUserReadGranted] = [false, false];
        const usersScope = 'https://www.googleapis.com/auth/admin.directory.user.readonly';
        return this.getAuthPermissions(usersScope).pipe(
            map(data => {
                if (data !== null) {
                    isGroupReadGranted = true;
                    isUserReadGranted = true;
                }
                return [isGroupReadGranted, isUserReadGranted];
            }));
    }

    public checkGrantReadUsers(): Observable<any> {
        let isUserReadGranted = false;
        const usersScope = 'https://www.googleapis.com/auth/admin.directory.user.readonly';
        return this.getAuthPermissions(usersScope).pipe(
            map(data => {
                if (data !== null) {
                    isUserReadGranted = true;
                }
                return isUserReadGranted;
            }));
    }

    public checkGrantReadUserGroups(): Observable<any> {
        let isGroupReadGranted = false;
        const groupsScope = 'https://www.googleapis.com/auth/admin.directory.group.readonly';
        return this.getAuthPermissions(groupsScope).pipe(
            map(data => {
                if(data !== null) {
                    isGroupReadGranted = true;
                }
                return isGroupReadGranted;
            }));
    }

    private searchRoomAPI(query, domains) {
        const queryURL = encodeURI(`name:"${query}*"&orderBy=resourceName`);
        const URI = `https://www.googleapis.com/admin/directory/v1/customer/${this.googleProfileDetails.customerId}/resources/calendars?query=${queryURL}`;
        const googleToken = (this.authService.googleAllScopes) ? this.googleAPIToken.access_token : this.featureAPIToken.WorkspaceBuildings.access_token;
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + googleToken,
                accept: '*/*',
            }),
        };
        return this.http.get<any>(URI, httpOptions)
            .pipe(
                map((res) => {
                    return this.mapRooms(res.items);
                }),
                mergeMap((roomResponse: any) => {
                    const $obsArr = [];
                    if (roomResponse && roomResponse.length > 0) {
                        roomResponse.forEach((ele) => {
                            const $arr = this.getBuildingInfo(ele.place)
                                .pipe(
                                    retryWhen((errors) => errors.pipe(delay(1000), take(1))),
                                    catchError((err, caught) => of(err)),
                                );
                            $obsArr.push($arr);
                        });
                    } else {
                        return of([]);
                    }
                    return forkJoin($obsArr)
                    .pipe(
                        map((response: any) => {
                            console.log(response);
                            const combinedResponse = roomResponse.map((ele, i) => {
                                ele.place = response[i].buildingName;
                                return ele;
                            });
                            return combinedResponse;
                        }),
                    ); // inner pipe end

                }),

            ); // outer pipe
    }

    private searchRoomAPIByEmail(query, domains) {
        const queryURL = encodeURI(`resourceEmail="${query}"&orderBy=resourceName`);
        const URI = `https://www.googleapis.com/admin/directory/v1/customer/${this.googleProfileDetails.customerId}/resources/calendars?query=${queryURL}`;
        const googleToken = (this.authService.googleAllScopes) ? this.googleAPIToken.access_token : this.featureAPIToken.WorkspaceBuildings.access_token;
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + googleToken,
                accept: '*/*',
            }),
        };
        return this.http.get<any>(URI, httpOptions)
            .pipe(
                map((res) => {
                    return this.mapRooms(res.items);
                }),
                mergeMap((roomResponse: any) => {
                    const $obsArr = [];
                    if (roomResponse && roomResponse.length > 0) {
                        roomResponse.forEach((ele) => {
                            const $arr = this.getBuildingInfo(ele.place)
                                .pipe(
                                    retryWhen((errors) => errors.pipe(delay(1000), take(1))),
                                    catchError((err, caught) => of(err)),
                                );
                            $obsArr.push($arr);
                        });
                    } else {
                        return of([]);
                    }
                    return forkJoin($obsArr)
                        .pipe(
                            map((response: any) => {
                                console.log(response);
                                const combinedResponse = roomResponse.map((ele, i) => {
                                    ele.place = response[i].buildingName;
                                    return ele;
                                });
                                return combinedResponse;
                            }),
                        ); // inner pipe end

                }),

            ); // outer pipe
    }

    private searchBuildingAPI(query, domains) {
        const URI = `https://www.googleapis.com/admin/directory/v1/customer/${this.googleProfileDetails.customerId}/resources/buildings`;
        const googleToken = (this.authService.googleAllScopes) ? this.googleAPIToken.access_token : this.featureAPIToken['WorkspaceBuildings'].access_token;
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + googleToken,
                accept: '*/*',
            }),
        };
        return this.http.get<any>(URI, httpOptions)
            .pipe(
                map((res: any) => {
                    return this.mapBuildings(res.buildings, query);
                }),
            ); // Parent pipe end
    }


    public getUserOrGroupList(query, type, domains, groupType): Observable<any> {
        if (type === 'users') {
            return this.searchUsers(query, domains, false);
        } else {
            return this.getGoogleGroups(query, groupType);
        }
    }

    public getGoogleGroups(query, groupType): Observable<any> {
        query = query.replace(/[&#"'[\\\]+]/g, '_');
        const queryURL = encodeURI(`name:${query}*`);
        const URI = `https://www.googleapis.com/admin/directory/v1/groups?customer=${this.googleProfileDetails.customerId}&query=${queryURL}`;
        const googleToken = (this.authService.googleAllScopes) ? this.googleAPIToken.access_token : this.featureAPIToken.userGroups.access_token;
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + googleToken,
                accept: '*/*',
            }),
        };
        return this.http.get<any>(URI, httpOptions)
            .pipe(map((res) => {
                return this.mapGroups(res.groups, groupType);
            }));
    }

    public async getDomains(): Promise<any> {
        return new Promise((resolve, reject) => {
            const domainScope = 'https://www.googleapis.com/auth/admin.directory.domain.readonly';
            this.getIdpToken(domainScope).subscribe((token: GoogleToken) => {
                const URI = `https://www.googleapis.com/admin/directory/v1/customer/${this.googleProfileDetails.customerId}/domains`;
                const httpOptions = {
                    headers: new HttpHeaders({
                        Authorization: 'Bearer ' + token.access_token,
                        accept: '*/*',
                    }),
                };
                return this.http.get<any>(URI, httpOptions)
                    .toPromise()
                    .then((res) => resolve(this.extractDomains(res)))
                    .catch((err) => reject(err));
            }, (err) => {
                console.error(`error occured while obtaining google token`);
                console.log(err);
                reject(err);
            });
        });
    }

    public getGoogleGroupsByEmail(query, groupType): Observable<any> {
        query = query.replace(/[&#"'[\\\]+]/g, '_');
        const queryURL = encodeURI(`email:${query}*`);
        const URI = `https://www.googleapis.com/admin/directory/v1/groups?customer=${this.googleProfileDetails.customerId}&query=${queryURL}`;
        const googleToken = (this.authService.googleAllScopes) ? this.googleAPIToken.access_token : this.featureAPIToken.userGroups.access_token;
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + googleToken,
                accept: '*/*',
            }),
        };
        return this.http.get<any>(URI, httpOptions)
            .pipe(map((res) => {
                return this.mapGroups(res.groups, groupType);
            }));
    }

    public getRoomListDetails(resourceId): Observable<any> {
        const URI = `https://www.googleapis.com/admin/directory/v1/customer/${this.googleProfileDetails.customerId}/resources/calendars/${resourceId}`;
        const googleToken = (this.authService.googleAllScopes) ? this.googleAPIToken.access_token : this.featureAPIToken['WorkspaceBuildings'].access_token;
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + googleToken,
                accept: '*/*',
            }),
        };
        return this.http.get<any[]>(URI, httpOptions).pipe(
            mergeMap((resourceResponse: any) => {
                return this.getBuildingInfo(resourceResponse.buildingId).pipe(
                    map((buildingResponse: any) => {
                        const combinedResponse = {
                            resourceId: resourceResponse.resourceId,
                            buildingId: resourceResponse.buildingId,
                            resourceName: resourceResponse.resourceName,
                            resourceEmail: resourceResponse.resourceEmail,
                            buildingName: buildingResponse.buildingName,
                        };
                        return combinedResponse;
                    }),
                );  // close inner pipe
            }),
        );  // close outer pipe
    }

    replaceAll(text, old, next) {
        const parts = text.split(old);
        return parts.join(next);
    }

    escapeQuotes(text) {
        text = this.replaceAll(text, "\'", "\\\'");
        text = this.replaceAll(text, '\"', '\\\"');
        return text;
    }

    public searchUsers(query, domains, isAdmin = true): Observable<any> {
        query = query.replace(/[&#"[\\\]+]/g, '_');
        query = this.escapeQuotes(query); // handling apostrophe in query
        // Return observable, if query has empty.
        if (query === '') {
            return of([]);
        }
        // Google Users API does not allow space followed by *.
        // So Remove * when the query contains space
        const queryURL = encodeURI(`givenName:${query.includes(' ') ? query : query + '*'}`);
        const URI = `https://www.googleapis.com/admin/directory/v1/users?customer=${this.googleProfileDetails.customerId}&query=${queryURL}`;
        const googleToken = (this.authService.googleAllScopes) ? this.googleAPIToken.access_token : this.featureAPIToken.users.access_token;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + googleToken,
                'accept': '*/*',
            }),
        };
        return this.http.get<any>(URI, httpOptions)
            .pipe(map((res) => {
                if (isAdmin) {
                    return this.mapAdminList(res.users, domains);
                } else {
                    return this.mapUserList(res.users, domains);
                }
            }));
    }

    public searchUsersByEmail(query, domains, isAdmin = true): Observable<any> {
        query = query.replace(/[&#"[\\\]+]/g, '_');
        query = this.escapeQuotes(query); // handling apostrophe in query
        // Return observable, if query has empty.
        if (query === '') {
            return of([]);
        }
        // Google Users API does not allow space followed by *.
        // So Remove * when the query contains space
        const queryURL = encodeURI(`email:${query.includes(' ') ? query : query + '*'}`);
        const URI = `https://www.googleapis.com/admin/directory/v1/users?customer=${this.googleProfileDetails.customerId}&query=${queryURL}`;
        const googleToken = (this.authService.googleAllScopes) ? this.googleAPIToken.access_token : this.featureAPIToken.users.access_token;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + googleToken,
                'accept': '*/*',
            }),
        };
        return this.http.get<any>(URI, httpOptions)
            .pipe(map((res) => {
                if (isAdmin) {
                    return this.mapAdminList(res.users, domains);
                } else {
                    return this.mapUserList(res.users, domains);
                }
            }));
    }

    public getGoogleUserByEmail(email): Observable<any> {
        return new Observable((observer) => {
            const userScope = 'https://www.googleapis.com/auth/admin.directory.user.readonly';
            this.getIdpToken(userScope).subscribe((token: GoogleToken) => {
                const URI = `https://www.googleapis.com/admin/directory/v1/users/${email}`;
                const httpOptions = {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.access_token,
                        'accept': '*/*',
                    }),
                };
                this.http.get<any>(URI, httpOptions).subscribe((res) => {
                    observer.next(res);
                    observer.complete();
                }, (error) => {
                    observer.error(error);
                });
            }, (err) => {
                console.error(`error occured while obtaining google token`);
                console.log(err);
                observer.error(err);
            });
        });
    }

    public getLoggedInUserDetails(): Observable<any> {
        return this.getGoogleUserByEmail(this.authService.userEmail)
            .pipe(map((res: any) => {
                return {
                    displayName: res.name.fullName,
                };
            }));
    }

    public async getWorkspaceGroupMembers(buildingId, domains, buildingName): Promise<any> {
        buildingId = buildingId.replace(/[&#"'[\\\]+]/g, '_'); // to escape " if any present before encoding
        const queryURL = encodeURIComponent(`buildingId="${buildingId}"`); // enlosing buildingid in double quotes for handling special characters
        const URI = `https://www.googleapis.com/admin/directory/v1/customer/${this.googleProfileDetails.customerId}/resources/calendars?query=${queryURL}`;
        const googleToken = (this.authService.googleAllScopes) ? this.googleAPIToken.access_token : this.featureAPIToken.WorkspaceBuildings.access_token;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + googleToken,
                'accept': '*/*',
            }),
        };
        return this.http.get<any>(URI, httpOptions)
            .toPromise()
            .then((res) => this.extractBuildingMembers(res, buildingName))
            .catch(Helper.handlePromiseError);
    }

    public async getGroupMembers(groupId, domains): Promise<any> {
        const URI = `https://www.googleapis.com/admin/directory/v1/groups/${groupId}/members`;
        const googleToken = (this.authService.googleAllScopes) ? this.googleAPIToken.access_token : this.featureAPIToken.userGroups.access_token;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + googleToken,
                'accept': '*/*',
            }),
        };
        return this.http.get<any>(URI, httpOptions)
            .pipe(
                mergeMap((res) => {
                    if (res.members) {
                        const validType = res.members.filter((result) => result.type =='USER');
                        const emailList = validType.map((ele) => ele.email);
                        // Get the email list of only valid domains
                        const validEmailList = emailList.filter((emailId) => domains.some((domain) => emailId.includes(domain)));
                        // For each email, make a call to get additional user info
                        const $obsArr = validEmailList.map((ele) => this.getGoogleUserByEmail(ele));
                        // Combine results from all observables
                        return forkJoin($obsArr);
                    } else {
                        return from([]);
                    }
                }),
            )
            .toPromise()
            .then((response) => this.extractGroupMembers(response, domains))
            .catch(Helper.handlePromiseError);
    }

    public getBuildingInfo(buildingId): Observable<any> {
        buildingId = encodeURIComponent(buildingId);
        const URI = `https://www.googleapis.com/admin/directory/v1/customer/${this.googleProfileDetails.customerId}/resources/buildings/${buildingId}`;
        const googleToken = (this.authService.googleAllScopes) ? this.googleAPIToken.access_token : this.featureAPIToken.WorkspaceBuildings.access_token;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + googleToken,
                'accept': '*/*',
            }),
        };
        return this.http.get<any>(URI, httpOptions);
    }
    private extractBuildingMembers(res, buildingName) {
        let body = [];
        if(res.items && res.items.length > 0) {
            body = res.items
            .filter((val) => val.resourceCategory === 'CONFERENCE_ROOM')
            .map((val) => {
                return {
                    name: val.resourceName.toString(),
                    emailId: val.resourceEmail.toString(),
                    place: (buildingName || '').toString(),
                };
            });
        }
        return Promise.resolve([body, []] || {});
    }

    private extractGroupMembers(res, domains) {
        res = res || [];
        const body = res.map((val) => {
            return {
                displayName: val.name.fullName,
                name: val.name.fullName,
                emailId: val.emails.filter((data) => data.primary)[0].address,
            };
        });
        const validAddr = body.filter((entry) => (Helper.isValidDomain(entry.emailId, domains)));
        const blockedAddr = body.filter((entry) => (!Helper.isValidDomain(entry.emailId, domains)));
        return Promise.resolve([validAddr, blockedAddr] || {});
    }

    private mapAdminList(arr, domains) {
        arr = arr || [];
        const mappedList = arr.map((item) => {
            return {
                userName: item.name.fullName,
                userEmail: item.emails.filter((data) => data.primary)[0].address,
            };
        }).filter((entry) => (Helper.isValidDomain(entry.userEmail, domains)));
        return mappedList || [];
    }

    private mapUserList(arr, domains) {
        arr = arr || [];
        const mappedList = arr.map((item) => {
            return {
                displayName: item.name.fullName,
                name: item.name.fullName,
                emailId: item.emails.filter((data) => data.primary)[0].address,
            };
        }).filter((entry) => Helper.isValidDomain(entry.emailId, domains));
        return mappedList || [];
    }

    private mapRooms(arr) {
        arr = arr || [];
        let mappedList = arr.map((item) => {
            return {
                displayName: item.resourceName,
                name: item.resourceName,
                emailId: item.resourceEmail,
                place: item.buildingId || '',
            };
        });
        return mappedList || [];
    }

    private mapBuildings(arr, query) {
        arr = arr || [];
        let mappedList = arr.map((item) => {
            return {
                id: item.buildingId,
                // displayName: entry.displayName.toString() + ' (' + entry.members.length + ((entry.members.length >= 20) ? '+' : '') + ' Rooms)',
                name: item.buildingName,
                emailId: 'exampleofficeGroup@gsuite.com', // hard-cording this value as email for groups is not required
                displayName: item.buildingName,
            };
        }).filter((ele) => ele.displayName.startsWith(query));
        return mappedList || [];
    }

    private mapGroups(arr, groupType) {
        const mappedList = (arr || []).map((item) => {
            return {
                id: item.id,
                name: `${item.name} ( ${item.directMembersCount} )`,
                emailId: item.email, // hard-cording this value as email for groups is not required
                displayName: `${item.name} (${item.directMembersCount} ` + groupType + `)`,
            };
        });
        return mappedList || [];
    }

    private extractDomains(res) {
        res = res || [];
        const body = res.domains.map((val) => {
            return val.domainName;
        });
        return Promise.resolve(body || []);
    }

    public getCalendarAccountResources(): Observable<any> {
        this.getCalendarTokenInfo().subscribe((token: GoogleToken) => {
            this.calendarAccToken = token;
            const URI = `https://www.googleapis.com/calendar/v3/users/me/calendarList`;
            const calendarToken = this.calendarAccToken.access_token;
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + calendarToken,
                    'accept': '*/*',
                }),
            };
            this.http.get<any>(URI, httpOptions).subscribe(
                (resources) => {
                    this.calendarResourceIds = resources.items.map(item => item.id);
                    this.authService.calendarResourceIds = this.calendarResourceIds;
                });
        });
        return of([]);
    }

    private getCalendarTokenInfo(): Observable<any> {
        const ADVANCE_REFRESH = 30;
        let tokenInfo = this.calendarAccToken;
        return new Observable<any>((observer) => {
            let isTokenValid = false;
            if (tokenInfo && tokenInfo.expiry_date) {
                const expiry = tokenInfo.expiry_date;
                const duration = (expiry.valueOf() - Date.now()) / 1000;
                if (duration <= ADVANCE_REFRESH && duration > 0) {
                    console.log(`calendar token expiration in ${duration} seconds`);
                } else if (duration < 0) {
                    console.log(`calendar token expired ${duration * -1} seconds ago`);
                }
                isTokenValid = (duration > ADVANCE_REFRESH);
            }
            if (isTokenValid) {
                setTimeout(() => {
                    observer.next(tokenInfo);
                    observer.complete();
                }, 10);
                return;
            } else {
                const httpOptions = {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.authService.accessToken
                    }),
                };
                this.requestCalendarToken(observer, httpOptions, 3);
            }
        });
    }

    private requestCalendarToken(observable, httpOptions, retryCount) {
        this.http.get(`${environment.rmpEndPointUser.url}/calendarToken`, httpOptions)
            .subscribe(
                (token) => {
                    observable.next(token);
                    observable.complete();
                },
                (error) => {
                    console.error(`Error renewal of token`);
                    console.warn(error);
                    if (error.status !== 429) {
                        observable.error(error);
                        observable.complete();
                    } else if (retryCount <= 0) {
                        observable.error(error);
                        observable.complete();
                    } else {
                        setTimeout(() => {
                            this.requestCalendarToken(observable, httpOptions, retryCount - 1);
                        }, environment.retryIntervalForHttp429);
                    }
                },
            );
    }

}
