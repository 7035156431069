import { Injectable } from '@angular/core';
import { O365AuthService } from './o365Auth.service';
import { GoogleAPIService } from './google-api.service';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { IdpBaseService } from './idpBaseService';

@Injectable({
  providedIn: 'root'
})
export class IdpCommonService {

    idpBaseService: IdpBaseService;
    private isGoogle: boolean;
    public domains: string[] = [];

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private translate: TranslateService,
    ) {
        this.authService.tenantAvailable.subscribe({
            next: (tenantData) => {
                if (tenantData) {
                    this.initialize(tenantData);
                }
            }
        });
    }

    initialize(tenantData) {
        // Multi Domain handling
        this.domains = (tenantData.domainAliases || []).slice();
        let tenantDomain = tenantData.customerContactEmail.split('@')[1];
        tenantDomain = tenantDomain.toLowerCase();
        this.domains.push(tenantDomain);

        this.isGoogle = this.authService.applicationType === 'google-apps';
        if (this.isGoogle) {
            this.idpBaseService = new GoogleAPIService(this.http, this.authService);
        } else {
            this.idpBaseService = new O365AuthService(this.http, this.authService);
        }
    }

    getIdpAccessToken() {
        this.idpBaseService.getIdpAccessToken();
    }

    getIdpToken() {
        return this.idpBaseService.getIdpToken();
    }

    renewToken(scope?: any): Observable<any> {
        return this.idpBaseService.renewToken(scope);
    }

getAuthPermissions(googlescope?, scopeCheck?) {
    return this.idpBaseService.getAuthPermissions(googlescope, scopeCheck);
}

findMissingPermissionsInAzure(googlescope?, scopeCheck?) {
    return this.idpBaseService.findMissingPermissionsInAzure(googlescope, scopeCheck);
}

checkOffice365Admin(googlescope ?, scopeCheck ?) {
    return this.idpBaseService.checkOffice365Admin(googlescope, scopeCheck);
}

getWorkspaceGroupMembers(groupId, groupName?): Promise<any[]> {
    if (groupName) {
        return this.idpBaseService.getWorkspaceGroupMembers(groupId, this.domains, groupName);
    } else {
        return this.idpBaseService.getWorkspaceGroupMembers(groupId, this.domains);
    }
}

getGroupMembers(groupId): Promise<any[]> {
        return this.idpBaseService.getGroupMembers(groupId, this.domains);
}

searchForUserIdp(userToSearch): Observable<any> {
        return this.idpBaseService.searchUsers(userToSearch.slice(0, 100), this.domains);
}

searchForUserIdpByEmail(userToSearch): Observable<any> {
    return this.idpBaseService.searchUsersByEmail(userToSearch.slice(0, 100), this.domains);
}

getRoomOrGroups(query, type): Observable<any> {
        return this.idpBaseService.getRoomOrGroupList(query.slice(0, 100).replace(/'/g, "''"), type, this.domains, this.translate.instant('Rooms'));
}

getRoomOrGroupsEmail(query, type): Observable<any> {
    return this.idpBaseService.getRoomOrGroupListByEmail(query.slice(0, 100).replace(/'/g, "''"), type, this.domains, this.translate.instant('Rooms'));
}

getUserOrGroups(query, type): Observable<any> {
        return this.idpBaseService.getUserOrGroupList(query.slice(0, 100).replace(/'/g, "''"), type, this.domains, this.translate.instant('Users'));
}


getUserOrGroupsEmail(query, type): Observable<any> {
        return this.idpBaseService.getUserOrGroupListByEmail(query.slice(0, 100).replace(/'/g, "''"), type, this.domains, this.translate.instant('Users'));
}

getLoggedInUserInfo(): Observable<any> {
        return this.idpBaseService.getLoggedInUserDetails();
}

getIdpDomain(): Promise<any> {
        return this.idpBaseService.getDomains();
}

getRoomListDetails(id): Observable<any> {
        return this.idpBaseService.getRoomListDetails(id);
}

checkGrantReadWorkspaceAndGroup(): Observable<[boolean, boolean]> {
        return this.idpBaseService.checkGrantReadWorkspaceAndGroup();
}

checkGrantReadUsersAndGroup(): Observable<[boolean, boolean]> {
    return this.idpBaseService.checkGrantReadUsersAndGroup();
}

checkGrantReadUsers(): Observable<boolean> {
    return this.idpBaseService.checkGrantReadUsers();
}

checkGrantReadUserGroups(): Observable<boolean> {
    return this.idpBaseService.checkGrantReadUserGroups();
}

getCalendarAccountResources(): Observable<any> {
    return this.idpBaseService.getCalendarAccountResources();
}

RenewIdpAccessToken(scope?: any): Observable<any> {
    return this.idpBaseService.RenewIdpAccessToken(scope);
}

}
