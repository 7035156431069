<div class="scp_theme mat-typography scp_dialog">
  <h2 mat-dialog-title>{{ data.title }}</h2>

  <div class="mat-mdc-dialog-content">
    <!-- data.message is not translated here, as it is already translated considering the possibility of variable strings within it. -->
    <div innerHTML="{{ data.mainMessage }}"></div>
    <div *ngIf="data.isMultiLine && data.subMessage1" innerHTML="{{ data.subMessage1 }}"></div>
    <div *ngIf="data.isMultiLine && data.subMessage2" innerHTML="{{ data.subMessage2 }}"></div>
  </div>

  <div mat-dialog-actions class="dialog-actions">
    <button mat-flat-button mat-dialog-close (click)="cancel()" class="mat-mdc-button">{{ data.cancelText }}</button>
    <button mat-flat-button type="submit" (click)="confirm()" class="mat-mdc-raised-button">
      {{ data.confirmText }}
    </button>
    <!-- <button *ngIf='!data.infoMode' mat-button (click)="cancel()" id="id-cancel"
            class="secondary_btn">{{data.cancelText}}</button> -->
  </div>
</div>
