import { Component, OnInit } from '@angular/core';
import { delay } from 'rxjs/operators';

import { BaseComponent } from '../../../shared/components/base.component';
import { ITenantUser } from '../../../shared/models/scp-model';

@Component({
  selector: 'settings-pin-replacement',
  templateUrl: './settings-pin-replacement.component.html',
  styleUrls: ['./settings-pin-replacement.component.scss'],
})
export class SettingsPinReplacementComponent extends BaseComponent implements OnInit {
  isSubmitting = false;
  isNewPinDisabled = false;
  showSuccessMessage = false;

  newPin;
  pinCounter = 0;
  loggedInUser: ITenantUser;

  constructor() {
    super();
  }

  ngOnInit() {
    this.getNewPin();
  }

  getNewPin() {
    if (this.pinCounter < 5) {
      this.isSubmitting = true;
      this.newPin = null;

      this.appService
        .getNewPin(this.appService.userDetails.tenantGuid)
        .pipe(this.takeUntilDestroyed(), delay(500))
        .subscribe(
          (data) => {
            this.newPin = data;

            this.pinCounter++;
            this.isSubmitting = false;
          },
          (err) => {
            console.error('getNewPin', JSON.stringify(err, null, 2));
            this.appService.showError(this.translate.instant('scp.settings.pin_replacement.new_pin_error_message'));
            this.isSubmitting = false;
          }
        );
    }

    if (this.pinCounter === 4) {
      this.isNewPinDisabled = true;
    }
  }

  updateUser() {
    this.isSubmitting = true;

    this.appService
      .updateUser(this.appService.userDetails.guid, {
        pin: this.newPin,
      })
      .pipe(this.takeUntilDestroyed())
      .subscribe(
        () => {
          this.showSuccessMessage = true;
          this.isNewPinDisabled = true;
        },
        () => {
          this.appService.showError(this.translate.instant('scp.settings.pin_replacement.save_error_message'));

          this.isSubmitting = false;
        }
      );
  }
}
