<div class="container">
    <h1 *ngIf="isO365" mat-dialog-title>{{"PermissionsRequired" | translate}}</h1>
    <ng-container *ngIf="isO365">
        <div class="permission_grant_msg">
            <span><b>{{"PermissionsRequiredDescription" | translate}}</b></span>
        </div>
        <div><b><span [innerHTML]="PermissionsRequiredMessage"></span></b></div>
    </ng-container>
    <ng-container *ngIf="isGoogle">
        <div *ngIf="!isStackRegionEU" class="permission_grant_msg">
            <span [innerHTML]="GooglePermissionDescription"><b></b></span><br>
            <br>
            <table>
                <tr>
                    <th width="33%">{{'GooglePrivilgeHeading' | translate}}</th>
                    <th width="67%">{{'GooglePrivilgePurposeHeading' | translate}}</th>
                </tr>
                <tr>
                    <td>{{'GoogleUserScopePrivilge' | translate}}</td>
                    <td>{{'GoogleUserPrivilgeDescription' | translate}}</td>
                </tr>
                <tr>
                    <td>{{'GoogleGroupScopePrivilge' | translate}}</td>
                    <td>{{'GoogleGroupPrivilgeDescription' | translate}}</td>
                </tr>
                <tr>
                    <td>{{'GoogleDomainScopePrivilge' | translate}}</td>
                    <td>{{'GoogleDomainPrivilgeDescription' | translate}}</td>
                </tr>
            </table>
            <br>
            <span [innerHTML]="GooglePermissionMessage"><b></b></span>
        </div>
        <div *ngIf="isStackRegionEU" class="permission_grant_msg">
            <div>{{'AdditionalPermissionRequired' | translate}}</div>
        </div>
    </ng-container>
    <div class="more-information-message">
        <span *ngIf="isStackRegionEU && isGoogle" [innerHTML]="MoreInformationMessage"><b></b></span>
        <button class="primary_btn" mat-raised-button (click)='submit();'>{{"Ok" | translate}}</button>
    </div>
</div>