
import { AuthEnv, Auth0Config } from 'auth-n';
import { environment } from '../environments/environment';


// Provide a value for Environment class defined in the library
// The values will be taken from the actual environment of this application
export const AUTH_ENV: AuthEnv = {
    production: environment.production,
    AuthConfig: environment.AuthConfig as Auth0Config,
    googleScopes: environment.googleScopes,
    sspCommonApiBaseUrl: environment.rmpEndPointUser.url,
    sspCommonTenantUrl: environment.rmpEndPointTenant.url,
    sharpstart_logout_url: environment.sharpstart_logout_url,
    stackRegion: environment.stackRegion
}
