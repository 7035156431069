import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class GoServiceGuard implements CanActivate, CanLoad {


    public isGoActive = true;

    constructor(private authService: AuthService) {
        this.isGoActive = this.authService.viewGo || this.authService.viewMeeting;
    }

    canActivate() {
        if (!this.isGoActive) {
            return false;
        } else {
            return true;
        }
    }

    canLoad() {
        if (!this.isGoActive) {
            return false;
        } else {
            return true;
        }
    }


}
