<div *ngIf="!ispageLoaded" class="load_supported_domain">
    <mat-spinner [diameter]="70" class="mat-spinner-color"></mat-spinner>
</div>
<mat-card *ngIf="ispageLoaded" class="custom_admin">
    <div class="fx_space-between-center">
        <mat-card-title id='room-list-mat-title-id'>{{ 'SupportedDomains' | translate }}</mat-card-title>
    </div>
<mat-card-content>
<div class="domains_refresh fx_space-between-center">
         <div *ngIf="!isPermissionGranted && this.isO365">
            <div class="fx_row">
                <div class="fx_startcenter">
                  <button mat-icon-button color="primary" (click)="addRow()" matTooltip="Add Admin" matTooltip="{{ 'AddDomain'| translate}}" class="primary_icon_btn mr_10 b_pos"
                        matTooltipPosition="above" mat-icon-button [disabled]="disableAddIcon">
                    <mat-icon>add_circle</mat-icon>
                  </button>
                  <button mat-icon-button color="primary" (click)="removeSelectedDomain()" [disabled]="deleteButtonDisabled" class="primary_icon_btn mr_10 b_pos"
                    matTooltip="Remove Admin"id="subDomain-remove-button" matTooltip="{{ 'RemoveDomain' | translate}}" matTooltipPosition="above" mat-icon-button>
                    <mat-icon>remove_circle</mat-icon>
                  </button>
                </div>
            </div>
        </div>
        <mat-card-title></mat-card-title>
        <button *ngIf="isPermissionGranted || this.isGoogle" class="icon_btn fx_space-between-center"
            mat-icon-button id="sspMulti-Domain"
            matTooltip="{{'refreshList'| translate}}" matTooltipPosition=above (click)="refreshDomain(true)">
            <mat-icon aria-label="Example icon-button with a refresh icon">refresh</mat-icon>
        </button>
    </div>
    <form #multiDomainForm="ngForm" novalidate>
        <div class="example-container mat-elevation-z8 mt_15">
            <mat-table #table *ngIf="isPermissionGranted || this.isGoogle" [dataSource]="dataSource" matSort
                class="mat-cell" matSortActive="Status" matSortDirection="asc">
                <!-- Admin Column -->
                <ng-container matColumnDef="Domains">
                    <mat-header-cell class="fx_space-between-center" *matHeaderCellDef>
                    <span *ngIf="this.isGoogle; else importOffice365" class="fx_startcenter">{{ 'DomainAliases' | translate :'{providerName:
                        "Google Workspace"}'}}</span>
                    <ng-template #importOffice365>{{ 'DomainAliases' | translate :'{providerName: "Azure AD"}' }}</ng-template>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.domain}}</mat-cell>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="Enabled">
                    <mat-header-cell class="pl_20" class="fx_space-between-center" *matHeaderCellDef>
                        <span class="fx_startcenter">{{ 'ENABLED' | translate}}</span>
                    </mat-header-cell>
                    <mat-cell class="pl_20" *matCellDef="let element">
                        <mat-checkbox input type="checkbox" name="{{element.domain}}" [(ngModel)]="element.checked"
                            [disabled]="(element.isUsedByOtherDomain || element.isPrimary)"
                            [checked]="element.checked" (change)="toggleDomain(element.domain, $event)">
                            <span *ngIf="element.isPrimary === true">&nbsp;({{ 'Primary_domain' | translate}})</span>
                            <span *ngIf="element.isUsedByOtherDomain === true">&nbsp;({{ 'Used_by_otherdomain' | translate}})</span>
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <mat-table #table *ngIf="!isPermissionGranted && this.isO365" [dataSource]="dataForDomains" class="mat-cell"
                matSortActive="Status" matSortDirection="asc">
                <!-- Checkbox Column -->
                <ng-container matColumnDef="Select">
                    <mat-header-cell class="fxFlex_10" *matHeaderCellDef>
                        <mat-checkbox [disabled]="dataForDomains.data.length=== 0"
                            (change)="$event ? selectAllButton() : null" [checked]="IsAllSelectedProp">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell class="fxFlex_10" *matCellDef="let row;let i=index">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkSelectedUser($event, row)" [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>

                <!-- Progress Column -->
                <ng-container matColumnDef="Domains">
                    <mat-header-cell  class="fx_space-between-center fxFlex_70" *matHeaderCellDef>
                    <span class="fx_startcenter">{{ 'DomainAliases' | translate :'{providerName: "Azure AD"}' }}</span>
                    </mat-header-cell>
                    <mat-cell class="fxFlex_70" *matCellDef="let row;let i=index">
                        <span *ngIf="i === 0">{{row.domain}}</span>
                        <span *ngIf="i > 0" style="display:contents">
                            <input autocomplete="off" matInput value="{{row.domain}}" (focusout)="focusingOut(row, enteredDomain,$event)"
                                (focus)="startEditDomain(row)" class="no-outline text_box_id_card " formControlname="name" type="text"
                                id="enteredDomain" name="enteredDomain" (keypress)="ignoreEnterKey($event)" #enteredDomain
                                placeholder="{{'SubDomain'| translate}}">
                            <div *ngIf="editDomain && row.id===editedRowId" class="id_card_actions"><button [disableRipple]="true"
                                    mat-icon-button type="submit" (mousedown)="validateAndSaveAlias(enteredDomain, row.id,false,row )">
                                    <mat-icon class="ml_6">check</mat-icon>
                                </button>
                                <button type="" (mousedown)="cancelEdit(enteredDomain, i)" mat-icon-button type="cancel">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </div>
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Delete">
                    <mat-header-cell class="fxFlex_20" *matHeaderCellDef>
                    </mat-header-cell>
                    <mat-cell class="fxFlex_20" *matCellDef="let row ; let i= index ">
                        <div style="padding-left:35%;">
                            <button [ngClass]="{'disableDelete': row.enableDeleteButton == false }"
                                *ngIf="row.enableDelete" (click)="deleteRow(row)" class="icon_btn end-center"
                                id='id-nfc-delete' mat-icon-button>
                                <mat-icon aria-label="Remove Checkin entry" tabindex="0">delete</mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="columnsForNotAccepted"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columnsForNotAccepted;"></mat-row>
            </mat-table>

        </div>
    </form>
    <div class="mt_36 fx_end"  *ngIf="dataSource.length > 1 && isPermissionGranted">
        <button mat-button class="primary_btn"
            (click)="saveDomainAliases(); multiDomainForm.form.markAsPristine()" [disabled]="!multiDomainForm.dirty"
            id="space-add-dilogue-button-id">{{'Save' | translate}}</button>
    </div>
</mat-card-content>
</mat-card>
