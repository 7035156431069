import { Component, OnInit } from '@angular/core';

import { BaseComponent } from '../../shared/components/base.component';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
})
export class JobsComponent extends BaseComponent implements OnInit {
  savedPaperData;

  constructor() {
    super();
  }

  ngOnInit() {
    this.appService
      .getUserPaperSaved(this.appService.userDetails.guid)
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        this.savedPaperData = data;
      });
  }
}
