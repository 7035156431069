<div class="container">
    <h2 mat-dialog-title>{{ 'Exportheading'| translate}}</h2>
    <div mat-dialog-content class="mat-typography font_14">
        <p>{{ 'CountOfFiles'| translate:'{total_files: "1"}'}}</p>
        <p>{{ 'DownloadTime'| translate:'{total_mins: "1"}'}}</p>
        <p>{{ 'StayOnPage'| translate}}</p>
    </div>
    <div class="mat-error">
        <ul *ngFor='let err of errors'>
            <li>{{err}}</li>
        </ul>
        <div *ngIf="status">
            <p>{{reportCount - errors.length}} {{ 'TotalDownloaded' | translate}}</p>
        </div>
    </div>
    <div mat-dialog-actions>
        <button class="primary_btn" mat-button (click)="onOk()" id="id-ok">{{"Ok" |translate}}</button>
    </div>
</div>