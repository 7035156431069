import { Injectable } from '@angular/core';
const SYNAPPX_CLOUD_PRINT_ADMIN_PAGE_SIZE = 'SYNAPPX_CLOUD_PRINT_ADMIN_PAGE_SIZE';
const SCP_ADMIN_LOG_PAGE_SIZE = 'SCP_ADMIN_LOG_PAGE_SIZE';
const SCP_SYSTEM_LOG_PAGE_SIZE ='SCP_SYSTEM_LOG_PAGE_SIZE'


@Injectable({
    providedIn: 'root'
})
export class TokenStorageService {
    
    /**
     * @description This method is used to set the page size of Synappx Cloud Print Admin User Page.
     * @param pageSize - Page size to set for Synappx Cloud Print Admin User Page.
     * @param gridName - Service name to set.
     */
    public setSynappxCloudPrintAdminPageSize(pageSize: number, gridName: string) {
        const getItem = localStorage.getItem(SYNAPPX_CLOUD_PRINT_ADMIN_PAGE_SIZE);
        if (getItem && typeof JSON.parse(getItem) == 'object') {
            const jsonObj = JSON.parse(getItem);
            if (jsonObj.hasOwnProperty(gridName)) {
                jsonObj[gridName]['pageSize'] = pageSize;
            } else { jsonObj[gridName] = { 'pageSize': pageSize }; }
            localStorage.setItem(SYNAPPX_CLOUD_PRINT_ADMIN_PAGE_SIZE, JSON.stringify(jsonObj));
        } else {
            const jsonObj = { [gridName]: { 'pageSize': pageSize } };
            localStorage.setItem(SYNAPPX_CLOUD_PRINT_ADMIN_PAGE_SIZE, JSON.stringify(jsonObj));
        }
    }

    /**
     * @description This method is used to get the page size of Synappx Cloud Print Admin User Page.
     * @param gridName - Service name to set.
     * @returns - Page size as number.
     */
    public getSynappxCloudPrintAdminPageSize(gridName: string): number {
        const getItem = localStorage.getItem(SYNAPPX_CLOUD_PRINT_ADMIN_PAGE_SIZE);
        if (getItem != null) {
            const jsonObj = JSON.parse(getItem);
            if (jsonObj[gridName] != null) {
                return jsonObj[gridName]['pageSize'];
            } else { return null; }
        } else { return null; }
    }

    /**
         * @description This method is used to set the page size of Synappx Cloud Print Logs Page.
         * @param pageSize - Page size to set for Synappx Cloud Print Logs Page.
         * @param gridName - Service name to set.
         */
    public setLogPageSize(pageSize: number, gridName: string) {
        const getItem = localStorage.getItem(gridName === 'adminLog' ? SCP_ADMIN_LOG_PAGE_SIZE : SCP_SYSTEM_LOG_PAGE_SIZE);
        if (getItem && typeof JSON.parse(getItem) === 'object') {
            const jsonObj = JSON.parse(getItem);
            if (jsonObj.hasOwnProperty(gridName)) {
                jsonObj[gridName]['pageSize'] = pageSize;
            } else {
                jsonObj[gridName] = { 'pageSize': pageSize };
            }
            localStorage.setItem(gridName === 'adminLog' ? SCP_ADMIN_LOG_PAGE_SIZE : SCP_SYSTEM_LOG_PAGE_SIZE, JSON.stringify(jsonObj));
        } else {
            const jsonObj = { [gridName]: { 'pageSize': pageSize } };
            localStorage.setItem(gridName === 'adminLog' ? SCP_ADMIN_LOG_PAGE_SIZE : SCP_SYSTEM_LOG_PAGE_SIZE, JSON.stringify(jsonObj));
        }
    }

    /**
        * @description This method is used to get the page size of Synappx Cloud Print Logs Page.
        * @param gridName - Service name to set.
        * @returns - Page size as number.
        */
    public getLogPageSize(gridName: string): number {
        const getItem = localStorage.getItem(gridName === 'adminLog' ? SCP_ADMIN_LOG_PAGE_SIZE : SCP_SYSTEM_LOG_PAGE_SIZE);
        if (getItem != null) {
            const jsonObj = JSON.parse(getItem);
            if (jsonObj[gridName] != null) {
                return jsonObj[gridName]['pageSize'];
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}
