import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDynamicItem, IStaticItem } from '../interfaces/AppData';
import { AppDataService } from './app-data.service';


@Injectable({
    providedIn: 'root'
})
export class LocalAppDataService {

    constructor(private _appData: AppDataService) {
    }

    /**
     * Get the static data entry injected by application based on keyname
     * @param name The name of the data (e.g: user_name, )
     * @returns A IStaticItem instance
     */
    public getStatic(name: string): IStaticItem {
        return this._appData.getStatic(name);
    }


    /**
     * Check if the application has inserted the given static item
     * @param name The key-name of the static item
     * @returns true if the static item is inserted by application. false otherwise
     */
    public hasStatic(name: string): boolean {
        return this._appData.hasStatic(name);
    }

    /**
     * gets the list of keys of static items injected from application
     */
    public staticEntries(): Array<string> {
        return this._appData.staticEntries();
    }

    /**
     * Get the dynamic data entry injected by application based on keyname
     * @param name The key-name of the item (e.g: auth_access_token, idp_access_token)
     * @returns A IDynamicItem instance
     */
    public getDynamic(name: string): IDynamicItem {
        return this._appData.getDynamic(name);
    }

    /**
     * Check if the application has inserted the given dynamic item
     * @param name The key-name of the dynamic item
     * @returns true if the dynamic item is inserted by application. false otherwise
     */
    public hasDynamic(name: string): boolean {
        return this._appData.hasDynamic(name);
    }

    /**
     * gets the list of keys of dynamic items injected from application
     */
    public dynamicEntries(): Array<string> {
        return this._appData.dynamicEntries();
    }

    public debug() {
        this._appData.debug();
    }

    /**
     * Get the synappx cloud print user-role in user as string
     */
    public get scpRole(): string {
        return this._appData.getStatic('scp-role').str;
    }

    /**
     * Get the logged in user as string
     */
    public get UserName(): string {
        return this._appData.getStatic('logged-in-user-name').str;
    }
    //For getting the azure_app_client_id
    public get AuthConfig(): any {
        return this._appData.getStatic('auth-config').str;
    }
    //For getting different services admin roles
    public get AdminRoles(): any {
        return this._appData.getStatic('admin-roles').str;
    }
    public get rmmAdminRole(): any {
        return this._appData.getStatic('rmmadmin-roles').str;
    }

    /**
     * Get the logged in user as string
     */
    public get Email(): string {
        return this._appData.getStatic('logged-in-user-email').str;
    }

    /**
     * Get the tenant-tier as string
     */
    public get TenantTier(): string {
        return this._appData.getStatic('tenant-tier').str;
    }

    /**
     * Get the Auth0 Application Type as string
     */
    public get ApplicationType(): string {
        return this._appData.getStatic('auth0-application-type').str;
    }

    /**
     * Get the Auth0 ConnectionName as string
     */
    public get ConnectionName(): string {
        return this._appData.getStatic('auth0-connection-name').str;
    }

    /**
     * Get the logged in user as string
     */
    public get Upn(): string {
        return this._appData.getStatic('logged-in-user-upn').str;
    }

    /**
     * Get the tenant information at login time
     */
    public get TenantInfoAtLogin(): any {
        return this._appData.getStatic('tenant-info').obj;
    }

    /**
     * Get the viewGo value from auth-service of admin portal.
     * True if the Synappx Go admin privileges available for this user
     */
    public get ViewGo(): boolean {
        return this._appData.getStatic('auth-view-go').obj;
    }

    /**
     * Get the viewRMM value from auth-service of admin portal.
     * True if the Synappx Manage admin privileges available for this user
     */
    public get ViewRMM(): boolean {
        return this._appData.getStatic('auth-view-rmm').obj;
    }

    /**
     * Get the viewGo value from auth-service of admin portal.
     * True if the Synappx Go admin privileges available for this user
     */
    public get ViewMeeting(): boolean {
        return this._appData.getStatic('auth-view-meeting').obj;
    }

    /**
     * Get the viewScp value from auth-service of admin portal.
     * True if the Synappx Cloud Print admin privileges available for this user
     */
    public get ViewScp(): boolean {
        return this._appData.getStatic('auth-view-scp').obj
    }

    /**
     * Get the browserLang value from auth-service of admin portal.
     */
    public browserLang():any{
        return this._appData.getDynamic('app-Lang').obj
    }


    /**
     * Get the getLanguage from dateformate service,getBrowserLang() check the two-letter language code and getBrowserCultureLang() check the language and region.
     */
    public getLanguage():any{
        return this._appData.getDynamic('browser-language-and-region').obj
    }

    /**
     * Get an observable that resolves to an object that auth information
     */
    public usingAuthInfo(): Observable<any> {
        return this._appData.getDynamic('auth-info').obj;
    }

    /**
     * Get an observable that resolves as idp access_token string
     */
    public usingIdpToken(): Observable<any> {
        return this._appData.getDynamic('idp-token').available;
    }

    public usingAuthResult(): Observable<any> {
        return this._appData.getDynamic('auth-result').available;
    }

    /**
    * Get the home-domain as string
    */
    public get HomeDomain(): string {
        return this._appData.getStatic('home-domain').str;
    }
};
