import {NativeDateAdapter, MatDateFormats } from '@angular/material/core';
import  dayjs from 'dayjs';
import format from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/en';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/sv';
import 'dayjs/locale/fi';
import 'dayjs/locale/nb';
import 'dayjs/locale/pl';
import 'dayjs/locale/it';
import 'dayjs/locale/es';
import 'dayjs/locale/da';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
dayjs.extend(format);

export class AppDateAdapter extends NativeDateAdapter {
    
    format(date: Date, displayFormat: Object): string {
        if (displayFormat === 'input') {
            const day: string = date.getDate().toString();
            const month: string = (date.getMonth() + 1).toString();
            const year = date.getFullYear();
            const lang = this.getBrowserLanguage(navigator.language);
            dayjs.locale(lang);
            if (lang == 'en' || lang == 'en-GB' || lang == 'sv') {
                const datePickerDate = dayjs(`${month}/${day}/${year}`).format('l');
                return datePickerDate;
            } else if (lang == 'nl') {
                const datePickerDate = dayjs(`${month}/${day}/${year}`).format('DD.MM.YYYY');
                return datePickerDate;
            } else {
                const datePickerDate = dayjs(`${month}/${day}/${year}`).format('L');
                return datePickerDate;
            }
        }
        const month = this.getMonthNames('short')[this.getMonth(date)];
        const year = this.getYear(date);
        return (month + ' ' + year);
    }

    private getBrowserLanguage(lang: string) {
        let langCode = this.langCultureConversion(lang);
        let supportedLanguages = ['en'];
            // Change the list of supported languages for EU stacks
            supportedLanguages = ['da', 'de', 'en-GB', 'es', 'fi', 'fr', 'it', 'nl', 'nb', 'pl', 'sv'];
        
        // All norwegian cultures default to 'nb'
        if (['nb', 'no', 'nn'].includes(langCode)) {
            langCode = 'nb';
        }
        // All spanish languages default to 'es'
        const spanishLangCode = ['ca', 'es'];
        if (spanishLangCode.includes(langCode)) {
            langCode = 'es';
        }
        let result = 'en';
        if (supportedLanguages.includes(langCode)) {
            // If language+culture combo is in supported list use it
            result = langCode;
        }
        return result;
    }

    langCultureConversion(lang) {
        return lang != "en-GB" ? lang.split("-")[0] : "en-GB";
    }
}

export const APP_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'short' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    },
};
