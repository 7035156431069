// The file contents for the current environment will overwrite these during build.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    AuthConfig: {
        clientID: 'CCx4NT7N8vahyED0w14weZ1qTNrInZ5H',
        domain: 'ssp-sharpb2bcloud.auth0.com', // Domain Path: Auth0 -> Applications -> Settings -> Domain
        callbackURL: location.origin + '/#/callback',   // Allowed Callback URLs Path: Auth0 -> Applications -> Settings -> Allowed Callback URLs
        apiUrl: 'https://marvel-portal.invalid', // Audience Path :Auth0 -> API -> Settings -> General Settings -> Identifier
        azure_app_client_id: 'ff9e72c5-6331-466c-8228-6757639d6fa7' // AAD -> App Registration -> Application (client)
    },
    scpCommonApiBaseUrl: {
        url: 'https:///api',
    },
    sspLicenseApi: {
        url: 'https://ssp-api.sharpb2bcloud.com/lic/v1'
    },
    rmpEndPoint: {
        url: 'https://ssp-api.sharpb2bcloud.com/rmi/v1/rooms/'
    },
    rmpEndPointToken: {
        url: 'https://ssp-api.sharpb2bcloud.com/rmi/v1/token'
    },
    rmpEndPointUser: {
        url: 'https://ssp-api.sharpb2bcloud.com/rmi/v1'
    },
    deviceEndPoint: {
        url: 'https://ssp-api.sharpb2bcloud.com/rmi/v1/devices',
    },
    rmpEndPointLicense: {
        url: 'https://ssp-api.sharpb2bcloud.com/lic/v1/license'
    },
    rmpEndPointSubscription: {
        url: 'https://ssp-api.sharpb2bcloud.com/lic/v1/subscription'
    },
    rmpEndPointTenant: {
        url: 'https://ssp-api.sharpb2bcloud.com/lic/v1/tenantInfo'
    },
    logReportUrl: {
        url: 'https://ssp-api.sharpb2bcloud.com/rmi/v1/graph/so'
    },
    exportlogUrl: {
        url: 'https://ssp-api.sharpb2bcloud.com/rmi/v1/csv/so',
    },
    rmpEndPointNotificationPreferences: {
        url: 'https://ssp-api.sharpb2bcloud.com/marvel/v1/subscriptions/',
    },
    domainsURL: {
        url: 'https://ssp-api.sharpb2bcloud.com/lic/v1/domainsAliasInfo',
    },
    sspCommonEndPoint: {
        url: 'https://ssp-api.sharpb2bcloud.com/rmi/v1'
    },
    marvelEndPoint: {
        url: 'https://ssp-api.sharpb2bcloud.com/marvel/v1'
    },
    marvelAgentDownloadEndPoint: {
        url: 'https://ssp-agentdl.sharpb2bcloud.com'
    },
    marvelAgentLogErrorsPoint: {
        url: 'https://ssp-api.sharpb2bcloud.com/errorlog/v1'
    },
    selectProviderURL: {
        url: 'https://ssp-api.sharpb2bcloud.com/lic/v1/events/tenantCreation',
    },
    logstashUrl: {
        url: 'https://ssp-api.sharpb2bcloud.com/rmi/v1/logstash',
    },
    auditLogElasticSearch: {
        url: 'https://ssp-api.sharpb2bcloud.com/rmi/v1/elasticsearch',
    },
    vender: [
        { value: 'ClickShare', viewValue: 'ClickShare' },
        { value: 'Miracast', viewValue: 'Miracast' },
        { value: 'MirrorOp', viewValue: 'MirrorOp' },
        { value: 'Google Cast', viewValue: 'Google Cast' }
    ],
    xFunctionsKey: {
        value: 'O4BAFjX1zI3d5NDNSESigG6nqyrh7mwBYwbQopcRGiBokXxPw1XB3A=='
    },
    go_app_store: {
        url: 'https://apps.apple.com/us/app/synappx-go/id1471039167'
    },
    go_play_store: {
        url: 'https://play.google.com/store/apps/details?id=jp.co.sharp.bs.smartoffice.synappxgomobile'
    },
    googleScopes: [
        'https://www.googleapis.com/auth/admin.directory.domain.readonly',
        'https://www.googleapis.com/auth/admin.directory.group.readonly',
        'https://www.googleapis.com/auth/admin.directory.resource.calendar.readonly',
        'https://www.googleapis.com/auth/admin.directory.user.readonly',
        'https://www.googleapis.com/auth/userinfo.profile'
    ],
    googleTagEnable: 'false',
    googleTagId: '',
    stackRegion: 'US',
    privacyUrl: 'https://business.sharpusa.com/synappx-support/about/privacy',
    termsUseUrl: 'https://business.sharpusa.com/synappx-support/about/termsofuse',
    helpUrl: 'https://business.sharpusa.com/synappx-support',
    castingSystemRequirementUrl: "https://business.sharpusa.com/synappx-support/Synappx-Meeting/System-Requirements",
    roomLimit : 100,
    version: '4.13.0-beta.1-SNAPSHOT-20240802-142111',
    oldAgentVersion: '1.0.64.0',
    userLimit: 5000,
    retryIntervalForHttp429: 1000,
    checkInStartDate: new Date(2020, 6, 1),
    agentMinVersion: { 'mfpAgent': '3.1.1008.0', 'displayAgent': '3.1.1021.0' },
    optional: {
        feat_v33: 'true',
        feat_v36: 'true',
        // flag used for rmm version check
        feat_RMM14: 'true',
        feat_v341: 'true',
        feat_scpv1: 'false',
    },
    show_sharp_start: 'true',
    sharpstart_logout_url: 'https://startrmm.sharpusa.net/DesktopModules/Inventua%20-%20Custom%20Login%20Services/1.0/Oauth2Logout.ashx'
};
