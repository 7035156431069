import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogInfo } from '../../interfaces/dialog';

@Component({
    selector: 'lib-confirm-action-dialog',
    templateUrl: './confirm-action-dialog.component.html',
    styleUrls: ['./confirm-action-dialog.component.scss']
})
export class ConfirmActionDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA)
    public data: DialogInfo,
        private mdDialogRef: MatDialogRef<ConfirmActionDialogComponent>) { }

    ngOnInit() {
        console.log(this.data);
    }

    public cancel() {
        this.close(0);
    }

    public close(value) {
        this.mdDialogRef.close(value);
    }

    public confirm() {
        this.close(1);
    }

    @HostListener("keydown.esc")
    public onEsc() {
        this.close(0);
    }
}
