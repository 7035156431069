import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LocalAppDataService } from '../../shared/services/local-app-data.service';
import { TenantService } from '../../shared/services/ssp/tenant.service';
import { NotificationService } from '../../shared/services/ssp/notification.service';
import { LogHandlerService } from '../../shared/services/ssp/log-handler.service';
import { ITenant } from '../../shared/interfaces/User';
import { emojieValidator, noWhitespaceValidator } from '../customValidator';
import { CloudPrintEnvService } from '../../shared/services/cloud-print-env.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-tenant',
    templateUrl: './tenant.component.html',
    styleUrls: ['./tenant.component.scss']
})
export class TenantComponent implements OnInit {
    tenantForm: UntypedFormGroup;
    tenant;
    ITenant: ITenant;
    oldTenantName: string;
    tenantName;
    currentTenantInfo;
    tenantNameMax;
    activeTenant: string;
    tenantList;
    authConnection: string;
    stack = this.scpEnvService.stackRegion;
    isSaveDisabled: boolean = true;

    constructor(
        private tenantFB: UntypedFormBuilder,
        private appService: LocalAppDataService,
        private tenantService: TenantService,
        private notificationService: NotificationService,
        private logHandlerService: LogHandlerService,
        private scpEnvService: CloudPrintEnvService,
        private translateService: TranslateService

    ) { this.ITenant = this.scpEnvService }

    ngOnInit(): void {
        if ((this.stack === 'US')) {
            this.tenantNameMax = "36";
        } else {
            this.tenantNameMax = "85";
        }
        this.activeTenant = this.appService.TenantInfoAtLogin;
        this.currentTenantDetails();
        this.getTenantInfo();
        this.formBuilder();
    }

    getTenantInfo() {
        this.tenantService.getGuestTenantInfo(this.authConnection).subscribe((result) => {
            if (result) {
                this.currentTenantInfo = result[0];
                this.tenantName = this.currentTenantInfo.renamedCustomerName ? this.currentTenantInfo.renamedCustomerName : this.currentTenantInfo.customerName;
                this.formBuilder();
            }
        });
    }

    formBuilder() {
        this.tenantForm = this.tenantFB.group({
            tenant: new UntypedFormControl(this.tenantName, [Validators.required, noWhitespaceValidator, emojieValidator]),
        });
        this.tenant = this.tenantForm.get('tenant') as UntypedFormControl;
    }

    validateUserName() {
        this.tenantName = this.tenantForm.get('tenant').value.toString().trim();
        this.currentTenantDetails();
        this.isSaveDisabled = (this.tenant.invalid) || (this.tenantName.length == 0) || (this.oldTenantName === this.tenantName) ? true : false;
    }

    currentTenantDetails() {
        this.tenantList = localStorage.getItem('currentTenantName');
        this.tenantList = JSON.parse(this.tenantList);
        this.oldTenantName = this.tenantList.tenantName;
        this.authConnection = this.tenantList.authCon;
    }

    confirmAdd() {
        this.currentTenantInfo.renamedCustomerName = this.tenantName;
        const tenantInfo = {
            renamedTenant: this.currentTenantInfo.renamedCustomerName,
            auth0ConnectionName: this.currentTenantInfo.auth0ConnectionName
        }
        this.tenantService.updateTenantName(tenantInfo).subscribe(data => {
            if (data) {
                const tenantDetails = {
                    tenantName: this.currentTenantInfo.renamedCustomerName,
                    authCon: this.currentTenantInfo.auth0ConnectionName
                }
                const currentTenant = JSON.stringify(tenantDetails);
                this.tenantService.tenantNameSub.next(currentTenant);
                this.notificationService.showNotification(this.translateService.instant('TenantUpdate'));
                const details = {
                    tenantName: this.tenantName,
                    authCon: this.currentTenantInfo.auth0ConnectionName
                }
                localStorage.setItem('currentTenantName', JSON.stringify(details));
                this.isSaveDisabled = true;
            }
        });
        this.logHandlerService.addAdminLog('Tenant', 'Tenant name changed', 'MAA00045', `Tenant name changed to ${this.currentTenantInfo.renamedCustomerName}`);
    }
}
