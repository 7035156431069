import { Component, OnInit } from '@angular/core';

import { SCPRouterLinks } from '../../models/router-model';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-offline',
  templateUrl: './offline.component.html',
})
export class OfflineComponent extends BaseComponent implements OnInit {
  nextRouterLink = this.window.history.state?.fromPage || SCPRouterLinks.root;

  ngOnInit() {
    this.dialog.closeAll();

    this.window.sessionStorage.removeItem('currentUrl');
  }
}
